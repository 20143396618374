import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Chip,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    TextField,
    Tooltip,
} from '@material-ui/core';
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete/Autocomplete';
import { delay } from 'lodash';

import { CheckFilledSquareIcon, SquareIcon } from '../../../assets/icons/icons';

const useStyles = makeStyles(() => ({
    inputRoot: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    popper: {
        zIndex: 9999,
    },
}));

export interface IAutoCompleteProps<T> {
    /**
     * Array of options.
     */
    options: any[];
    /**
     * onChange function
     */
    onChange?: (event: object, value: T | T[]) => void;
    /**
     * This prop is used to help implement the accessibility logic.
     * If you don't provide this prop. It falls back to a randomly generated id.
     */
    id?: string;
    /**
     * The value must have reference equality with the option in order to be selected.
     * You can customize the equality behavior with the getOptionSelected prop.
     */
    value?: any;
    /**
     * The default input value. Use when the component is not controlled.
     */
    defaultValue?: any[];
    /**
     * If true, the portion of the selected suggestion that has not been typed by the user,
     * known as the completion string, appears inline after the input cursor in the textbox.
     * The inline completion string is visually highlighted and has a selected state.
     */
    autoComplete?: boolean;
    /**
     * If true, the Autocomplete is free solo, meaning that the user input is not bound to provided options.
     */
    freeSolo?: boolean;
    /**
     * Used to determine if an option is selected, considering the current value. Uses strict equality by default
     */
    getOptionSelected?: (option: T, value: T) => boolean;
    /**
     * A filter function that determines the options that are eligible
     */
    filterOptions?: (options: T[], state: object) => undefined;
    /**
     * number of options that needs to be displayed on text field for multi selecet
     */
    optionsToDisplay?: number;
    /**
     * If true, the component is in a loading state.
     */
    loading?: boolean;
    /**
     * If true, the selected option becomes the value of the input when the Autocomplete
     * loses focus unless the user chooses a different option or changes the character string in the input
     */
    autoSelect?: boolean;
    /**
     * If true, the first option is automatically highlighted
     */
    autoHighlight?: boolean;
    /**
     * Used to determine the string value for a given option.
     * It's used to fill the input (and the list box options if renderOption is not provided).
     */
    getOptionLabel?: (option: T) => string;
    /**
     * Callback fired when the input value changes.
     */
    onInputChange?: (
        event: React.ChangeEvent<{}>,
        value: any,
        reason: any
    ) => void;
    /**
     * input field label
     */
    label?: string;
    /**
     * placement of the tooltip on hover the number of items selected
     */
    tooltipPlacement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    /**
     * Label for select all checkbox
     */

    selectAllLabel?: string;
    /**
     * Text to display when there are no options.
     */
    noOptionsText?: string;
    /**
     * Override the default text for the clear icon button
     */
    clearText?: string;
    /**
     * Render the input.

     */
    renderInput?: (params: Object) => ReactNode;
    /**
     * If true, value must be an array and the menu will support multiple selections.
     */
    multiple?: boolean;
    /**
     * If true, component will be disabled
     */
    disabled?: boolean;
    /**
     * If true, Autocomplete options will be groupable
     */
    groupable?: boolean;
    /**
     * Autocomplete options will be groupable with this field
     */
    groupableField?: string;
    /**
     * If true, Autocomplete will show error
     */
    error?: boolean;
    /**
     * Autocomplete helper text
     */
    helperText?: string;
    /**
     * If true, Autocomplete will be required
     */
    required?: boolean;
    /**
     * Callback fired when the input value changes.
     */
    OnOptionsChange?: (options: T[]) => void;

    disablePortal?: boolean;

    showAllOption?: boolean;

    flag?: boolean;
    siteComponent?: boolean;
    displaySiteNameDropDown?: boolean;
    siteType?: string;
    siteTypeLabel?: string;
    clearInputValues?: boolean;
    size?: 'small' | 'medium' | undefined;
    autoAddOptionOnFind?: boolean;
}

export const AutoComplete: FC<IAutoCompleteProps<any>> = ({
    options,
    onChange,
    id,
    value,
    defaultValue,
    autoComplete = true,
    freeSolo = false,
    getOptionSelected,
    filterOptions,
    optionsToDisplay = 1,
    loading = false,
    autoSelect = false,
    autoHighlight = false,
    getOptionLabel,
    onInputChange,
    label = 'TEST',
    tooltipPlacement = 'bottom',
    selectAllLabel = 'Select All',
    noOptionsText,
    clearText = 'Clear',
    renderInput,
    multiple = true,
    disabled,
    groupable,
    groupableField,
    error = false,
    helperText = '',
    required = false,
    disablePortal = true,
    OnOptionsChange,
    siteType,
    size = 'medium',
    autoAddOptionOnFind = true,
    ...rest
}) => {
    const autoCompleteClasses = useStyles();
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const allSelected = options.length === selectedOptions.length;
    const handleToggleOption = (selectedOptions: any) => {
        let uniqueOptions = Array.from(
            new Set(selectedOptions.map((a: any) => a.id))
        ).map((id) => {
            return selectedOptions.find((a: any) => a.id === id);
        });
        setSelectedOptions(() => [...uniqueOptions]);
    };
    const handleClearOptions = () => setSelectedOptions(() => []);
    const styles = makeStyles(() => ({
        chipRoot: {
            backgroundColor: '#f4f7fa',
            height: 25,
            minWidth: 75,
            maxWidth: 250,
            display: 'flex',
            gap: 10,
        },
        label: {
            textOverflow: 'ellipsis',
            paddingRight: '0px !important',
        },
    }));

    useEffect(() => {
        setSelectedOptions(defaultValue ? defaultValue : []);
    }, [defaultValue]);

    useEffect(() => {
        if (OnOptionsChange && selectedOptions?.length > 0) {
            OnOptionsChange(selectedOptions);
        }
    }, [selectedOptions]);

    const handleSelectAll = (isSelected: boolean) => {
        if (isSelected) {
            setSelectedOptions(options);
        } else {
            handleClearOptions();
        }
    };
    const handleToggleSelectAll = () => {
        handleSelectAll && handleSelectAll(!allSelected);
    };

    const handleChange = (event: any, selectedOptions: any) => {
        if (multiple) {
            if (
                selectedOptions.find(
                    (option: any) => option.id === 'select-all'
                )
            ) {
                handleToggleSelectAll();
                let result = [];
                result = options.filter((el: any) => el.id !== 'select-all');
                if (onChange) {
                    return onChange(event, !allSelected ? result : []);
                }
            } else {
                handleToggleOption(selectedOptions);
                if (onChange) {
                    return onChange(event, selectedOptions);
                }
            }
        } else {
            if (onChange) {
                return onChange(event, selectedOptions);
            }
        }
    };

    const classes = styles({});
    const getOptionLabels = (option: any) => {
        if (getOptionLabel) {
            if (option.id === 'select-all') {
                return option.description;
            } else {
                return getOptionLabel(option);
            }
        } else {
            return option.description;
        }
    };
    const autoCompleteToolTip = (value: any) => {
        return (
            <List dense={true}>
                {value
                    .slice(optionsToDisplay)
                    .map((option: any, index: number) => {
                        return (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={getOptionLabels(option)}
                                />
                            </ListItem>
                        );
                    })}
            </List>
        );
    };
    const optionRenderer = (option: any, state: any) => {
        const selectAllProps =
            options.length > 0 && option.id === 'select-all' // To control the state of 'select-all' checkbox
                ? {
                      checked: allSelected,
                  }
                : {};
        return (
            <>
                {multiple && (
                    <Checkbox
                        color="primary"
                        icon={<SquareIcon fontSize="small" />}
                        checkedIcon={<CheckFilledSquareIcon fontSize="small" />}
                        checked={state.selected}
                        {...selectAllProps}
                    />
                )}
                {getOptionLabels(option)}
            </>
        );
    };
    const renderInputField = (params: any) => {
        if (renderInput) {
            return renderInput(params);
        } else {
            return (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    placeholder=""
                    error={error}
                    helperText={helperText}
                    required={required}
                    onChange={handleChange}
                    style={{
                        width: '100%',
                    }}
                />
            );
        }
    };
    const filter = createFilterOptions();

    const CustomChipComponent = function (chipProps: any) {
        const chipRef = useRef<HTMLDivElement>();
        const [showTooltip, setShowTooltip] = useState<boolean>(false);

        useEffect(function () {
            delay(function () {
                const scrollWidth =
                    chipRef.current?.querySelector?.('span')?.scrollWidth ?? 0;
                const offsetWidth =
                    chipRef.current?.querySelector?.('span')?.offsetWidth ?? 0;
                setShowTooltip(scrollWidth > offsetWidth);
            }, 500);
        }, []);

        return (
            <Tooltip
                interactive={showTooltip}
                arrow={showTooltip}
                title={showTooltip ? chipProps.label : ''}
                placement={'top-end'}
                classes={{ popper: autoCompleteClasses.popper }}
            >
                <div>
                    <Chip
                        innerRef={chipRef}
                        variant="default"
                        classes={{
                            root: classes.chipRoot,
                            label: classes.label,
                        }}
                        {...chipProps}
                    />
                </div>
            </Tooltip>
        );
    };

    return (
        <React.Fragment>
            {multiple ? (
                <>
                    {groupable ? (
                        <Autocomplete
                            multiple={multiple}
                            id={id}
                            freeSolo={freeSolo}
                            autoSelect={autoSelect}
                            autoHighlight={autoHighlight}
                            options={options}
                            clearText={clearText}
                            value={selectedOptions}
                            loading={loading}
                            defaultValue={defaultValue}
                            autoComplete={true}
                            disabled={disabled}
                            disableCloseOnSelect
                            onInputChange={onInputChange}
                            noOptionsText={noOptionsText}
                            filterOptions={(options: any, state: any) => {
                                if (options.length > 0) {
                                    const filtered = filter(options, state);
                                    if (
                                        options.length >= 2 &&
                                        filtered &&
                                        filtered.length === 1
                                    ) {
                                        handleToggleOption([
                                            ...selectedOptions,
                                            ...filtered,
                                        ]);
                                    }
                                    return [
                                        {
                                            description: selectAllLabel,
                                            id: 'select-all',
                                        },
                                        ...filtered,
                                    ];
                                } else {
                                    const filtered = filter(options, state);
                                    return [...filtered];
                                }
                            }}
                            getOptionSelected={(option, value) =>
                                option.id === value.id
                            }
                            onChange={handleChange}
                            renderTags={(value: string[], getTagProps) => {
                                const numTags = value.length;
                                return (
                                    <React.Fragment>
                                        {value.slice(0, optionsToDisplay).map(
                                            (option: any, index: number) =>
                                                option.description && (
                                                    <CustomChipComponent
                                                        key={`${option.description}-${index}`}
                                                        label={getOptionLabels(
                                                            option
                                                        )}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                    />
                                                )
                                        )}
                                        {numTags > optionsToDisplay && (
                                            <Tooltip
                                                title={autoCompleteToolTip(
                                                    value
                                                )}
                                                arrow
                                                placement={tooltipPlacement}
                                                interactive
                                            >
                                                <span>{` +${
                                                    numTags - optionsToDisplay
                                                }`}</span>
                                            </Tooltip>
                                        )}
                                    </React.Fragment>
                                );
                            }}
                            renderOption={optionRenderer}
                            getOptionLabel={getOptionLabels}
                            renderInput={renderInputField}
                            groupBy={(option) => option[`${groupableField}`]}
                            {...rest}
                            classes={{
                                inputRoot: autoCompleteClasses.inputRoot,
                            }}
                        />
                    ) : (
                        <Autocomplete
                            multiple={multiple}
                            id={id}
                            freeSolo={freeSolo}
                            autoSelect={autoSelect}
                            autoHighlight={autoHighlight}
                            options={options}
                            clearText={clearText}
                            value={selectedOptions}
                            size={size}
                            loading={loading}
                            defaultValue={defaultValue}
                            autoComplete={true}
                            disabled={disabled}
                            disableCloseOnSelect
                            onInputChange={onInputChange}
                            noOptionsText={noOptionsText}
                            filterOptions={(options: any, state: any) => {
                                if (options.length > 0) {
                                    const filtered = filter(options, state);
                                    if (
                                        options.length >= 2 &&
                                        filtered &&
                                        filtered.length === 1
                                    ) {
                                        if (autoAddOptionOnFind) {
                                            handleToggleOption([
                                                ...selectedOptions,
                                                ...filtered,
                                            ]);
                                        }
                                    }
                                    return [
                                        {
                                            description: selectAllLabel,
                                            id: 'select-all',
                                        },
                                        ...filtered,
                                    ];
                                } else {
                                    const filtered = filter(options, state);
                                    return [...filtered];
                                }
                            }}
                            getOptionSelected={(option, value) =>
                                option.id === value.id
                            }
                            onChange={handleChange}
                            renderTags={(value: string[], getTagProps) => {
                                const numTags = value.length;
                                return (
                                    <React.Fragment>
                                        {value.slice(0, optionsToDisplay).map(
                                            (option: any, index: number) =>
                                                option.description && (
                                                    <CustomChipComponent
                                                        key={`${option.description}-${index}`}
                                                        label={getOptionLabels(
                                                            option
                                                        )}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                    />
                                                )
                                        )}
                                        {numTags > optionsToDisplay && (
                                            <Tooltip
                                                title={autoCompleteToolTip(
                                                    value
                                                )}
                                                arrow
                                                placement={tooltipPlacement}
                                                interactive
                                            >
                                                <span>{` +${
                                                    numTags - optionsToDisplay
                                                }`}</span>
                                            </Tooltip>
                                        )}
                                    </React.Fragment>
                                );
                            }}
                            renderOption={optionRenderer}
                            getOptionLabel={getOptionLabels}
                            renderInput={renderInputField}
                            {...rest}
                            classes={{
                                inputRoot: autoCompleteClasses.inputRoot,
                            }}
                        />
                    )}
                </>
            ) : (
                <Autocomplete
                    disablePortal={disablePortal}
                    id={id}
                    options={options}
                    key={siteType ? siteType : ''}
                    onChange={handleChange}
                    getOptionLabel={getOptionLabels}
                    renderInput={renderInputField}
                    style={{
                        width: '100%',
                    }}
                    disabled={disabled}
                    {...rest}
                    classes={{
                        inputRoot: autoCompleteClasses.inputRoot,
                    }}
                />
            )}
        </React.Fragment>
    );
};
