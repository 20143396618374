import React, { FC } from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Grid } from '@material-ui/core';

import { ChevronRightIcon } from '../../assets/icons';

const Breadcrumbs: FC = ({ children }) => {
    return (
        <Grid item>
            <MuiBreadcrumbs
                separator={<ChevronRightIcon size="20" />}
                aria-label="breadcrumb"
            >
                {children}
            </MuiBreadcrumbs>
        </Grid>
    );
};

export default Breadcrumbs;
