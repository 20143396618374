import React, { useCallback, useContext, useEffect } from 'react';
import { Tour } from 'react-shepherd';
import { findLast, isNil, isString } from 'lodash';

import { useProfile } from '../../core/Providers';
import ModuleInfoContext from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import { createTaskForFinishedTour } from '../../layouts/Main/ToursApi';

import {
    ISteps,
    ITourProgressType,
    ITourStatusRegisterType,
    ITourStepStatusRegisterType,
    TourStatusEnum,
} from './types';
import {
    getTourEntry,
    setTourExited,
    setTourFinished,
    updateTourStatus,
} from './util';

interface IProps {
    tourContext: React.Context<Tour | null>;
    steps: ISteps[];
    tourName: string;
}

/**
 * @hook
 * @useTourSettings
 * Hook that defines the operations to be performed to update the tour/step status
 * based on event propagated from the tour action buttons.
 *
 * @returns context SpepherdContext
 *
 * ```typescript
 * useTourSettings({tourContext, steps});
 * ```
 */
const useTourSettings = ({ tourContext, steps }: IProps) => {
    const context = useContext(tourContext);
    const { tours, updateTours, apimUrl } = useContext(ModuleInfoContext);
    const { user } = useProfile();

    const initializeTour = () => {
        if (context && steps?.length) {
            const tourName = ((context as any)?.id ?? '').split?.('--')?.[0];
            const currentTour = tours?.find((tour: ITourStatusRegisterType) => {
                return tour.tourname === tourName;
            });
            if (!isNil(currentTour)) {
                let exitIndex = 0;
                exitIndex = currentTour.steps.findIndex(
                    (step: ITourStepStatusRegisterType) => {
                        return step.status === TourStatusEnum.EXIT;
                    }
                );
                if (steps.length > 0) {
                    const districtOnboardingRole = user?.Roles.includes(
                        'District Onboarding'
                    );
                    const isTourNotFinished = !currentTour.isFinished;

                    const isDistrictOnboardingTour =
                        districtOnboardingRole && isTourNotFinished;

                    const isWorkspaceTour =
                        tourName === 'Workspace' && isTourNotFinished;
                    const isProfilePageTour =
                        tourName === 'Profile Page' && isTourNotFinished;

                    if (
                        isDistrictOnboardingTour ||
                        isWorkspaceTour ||
                        isProfilePageTour
                    ) {
                        if (exitIndex === 0 || exitIndex === -1) {
                            context?.start();
                        }

                        if (exitIndex > 0) {
                            context?.show(exitIndex);
                        }
                    }
                }
            }
        }
    };

    const saveSkipTour = useCallback(
        function (tourname: string, stepName: string, type: TourStatusEnum) {
            const updatedTours = tours?.map((tour: ITourStatusRegisterType) => {
                if (tour.tourname === tourname) {
                    const step: number =
                        tour?.steps.findIndex?.(
                            ({ name }: ITourStepStatusRegisterType) =>
                                name === stepName
                        ) ?? -1;
                    if (type === TourStatusEnum.SKIPPED) {
                        tour.isExited = true;
                        tour.steps.forEach(
                            (
                                savedStep: ITourStepStatusRegisterType,
                                index: number
                            ) => {
                                if (index < step) {
                                    savedStep.status = TourStatusEnum.VISITED;
                                }
                                if (index === step) {
                                    savedStep.status = TourStatusEnum.SKIPPED;
                                }
                                if (index > step) {
                                    savedStep.status =
                                        TourStatusEnum.NOT_VISITED;
                                }
                            }
                        );
                    } else if (type === TourStatusEnum.EXIT) {
                        try {
                            tour.isDisabled = true;
                            tour.disabledAt = step;
                            tour.isExited = true;
                            tour.steps.forEach(
                                (
                                    savedStep: ITourStepStatusRegisterType,
                                    index: number
                                ) => {
                                    if (index < step) {
                                        savedStep.status =
                                            TourStatusEnum.VISITED;
                                    }
                                    if (index === step) {
                                        savedStep.status = TourStatusEnum.EXIT;
                                    }
                                    if (index > step) {
                                        savedStep.status =
                                            TourStatusEnum.NOT_VISITED;
                                    }
                                }
                            );
                        } catch (e) {
                            console.log('exception on exit', e);
                        }
                    }
                }
                return tour;
            });
            updateTours?.(updatedTours);
        },
        [tours, updateTours]
    );

    const saveOnFinish = async function (tourname: string) {
        const currentTour = getTourEntry(tourname);
        const finishedTours = tours?.map((tour: ITourStatusRegisterType) => {
            if (tour.tourname === currentTour?.tourname) {
                tour.disabledAt = currentTour.disabledAt;
                tour.isDisabled = currentTour.isDisabled;
                tour.isFinished = true;
                tour.steps.forEach((step: ITourStepStatusRegisterType) => {
                    step.status = TourStatusEnum.FINISH;
                });
            }
            return tour;
        });
        await updateTours?.(finishedTours);
    };

    const tourProgressListener = useCallback(
        function (event: CustomEvent<ITourProgressType>) {
            const {
                detail: { tourName, status, step },
            } = event;
            if (status === TourStatusEnum.NEXT) {
                updateTourStatus(
                    tourName,
                    step,
                    TourStatusEnum.VISITED,
                    TourStatusEnum.FINISH
                );
                context?.next();
            } else if (status === TourStatusEnum.BACK) {
                updateTourStatus(
                    tourName,
                    step,
                    TourStatusEnum.VISITED,
                    TourStatusEnum.FINISH
                );
                context?.back();
            } else if (status === TourStatusEnum.EXIT) {
                updateTourStatus(
                    tourName,
                    step,
                    TourStatusEnum.EXIT,
                    TourStatusEnum.VISITED
                );
                setTourExited(tourName);
                saveSkipTour(tourName, step, TourStatusEnum.EXIT);
                context?.cancel();
            } else if (status === TourStatusEnum.FINISH) {
                updateTourStatus(
                    tourName,
                    step,
                    TourStatusEnum.VISITED,
                    TourStatusEnum.FINISH
                );
                const currentStep = getTourEntry(tourName)?.steps;
                if (currentStep) {
                    const lastStep = findLast(
                        currentStep,
                        (item) => item.status === 'FINISH'
                    );
                    const stepInfo = steps.find(
                        (step) => step.id === lastStep?.name
                    );
                    createTaskForFinishedTour({
                        url: apimUrl,
                        title: tourName,
                        description:
                            stepInfo &&
                            isString(stepInfo?.text) &&
                            !isNil(stepInfo?.text)
                                ? stepInfo?.text
                                : stepInfo?.title ?? '',
                        dueDate: new Date().toISOString(),
                    });
                }
                setTourFinished(tourName, step);
                saveOnFinish(tourName);
                context?.complete();
            } else if (status === TourStatusEnum.STARTED) {
                updateTourStatus(
                    tourName,
                    step,
                    TourStatusEnum.STARTED,
                    TourStatusEnum.VISITED
                );
                context?.next();
            } else if (status === TourStatusEnum.SKIPPED) {
                updateTourStatus(
                    tourName,
                    step,
                    TourStatusEnum.SKIPPED,
                    TourStatusEnum.VISITED
                );
                saveSkipTour(tourName, step, TourStatusEnum.SKIPPED);
                context?.cancel();
            } else if (status === TourStatusEnum.NOT_VISITED) {
                context?.hide();
            }
        } as any,
        [
            tours,
            updateTours,
            saveSkipTour,
            saveOnFinish,
            context,
            updateTourStatus,
            setTourFinished,
            setTourExited,
        ]
    );

    useEffect(function () {
        return () => {
            context?.cancel();
        };
    }, []);

    useEffect(
        function () {
            if (steps && tours) {
                window?.removeEventListener?.(
                    'tour-progress',
                    tourProgressListener
                );
                window?.addEventListener?.(
                    'tour-progress',
                    tourProgressListener
                );
                context?.cancel();
                initializeTour();
            }
            return () => {
                window?.removeEventListener?.(
                    'tour-progress',
                    tourProgressListener
                );
            };
        },
        [steps, tours]
    );

    return context;
};

export default useTourSettings;
