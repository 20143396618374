import React from 'react';
import { Helmet } from 'react-helmet';

export interface IAppTitleProps {
    title?: string;
}

const AppTitle = ({ title }: IAppTitleProps) => {
    return (
        <Helmet>
            <title>
                SchoolCafé
                {title ? ` - ${title}` : ''}
            </title>
        </Helmet>
    );
};

export default AppTitle;
