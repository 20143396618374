import React, { FC, ReactNode, useEffect } from 'react';
import {
    createStyles,
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { Button, ITheme, Tooltip } from '../..';
import { CloseIcon } from '../../assets/icons';

const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        title: {
            margin: 0,
            minWidth: 150,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
        },
    })
);

export interface IDialogProps extends DialogProps {
    /**
     * Unique id for Dialog
     */
    id: string;
    /**
     * Open for Dialog
     */
    open: boolean;
    /**
     * Close for Dialog
     */
    onClose: () => void;
    /**
     * Title for Dialog
     */
    title?: string;
    /**
     * Content for Dialog
     */
    content: ReactNode | null;
    /**
     * Actions for Dialog
     */
    maxWidth?: 'md' | 'sm' | 'xs';
    /**
     * Primary button text for Dialog
     */
    primaryText?: string;
    /**
     * Secondary button text for Dialog
     */
    secondaryText?: string;
    /**
     * Primary button click action for Dialog
     */
    onPrimaryAction?: () => void;
    /**
     * Secondary button click action for Dialog
     */
    onSecondaryAction?: () => void;
    /**
     * Disable Backdrop Click action for Dialog
     */
    disableBackdropClick?: boolean;
    /**
     * Disable escape button Click action for Dialog
     */
    disableEscapeKeyDown?: boolean;
    /**
     * Custom Action Buttons
     */
    customActions?: React.ReactNode;
    /**
     * Title styles
     */
    titleStyles?: any;
}

const Dialog: FC<IDialogProps> = ({
    id,
    open,
    onClose,
    className,
    title = '',
    content,
    maxWidth = 'md',
    primaryText = '',
    onPrimaryAction,
    secondaryText = '',
    onSecondaryAction,
    disableBackdropClick,
    disableEscapeKeyDown,
    customActions,
    titleStyles = {},
    ...rest
}) => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = React.useState(open);

    useEffect(() => {
        setDialogOpen(open);
    }, [open]);

    return (
        <MuiDialog
            id={id}
            open={dialogOpen}
            onClose={onClose}
            className={clsx(className)}
            maxWidth={maxWidth}
            aria-describedby={id}
            aria-labelledby={id}
            disableEnforceFocus={true}
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableEscapeKeyDown}
            {...rest}
        >
            {title !== '' && (
                <DialogTitle id="dialog-title" className={classes.title}>
                    <Typography variant="h6" style={titleStyles}>
                        {title}
                    </Typography>
                    {onClose ? (
                        <Tooltip
                            title="Close"
                            placement="bottom-end"
                            arrow={true}
                        >
                            <IconButton
                                aria-label="close"
                                className={classes.closeButton}
                                onClick={onClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </DialogTitle>
            )}
            {content && (
                <DialogContent dividers={true}>{content}</DialogContent>
            )}
            {(primaryText !== '' || secondaryText !== '' || customActions) && (
                <DialogActions>
                    {secondaryText !== '' && (
                        <Grid item={true}>
                            <Button
                                id="cancelBtn"
                                name="Cancel"
                                color="default"
                                variant="text"
                                onClick={onSecondaryAction}
                            >
                                {secondaryText}
                            </Button>
                        </Grid>
                    )}
                    {primaryText !== '' && (
                        <Grid item={true}>
                            <Button
                                id="saveBtn"
                                name="Save"
                                color="primary"
                                variant="text"
                                onClick={onPrimaryAction}
                            >
                                {primaryText}
                            </Button>
                        </Grid>
                    )}
                    {customActions && customActions}
                </DialogActions>
            )}
        </MuiDialog>
    );
};

export default Dialog;
