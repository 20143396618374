import React from 'react';

import { IVerticalModuleIcons } from './index';

const ProductionModuleIcon = ({ selected }: IVerticalModuleIcons) => (
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0)">
            <path
                d="M16 4.89001V7.89002H15.5V4.89001H14.5V7.89002H14V4.89001H13V7.89002C13 8.28784 13.158 8.66937 13.4393 8.95067C13.7206 9.23198 14.1022 9.39002 14.5 9.39002V12.94H15.5V9.39002C15.8978 9.39002 16.2794 9.23198 16.5607 8.95067C16.842 8.66937 17 8.28784 17 7.89002V4.89001H16ZM18 6.89002V9.89002H19V12.89H20V4.89001C19.4713 4.89001 18.9641 5.09935 18.5893 5.47226C18.2145 5.84517 18.0026 6.35132 18 6.88001V6.89002Z"
                fill={selected === 'white' ? selected : '#04004D'}
            />
            <path
                d="M15.06 20.88L12.23 18.05L13.64 16.64L15.05 18.05L18.59 14.51L20 15.91L15.06 20.88Z"
                fill={selected}
            />
            <path
                d="M12 12H10V2H18V3H20V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H12V12ZM8 12H2V8H8V12ZM8 6H2V2H8V6Z"
                fill={selected}
            />
            <path d="M6 17H4V19H6V17Z" fill={selected} />
            <path d="M2 17H0V19H2V17Z" fill={selected} />
            <path d="M10 17H8V19H10V17Z" fill={selected} />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="20" height="20.88" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default ProductionModuleIcon;
