import { ISavedViews } from '../ViewsSelection';

export const filterViewsWithDuplicateIds = (views: ISavedViews[]) => {
    const seenIds = new Set();
    const result: ISavedViews[] = [];
    for (const view of views) {
        if (!seenIds.has(view.id)) {
            result.push(view);
            seenIds.add(view.id);
        } else {
            if (view.isSystem || view.isSharedView) {
            } else {
                const index = result.findIndex((item) => item.id === view.id);
                result[index] = view;
            }
        }
    }
    return result;
};
