import { isNil, isString } from 'lodash';
import moment from 'moment';

import AppInsightsImpl from './AppInsightsImpl';
import ILogger from './ILogger';
import {
    ErrorSourceEnum,
    ILoggerError,
    ILoggerException,
    ILoggerMessage,
    ILoggerTraceType,
} from './types';

/**
 * @Logger
 * Implementation of SchoolCafe 2.0 Web Logging to collect errors, exception and failures
 * in AppInsight.
 *
 * @returns Logger Class
 * @method logException called to log exceptions
 * @method logError called to log errors
 * @method logComponentTrace called to log component render failures
 *
 * ```typescript
 * import Logger as PrimeroEdgeLogger, {ErrorSourceEnum, ErrorSeverity} from '@primeroedge/components';
 *
 * export class Logger extends PrimeroEdgeLogger {
 *  constructor() {
 *      super();
 *  }
 *
 *  error(e, source: ErrorSourceEnum) {
 *      this.log({
 *          type: ErrorSourceEnum.COMPONENT | ErrorSourceEnum.NETWORK | ErrorSourceEnum.SERVICE,
 *          message: {
 *            moduleName,
 *            page,
 *            severity: ErrorSeverity.ERROR,
 *            api: {
 *               method: 'GET',
 *               url: '/Users'
 *            },
 *            error: 'Failed to get users',
 *            description: 'Insufficient user permissions'
 *          }
 *        });
 *   }
 * }
 * ```
 */
class Logger implements ILogger {
    private _aiLogger: any;
    private _ai: any;
    constructor(moduleRole = 'PrimeroedgeSystemUI') {
        this._aiLogger = AppInsightsImpl.getAiInsights(moduleRole);
        this._ai = AppInsightsImpl.getAi();
    }

    private _verifyTelemetryPush(exceptionTrace: ILoggerMessage) {
        const { moduleName, page, error } = exceptionTrace;
        return moduleName?.length > 0 && page?.length > 0 && error?.length > 0;
    }

    private _logErrorException(exception: ILoggerException | ILoggerError) {
        if (this._verifyTelemetryPush(exception)) {
            const {
                error,
                moduleName,
                page,
                severity,
                time,
                api,
                description,
            } = exception;
            this._aiLogger.trackException(
                {
                    exception: new Error(error),
                    severityLevel: severity,
                },
                {
                    moduleName,
                    page,
                    time:
                        !isNil(time) && isString(time)
                            ? time
                            : moment()?.format('MM-DD-YYYY hh:mm:ss'),
                    api,
                    description,
                }
            );
            AppInsightsImpl.flush();
        }
    }

    logException(exception: ILoggerException): void {
        this._logErrorException(exception);
    }

    logError(logInfo: ILoggerError): void {
        this._logErrorException(logInfo);
    }

    logComponentTrace(trace: ILoggerTraceType): void {
        if (this._verifyTelemetryPush(trace)) {
            const {
                error,
                moduleName,
                page,
                severity,
                time,
                description,
                component,
            } = trace;
            this._aiLogger.trackTrace(
                {
                    message: component ?? 'Component',
                    severityLevel: severity,
                },
                {
                    moduleName,
                    page,
                    time:
                        !isNil(time) && isString(time)
                            ? time
                            : moment()?.format('MM-DD-YYYY hh:mm:ss'),
                    description,
                    component,
                    error,
                }
            );
            AppInsightsImpl.flush();
        }
    }

    log({ type, message }: { type: ErrorSourceEnum; message: ILoggerMessage }) {
        if (type === ErrorSourceEnum.COMPONENT) {
            this.logComponentTrace(message);
        } else if (type === ErrorSourceEnum.NETWORK) {
            this.logError(message);
        } else {
            this.logException(message);
        }
    }

    removeLogger() {
        this._ai.unload();
    }
}

export default Logger;
