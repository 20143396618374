import type { FC, ReactNode } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { ITheme } from '../../core/Providers';
import { ThemeColorType } from '../../types/theme';

export interface ILabelProps {
    className?: string;
    color?: ThemeColorType;
    children?: ReactNode;
    style?: {};
    id: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        alignItems: 'center',
        borderRadius: 2,
        display: 'inline-flex',
        flexGrow: 0,
        whiteSpace: 'nowrap',
        flexShrink: 0,
        fontSize: '0.84rem',
        fontWeight: theme.typography.fontWeightMedium,
        height: 20,
        justifyContent: 'center',
        letterSpacing: 0.5,
        minWidth: 20,
        padding: theme.spacing(0.5, 1),
        textTransform: 'uppercase',
    },
    default: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.background.dark,
    },
    primary: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
    },
    secondary: {
        color: theme.palette.background.light,
        backgroundColor: theme.palette.secondary.main,
    },
    tertiary: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.tertiary.main,
    },
    success: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.success.main,
    },
    warning: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.warning.main,
    },
    error: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.error.main,
    },
    info: {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.info.main,
    },
}));

const Label: FC<ILabelProps> = ({
    className = '',
    color = 'default',
    children,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <span
            className={clsx(
                classes.root,
                {
                    [classes[color]]: color,
                },
                className
            )}
            {...rest}
        >
            {children}
        </span>
    );
};

export default Label;
