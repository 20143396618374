import React, { FC, Fragment, Suspense } from 'react';
import { Route as RDRoute, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { AppLoader } from '../../../components/Loaders';

import { Route } from './RouteProvider';

const Loader: FC = () => {
    // ToDo: Change MainLayout to full height and remove minHeight
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100vh - 116px)"
        >
            <AppLoader />
        </Box>
    );
};

const renderRoutes = (routes: Route[] = []): JSX.Element => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component = route.component;

                    return (
                        <RDRoute
                            key={`${i}${route.path}`}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Guard>
                                    <Layout>
                                        {route.routes ? (
                                            renderRoutes(route.routes)
                                        ) : (
                                            <Component {...props} />
                                        )}
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    );
};

export default renderRoutes;
