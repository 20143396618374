import React, { FC, ReactNode, useState } from 'react';
import {
    Box,
    Card,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Formik, FormikValues } from 'formik';

import { EditIcon } from '../../../assets/icons';
import { Button } from '../../Button';

import { TextField } from './TextField';

export interface ISummaryListProps {
    header: string;
    items: ISummaryListItemProps[];
    canEdit?: boolean;
    /**
     * A Yup Schema or a function that returns a Yup schema
     */
    validationSchema?: any | (() => any);
    onChange?: (response: ISummaryListItemProps[]) => void;
    className?: string;
    titleClassName?: string | null;
    valueClassName?: string | null;
}

export interface ISummaryListItemProps {
    title: string;
    value: number | string | ReactNode;
    type?: 'number' | 'text';
}

export enum ViewEditMode {
    View = 'view',
    Edit = 'edit',
}

const useStyles = makeStyles(() => ({
    editButton: {
        padding: 6,
    },
    title: {
        width: '25%',
        minWidth: '80px',
    },
    value: {
        paddingTop: 2,
        paddingBottom: 2,
    },
}));

const SummaryList: FC<ISummaryListProps> = ({
    header,
    canEdit = false,
    items = [],
    validationSchema,
    onChange,
    className,
    titleClassName,
    valueClassName,
}) => {
    const classes = useStyles();
    const [mode, setMode] = useState(ViewEditMode.View);

    const getDefaultValues = items.reduce(
        (acc: FormikValues, { title, value }) => {
            acc[title] = value;
            return acc;
        },
        {}
    );

    const [initialValues, setInitialValues] = useState(getDefaultValues);

    const setViewMode = () => setMode(ViewEditMode.View);
    const setEditMode = () => setMode(ViewEditMode.Edit);

    const saveChanges = (values: FormikValues) => {
        const newValues = [
            ...items.map((item) => {
                item.value = values[item.title];
                return item;
            }),
        ];
        if (onChange) {
            onChange(newValues);
        }
        setViewMode();
    };

    const cancelChanges = () => {
        setViewMode();
        setInitialValues(getDefaultValues);
    };

    return (
        <Card className={className}>
            <Formik
                key={initialValues.toString()}
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={saveChanges}
            >
                {(formikState) => (
                    <form onSubmit={formikState.handleSubmit}>
                        {canEdit ? (
                            <CardHeader
                                title={header}
                                action={
                                    mode === ViewEditMode.Edit ? (
                                        <>
                                            <Grid container>
                                                <Grid item>
                                                    <Button
                                                        id="listCancel"
                                                        type="button"
                                                        onClick={cancelChanges}
                                                        label="cancel"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Box ml={1}>
                                                        <Button
                                                            id="listSave"
                                                            type="submit"
                                                            color="primary"
                                                            label="save"
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (
                                        <IconButton
                                            id="editDetails"
                                            type="button"
                                            color="secondary"
                                            aria-label="Edit"
                                            onClick={setEditMode}
                                            className={classes.editButton}
                                            title="test"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    )
                                }
                            />
                        ) : (
                            <CardHeader title={header} />
                        )}
                        <Divider />
                        <Table>
                            <TableBody>
                                {items.map(
                                    (
                                        {
                                            title,
                                            value,
                                            type,
                                        }: ISummaryListItemProps,
                                        index: number
                                    ) => {
                                        // Create input field
                                        // ToDo: Add multiple type inputs
                                        let Field = (
                                            <TextField
                                                title={title}
                                                type={type}
                                                formikState={formikState}
                                            />
                                        );

                                        return (
                                            <TableRow key={index}>
                                                <TableCell
                                                    className={clsx(
                                                        classes.title,
                                                        titleClassName
                                                    )}
                                                >
                                                    {title}
                                                </TableCell>
                                                <TableCell
                                                    className={clsx(
                                                        classes.value,
                                                        valueClassName
                                                    )}
                                                >
                                                    {mode ===
                                                    ViewEditMode.Edit ? (
                                                        <>{Field}</>
                                                    ) : typeof value ===
                                                          'string' ||
                                                      typeof value ===
                                                          'number' ? (
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            {value}
                                                        </Typography>
                                                    ) : (
                                                        value
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </form>
                )}
            </Formik>
        </Card>
    );
};

export default SummaryList;
