import React, { FC, useState } from 'react';
import {
    Box,
    Button as MuiButton,
    Collapse,
    createStyles,
    makeStyles,
} from '@material-ui/core';

import { ChevronDownIcon, ChevronUpIcon } from '../../../assets/icons';
import { ITheme } from '../../../core/Providers';

import { ICustomFilterProps, IMoreFilterProps } from './types';

export interface IGridFilterProps {
    moreFilters?: IMoreFilterProps;
}

const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        filterTitle: {
            marginRight: 'auto',
        },
        filterToggle: {
            color: theme.palette.text.secondary,
            padding: theme.spacing(1),
            justifyContent: 'flex-start',
            textTransform: 'none',
            letterSpacing: 0,
            width: '100%',
        },
        filterContent: {
            padding: '8px 16px 16px 16px',
        },
    })
);

const MoreFilters: FC<IGridFilterProps> = ({ moreFilters }) => {
    return (
        <Box mt={2}>
            {moreFilters?.filters?.map((filter, i) => {
                return <div key={i}>{RenderFilterComponent(filter)}</div>;
            })}
        </Box>
    );
};

const RenderFilterComponent: FC<ICustomFilterProps> = ({
    title,
    component,
    expanded = false,
}) => {
    const classes = useStyles();
    const Children = component;

    const [isOpen, setOpen] = useState<boolean>(expanded);
    const toggleState = () => setOpen((prevOpen) => !prevOpen);

    return (
        <>
            <MuiButton className={classes.filterToggle} onClick={toggleState}>
                <span className={classes.filterTitle}>{title}</span>
                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </MuiButton>
            <Collapse in={isOpen}>
                <div className={classes.filterContent}>
                    <Children />
                </div>
            </Collapse>
        </>
    );
};

export default MoreFilters;
