import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';

import { StandardProps } from '../../types/standardProps';
import NavPermissions from '../Permissions/NavPermissions';

import { navStyles } from './styles';

export interface INavItemProps extends StandardProps<HTMLButtonElement> {
    title?: string;
    path: string;
    icon?: any;
    info?: any;
}

const NavItem: FC<INavItemProps> = ({
    children,
    title,
    path,
    icon: Icon,
    info: Info,
    ...rest
}) => {
    const classes = navStyles();

    return (
        <Box className={classes.itemLeaf} key={`${path}${title}`}>
            <Button
                {...rest}
                activeClassName={classes.active}
                className={clsx(classes.buttonLeaf, 'ellipsis')}
                component={NavLink}
                exact
                to={path}
            >
                {Icon && <Icon className={classes.icon} size="20" />}
                <span className={classes.title}>
                    {title}
                    {children}
                </span>
                {Info && <Info />}
            </Button>
        </Box>
    );
};

export default NavPermissions(NavItem);
