import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ModuleInfoContext, {
    IModuleInfoContextValue,
} from '../../../core/Providers/ModuleInfo/ModuleInfoContext';
import { ModuleName } from '../../../utils';
import { IPageListItem } from '../types';

const useTopBarPageSearch = function () {
    const { routes, moduleName } = useContext<IModuleInfoContextValue>(
        ModuleInfoContext
    );
    const history = useHistory();
    const [selectedPage, setSelectedPage] = useState<IPageListItem | null>(
        null
    );
    const [input, setInput] = useState<string>('');

    const onSearchChange = function ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) {
        setInput(value);
    };

    const isPageListItem = (item: any): item is IPageListItem =>
        'routePath' in item;

    const onSelect = function (
        event: any,
        value: IPageListItem | null | string
    ) {
        if (event && isPageListItem(value)) {
            setSelectedPage(value);
            if (moduleName === value.moduleName) {
                if (moduleName === ModuleName.Workspace) {
                    if (window.location.pathname === '/') {
                        const pageName = value.routePath.split('/');
                        history.replace(`workspace/${pageName[1]}`);
                    } else {
                        const pageName = value.routePath.split('/');
                        history.replace(pageName[1]);
                    }
                } else {
                    const pageName = value.routePath.split('/');
                    history.replace(pageName[1]);
                }
            } else {
                if (`/${value.routePath}` === window.location.pathname) {
                    window.location.reload();
                } else {
                    window.location.pathname = value.routePath;
                }
            }
        }
    };

    return {
        data: routes.filter((item: any) => item.isSearchable),
        input,
        selectedPage,
        onSearchChange,
        onSelect,
    };
};

export default useTopBarPageSearch;
