import { useCallback } from 'react';
import { isNil } from 'lodash';

import ScStorage from '../core/Storage';

export interface IUseOnRefreshApplicationResult {
    setSession: (tourName: string) => void;
    resetSession: () => void;
    isSameSession: (tourName: string) => boolean;
    isSessionSet: (tourName: string) => boolean;
}

/**
 * @hook
 * @useOnRefreshApplication
 * Hook to verify if the application is refreshed but is in the same session.
 * Session is valid till logout happens. Even if refresh occurs.
 *
 * @returns {setSession, resetSession, isSameSession}
 */
const useSessionCheckOnRefresh = (): IUseOnRefreshApplicationResult => {
    const setSession = useCallback(function (tourName: string) {
        if (isNil(ScStorage()?.['in-session'])) {
            ScStorage()?.setItem(
                'in-session',
                JSON.stringify([{ [tourName]: 1 }])
            );
        } else {
            const currentSessionsSet = JSON.parse(ScStorage()['in-session']);
            const isTourSet = checkIfTourNameExists(tourName);
            if (isNil(isTourSet) || isTourSet === -1) {
                currentSessionsSet.push({ [tourName]: 1 });
                ScStorage()?.setItem(
                    'in-session',
                    JSON.stringify(currentSessionsSet)
                );
            }
        }
    }, []);

    const resetSession = useCallback(function () {
        ScStorage()?.removeItem('in-session');
    }, []);

    const isSessionSet = useCallback(function (tourName: string) {
        if (isNil(ScStorage()['in-session'])) {
            return false;
        } else {
            const isTourSet = checkIfTourNameExists(tourName);
            return isTourSet !== -1;
        }
    }, []);

    const isSameSession = useCallback(function (tourName: string) {
        const isTourSet = checkIfTourNameExists(tourName);
        return isTourSet === 1;
    }, []);

    const checkIfTourNameExists = function (tourName: string): number {
        try {
            const currentSessionsSet = JSON.parse(ScStorage()['in-session']);
            const isTourNameExists = currentSessionsSet.findIndex(
                (item: { [key: string]: any }) => {
                    return Object.keys(item)[0] === tourName;
                }
            );
            return isTourNameExists;
        } catch (e) {
            return -1;
        }
    };

    return {
        setSession,
        resetSession,
        isSessionSet,
        isSameSession,
    };
};

export default useSessionCheckOnRefresh;
