import React from 'react';

import { IVerticalModuleIcons } from './index';

const AccountabilityModuleIcon = ({ selected }: IVerticalModuleIcons) => (
    <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0)">
            <path
                d="M2.75 2H20.75V0H2.75C2.21957 0 1.71086 0.210714 1.33579 0.585786C0.960714 0.960859 0.75 1.46957 0.75 2V18C0.75 18.5304 0.960714 19.0391 1.33579 19.4142C1.71086 19.7893 2.21957 20 2.75 20H6.75V18H2.75V2Z"
                fill={selected}
            />
            <path d="M7.75 4H4.75V7H7.75V4Z" fill={selected} />
            <path d="M20.75 4H17.75V7H20.75V4Z" fill={selected} />
            <path d="M7.75 9H4.75V12H7.75V9Z" fill={selected} />
            <path
                d="M19.4301 12L17.1601 10.3C16.872 10.0821 16.5213 9.96288 16.1601 9.96H15.7501V6.25C15.7501 5.58696 15.4867 4.95107 15.0179 4.48223C14.549 4.01339 13.9131 3.75 13.2501 3.75C12.5871 3.75 11.9512 4.01339 11.4823 4.48223C11.0135 4.95107 10.7501 5.58696 10.7501 6.25V12.6L9.9001 12.4C9.68816 12.3595 9.46977 12.3682 9.26172 12.4254C9.05368 12.4826 8.86154 12.5868 8.7001 12.73L8.1201 13.24C7.84414 13.4897 7.67238 13.8342 7.63902 14.2048C7.60566 14.5755 7.71316 14.9451 7.9401 15.24L11.7501 20H14.3001L9.9001 14.44L12.7901 15.15V6.25C12.7901 6.11739 12.8428 5.99021 12.9365 5.89645C13.0303 5.80268 13.1575 5.75 13.2901 5.75C13.4227 5.75 13.5499 5.80268 13.6437 5.89645C13.7374 5.99021 13.7901 6.11739 13.7901 6.25V12H16.0801L18.3201 13.67C18.3201 13.67 18.7901 13.98 18.7901 14.38C18.7901 14.78 18.2401 17.38 17.6501 20H19.7001C20.1575 18.1483 20.508 16.2719 20.7501 14.38C20.746 13.9047 20.6231 13.4379 20.3925 13.0223C20.162 12.6066 19.8311 12.2552 19.4301 12Z"
                fill={selected === 'white' ? selected : '#04004D'}
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0.75)"
                />
            </clipPath>
        </defs>
    </svg>
);

export default AccountabilityModuleIcon;
