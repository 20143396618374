import React, { useEffect, useRef } from 'react';
import { isNil } from 'lodash';

import { fetchIcon } from '../icon-utils';

import SvgIcon, { ISvgIconProps } from './SvgIcon';

const CreateSvgIcon = (icon: string) => {
    const parser = new DOMParser();
    const iconFromFile: (
        iconRef: SVGSVGElement | null,
        dims: {
            height?: string | number;
            width?: string | number;
            title?: string;
            style?: {
                color?: string;
            };
        }
    ) => Promise<void> = async function (iconRef: SVGSVGElement | null, dims) {
        const file = await fetchIcon(icon);
        const htmlDoc = parser.parseFromString(file, 'text/html');
        const svgElement: SVGElement = htmlDoc.body.firstChild as SVGElement;
        svgElement.setAttribute('height', `${dims?.height ?? 24}`);
        svgElement.setAttribute('width', `${dims?.width ?? 24}`);

        if (isNil(svgElement.getAttribute('data-original'))) {
            svgElement.setAttribute('fill', 'currentcolor');
            for (let i = 0; i < svgElement.children.length; i++) {
                if (
                    svgElement.children.item(i)?.getAttribute?.('fill') !==
                    'none'
                ) {
                    svgElement.children
                        .item(i)
                        ?.setAttribute('fill', 'currentcolor');
                }
            }
            if (dims?.style?.color) {
                svgElement.setAttribute('fill', `${dims?.style?.color}`);
            }
        }
        if (svgElement && iconRef) {
            iconRef.appendChild(svgElement);
        }
    };
    return React.memo((props: ISvgIconProps) => {
        const iconRef = useRef<SVGSVGElement | null>(null);
        useEffect(function () {
            iconFromFile(iconRef.current, {
                height: props?.size?.toString?.() ?? props?.height ?? 24,
                width: props?.size?.toString?.() ?? props?.width ?? 24,
                style: props?.style,
                title: props?.title ?? '',
            });
        }, []);
        const CurrentIcon = <SvgIcon ref={iconRef} {...props} />;

        return CurrentIcon;
    });
};

export default CreateSvgIcon;
