import { CreateSvgIcon } from './SvgIcon';

export const AccessDeniedIcon = CreateSvgIcon('accessdenied1');
export const ActivityIcon = CreateSvgIcon('activity');
export const AddPhotoIcon = CreateSvgIcon('addphotoicon');
export const AddPhotoAlternateIcon = CreateSvgIcon(
    'baselineaddphotoalternate24pxdefault'
);
export const AirplayIcon = CreateSvgIcon('airplay');
export const AlertCircleIcon = CreateSvgIcon('alertcircle');
export const AlertOctagonIcon = CreateSvgIcon('alertoctagon');
export const AlertTriangleIcon = CreateSvgIcon('alerttriangle');
export const AlertTriangleFilledIcon = CreateSvgIcon('alerttrianglefilled');
export const AlignCenterIcon = CreateSvgIcon('aligncenter');
export const AlignJustifyIcon = CreateSvgIcon('alignjustify');
export const AlignLeftIcon = CreateSvgIcon('alignleft');
export const AlignRightIcon = CreateSvgIcon('alignright');
export const AnchorIcon = CreateSvgIcon('anchor');
export const ApertureIcon = CreateSvgIcon('aperture');
export const AppsIcon = CreateSvgIcon('apps');
export const ArchiveIcon = CreateSvgIcon('archive');
export const ArrowDownIcon = CreateSvgIcon('arrowdown');
export const ArrowDownCircleIcon = CreateSvgIcon('arrowdowncircle');
export const ArrowDownLeftIcon = CreateSvgIcon('arrowdownleft');
export const ArrowDownRightIcon = CreateSvgIcon('arrowdownright');
export const ArrowLeftIcon = CreateSvgIcon('arrowleft');
export const ArrowLeftCircleIcon = CreateSvgIcon('arrowleftcircle');
export const ArrowRightIcon = CreateSvgIcon('arrowright');
export const ArrowRightCircleIcon = CreateSvgIcon('arrowrightcircle');
export const ArrowUpIcon = CreateSvgIcon('arrowup');
export const ArrowUpCircleIcon = CreateSvgIcon('arrowupcircle');
export const ArrowUpLeftIcon = CreateSvgIcon('arrowupleft');
export const ArrowUpRightIcon = CreateSvgIcon('arrowupright');
export const AttachMoneyIcon = CreateSvgIcon('attachmoneydefault');
export const AtSignIcon = CreateSvgIcon('atsign');
export const AwardIcon = CreateSvgIcon('award');
export const AddIcon = CreateSvgIcon('add');
export const AddCircleIcon = CreateSvgIcon('addcircle');
export const AttachFileIcon = CreateSvgIcon('attachfile');

export const BarChartIcon = CreateSvgIcon('barchart');
export const BarChart2Icon = CreateSvgIcon('barchart2');
export const BatteryIcon = CreateSvgIcon('battery');
export const BatteryChargingIcon = CreateSvgIcon('batterycharging');
export const BeansIcon = CreateSvgIcon('beans');
export const BellIcon = CreateSvgIcon('bell');
export const BellOffIcon = CreateSvgIcon('belloff');
export const BluetoothIcon = CreateSvgIcon('bluetooth');
export const BoldIcon = CreateSvgIcon('bold');
export const BookIcon = CreateSvgIcon('book');
export const BookOpenIcon = CreateSvgIcon('bookopen');
export const BookmarkIcon = CreateSvgIcon('bookmark');
export const BookmarkStarIcon = CreateSvgIcon('bookmarkstar');
export const BoxIcon = CreateSvgIcon('box');
export const BriefcaseIcon = CreateSvgIcon('briefcase');
export const BulbIcon = CreateSvgIcon('bulbicon');
export const BusinessCenterOutlinedIcon = CreateSvgIcon(
    'businesscenteroutlined'
);

export const CalendarIcon = CreateSvgIcon('calendar');
export const CalendarTodayIcon = CreateSvgIcon('calendartodayicon');
export const CameraIcon = CreateSvgIcon('camera');
export const CameraOffIcon = CreateSvgIcon('cameraoff');
export const CancelIcon = CreateSvgIcon('cancel24px');
export const CastIcon = CreateSvgIcon('cast');
export const CategoriesIcon = CreateSvgIcon('categories');
export const CheckIcon = CreateSvgIcon('check');
export const CheckOutlinedIcon = CreateSvgIcon('checkoutlined');
export const CheckCircleIcon = CreateSvgIcon('checkcircle');
export const CheckCircleOutlinedIcon = CreateSvgIcon('checkcircleoutlined');
export const CheckCircleFilledIcon = CreateSvgIcon('checkcirclefilled');
export const CheckCircleOutlineIcon = CreateSvgIcon('checkcircleoutline');
export const CheckCircleDefault = CreateSvgIcon('checkdefault');
export const CheckSquareIcon = CreateSvgIcon('checksquare');
export const ChevronDownIcon = CreateSvgIcon('chevrondown');
export const ChevronLeftIcon = CreateSvgIcon('chevronleft');
export const ChevronRightIcon = CreateSvgIcon('chevronright');
export const ChevronUpIcon = CreateSvgIcon('chevronup');
export const ChevronsDownIcon = CreateSvgIcon('chevronsdown');
export const ChevronsLeftIcon = CreateSvgIcon('chevronleft');
export const ChevronsRightIcon = CreateSvgIcon('chevronright');
export const ChevronsUpIcon = CreateSvgIcon('chevronsup');
export const ChromeIcon = CreateSvgIcon('chrome');
export const CircleIcon = CreateSvgIcon('circle');
export const CommentIcon = CreateSvgIcon('comment');
export const ClipboardIcon = CreateSvgIcon('clipboard');
export const ClockIcon = CreateSvgIcon('clock');
export const CloseIcon = CreateSvgIcon('close');
export const CloudIcon = CreateSvgIcon('cloud');
export const CloudDrizzleIcon = CreateSvgIcon('clouddrizzle');
export const CloudLightningIcon = CreateSvgIcon('cloudlightning');
export const CloudOffIcon = CreateSvgIcon('cloudoff');
export const CloudRainIcon = CreateSvgIcon('cloudrain');
export const CloudSnowIcon = CreateSvgIcon('cloudsnow');
export const CodeIcon = CreateSvgIcon('code');
export const CodepenIcon = CreateSvgIcon('codepen');
export const CommandIcon = CreateSvgIcon('command');
export const CompassIcon = CreateSvgIcon('compass');
export const ConfigurationIcon = CreateSvgIcon('configuration');
export const ConfigurationSettingsIcon = CreateSvgIcon('configuration1');
export const CopyIcon = CreateSvgIcon('copy');
export const CornerDownLeftIcon = CreateSvgIcon('cornerdownleft');
export const CornerDownRightIcon = CreateSvgIcon('cornerdownright');
export const CornerLeftDownIcon = CreateSvgIcon('cornerleftdown');
export const CornerLeftUpIcon = CreateSvgIcon('cornerleftup');
export const CornerRightDownIcon = CreateSvgIcon('cornerrightdown');
export const CornerRightUpIcon = CreateSvgIcon('cornerrightup');
export const CornerUpLeftIcon = CreateSvgIcon('cornerupleft');
export const CornerUpRightIcon = CreateSvgIcon('cornerupright');
export const CostingMissingIcon = CreateSvgIcon('costingmissing');
export const CostingNotMissingIcon = CreateSvgIcon('costingnotmissing');
export const CPUIcon = CreateSvgIcon('cpu');
export const CreditCardIcon = CreateSvgIcon('creditcard');
export const CropIcon = CreateSvgIcon('crop');
export const CrosshairIcon = CreateSvgIcon('crosshair');
export const CarryOverCircleIcon = CreateSvgIcon('carryovercircle');
export const CommentOutLineIcon = CreateSvgIcon('commentoutlinedicon');
export const CheckFilledSquareIcon = CreateSvgIcon('checkedfilledicon');
export const CircleFilledIcon = CreateSvgIcon('fibermanualrecordoutlinedicon');
export const CircleFullyFilledIcon = CreateSvgIcon('circlefullyfilledicon');
export const ContactsOutlinedIcon = CreateSvgIcon('contactsoutlined');
export const CheckBoxIcon = CreateSvgIcon('checkedfilledicon');
export const CheckBoxOutlineBlankIcon = CreateSvgIcon('square');
export const ChatBubbleOutlinedIcon = CreateSvgIcon('chatbubbleoutlined');
export const CachedOutlinedIcon = CreateSvgIcon('cachedoutlined');

export const DateRangeIcon = CreateSvgIcon('daterange');
export const DashboardDefaultIcon = CreateSvgIcon('dashboarddefault');
export const DashboardWithClockIcon = CreateSvgIcon('dashboards1');
export const DashboardIcon = CreateSvgIcon('dashboards1');
export const DatabaseIcon = CreateSvgIcon('database');
export const DeleteIcon = CreateSvgIcon('delete');
export const DiscIcon = CreateSvgIcon('disc');
export const DoNotTouchIcon = CreateSvgIcon('donottouch');
export const DollarSignIcon = CreateSvgIcon('dollarsign');
export const DownloadIcon = CreateSvgIcon('download');
export const DownloadCloudIcon = CreateSvgIcon('downloadcloud');
export const DownloadFileIcon = CreateSvgIcon('getappdefault');
export const DragIndicatorIcon = CreateSvgIcon('dragindicator24p');
export const DropletIcon = CreateSvgIcon('droplet');
export const DomainOutlinedIcon = CreateSvgIcon('domainoutlinedicon');
export const DraftsIcon = CreateSvgIcon('draftsicon');
export const DescriptionIcon = CreateSvgIcon('descriptionfilled');

export const EditIcon = CreateSvgIcon('edit2');
export const EditFilledIcon = CreateSvgIcon('edit');
export const Edit2Icon = CreateSvgIcon('edit2');
export const Edit3Icon = CreateSvgIcon('edit2');
export const ExcelIcon = CreateSvgIcon('excel');
export const ExtensionIcon = CreateSvgIcon('extension');
export const ExternalLinkIcon = CreateSvgIcon('externallink');
export const EyeIcon = CreateSvgIcon('eye');
export const EyeOffIcon = CreateSvgIcon('eyeoff');
export const ErrorIcon = CreateSvgIcon('erroricon');
export const ErrorOutlineIcon = CreateSvgIcon('erroroutlineicon');
export const ErrorOutlinedIcon = CreateSvgIcon('erroroutlined');
export const ExpandMoreIcon = CreateSvgIcon('chevrondown');
export const ExpandLessIcon = CreateSvgIcon('cheveronupicon');
export const EventIcon = CreateSvgIcon('eventfilled');

export const FacebookIcon = CreateSvgIcon('facebook');
export const FastForwardIcon = CreateSvgIcon('fastforward');
export const FeatherIcon = CreateSvgIcon('feather');
export const FavoriteIcon = CreateSvgIcon('favoritedefault');
export const FavoriteBorderIcon = CreateSvgIcon('favoriteborderdefault');
export const FileIcon = CreateSvgIcon('file');
export const FileMinusIcon = CreateSvgIcon('fileminus');
export const FilePlusIcon = CreateSvgIcon('fileplus');
export const FileTextIcon = CreateSvgIcon('filetext');
export const FileTextFilledIcon = CreateSvgIcon('filetextfilledicon');
export const FileUpload = CreateSvgIcon('fileupload');
export const FilmIcon = CreateSvgIcon('film');
export const FilterIcon = CreateSvgIcon('filter');
export const FilterListIcon = CreateSvgIcon('filterlistdefault');
export const FirstPageIcon = CreateSvgIcon('firstpage');
export const FlagIcon = CreateSvgIcon('flag');
export const FolderIcon = CreateSvgIcon('folder');
export const FilderFilledIcon = CreateSvgIcon('folderfilled');
export const FolderMinusIcon = CreateSvgIcon('folderminus');
export const FolderPlusIcon = CreateSvgIcon('folderplus');
export const FormatBoldIcon = CreateSvgIcon('formatbolddefault');
export const FormatItalicIcon = CreateSvgIcon('formatitalicdefault');
export const FruitsIcon = CreateSvgIcon('fruits');
export const FolderSpecialOutlinedIcon = CreateSvgIcon(
    'folderspecialoutlinedicon'
);
export const FontDownloadOutlinedIcon = CreateSvgIcon(
    'fontdownloadoutlinedicon'
);
export const FiberManualRecordIcon = CreateSvgIcon('fibermanualrecord');

export const GiftIcon = CreateSvgIcon('gift');
export const GitBranchIcon = CreateSvgIcon('gitbranch');
export const GitCommitIcon = CreateSvgIcon('gitcommit');
export const GitMergeIcon = CreateSvgIcon('gitmerge');
export const GitPullRequestIcon = CreateSvgIcon('gitpullrequest');
export const GithubIcon = CreateSvgIcon('github');
export const GitlabIcon = CreateSvgIcon('gitlab');
export const GlobeIcon = CreateSvgIcon('gitpullrequest');
export const GridIcon = CreateSvgIcon('grid');
export const GrainsIcon = CreateSvgIcon('grains');
export const GetAppIcon = CreateSvgIcon('getappdefault');

export const HardDriveIcon = CreateSvgIcon('harddrive');
export const HashIcon = CreateSvgIcon('hash');
export const HeadphonesIcon = CreateSvgIcon('headphones');
export const HeartIcon = CreateSvgIcon('heart');
export const HelpCircleIcon = CreateSvgIcon('helpcircle');
export const HistoryIcon = CreateSvgIcon('history24p');
export const HomeIcon = CreateSvgIcon('home');
export const HomeFilledIcon = CreateSvgIcon('homefilled');
export const HolidayIcon = CreateSvgIcon('holiday');

export const ImageIcon = CreateSvgIcon('image');
export const InboxIcon = CreateSvgIcon('inbox2icon');
export const Inbox2Icon = CreateSvgIcon('inbox');
export const InfoIcon = CreateSvgIcon('info');
export const InstagramIcon = CreateSvgIcon('instagram');
export const ItalicIcon = CreateSvgIcon('italic');
export const InsertDriveIcon = CreateSvgIcon('insertdrive');
export const InfoFilledIcon = CreateSvgIcon('infofilled');
export const IntermediateCheckboxFilledIcon = CreateSvgIcon(
    'intermediatecheckboxfilled'
);
export const IntermediateCheckboxOutlinedIcon = CreateSvgIcon(
    'intermediatecheckboxoutlined'
);

export const KeyboardArrowDownIcon = CreateSvgIcon('keyboarddown');

export const LabelIcon = CreateSvgIcon('labelicon');
export const LabelImportantIcon = CreateSvgIcon('labelimportanticon');
export const LastPageIcon = CreateSvgIcon('lastpage');
export const LayersIcon = CreateSvgIcon('layers');
export const LayoutIcon = CreateSvgIcon('layout');
export const LifeBuoyIcon = CreateSvgIcon('lifebuoy');
export const LinkIcon = CreateSvgIcon('link');
export const Link2Icon = CreateSvgIcon('link2');
export const LinkedinIcon = CreateSvgIcon('linkedin');
export const ListIcon = CreateSvgIcon('list');
export const ListAltIcon = CreateSvgIcon('listalt');
export const LoaderIcon = CreateSvgIcon('loader');
export const LockIcon = CreateSvgIcon('lock');
export const LoginIcon = CreateSvgIcon('login');
export const LogoutIcon = CreateSvgIcon('logout');
export const LocallyGrownIcon = CreateSvgIcon('locallygrownicon');
export const LocationOnIcon = CreateSvgIcon('locationonblack');
export const LikeFilledIcon = CreateSvgIcon('recommendblack24dp');
export const LocalDiningOutlinedIcon = CreateSvgIcon('localdiningoutlined');
export const LocalCafeIcon = CreateSvgIcon('localcafe');

export const MailFilledIcon = CreateSvgIcon('mailfilledicon');
export const MailIcon = CreateSvgIcon('mail');
export const MapIcon = CreateSvgIcon('map');
export const MapPinIcon = CreateSvgIcon('mappin');
export const MatchedIcon = CreateSvgIcon('matched');
export const MaximizeIcon = CreateSvgIcon('maximize');
export const Maximize2Icon = CreateSvgIcon('maximize2');
export const MenuIcon = CreateSvgIcon('menu');
export const MessageCircleIcon = CreateSvgIcon('messagecircle');
export const MessageSquareIcon = CreateSvgIcon('messagesquare');
export const MicIcon = CreateSvgIcon('mic');
export const MicOffIcon = CreateSvgIcon('micoff');
export const MinimizeIcon = CreateSvgIcon('minimize');
export const Minimize2Icon = CreateSvgIcon('minimize2');
export const MinusIcon = CreateSvgIcon('minus');
export const MinusCircleIcon = CreateSvgIcon('minuscircle');
export const MinusSquareIcon = CreateSvgIcon('minussquare');
export const ModuleIcon = CreateSvgIcon('module');
export const MonitorIcon = CreateSvgIcon('monitor');
export const MoonIcon = CreateSvgIcon('moon');
export const MoreHorizontalIcon = CreateSvgIcon('morehorizontal');
export const MoreVerticalIcon = CreateSvgIcon('morevertical');
export const MoveIcon = CreateSvgIcon('move');
export const MusicIcon = CreateSvgIcon('music');
export const MenuPlanCompleteIcon = CreateSvgIcon('menuplancomplete');
export const MenuPlanInCompleteIcon = CreateSvgIcon('menuplanincomplete');
export const MenuPlanHolidayIcon = CreateSvgIcon('menuholiday');
export const MenuRecordCompleteIcon = CreateSvgIcon('menurecordcomplete');
export const MenuWithdrawalCompleteIcon = CreateSvgIcon(
    'menuwithdrawalcomplete'
);
export const ModeCommentIcon = CreateSvgIcon('modecomment');
export const ModeCommentOutlineIcon = CreateSvgIcon('modecommentoutlineicon');
export const MergeTypeicon = CreateSvgIcon('mergetype');
export const MilkIcon = CreateSvgIcon('milk');

export const NavigationIcon = CreateSvgIcon('navigation');
export const Navigation2Icon = CreateSvgIcon('navigation2');
export const NotIntrestedIocn = CreateSvgIcon('notinteresteddefault');
export const NoteAddIcon = CreateSvgIcon('noteaddicon');

export const OctagonIcon = CreateSvgIcon('octagon');
export const OpenWithIcon = CreateSvgIcon('openwith');

export const PackageIcon = CreateSvgIcon('package');
export const PageViewIcon = CreateSvgIcon('pageview');
export const PageViewOutlinedIcon = CreateSvgIcon('pageview');
export const PagerClipIcon = CreateSvgIcon('paperclip');
export const PauseIcon = CreateSvgIcon('pause');
export const PauseCircleIcon = CreateSvgIcon('pausecircle');
export const PauseCircleFilledIcon = CreateSvgIcon(
    'pausecirclefilledblack24dp'
);
export const PdfIcon = CreateSvgIcon('pdf');
export const PercentIcon = CreateSvgIcon('percent');
export const PhoneIcon = CreateSvgIcon('phone');
export const PhoneCallIcon = CreateSvgIcon('phonecall');
export const PhoneForwardedIcon = CreateSvgIcon('phoneforwarded');
export const HideFiltersIcon = CreateSvgIcon('hidefilters');
export const ShowFiltersIcon = CreateSvgIcon('showfilters');
export const PhoneIncomingIcon = CreateSvgIcon('phoneincoming');
export const PhoneMissedIcon = CreateSvgIcon('phonemissed');
export const PhoneOffIcon = CreateSvgIcon('phoneoff');
export const PhoneOutgoingIcon = CreateSvgIcon('phoneoutgoing');
export const PieChartIcon = CreateSvgIcon('piechart');
export const PlanCompleteIcon = CreateSvgIcon('plancomplete');
export const PlanMeetingIcon = CreateSvgIcon('planmeeting');
export const PlannedCommentIcon = CreateSvgIcon('plannedcomment');
export const PlayIcon = CreateSvgIcon('play');
export const PlayCircleIcon = CreateSvgIcon('playcircle');
export const PlusIcon = CreateSvgIcon('plus');
export const PlusCircleIcon = CreateSvgIcon('pluscircle');
export const PlusSquareIcon = CreateSvgIcon('plussquare');
export const PocketIcon = CreateSvgIcon('pocket');
export const POSIcon = CreateSvgIcon('pos');
export const PowerIcon = CreateSvgIcon('power');
export const PrinterIcon = CreateSvgIcon('printer');
export const PreviewIcon = CreateSvgIcon('preview');
export const ProcessRunIcon = CreateSvgIcon('processrun');
export const PublishIcon = CreateSvgIcon('publishicon');
export const PermMediaIcon = CreateSvgIcon('permmedia');
export const PermMediaOutlinedIcon = CreateSvgIcon('permmediaoutlined');
export const PencilFilledIcon = CreateSvgIcon('editfilled');
export const PersonIcon = CreateSvgIcon('person');
export const PictureAsPdfIcon = CreateSvgIcon('pictureaspdf');
export const PhoneOutlinedIcon = CreateSvgIcon('phoneoutlined');

export const QueryBuilderOutlinedIcon = CreateSvgIcon('querybuilder');

export const RadioIcon = CreateSvgIcon('radio');
export const RadioButtonUncheckedIcon = CreateSvgIcon('radiobuttonunchecked');
export const RadioButtonCheckedIcon = CreateSvgIcon('radiobuttonchecked');
export const ReceiptsIcon = CreateSvgIcon('recipts');
export const ReceiptsChecksIcon = CreateSvgIcon('reciptschecks');
export const ReconcileIcon = CreateSvgIcon('reconcileicon');
export const ReconcileDisabledIcon = CreateSvgIcon('reconcileicondisabled');
export const RecordCompleteIcon = CreateSvgIcon('recordcomplete');
export const RecordCommentIcon = CreateSvgIcon('recordcomment');
export const RefreshIcon = CreateSvgIcon('refreshcw');
export const RefreshCCWIcon = CreateSvgIcon('refreshccw');
export const RefreshCWIcon = CreateSvgIcon('refreshcw');
export const RepeatIcon = CreateSvgIcon('repeat');
export const ReplyAllIcon = CreateSvgIcon('replyalldefault');
export const ReplyIcon = CreateSvgIcon('replydefault');
export const RestoreIcon = CreateSvgIcon('restore');
export const RewindIcon = CreateSvgIcon('rewind');
export const RotateCCWIcon = CreateSvgIcon('rotateccw');
export const RotateCWIcon = CreateSvgIcon('rotatecw');
export const RSSIcon = CreateSvgIcon('rss');
export const RemoveCircleFilledIcon = CreateSvgIcon('removecircleicon');
export const RemoveCircleOutlinedIcon = CreateSvgIcon('removecircleoutlined');
export const ReportIcon = CreateSvgIcon('reporticon');
export const ReplayIcon = CreateSvgIcon('replay');
export const RefreshOutlinedIcon = CreateSvgIcon('refreshoutlined');

export const SaveIcon = CreateSvgIcon('save');
export const SchoolCafe = CreateSvgIcon('schoolcafe');
export const SchoolCafeIcon = CreateSvgIcon('schoolcafeicon');
export const ScissorsIcon = CreateSvgIcon('scissors');
export const SearchIcon = CreateSvgIcon('search');
export const SearchOutlined = CreateSvgIcon('searchoutlined');
export const Send2Icon = CreateSvgIcon('send');
export const SendIcon = CreateSvgIcon('senddefault');
export const ServerIcon = CreateSvgIcon('server');
export const SettingsDefaultIcon = CreateSvgIcon('settingsdefault');
export const SettingsIcon = CreateSvgIcon('settingsdefault');
export const ShareIcon = CreateSvgIcon('share');
export const Share2Icon = CreateSvgIcon('share2');
export const ShareDefaultIcon = CreateSvgIcon('sharedefault');
export const ShieldIcon = CreateSvgIcon('shield');
export const ShieldOffIcon = CreateSvgIcon('shieldoff');
export const ShopIcon = CreateSvgIcon('shop');
export const ShoppingBagIcon = CreateSvgIcon('shoppingbag');
export const ShuffleIcon = CreateSvgIcon('shuffle');
export const SidebarIcon = CreateSvgIcon('sidebar');
export const SkipBackIcon = CreateSvgIcon('skipback');
export const SkipForwardIcon = CreateSvgIcon('skipforward');
export const SlackIcon = CreateSvgIcon('slack');
export const SlashIcon = CreateSvgIcon('slash');
export const SlidersIcon = CreateSvgIcon('sliders');
export const SmartphoneIcon = CreateSvgIcon('smartphone');
export const SortingIcon = CreateSvgIcon('sortingicon');
export const SortingAZIcon = CreateSvgIcon('sortingiconaz');
export const SortingZAIcon = CreateSvgIcon('sortingiconza');
export const SpeakerIcon = CreateSvgIcon('speaker');
export const SquareIcon = CreateSvgIcon('square');
export const StarOutlinedCurvedIcon = CreateSvgIcon('star');
export const StarOutlineIcon = CreateSvgIcon('staroutlineicon');
export const StarIcon = CreateSvgIcon('starfilled');
export const StopCircleIcon = CreateSvgIcon('stopcircle');
export const SunIcon = CreateSvgIcon('sun');
export const SunriseIcon = CreateSvgIcon('sunrise');
export const SunsetIcon = CreateSvgIcon('sunset');
export const SwapIcon = CreateSvgIcon('swap');
export const StarFilledIcon = CreateSvgIcon('starsblack24dp');
export const ScheduleIcon = CreateSvgIcon('clock');
export const SentimentVeryDissatisfiedIcon = CreateSvgIcon(
    'sentimentverydissatisfiedicon'
);
export const SupervisorAccountIcon = CreateSvgIcon('supervisoraccountfilled');
export const SmartPhoneOutlinedIcon = CreateSvgIcon('smartphoneoutlined');

export const TabletIcon = CreateSvgIcon('tablet');
export const TagIcon = CreateSvgIcon('tag');
export const TargetIcon = CreateSvgIcon('target');
export const TaskIcon = CreateSvgIcon('task');
export const TerminalIcon = CreateSvgIcon('terminal');
export const ThermometerIcon = CreateSvgIcon('thermometer');
export const ThumbsDownIcon = CreateSvgIcon('thumbsdown');
export const ThumbsUpIcon = CreateSvgIcon('thumbsup');
export const ToggleLeftIcon = CreateSvgIcon('toggleleft');
export const ToggleRightIcon = CreateSvgIcon('toggleright');
export const TrashIcon = CreateSvgIcon('trash');
export const TrashFilledIcon = CreateSvgIcon('trashfilled');
export const Trash2Icon = CreateSvgIcon('trash2');
export const TrendingDownIcon = CreateSvgIcon('trendingdown');
export const TrendingUpIcon = CreateSvgIcon('trendingup');
export const TriangleIcon = CreateSvgIcon('triangle');
export const TruckIcon = CreateSvgIcon('truck');
export const TVIcon = CreateSvgIcon('tv');
export const TwitterIcon = CreateSvgIcon('twitter');
export const TypeIcon = CreateSvgIcon('twitter');
export const TranslateOutlinedIcon = CreateSvgIcon('translateoutlinedicon');
export const ThumbUpAltOutlinedIcon = CreateSvgIcon('thumbupaltoutlined');

export const UmbrellaIcon = CreateSvgIcon('umbrella');
export const UnderlineIcon = CreateSvgIcon('underline');
export const UnitsDoNotPublishIcon = CreateSvgIcon('unitsdonotpublish');
export const UnitsInventoryCompleteIcon = CreateSvgIcon(
    'unitsinventorycomplete'
);
export const UnitsInventoryIncompleteIcon = CreateSvgIcon(
    'unitsinventoryincomplete'
);
export const UnitsInventoryIneligibleIcon = CreateSvgIcon(
    'unitsinventoryineligible'
);
export const UnlockIcon = CreateSvgIcon('unlock');
export const UnmatchedIcon = CreateSvgIcon('unmatched');
export const UploadIcon = CreateSvgIcon('upload');
export const UploadCloudIcon = CreateSvgIcon('uploadcloud');
export const UploadCloudFilledIcon = CreateSvgIcon('clouduploadfilled');
export const UploadCloudOutlinedIcon = CreateSvgIcon('clouduploadoutlined');
export const UploadFileIcon = CreateSvgIcon('fileupload');
export const UserIcon = CreateSvgIcon('user');
export const UserCheckIcon = CreateSvgIcon('usercheck');
export const UserDocumentIcon = CreateSvgIcon('userdocument');
export const UserFilledIcon = CreateSvgIcon('useravatar');
export const UserMinusIcon = CreateSvgIcon('userminus');
export const UserPlusIcon = CreateSvgIcon('userplus');
export const UserXIcon = CreateSvgIcon('userx');
export const UsersIcon = CreateSvgIcon('users');

export const VegIcon = CreateSvgIcon('vegitable');
export const VerifiedIcon = CreateSvgIcon('verified');
export const VideoIcon = CreateSvgIcon('video');
export const VideoOffIcon = CreateSvgIcon('videooff');
export const ViewColumnIcon = CreateSvgIcon('viewcolumn');
export const ViewWeekIcon = CreateSvgIcon('viewweekdefault');
export const ViewDayIcon = CreateSvgIcon('viewdaydefault');
export const ViewModuleIcon = CreateSvgIcon('viewmoduledefault');
export const ViewModule1Icon = CreateSvgIcon('viewmoduledefault1');
export const VisibilityIcon = CreateSvgIcon('visibility');
export const VisibilityOutlineIcon = CreateSvgIcon('visibilityoutlionedicon');
export const VoicemailIcon = CreateSvgIcon('voicemail');
export const VolumeIcon = CreateSvgIcon('volume');
export const Volume1Icon = CreateSvgIcon('volume1');
export const Volume2Icon = CreateSvgIcon('volume2');
export const VolumeXIcon = CreateSvgIcon('volumex');
export const VisibilityOffIcon = CreateSvgIcon('visibilityofficon');
export const ViewQuiltIcon = CreateSvgIcon('viewquilt');
export const ViewQuiltOutlinedIcon = CreateSvgIcon('viewquiltoutlined');

export const WarningIcon = CreateSvgIcon('warning');
export const WatchIcon = CreateSvgIcon('watch');
export const WifiIcon = CreateSvgIcon('wifi');
export const WifiOffIcon = CreateSvgIcon('wifioff');
export const WindIcon = CreateSvgIcon('wind');
export const WithdrawCompleteIcon = CreateSvgIcon('withdrawcomplete');
export const WorkOutlineOutlined = CreateSvgIcon('workoutline');

export const XIcon = CreateSvgIcon('x');
export const XCircleIcon = CreateSvgIcon('xcircle');
export const XSquareIcon = CreateSvgIcon('xsquare');

export const YoutubeIcon = CreateSvgIcon('youtube');
export const ZapIcon = CreateSvgIcon('zap');
export const ZapOffIcon = CreateSvgIcon('zapoff');
export const ZoomInIcon = CreateSvgIcon('zoomin');
export const ZoomOutIcon = CreateSvgIcon('zoomout');
export const UploadDocIcon = CreateSvgIcon('uploaddoc');

// Not available
// export const UpLoadIcon = CreateSvgIcon('upload1');

// Topbar Spaces Icons
export const SupportIcon = CreateSvgIcon('contactsupporticon');
export const InsightsIcon = CreateSvgIcon('findinpageicon');
export const GoIcon = CreateSvgIcon('flashonicon');
export const CommunityIcon = CreateSvgIcon('communityicon');
export const MarketIcon = CreateSvgIcon('shoppingbasket');
export const LearnIcon = CreateSvgIcon('menubook');
