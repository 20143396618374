import React, {
    ChangeEvent,
    FC,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    useEffect,
    useState,
} from 'react';
import {
    Grid,
    makeStyles,
    Tab as MuiTab,
    Tabs as MuiTabs,
    Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';

import { ITheme } from '../../core/Providers';
import { StandardProps, ThemeColorType } from '../../types';
import Accordion from '../Accordion/Accordion';
import { Button } from '../Button';
import { Label } from '../Label';
import { NotificationWithDialog } from '../Notifications';

interface ITabFilterButtonProps {
    type?: 'submit' | 'reset' | 'button';
    loading?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    iconSeparator?: boolean;
    disabled?: boolean;
    useEllipsis?: boolean;
    buttonTooltip?: string;
}

export interface ITabsProps extends StandardProps<HTMLDivElement> {
    /**
     * List of tabs
     */
    tabs: ITabProps[];
    /**
     * The value of the currently selected `Tab`.
     * If you don't want any selected `Tab`, you can set this property to `false`.
     * Index value or string passed to ITabProps
     */
    value?: number | string;
    /**
     * Callback fired when the value changes.
     */
    onChange?: (value: number | string, ev?: ChangeEvent<{}>) => void;
    /**
     * Color of tab text
     */
    textColor?: 'primary' | 'secondary';
    /**
     * Tabs style variant
     */
    variant?: 'default' | 'contained';
    /**
     * Add spacing at bottom of tabs
     */
    gutterBottom?: boolean;
    /**
     * Check if confirmation is needed before switching tab
     */
    promptCanChange?: boolean;
    /**
     * Dialog text
     */
    promptDialogText?: string;
    /**
     * Dialog title
     */
    dialogTitle?: string;
    /**
     * Dialog message
     */
    dialogMessage?: string;
    /**
     * Primary Button Text
     */
    primaryButtonText?: string;
    /**
     * Secondary Button Text
     */
    secondaryButtonText?: string;
    /**
     * state parameter to set on notification dialog
     */
    isDirty?: any;
    /**
     * setstate parameter to set on notification dialog
     */
    setIsDirty?: any;
    /**
     * enable filter section
     */
    enableFilters?: boolean;
    /**
     * filter section
     */
    filters?: React.ReactNode;
    /**
     * filter section primary action text
     */
    filtersPrimaryActionText?: string;
    /**
     * filter section primary action
     */
    filtersPrimaryActionClick?: MouseEventHandler<HTMLButtonElement>;
    /**
     * filter section secondary action text
     */
    filtersSecondaryActionText?: string;
    /**
     * filter section secondary action
     */
    filtersSecondaryActionClick?: MouseEventHandler<HTMLButtonElement>;
    /**
     * Filters Primary Action Button props
     */
    filtersPrimaryActionProps?: ITabFilterButtonProps;
    /**
     * Filters Secondary Action Button props
     */
    filtersSecondaryActionProps?: ITabFilterButtonProps;
}

export interface ITabProps extends StandardProps<HTMLDivElement> {
    label: ReactNode;
    component?: ReactNode;
    value?: string;
    icon?: ReactElement;
    disabled?: boolean;
    hidden?: boolean;
    /**
     * Wrap tab labels
     */
    wrapped?: boolean;
    enableLabel?: boolean;
    labelText?: string;
    labelId?: string;
    labelColor?: ThemeColorType;
    tooltip?: string;
}

interface ITabPanelProps {
    value: number | string;
    currentTab: number | string;
}

const useStyles = makeStyles((theme: ITheme) => ({
    tabsRoot: {
        minHeight: '40px',
    },
    tabRoot: {
        minHeight: '40px',
        maxWidth: '400px',
    },
    gutter: {
        marginBottom: theme.spacing(1.5),
    },
    default: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    contained: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.light,
        boxShadow: theme.shadows[1],
    },
}));

const Tabs: FC<ITabsProps> = ({
    children,
    className,
    tabs,
    value,
    variant = 'default',
    textColor = 'primary',
    onChange,
    gutterBottom,
    promptCanChange = false,
    dialogTitle = 'Are you sure?',
    primaryButtonText = 'Agree',
    secondaryButtonText = 'Disagree',
    dialogMessage = 'Changes will be lost on switching tabs wothout saving. Is that okay ?',
    isDirty,
    setIsDirty,
    enableFilters = false,
    filters,
    filtersPrimaryActionText = 'Appy',
    filtersPrimaryActionClick,
    filtersSecondaryActionText = 'Clear Filter',
    filtersSecondaryActionClick,
    filtersPrimaryActionProps,
    filtersSecondaryActionProps,
    ...rest
}) => {
    const classes = useStyles();

    const [currentTab, setCurrentTab] = useState<number | string>(value || 0);
    const [activeTabs, setActiveTabs] = useState<ITabProps[]>([]);
    const [showConfirmationDialog, canShowConfirmationDialog] = useState(false);
    const [tabToSwitch, setTabToSwitch] = useState<number | string>(-1);

    /**
     * Filter out Hidden Tabs
     */
    useEffect(() => {
        if (tabs?.length > 0) {
            let nonHiddenTabs: ITabProps[] = [];
            tabs.forEach((tab: ITabProps) => {
                if (!tab.hidden) {
                    nonHiddenTabs.push(tab);
                }
            });

            setActiveTabs(nonHiddenTabs);
        }
    }, [tabs]);

    /**
     * Update current tab on value change
     */
    useEffect(() => {
        if (value !== undefined) {
            setCurrentTab(value);
        }
    }, [value]);

    const handleTabsChange = (ev: ChangeEvent<{}>, val: string | number) => {
        if (!promptCanChange) {
            setCurrentTab(val);
            if (typeof onChange === 'function') {
                onChange(val, ev);
            }
        } else {
            if (val === currentTab) {
                canShowConfirmationDialog(() => false);
            } else {
                canShowConfirmationDialog(() => true);
            }
            setTabToSwitch(() => val);
        }
    };

    const handleCloseConfirmationDialog = () => {
        canShowConfirmationDialog(() => false);
        setIsDirty(true);
    };

    const handleConfirmTabChange = () => {
        setCurrentTab(tabToSwitch);
        if (typeof onChange === 'function') {
            onChange(tabToSwitch, undefined);
        }
        canShowConfirmationDialog(() => false);
        setIsDirty(false);
        setTabToSwitch(-1);
    };

    const customTitle = (tabProps: ITabProps) => {
        const content = (
            <span>
                {tabProps?.label}{' '}
                <Label
                    id={tabProps?.labelId || `label-${tabProps?.id}`}
                    color={tabProps?.labelColor}
                >
                    {tabProps?.labelText}
                </Label>
            </span>
        );
        return content;
    };
    return (
        <>
            <NotificationWithDialog
                open={showConfirmationDialog}
                onClose={handleCloseConfirmationDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                title={dialogTitle}
                message={dialogMessage}
                primaryAction={{
                    title: primaryButtonText,
                    callback: () => handleConfirmTabChange(),
                }}
                secondaryAction={{
                    title: secondaryButtonText,
                    callback: () => handleCloseConfirmationDialog(),
                }}
            ></NotificationWithDialog>
            <div
                className={clsx(
                    classes[variant],
                    {
                        [classes.gutter]: gutterBottom,
                    },
                    className
                )}
            >
                <MuiTabs
                    id={'tabsPanel'}
                    onChange={handleTabsChange}
                    value={currentTab}
                    scrollButtons="auto"
                    textColor={textColor}
                    indicatorColor={textColor}
                    variant="scrollable"
                    classes={{
                        root: classes.tabsRoot,
                    }}
                    {...rest}
                >
                    {activeTabs?.map((tabProps, index) => (
                        <MuiTab
                            key={index}
                            icon={tabProps.icon}
                            label={
                                tabProps?.enableLabel ? (
                                    customTitle(tabProps)
                                ) : tabProps.tooltip ? (
                                    <Tooltip title={tabProps.tooltip}>
                                        <span>{tabProps.label}</span>
                                    </Tooltip>
                                ) : (
                                    tabProps.label
                                )
                            }
                            aria-label={tabProps.label?.toString()}
                            value={tabProps.value || index}
                            disabled={tabProps.disabled}
                            wrapped={tabProps.wrapped}
                            classes={{
                                root: classes.tabRoot,
                            }}
                            className={tabProps.className}
                            style={tabProps.style}
                        />
                    ))}
                </MuiTabs>
            </div>
            {tabs?.map((tab, index) => (
                <TabPanel
                    key={index}
                    currentTab={currentTab}
                    value={tab.value || index}
                >
                    {enableFilters && (
                        <>
                            <Accordion
                                title="Filters"
                                variant="default"
                                expanded={true}
                                style={{
                                    marginTop: 20,
                                }}
                            >
                                <Grid
                                    container={true}
                                    direction="column"
                                    style={{
                                        justifyContent: 'flex-start',
                                    }}
                                    alignItems="stretch"
                                    spacing={2}
                                >
                                    <Grid item={true}>{filters}</Grid>
                                    <Grid item={true}>
                                        <Grid
                                            container
                                            direction="row"
                                            style={{
                                                justifyContent: 'flex-end',
                                            }}
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Grid item={true}>
                                                <Button
                                                    color="secondary"
                                                    id="secondaryButton"
                                                    label={
                                                        filtersSecondaryActionText
                                                    }
                                                    name="Button"
                                                    onClick={
                                                        filtersSecondaryActionClick
                                                    }
                                                    variant="default"
                                                    {...filtersPrimaryActionProps}
                                                />
                                            </Grid>
                                            <Grid item={true}>
                                                <Button
                                                    color="primary"
                                                    id="primaryButton"
                                                    label={
                                                        filtersPrimaryActionText
                                                    }
                                                    name="Button"
                                                    onClick={
                                                        filtersPrimaryActionClick
                                                    }
                                                    variant="default"
                                                    {...filtersSecondaryActionProps}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Accordion>
                        </>
                    )}
                    {tab.component}
                </TabPanel>
            ))}
            {children}
        </>
    );
};

const TabPanel: FC<ITabPanelProps> = ({ children, currentTab, value }) => {
    return (
        <div
            role="tabpanel"
            hidden={currentTab !== value}
            id={`tabpanel-${value}`}
            aria-label={`tab-${value}`}
        >
            {currentTab === value && children}
        </div>
    );
};

export default Tabs;
