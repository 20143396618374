import {
    AccountabilityModuleIcon,
    AccountManagementModuleIcon,
    EligibilityModuleIcon,
    InsightsModuleIcon,
    InventoryModuleIcon,
    ItemManagementModuleIcon,
    MenuPlanningModuleIcon,
    ProductionModuleIcon,
    SystemModuleIcon,
    WorkspaceModuleIcon,
} from '../../assets/icons/Vertical_modules';
import ReportsModuleIcon from '../../assets/icons/Vertical_modules/Reports';

import { ModuleName } from './moduleName';

const ModuleIcon = {
    Default: WorkspaceModuleIcon,
    [ModuleName.Workspace]: WorkspaceModuleIcon,
    [ModuleName.Inspections]: WorkspaceModuleIcon,
    [ModuleName.Accountability]: AccountabilityModuleIcon,
    [ModuleName.Eligibility]: EligibilityModuleIcon,
    [ModuleName.Inventory]: InventoryModuleIcon,
    [ModuleName.ItemManagement]: ItemManagementModuleIcon,
    [ModuleName.AccountManagement]: AccountManagementModuleIcon,
    [ModuleName.System]: SystemModuleIcon,
    [ModuleName.Platform]: WorkspaceModuleIcon,
    [ModuleName.MenuPlanning]: MenuPlanningModuleIcon,
    [ModuleName.FoodDistribution]: WorkspaceModuleIcon,
    [ModuleName.Reviews]: WorkspaceModuleIcon,
    [ModuleName.Production]: ProductionModuleIcon,
    [ModuleName.Insights]: InsightsModuleIcon,
    [ModuleName.Reports]: ReportsModuleIcon,
};

export const getModuleIcon = (moduleName: ModuleName) => {
    return ModuleIcon[moduleName] ?? ModuleIcon.Default;
};
