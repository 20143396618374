import { isNil } from 'lodash';
import moment from 'moment';

import { IUser } from '../../types/user';
import { IAuthResponse } from '../Providers';
import ScStorage from '../Storage';

export const persistAuth = (payload: IAuthResponse): void => {
    ScStorage().setItem('accessToken', payload.accessToken);
    ScStorage().setItem('refreshToken', payload.refreshToken || '');
    ScStorage().setItem('expiresIn', payload.expiresIn?.toString() || '0');
    ScStorage().setItem('message', payload.message || '');
    if (payload?.clientSessionIdleTimeout) {
        ScStorage().setItem(
            'clientSessionIdleTimeout',
            payload?.clientSessionIdleTimeout?.toString() || ''
        );
    }
    ScStorage().setItem(
        'refreshExpiresIn',
        payload.refreshExpiresIn?.toString() || '0'
    );
    ScStorage().setItem('tokenType', payload.tokenType || '');
    ScStorage().setItem('posVersion', payload.posVersion || '');
    ScStorage().setItem('posBetaVersion', payload.posBetaVersion || '');
};

export const persistUserProfile = (payload: IUser) => {
    ScStorage().setItem('email', payload?.Email);
    ScStorage().setItem('firstName', payload?.FirstName);
    ScStorage().setItem('lastName', payload?.LastName);
    persistRememberedUser(payload);
};

export const resetPersistedValues = () => {
    ScStorage().clear();
    ScStorage().removeItem('accessToken');
    ScStorage().removeItem('refreshToken');
    ScStorage().removeItem('expiresIn');
    ScStorage().removeItem('refreshExpiresIn');
    ScStorage().removeItem('message');
    ScStorage().removeItem('clientSessionIdleTimeout');
    ScStorage().removeItem('tokenType');
    ScStorage().removeItem('lastName');
    ScStorage().removeItem('email');
    ScStorage().removeItem('firstName');
    ScStorage().removeItem('rolesPermissions');
    ScStorage().removeItem('activeMenuKey');
    ScStorage().removeItem('activeCycleKey');
    ScStorage().removeItem('posVersion');
    ScStorage().removeItem('posBetaVersion');
    ScStorage().removeItem('system-onboarding-setup');
    ScStorage().removeItem('frontoffice-onboarding-setup');
    ScStorage().removeItem('allTours');
    ScStorage().removeItem('isSmartLogin');
    ScStorage().removeItem('dashboardV2');
    ScStorage().removeItem('dashboardV2Settings');
    ScStorage().removeItem('activeDistrict');
};

export const getRefreshToken = () => {
    return ScStorage().getItem('refreshToken');
};

export const persistRememberMe = (value: boolean) => {
    ScStorage().setItem('rememberMe', value.toString());
};

export const persistRememberedUser = async (payload: IUser) => {
    const rememberMe = ScStorage().getItem('rememberMe') === 'true';
    const { email } =
        JSON.parse(ScStorage().getItem('rememberedUser') ?? '{}') ?? {};
    if (rememberMe && email !== payload?.Email) {
        const rememberedUser = {
            email: payload?.Email,
            firstName: payload?.FirstName,
            lastName: payload?.LastName,
        };
        ScStorage().setItem('rememberedUser', JSON.stringify(rememberedUser));
    }
};

export const resetRememberedUser = () => {
    ScStorage().removeItem('rememberMe');
    ScStorage().removeItem('rememberedUser');
    ScStorage().removeItem('rememberMeProfileImage');
};

export const isRememberMeEnabled = () => {
    return ScStorage().getItem('rememberMe') === 'true';
};

export const isUserProfileImageExists = () => {
    return !isNil(ScStorage().getItem('rememberMeProfileImage'));
};

export const setLoginTime = function () {
    ScStorage().setItem('loginTime', moment().toString());
    ScStorage().setItem('refreshTime', '[]');
};

export const resetLoginTime = function () {
    ScStorage().setItem('loginTime', '');
    ScStorage().setItem('refreshTime', '[]');
};

export const updateRefreshTokenCount = function () {
    const refreshTime = JSON.parse(
        ScStorage().getItem('refreshTime') as string
    );
    if (refreshTime.length < 10) {
        refreshTime.push(moment().toString());
    }
    ScStorage().setItem('refreshTime', JSON.stringify(refreshTime));
};

export const getLatestTime = function () {
    const loginTime = ScStorage().getItem('loginTime');
    const refreshTimes = JSON.parse(
        ScStorage().getItem('refreshTime') as string
    );

    return refreshTimes.length > 0
        ? moment(refreshTimes[refreshTimes.length - 1])
        : moment(loginTime);
};

export const getRefreshCount = function () {
    return JSON.parse(ScStorage().getItem('refreshTime') ?? '[]').length ?? 0;
};
