import React, { useEffect } from 'react';

import AppInsightsProvider from '../../core/AppInsightsProvider';
import { ErrorSeverity } from '../../core/Providers';
import { IErrorCaptureType } from '../../hooks/useErrorCapture';

interface IErrorCaptureProps {
    children?: React.ReactNode;
}

/**
 * @ErrorCapture
 * @Component
 * Functional component that returns children from page layout.
 * Introduces a 'error-capture' event listener.
 * Any event dispatch of type='error-capture' will be logged in this component.
 */
const ErrorCapture = ({ children }: IErrorCaptureProps) => {
    const errorListener = function (event: CustomEvent<IErrorCaptureType>) {
        const { detail: error } = event;
        AppInsightsProvider.createTrace({
            message: error.error,
            page: error.page,
            severityLevel: ErrorSeverity.ERROR,
            detail: error.errorMsg,
            time: error.time ?? Date.now(),
            component: error.component,
        });
    } as EventListener;

    useEffect(function () {
        window?.addEventListener?.('error-capture', errorListener);

        return () => {
            window?.removeEventListener?.('error-capture', errorListener);
        };
    }, []);
    return <>{children}</>;
};

export default ErrorCapture;
