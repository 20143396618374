import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import type { Theme as MuiTheme } from '@material-ui/core/styles/createMuiTheme';
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import { merge } from 'lodash';

import { Themes } from '../../../types/theme';

import { darkPalette, IPalette, lightPalette, perseusPalette } from './palette';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

import './main.scss';

export interface ITheme extends MuiTheme {
    name: string;
    palette: IPalette;
}

export enum Direction {
    LTR = 'ltr',
    RTL = 'rtl',
}

interface IThemeConfig {
    direction?: Direction;
    responsiveFontSizes?: boolean;
    theme?: string;
}

interface IThemeOptions {
    name?: string;
    direction?: Direction;
    typography?: Record<string, any>;
    overrides?: Record<string, any>;
    palette?: Record<string, any>;
    shadows?: MuiShadows;
}

const baseOptions: IThemeOptions = {
    direction: Direction.LTR,
    typography,
    overrides: {
        MuiLinearProgress: {
            root: {
                borderRadius: 3,
                overflow: 'hidden',
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32,
            },
        },
        MuiChip: {
            root: {
                backgroundColor: 'rgba(0,0,0,0.075)',
            },
        },
        MuiOutlinedInput: {
            input: {
                '&:-webkit-autofill': {
                    WebkitBoxShadow: 'none',
                },
            },
        },
    },
};

const themesOptions: IThemeOptions[] = [
    {
        name: Themes.Perseus,
        palette: perseusPalette,
        shadows: softShadows,
    },
    {
        name: Themes.Light,
        palette: lightPalette,
        shadows: softShadows,
    },
    {
        name: Themes.Dark,
        palette: darkPalette,
        shadows: strongShadows,
    },
];

export const createTheme = (config: IThemeConfig = {}): ITheme => {
    let themeOptions = themesOptions.find(
        (theme) => theme.name === config.theme
    );

    if (!themeOptions) {
        console.warn(new Error(`The theme ${config.theme} is not valid`));
        [themeOptions] = themesOptions;
    }

    let theme = createMuiTheme(
        merge({}, baseOptions, themeOptions, {
            direction: config.direction,
        })
    );

    if (config.responsiveFontSizes) {
        theme = responsiveFontSizes(theme);
    }

    return theme as ITheme;
};
