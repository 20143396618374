import { createContext } from 'react';

import { IAuthState } from './auth.model';

export interface IPasswordChangePayload {
    password: string;
}

/**
 * @iLogoutCleanupList
 * Set of api's to be called before logout
 * @param api Function
 * @param property string localstorage property to fetch value.
 * @param method string API Method
 */
export interface ILogoutCleanupList {
    api: Function;
    property: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    isLocalStorage?: boolean;
    localStorageProperties?: string[];
}

export interface IAuthContextValue extends IAuthState {
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
    passwordChange: (payload: IPasswordChangePayload) => Promise<boolean>;
    passwordPolicies: () => any;
    validatePasswordChangeToken: (payload: any) => any;
    cleanupApi: ILogoutCleanupList[];
    updateCleanupApi: Function;
    environment?: string;
}

export const initialAuthState: IAuthState = {
    auth: null,
    error: null,
    isAuthenticated: false,
    isInitialised: false,
    registeredEmail: undefined,
    firstToken: null,
    changeDefaults: false,
    errorMessage: '',
};

const AuthContext = createContext<IAuthContextValue>({
    ...initialAuthState,
    environment: undefined,
    login: () => Promise.resolve(),
    logout: () => {},
    passwordChange: () => Promise.resolve(false),
    passwordPolicies: () => Promise.resolve(false),
    validatePasswordChangeToken: () => Promise.resolve(),
    cleanupApi: [],
    updateCleanupApi: () => {},
});

export default AuthContext;
