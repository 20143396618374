import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Box, makeStyles, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import Modules, {
    HoverStates,
    IPermissionModel,
} from '../../components/TopBar/Modules';
import { ITheme, useProfile, useSettings } from '../../core/Providers';
import ModuleInfoContext from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import ScStorage from '../../core/Storage';
import { storage } from '../../core/Store';
import { IPermission } from '../../types/user';
import { ModuleName } from '../../utils/modules/moduleName';

import { FullNav } from './FullNav';

export interface ITopNavProps {
    moduleName: ModuleName;
    getNavMode: (mode: boolean) => void;
    getExpandedMode: (mode: boolean) => void;
    broadcastMessageExists: boolean;
}

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        height: '100%',
        position: 'sticky',
        zIndex: 5,
        left: 0,
        transition: '.5s ease',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    rootMdBelow: {
        backgroundColor: theme.palette.common.white,
        height: '100%',
        position: 'absolute',
        transition: '300ms ease',
        zIndex: 999,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    scrollable: {
        height: 40,
        overflow: 'hidden',
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.standard,
        }),
    },
    actionContainer: {
        width: '24px',
    },
    prevActionContainer: {
        marginRight: theme.spacing(0.5),
    },
    nextActionContainer: {
        marginLeft: theme.spacing(0.5),
    },
    action: {
        width: '24px',
        height: '20px',
        minHeight: 'unset',
        color: theme.palette.grey[600],
        borderColor: theme.palette.grey[600],
        borderRadius: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    prevAction: {
        borderRight: '1px solid',
    },
    nextAction: {
        borderLeft: '1px solid',
    },
    navRoot: {
        display: 'flex',
        transition: '300ms',
        marginTop: '64px',
        [theme.breakpoints.up('md')]: {
            width: '300px',
            // boxShadow: '0px -4px 4px rgb(0 0 0 / 25%)',
            height: '100vh',
            marginTop: '114px',
        },
        [theme.breakpoints.down('md')]: {
            width: '300px',
            // boxShadow: '0px -4px 4px rgb(0 0 0 / 25%)',
            height: '92vh',
            marginTop: '114px',
        },
    },
    navRootBroadcast: {
        display: 'flex',
        transition: '300ms',
        marginTop: '64px',
        [theme.breakpoints.up('md')]: {
            width: '300px',
            height: '100vh',
            marginTop: '114px',
        },
        [theme.breakpoints.down('md')]: {
            width: '300px',
            height: '92vh',
            marginTop: '114px',
        },
    },
    navCollapse: {
        width: '64px',
        marginTop: '114px',
        transition: '300ms',
        [theme.breakpoints.up('md')]: {
            height: '100vh',
        },
        [theme.breakpoints.down('md')]: {
            height: '92vh',
        },
    },
    navCollapseBroadcast: {
        width: '64px',
        transition: '300ms',
        marginTop: '114px',
        [theme.breakpoints.up('md')]: {
            height: '100vh',
        },
        [theme.breakpoints.down('md')]: {
            height: '92vh',
        },
    },
}));

const TopNav = ({
    moduleName,
    getNavMode,
    getExpandedMode,
    broadcastMessageExists,
}: ITopNavProps) => {
    const classes = useStyles();
    const { setIsNavExpanded } = useContext(ModuleInfoContext);
    const { navClosedOnLoad } = useSettings();
    const { pathname } = useLocation();
    const [navList, setNavList] = useState<IPermissionModel[]>([]);
    const [expanded, isExpaded] = useState<boolean>(function () {
        return !navClosedOnLoad?.includes(window?.location?.pathname);
    });
    const [isHoverExpanded, setHoverExpand] = useState<HoverStates>(
        HoverStates.LEAVE
    );
    const { user } = useProfile();
    const [currentNav, changeNav] = useState<ModuleName>(moduleName);
    const [isBroadcastMessageExists, setIsBroadcastMessageExists] = useState<
        boolean
    >(broadcastMessageExists);
    const theme = useTheme();
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(
        function () {
            if (navClosedOnLoad.includes(pathname) && expanded) {
                isExpaded(false);
                setIsNavExpanded(false);
                getExpandedMode(false);
            }
            getNavMode(false);
        },
        [pathname]
    );

    useEffect(() => {
        setIsBroadcastMessageExists(broadcastMessageExists);
    }, [broadcastMessageExists]);

    /**
     * Load nav list from localStorage
     */
    useEffect(() => {
        try {
            const rolesPermissions = storage.getItem('rolesPermissions');
            if (rolesPermissions) {
                const permissions = JSON.parse(rolesPermissions);
                const isWorkspaceExists = user?.RolesPermissions.find(
                    (perm: IPermission) =>
                        perm.moduleName === ModuleName.Workspace
                );
                if (!isWorkspaceExists) {
                    permissions.unshift({
                        name: 'Workspace',
                        path: '/workspace',
                        id: '300',
                    });
                }
                setNavList(permissions);
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    useEffect(() => {
        if (user?.RolesPermissions?.length) {
            const permission = user?.RolesPermissions.map(
                (perm: IPermission) => ({
                    name: perm.moduleName,
                    path: perm.path,
                    id: perm.moduleId,
                })
            );
            if (!user.Roles.includes('District Onboarding')) {
                const isWorkspaceExists = user?.RolesPermissions.find(
                    (perm: IPermission) =>
                        perm.moduleName === ModuleName.Workspace
                );
                if (!isWorkspaceExists) {
                    permission.unshift({
                        name: 'Workspace',
                        path: '/workspace',
                        id: '300',
                    });
                }
            }
            setNavList(permission);
            ScStorage().setItem('rolesPermissions', JSON.stringify(permission));
        } else {
            setNavList([]);
            ScStorage().removeItem('rolesPermissions');
        }
    }, [user]);

    const toggleExpansion = useCallback(() => {
        if (!navClosedOnLoad.includes(pathname)) {
            isExpaded(() => !expanded);
            setIsNavExpanded(!expanded);
            getExpandedMode(!expanded);
        }
    }, [expanded, isHoverExpanded, navClosedOnLoad, pathname]);

    const expandNavigationonClick = useCallback(() => {
        if (!navClosedOnLoad.includes(pathname)) {
            isExpaded(() => true);
            setIsNavExpanded(true);
            getExpandedMode(true);
        }
    }, [expanded, isHoverExpanded, navClosedOnLoad, pathname]);

    const onHoverExpand = useCallback(() => {
        if (!expanded) {
            if (isHoverExpanded === HoverStates.LEAVE) {
                getNavMode(true);
                setHoverExpand(HoverStates.ENTER);
            }
        } else {
            getNavMode(false);
            setHoverExpand(HoverStates.LEAVE);
        }
    }, [isHoverExpanded, expanded, getNavMode]);

    const onHoverCollapse = useCallback(() => {
        if (!expanded) {
            if (isHoverExpanded === HoverStates.ENTER) {
                getNavMode(false);
                setHoverExpand(HoverStates.LEAVE);
            }
        } else {
            getNavMode(false);
            setHoverExpand(HoverStates.LEAVE);
        }
    }, [isHoverExpanded, expanded, getNavMode]);

    function rootClass() {
        if (expanded) {
            if (isExtraSmall || isHoverExpanded === HoverStates.ENTER) {
                return clsx([classes.rootMdBelow]);
            }
            return classes.root;
        }
        return classes.rootMdBelow;
    }

    const classNames = () => {
        if (isHoverExpanded === HoverStates.ENTER || expanded) {
            if (!isBroadcastMessageExists) {
                return classes.navRoot;
            } else {
                return classes.navRootBroadcast;
            }
        } else {
            if (!isBroadcastMessageExists) {
                return classes.navCollapse;
            } else {
                return classes.navCollapseBroadcast;
            }
        }
    };

    return (
        <div className={rootClass()}>
            <Box
                display="contents"
                alignItems="center"
                width="100%"
                height="100%"
            >
                <div className={classNames()} onMouseLeave={onHoverCollapse}>
                    <Modules
                        navList={navList}
                        expanded={
                            isHoverExpanded === HoverStates.ENTER || expanded
                        }
                        moduleName={currentNav}
                        shoudlExpand={() => {
                            toggleExpansion();
                        }}
                        onHoverExpand={onHoverExpand}
                        onNavigation={(navname: any) =>
                            changeNav(() => navname)
                        }
                        broadcastMessageExists={broadcastMessageExists}
                        expandNavigationonClick={expandNavigationonClick}
                        navigateOption={user?.NavigateOption}
                    />

                    <FullNav
                        isExpanded={
                            isHoverExpanded === HoverStates.ENTER || expanded
                        }
                        expandType={isHoverExpanded}
                        currentModule={currentNav}
                    />
                </div>
            </Box>
        </div>
    );
};

export default TopNav;
