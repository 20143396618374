import type { FC } from 'react';
import {
    colors as MuiColors,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

import { Themes } from '../../../types/theme';

import { ITheme } from './theme';

const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        '@global': {
            '.k-grid': {
                fontSize: '14px',
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
                boxShadow:
                    '0 1px 3px 0 rgb(0 0 0 / 17%), 0 1px 1px 0 rgb(0 0 0 / 19%), 0 2px 1px -1px rgb(0 0 0 / 0%)',
            },
            '.k-content': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.common.white,
            },
            '.k-text-selection ::selection, .k-block ::selection, .k-panel ::selection, .k-combobox ::selection, .k-dropdown ::selection, .k-dropdowntree ::selection, .k-multiselect ::selection, .k-maskedtextbox ::selection, .k-numerictextbox ::selection, .k-dateinput ::selection, .k-datepicker ::selection, .k-datetimepicker ::selection, .k-timepicker ::selection, .k-pane-wrapper ::selection, .k-view ::selection, .k-progressbar ::selection, .k-window ::selection, .k-pager-wrap ::selection': {
                backgroundColor: theme.palette.primary.main,
            },
            '.k-block, .k-panel': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-block>.k-header, .k-panel>.k-header': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-hr': {
                borderColor: theme.palette.divider,
            },
            '.k-drop-hint-line': {
                backgroundColor: theme.palette.primary.main,
            },
            '.k-drop-hint-h .k-drop-hint-start': {
                borderLeftColor: theme.palette.primary.main,
            },
            '.k-drop-hint-h .k-drop-hint-end': {
                borderRightColor: theme.palette.primary.main,
            },
            '.k-drop-hint-v .k-drop-hint-start': {
                borderTopColor: theme.palette.primary.main,
            },
            '.k-drop-hint-v .k-drop-hint-end': {
                borderBottomColor: theme.palette.primary.main,
            },
            '.k-drag-clue': {
                color: theme.palette.text.primary,
                backgroundColor: MuiColors.blueGrey[200],
            },
            '.k-reorder-cue': {
                backgroundColor: theme.palette.primary.main,
            },
            '.k-loading-color': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-marquee-color': {
                color: theme.palette.text.primary,
            },
            '.k-marquee-text': {
                color: theme.palette.text.primary,
            },
            '.k-badge-solid.k-badge-primary': {
                borderColor: theme.palette.primary.main,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.main,
            },
            '.k-badge-outline.k-badge-primary': {
                color: theme.palette.primary.main,
            },
            '.k-button, .k-button-secondary': {
                borderColor: theme.palette.background.light,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
                boxShadow:
                    '0 3px 1px -2px rgba(0,0,0,0.2),0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12)',
            },
            '.k-button-primary, .k-button.k-primary': {
                borderColor: theme.palette.primary.main,
                color: theme.palette.getContrastText(
                    theme.palette.text.primary
                ),
                backgroundColor: theme.palette.primary.main,
            },
            '.k-button-flat-primary, .k-button-flat.k-primary, .k-primary.k-button.k-flat, .k-primary.k-button.k-bare, .k-calendar-header .k-primary.k-button, .k-edit-buttons .k-primary.k-button, .k-button.k-flat.k-primary': {
                color: theme.palette.primary.main,
            },
            '.k-button-outline-primary, .k-button-outline.k-primary, .k-primary.k-button.k-outline, .k-button.k-outline.k-primary': {
                borderColor: 'currentColor',
                color: theme.palette.primary.main,
                backgroundColor: 'transparent',
            },
            '.k-button.k-button-clear:hover, .k-button.k-button-clear.k-state-hover, .k-button.k-button-clear:active, .k-button.k-button-clear.k-state-active, .k-button.k-button-clear:focus, .k-button.k-button-clear.k-state-focused': {
                color: theme.palette.text.primary,
            },
            '.k-textbox-container::after, .k-floating-label-container::after': {
                backgroundColor: theme.palette.primary.main,
            },
            '.k-textbox-container.k-state-focused>.k-label, .k-textarea-container.k-state-focused>.k-label, .k-floating-label-container.k-state-focused>.k-label': {
                color: theme.palette.primary.main,
            },
            '.k-popup': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
                borderColor: theme.palette.divider,
                borderRadius: theme.shape.borderRadius,
            },
            '.k-group-header+div>.k-list>.k-item.k-first::before': {
                borderColor: theme.palette.divider,
            },
            '.k-list-filter>.k-textbox': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '.k-list-container .k-nodata .k-button': {
                borderColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-list>.k-outer-group-header': {
                borderColor: theme.palette.divider,
            },
            '.k-list .k-item:hover, .k-list .k-item.k-state-hover, .k-list-optionlabel:hover, .k-list-optionlabel.k-state-hover': {
                color: theme.palette.text.primary,
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '.k-list-container .k-button:hover, .k-list-container .k-button.k-state-hover': {
                color: theme.palette.text.primary,
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '.k-list-container .k-button:active, .k-list-container .k-button.k-state-active': {
                color: MuiColors.blueGrey[200],
                backgroundColor: theme.palette.background.paper,
            },
            '.k-list .k-item:hover.k-state-selected, .k-list .k-item.k-state-hover.k-state-selected, .k-list-optionlabel:hover.k-state-selected, .k-list-optionlabel.k-state-hover.k-state-selected': {
                color: theme.palette.primary.main,
            },
            '.k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected': {
                color: theme.palette.primary.main,
            },
            '.MuiChip-deleteIcon': {
                color: 'rgba(97, 97, 97, 0.9)',
            },
            '.k-autocomplete.k-header, .k-autocomplete': {
                borderColor: 'rgba(0,0,0,0.42)',
                color: theme.palette.text.primary,
            },
            '.k-autocomplete.k-header:hover, .k-autocomplete.k-header.k-state-hover, .k-autocomplete:hover, .k-autocomplete.k-state-hover': {
                borderColor: theme.palette.text.primary,
            },
            '.k-autocomplete.k-header.k-state-open, .k-autocomplete.k-header.k-state-focused, .k-autocomplete.k-state-open, .k-autocomplete.k-state-focused': {
                borderColor: theme.palette.primary.main,
            },
            '.k-autocomplete.k-header:focus-within, .k-autocomplete:focus-within': {
                borderColor: theme.palette.primary.main,
            },
            '.k-combobox': {
                color: theme.palette.text.primary,
            },
            '.k-combobox .k-dropdown-wrap .k-input': {
                color: theme.palette.text.primary,
            },
            '.k-combobox .k-dropdown-wrap:hover, .k-combobox .k-dropdown-wrap.k-state-hover': {
                borderColor: theme.palette.text.primary,
            },
            '.k-combobox .k-dropdown-wrap.k-state-focused': {
                borderColor: theme.palette.primary.main,
            },
            '.k-combobox.k-state-focused>.k-dropdown-wrap': {
                borderColor: theme.palette.primary.main,
            },
            '.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap': {
                color: theme.palette.text.primary,
                borderColor: fade(theme.palette.text.primary, 0.5),
            },
            'select.k-dropdown, select.k-dropdowntree': {
                color: theme.palette.text.primary,
            },
            '.k-dropdown .k-dropdown-wrap:hover, .k-dropdowntree .k-dropdown-wrap:hover, .k-dropdown .k-dropdown-wrap.k-state-hover, .k-dropdowntree .k-dropdown-wrap.k-state-hover': {
                borderColor: theme.palette.text.primary,
            },
            '.k-dropdown .k-dropdown-wrap.k-state-focused, .k-dropdowntree .k-dropdown-wrap.k-state-focused': {
                borderColor: theme.palette.primary.main,
            },
            'select.k-dropdown:hover, select.k-dropdowntree:hover': {
                borderColor: theme.palette.text.primary,
            },
            'select.k-dropdown:focus, select.k-dropdowntree:focus': {
                borderColor: theme.palette.primary.main,
            },
            '.k-multiselect, .k-dropdowntree': {
                color: theme.palette.text.primary,
            },
            '.k-multiselect .k-multiselect-wrap .k-input, .k-dropdowntree .k-multiselect-wrap .k-input': {
                color: theme.palette.text.primary,
            },
            '.k-multiselect.k-state-focused>.k-multiselect-wrap, .k-state-focused.k-dropdowntree>.k-multiselect-wrap': {
                borderColor: theme.palette.primary.main,
            },
            '.k-multiselect .k-multiselect-wrap .k-button.k-state-focused, .k-dropdowntree .k-multiselect-wrap .k-button.k-state-focused, .k-multiselect .k-multiselect-wrap .k-button:focus, .k-dropdowntree .k-multiselect-wrap .k-button:focus': {
                color: theme.palette.background.light,
                background: theme.palette.background.default,
            },
            '.k-multiselect .k-multiselect-wrap:hover, .k-dropdowntree .k-multiselect-wrap:hover, .k-multiselect .k-multiselect-wrap.k-state-hover, .k-dropdowntree .k-multiselect-wrap.k-state-hover': {
                borderColor: theme.palette.text.primary,
            },
            '.k-multiselect .k-multiselect-wrap.k-state-focused, .k-dropdowntree .k-multiselect-wrap.k-state-focused': {
                borderColor: theme.palette.primary.main,
            },
            '.k-checkbox': {
                borderColor: fade(theme.palette.text.primary, 0.64),
            },
            '.k-checkbox:hover': {
                borderColor: theme.palette.text.primary,
            },
            '.k-checkbox:focus': {
                borderColor: theme.palette.text.primary,
            },
            '.k-checkbox-label .k-ripple-blob': {
                backgroundColor: theme.palette.primary.main,
            },
            '.k-ripple-container .k-checkbox::after': {
                background: theme.palette.primary.main,
            },
            '.k-checkbox:indeterminate, .k-checkbox.k-state-indeterminate': {
                borderColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '.k-checkbox:checked': {
                borderColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '.k-checkbox:checked:focus': {
                borderColor: theme.palette.primary.main,
            },
            '.k-treeview': {
                color: theme.palette.text.primary,
            },
            '.k-numerictextbox .k-numeric-wrap': {
                color: theme.palette.text.primary,
                borderColor: fade(theme.palette.text.primary, 0.5),
            },
            '.k-numerictextbox .k-numeric-wrap .k-select': {
                color: theme.palette.text.primary,
            },
            '.k-numerictextbox .k-numeric-wrap .k-select>.k-state-selected, .k-numerictextbox .k-numeric-wrap .k-select>.k-state-active': {
                borderColor: MuiColors.blueGrey[300],
                color: theme.palette.text.primary,
                backgroundColor: MuiColors.blueGrey[300],
            },
            '.k-numerictextbox .k-numeric-wrap:hover, .k-numerictextbox .k-numeric-wrap.k-state-hover': {
                borderColor: theme.palette.text.primary,
            },
            '.k-numerictextbox .k-numeric-wrap:hover .k-select, .k-numerictextbox .k-numeric-wrap.k-state-hover .k-select': {
                borderColor: MuiColors.blueGrey[300],
            },
            '.k-numerictextbox .k-numeric-wrap.k-state-focused': {
                borderColor: theme.palette.primary.main,
            },
            '.k-numerictextbox .k-numeric-wrap.k-invalid, .k-numerictextbox .k-numeric-wrap.k-invalid:hover, .k-numerictextbox .k-numeric-wrap.k-state-invalid': {
                borderColor: theme.palette.error.main,
            },
            '.k-numerictextbox.k-state-invalid .k-numeric-wrap, .k-numerictextbox.ng-invalid.ng-touched .k-numeric-wrap, .k-numerictextbox.ng-invalid.ng-dirty .k-numeric-wrap': {
                borderColor: theme.palette.error.main,
            },
            '.k-numerictextbox .k-numeric-wrap .k-input': {
                color: theme.palette.text.primary,
            },
            '.k-numerictextbox.k-state-focused .k-numeric-wrap': {
                borderColor: theme.palette.primary.main,
            },
            '.k-textarea': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-textarea:disabled::selection, .k-textarea[disabled]::selection, .k-textarea.k-state-disabled::selection': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-textarea::selection, .k-textarea .k-input::selection': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.default,
            },
            '.k-textarea:hover, .k-textarea.k-state-hover': {
                borderColor: 'rgba(0,0,0,0.42)',
                backgroundColor: MuiColors.blueGrey[200],
            },
            '.k-textarea:focus, .k-textarea.k-state-focus, .k-textarea.k-state-focused': {
                borderColor: theme.palette.primary.main,
                boxShadow: `0 0 0 1px ${theme.palette.success.main}`,
            },
            '.k-textarea:focus-within': {
                borderColor: theme.palette.primary.main,
                boxShadow: `0 0 0 1px ${theme.palette.success.main}`,
            },
            '.k-calendar': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-calendar .k-header': {
                color: theme.palette.text.primary,
            },
            '.k-calendar .k-content': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-textbox-container > .k-label, .k-textbox-container.k-state-focused > .k-label, .k-floating-label-container > .k-label, .k-floating-label-container.k-state-focused > .k-label': {
                top: '10px',
                left: '10px',
            },
            '.k-calendar .k-content th, .k-calendar .k-calendar-content th, .k-calendar .k-content .k-calendar-th, .k-calendar .k-calendar-content .k-calendar-th': {
                color: theme.palette.text.primary,
            },
            '.k-calendar .k-content .k-weekend, .k-calendar .k-calendar-content .k-weekend': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-calendar .k-footer .k-nav-today:hover, .k-calendar .k-footer .k-nav-today:focus, .k-calendar .k-calendar-header .k-today:hover, .k-calendar .k-calendar-header .k-today:focus': {
                color: theme.palette.text.primary,
            },
            '.k-button k-bare k-title:hover': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-calendar .k-footer .k-nav-today, .k-calendar .k-calendar-header .k-today': {
                color: theme.palette.primary.main,
            },
            '.k-calendar .k-content .k-today, .k-calendar .k-calendar-content .k-today': {
                color: theme.palette.primary.main,
            },
            '.k-calendar .k-weekend': {
                color: 'inherit',
                backgroundColor: theme.palette.background.dark,
            },
            '.k-calendar .k-alt': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-calendar .k-state-hover .k-link, .k-calendar td:hover .k-link, .k-calendar .k-calendar-td:hover .k-link': {
                borderColor: 'rgba(0,0,0,0.15)',
                color: theme.palette.text.primary,
                backgroundColor: 'rgba(0,0,0,0.04)',
            },
            '.k-calendar .k-state-selected .k-link': {
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
            },
            '.k-calendar .k-state-selected.k-state-hover .k-link, .k-calendar .k-state-selected:hover .k-link': {
                borderColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.dark,
            },
            '.k-calendar .k-calendar-navigation': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-calendar .k-calendar-navigation li:hover': {
                color: theme.palette.primary.main,
            },
            '.k-calendar .k-calendar-navigation-highlight': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.common.white,
            },
            '.k-calendar.k-invalid, .k-calendar.k-invalid:hover, .k-calendar.k-state-invalid, .k-calendar.ng-invalid.ng-touched, .k-calendar.ng-invalid.ng-dirty': {
                borderColor: theme.palette.error.main,
            },
            '.k-calendar.k-calendar-range .k-range-start, .k-calendar.k-calendar-range .k-range-end, .k-calendar.k-calendar-range .k-range-mid': {
                backgroundImage: 'none',
                backgroundColor: fade(theme.palette.primary.main, 0.12),
            },
            '.k-ie .k-calendar.k-calendar-range .k-range-start, .k-edge .k-calendar.k-calendar-range .k-range-start, .k-ie .k-calendar.k-calendar-range .k-range-end, .k-edge .k-calendar.k-calendar-range .k-range-end, .k-ie .k-calendar.k-calendar-range .k-range-mid, .k-edge .k-calendar.k-calendar-range .k-range-mid': {
                backgroundImage: 'none',
                backgroundColor: fade(theme.palette.primary.main, 0.25),
            },
            '.k-calendar.k-calendar-range .k-range-start .k-link, .k-calendar.k-calendar-range .k-range-end .k-link': {
                backgroundColor: theme.palette.primary.main,
            },
            '.k-calendar.k-calendar-range .k-range-split-end::after, .k-calendar.k-calendar-range .k-range-split-start::after': {
                backgroundImage: 'none',
                backgroundColor: fade(theme.palette.primary.main, 0.06),
            },
            '.k-daterangepicker.k-state-invalid .k-dateinput-wrap': {
                borderColor: theme.palette.error.main,
            },
            '.k-calendar .k-state-focused .k-link': {
                borderColor: 'rgba(0,0,0,0.15)',
                color: theme.palette.text.primary,
                backgroundColor: 'rgba(0,0,0,0.04)',
            },
            '.k-calendar .k-state-selected.k-state-focused .k-link': {
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
            },
            '.k-dateinput, .k-datepicker, .k-datetimepicker, .k-timepicker': {
                color: theme.palette.text.primary,
            },
            '.k-time-highlight': {
                borderColor: theme.palette.divider,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-timeselector .k-time-header .k-title, .k-datetime-wrap .k-time-header .k-title': {
                color: theme.palette.text.primary,
            },
            '.k-timeselector .k-time-header .k-time-now, .k-datetime-wrap .k-time-header .k-time-now': {
                color: fade(theme.palette.primary.main, 0.87),
            },
            '.k-timeselector .k-time-list-wrapper .k-title, .k-datetime-wrap .k-time-list-wrapper .k-title': {
                color: theme.palette.text.primary,
            },
            '.k-actions .k-button:not(.k-primary), .k-actions .k-button-secondary, .k-action-buttons .k-button:not(.k-primary), .k-action-buttons .k-button-secondary, .k-columnmenu-actions .k-button:not(.k-primary), .k-columnmenu-actions .k-button-secondary': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.default,
            },

            '.k-dateinput .k-dateinput-wrap .k-input, .k-dateinput .k-picker-wrap .k-input, .k-datepicker .k-dateinput-wrap .k-input, .k-datepicker .k-picker-wrap .k-input, .k-datetimepicker .k-dateinput-wrap .k-input, .k-datetimepicker .k-picker-wrap .k-input, .k-timepicker .k-dateinput-wrap .k-input, .k-timepicker .k-picker-wrap .k-input': {
                color: theme.palette.text.primary,
                border: '1px solid',
                borderColor: () => {
                    switch (theme.name) {
                        case Themes.Dark:
                            return 'rgba(255, 255, 255, 0.23)';
                        default:
                            return 'rgba(0, 0, 0, 0.23)';
                    }
                },
                zIndex: '30',
                borderRadius: theme.shape.borderRadius,
                font: 'inherit',
                height: '1.1876em',
                margin: '0',
                display: 'block',
                padding: '15px',
                background: 'none',
                boxSizing: 'content-box',
                animationName: 'mui-auto-fill-cancel',
                letterSpacing: 'inherit',
                animationDuration: '10ms',
            },
            '.k-textbox-container:not(:last-child), .k-floating-label-container:not(:last-child)': {
                marginRight: theme.spacing(1),
            },
            '.k-text-disabled': {
                opacity: 1,
            },
            '.k-dateinput .k-dateinput-wrap:hover, .k-dateinput .k-dateinput-wrap.k-state-hover, .k-dateinput .k-picker-wrap:hover, .k-dateinput .k-picker-wrap.k-state-hover, .k-datepicker .k-dateinput-wrap:hover, .k-datepicker .k-dateinput-wrap.k-state-hover, .k-datepicker .k-picker-wrap:hover, .k-datepicker .k-picker-wrap.k-state-hover, .k-datetimepicker .k-dateinput-wrap:hover, .k-datetimepicker .k-dateinput-wrap.k-state-hover, .k-datetimepicker .k-picker-wrap:hover, .k-datetimepicker .k-picker-wrap.k-state-hover, .k-timepicker .k-dateinput-wrap:hover, .k-timepicker .k-dateinput-wrap.k-state-hover, .k-timepicker .k-picker-wrap:hover, .k-timepicker .k-picker-wrap.k-state-hover': {
                borderColor: theme.palette.text.primary,
            },
            '.k-dateinput .k-dateinput-wrap.k-state-focused, .k-state-focused>.k-dateinput .k-dateinput-wrap, .k-dateinput .k-picker-wrap.k-state-focused, .k-state-focused>.k-dateinput .k-picker-wrap, .k-datepicker .k-dateinput-wrap.k-state-focused, .k-state-focused>.k-datepicker .k-dateinput-wrap, .k-datepicker .k-picker-wrap.k-state-focused, .k-state-focused>.k-datepicker .k-picker-wrap, .k-datetimepicker .k-dateinput-wrap.k-state-focused, .k-state-focused>.k-datetimepicker .k-dateinput-wrap, .k-datetimepicker .k-picker-wrap.k-state-focused, .k-state-focused>.k-datetimepicker .k-picker-wrap, .k-timepicker .k-dateinput-wrap.k-state-focused, .k-state-focused>.k-timepicker .k-dateinput-wrap, .k-timepicker .k-picker-wrap.k-state-focused, .k-state-focused>.k-timepicker .k-picker-wrap': {
                borderColor: theme.palette.primary.main,
            },
            '.k-dateinput .k-dateinput-wrap, .k-dateinput .k-picker-wrap, .k-datepicker .k-dateinput-wrap, .k-datepicker .k-picker-wrap, .k-datetimepicker .k-dateinput-wrap, .k-datetimepicker .k-picker-wrap, .k-timepicker .k-dateinput-wrap, .k-timepicker .k-picker-wrap': {
                borderColor: fade(theme.palette.text.primary, 0.5),
                borderStyle: 'none',
                borderWidth: 0,
            },
            '.k-timeselector .k-time-list-wrapper .k-time-list::before, .k-timeselector .k-time-list-wrapper .k-time-list::after, .k-datetime-wrap .k-time-list-wrapper .k-time-list::before, .k-datetime-wrap .k-time-list-wrapper .k-time-list::after': {
                boxShadow: 'none',
            },
            '.k-time-list-container .k-time-list-wrapper .k-time-list .k-content': {
                backgroundColor: () => {
                    switch (theme.name) {
                        case Themes.Dark:
                            return theme.palette.background.dark;
                        default:
                            return theme.palette.background.paper;
                    }
                },
            },
            '.k-calendar-navigation, .k-calendar-navigation .k-content ul, .k-calendar-navigation .k-calendar-content ul': {
                width: '84px',
            },
            '.k-calendar-navigation .k-content li, .k-calendar-navigation .k-calendar-content li': {
                padding: '0 2px',
            },
            '.k-calendar-navigation .k-calendar-navigation-marker': {
                fontWeight: 500,
            },
            '.k-calendar.k-week-number .k-calendar-view': {
                marginLeft: '-22px',
            },
            '.k-calendar-container .k-calendar, .k-datetime-container .k-calendar': {
                borderRadius: theme.shape.borderRadius,
            },
            '.k-datetime-container .k-date-tab .k-datetime-buttongroup, .k-datetime-container .k-date-tab .k-datetime-selector': {
                backgroundColor: () => {
                    switch (theme.name) {
                        case Themes.Dark:
                            return theme.palette.background.dark;
                        default:
                            return theme.palette.background.paper;
                    }
                },
            },
            '.k-datetime-picker .k-date-time-label': {
                position: 'absolute',
                marginTop: '-9px',
                marginLeft: '10px',
                zIndex: 100,
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
            },
            '.k-calendar-infinite .k-calendar-view::after, .k-calendar-infinite .k-calendar-monthview::after': {
                boxShadow: 'none',
            },
            '.k-timeselector .k-time-list-wrapper.k-state-focused .k-title, .k-datetime-wrap .k-time-list-wrapper.k-state-focused .k-title': {
                color: theme.palette.text.primary,
            },
            '.k-timeselector .k-time-list-wrapper .k-time-list .k-item:hover, .k-datetime-wrap .k-time-list-wrapper .k-time-list .k-item:hover': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: 'rgba(0,0,0,0.04)',
            },
            '.k-listgroup': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-radio:hover': {
                borderColor: theme.palette.text.primary,
            },
            '.k-ripple-container .k-radio::after': {
                background: theme.palette.primary.main,
            },
            '.k-radio:checked': {
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
            },
            '.k-radio:checked:focus': {
                borderColor: theme.palette.primary.main,
            },
            '.k-toolbar': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-floating-toolbar, .editorToolbarWindow.k-window-content': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-toolbar .k-separator': {
                borderColor: theme.palette.divider,
            },
            '.k-toolbar .k-button, .k-toolbar .k-button-secondary': {
                backgroundColor: theme.palette.background.default,
            },
            '.k-toolbar .k-button-primary, .k-toolbar .k-button.k-primary': {
                backgroundColor: theme.palette.primary.main,
            },
            '.k-pane-wrapper, .k-view': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-pane-wrapper .k-pane>.k-view>.k-content': {
                backgroundPosition: '50% 50%',
                backgroundColor: theme.palette.background.paper,
            },
            '.k-pane-wrapper .k-pane .k-grid-mobile .k-column-active+th.k-header': {
                borderLeftColor: theme.palette.text.primary,
            },
            '.k-pane-wrapper .k-pane .k-grid-mobile .k-resize-handle-inner::before': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-pane-wrapper .k-pane .k-scheduler-mobile .k-nav-current': {
                color: theme.palette.primary.main,
            },
            '.k-pane-wrapper .k-pane .k-grid-edit-form .k-header, .k-pane-wrapper .k-pane .k-grid-column-menu .k-header, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-header, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-header': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '.k-pane-wrapper .k-pane .k-grid-edit-form .k-item, .k-pane-wrapper .k-pane .k-grid-edit-form .k-link, .k-pane-wrapper .k-pane .k-grid-column-menu .k-item, .k-pane-wrapper .k-pane .k-grid-column-menu .k-link, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-item, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-link, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-item, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-link': {
                color: theme.palette.text.primary,
            },
            '.k-pane-wrapper .k-pane .k-grid-edit-form .k-clear, .k-pane-wrapper .k-pane .k-grid-edit-form .k-select-all, .k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-delete, .k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-resetSeries, .k-pane-wrapper .k-pane .k-grid-column-menu .k-clear, .k-pane-wrapper .k-pane .k-grid-column-menu .k-select-all, .k-pane-wrapper .k-pane .k-grid-column-menu .k-scheduler-delete, .k-pane-wrapper .k-pane .k-grid-column-menu .k-scheduler-resetSeries, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-clear, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-select-all, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-delete, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-resetSeries, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-clear, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-select-all, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-delete, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-resetSeries': {
                color: theme.palette.primary.main,
            },
            '.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-state-selected': {
                color: theme.palette.primary.main,
            },
            '.k-progressbar': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.main,
            },
            '.k-progressbar-indeterminate::before, .k-progressbar-indeterminate::after': {
                backgroundColor: theme.palette.primary.main,
            },
            '.k-progressbar .k-state-selected': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.main,
            },
            '.k-textbox': {
                color: theme.palette.text.primary,
                borderColor: fade(theme.palette.text.primary, 0.5),
            },
            '.k-textbox:disabled::selection, .k-textbox[disabled]::selection, .k-textbox.k-state-disabled::selection': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-textbox::selection, .k-textbox .k-input::selection': {
                color: theme.palette.getContrastText(
                    theme.palette.primary.main
                ),
                backgroundColor: theme.palette.primary.main,
            },
            '.k-textbox .k-input-prefix>.k-icon, .k-textbox .k-input-suffix>.k-icon': {
                color: 'rgba(0,0,0,0.42)',
            },
            '.k-textbox .k-input-prefix .k-clear-value, .k-textbox .k-input-suffix .k-clear-value': {
                color: 'rgba(0,0,0,0.67)',
            },
            '.k-textbox:focus, .k-textbox.k-state-focus, .k-textbox.k-state-focused': {
                borderColor: theme.palette.primary.main,
            },
            '.k-textbox:focus-within': {
                borderColor: theme.palette.primary.main,
            },
            '.k-textbox:hover, .k-textbox.k-state-hover': {
                borderColor: theme.palette.text.primary,
            },
            '.k-textbox.k-invalid, .k-textbox.k-state-invalid, .k-textbox.ng-invalid.ng-touched, .k-textbox.ng-invalid.ng-dirty': {
                borderColor: theme.palette.error.main,
            },
            '.k-textbox-container > .k-label, .k-textarea-container > .k-label, .k-floating-label-container > .k-label': {
                color: theme.palette.text.secondary,
            },
            '.k-textbox-container > .k-label, .k-textarea-container > .k-label, .k-floating-label-container > .k-label:after': {
                content: '" "',
                marginLeft: 0,
                marginTop: '-3px',
                zIndex: 100,
                background: theme.palette.background.paper,
            },
            '.k-tooltip': {
                borderColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.default,
            },
            '.k-tooltip-primary': {
                borderColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
            },
            '.k-tooltip-primary .k-callout': {
                color: theme.palette.primary.main,
            },
            '.k-menu-vertical>.k-separator': {
                borderColor: theme.palette.divider,
            },
            '.k-menu-group .k-separator': {
                borderColor: theme.palette.divider,
            },
            '.k-menu:not(.k-context-menu)': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-menu:not(.k-context-menu)>.k-item': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-menu:not(.k-context-menu)>.k-item:hover, .k-menu:not(.k-context-menu)>.k-item.k-state-hover': {
                backgroundColor: 'rgba(0,0,0,0.1044)',
            },
            '.k-menu:not(.k-context-menu)>.k-item>.k-state-active': {
                backgroundColor: 'rgba(0,0,0,0.1392)',
            },
            '.k-menu-group, .k-menu.k-context-menu': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-menu-group .k-item:hover, .k-menu-group .k-item.k-state-hover, .k-menu.k-context-menu .k-item:hover, .k-menu.k-context-menu .k-item.k-state-hover': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-menu-group .k-item>.k-state-active, .k-menu.k-context-menu .k-item>.k-state-active': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-menu-scroll-button:hover': {
                color: theme.palette.text.primary,
            },
            '.k-menu:not(.k-context-menu)>.k-menu-item:focus, .k-menu:not(.k-context-menu)>.k-menu-item.k-state-focused': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-menu-group .k-menu-item:focus, .k-menu-group .k-menu-item.k-state-focused, .k-context-menu .k-menu-item:focus, .k-context-menu .k-menu-item.k-state-focused': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-form, .k-form-inline': {
                color: theme.palette.text.primary,
            },
            '.k-form-separator': {
                borderColor: theme.palette.divider,
            },
            '.k-form fieldset legend, .k-form-inline fieldset legend': {
                color: theme.palette.text.primary,
            },
            '.k-form .k-form-legend, .k-form-inline .k-form-legend': {
                borderColor: theme.palette.divider,
            },
            '.k-form .k-field-info, .k-form-inline .k-field-info': {
                color: theme.palette.info.main,
            },
            '.k-form .k-alert-error, .k-form-inline .k-alert-error': {
                color: theme.palette.error.main,
            },
            '.k-form-error, .k-text-error, .k-form-field-error .k-label': {
                color: theme.palette.error.main,
            },
            '.k-window': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
                boxShadow:
                    '0 8px 10px -5px rgba(0,0,0,0.2),0 16px 24px 2px rgba(0,0,0,0.14),0 6px 30px 5px rgba(0,0,0,0.12)',
            },
            '.k-dialog-content.k-scroll': {
                borderColor: theme.palette.divider,
            },
            '.k-dialog-buttongroup .k-button, .k-dialog-buttongroup .k-button-secondary': {
                backgroundColor: theme.palette.background.default,
            },
            '.k-dialog-buttongroup .k-button-primary, .k-dialog-buttongroup .k-button.k-primary': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.main,
            },
            '.k-pager-wrap': {
                borderColor: theme.palette.divider,
                color: fade(theme.palette.text.primary, 0.64),
                backgroundColor: theme.palette.background.paper,
            },
            '.k-pager-numbers .k-link': {
                color: theme.palette.text.primary,
            },
            '.k-pager-md .k-pager-numbers .k-current-page .k-link, .k-pager-sm .k-pager-numbers .k-current-page .k-link': {
                borderColor: 'rgba(0,0,0,0.42)',
                color: theme.palette.text.primary,
            },
            '.k-pager-nav.k-link:hover, .k-pager-refresh:hover, .k-state-hover.k-pager-nav.k-link, .k-state-hover.k-pager-refresh': {
                color: 'rgba(0,0,0,0.54)',
                backgroundColor: 'rgba(0,0,0,0.04)',
            },
            '.k-state-selected.k-pager-nav.k-link, .k-state-selected.k-pager-refresh': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.main,
            },
            '.k-pager-nav.k-link:focus, .k-pager-refresh:focus, .k-state-focus.k-pager-nav.k-link, .k-state-focus.k-pager-refresh': {
                backgroundColor: 'rgba(0,0,0,0.12)',
            },
            '.k-pager-numbers .k-link:hover, .k-pager-numbers .k-link.k-state-hover': {
                color: theme.palette.text.primary,
                backgroundColor: fade(theme.palette.background.dark, 0.24),
            },
            '.k-pager-numbers .k-link:focus, .k-pager-numbers .k-link.k-state-focus': {
                backgroundColor: fade(theme.palette.background.dark, 0.32),
            },
            '.k-pager-numbers .k-link.k-state-selected': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.dark,
            },
            '.k-pager-md .k-pager-numbers .k-current-page .k-link:hover, .k-pager-md .k-pager-numbers .k-current-page .k-link.k-state-hover, .k-pager-sm .k-pager-numbers .k-current-page .k-link:hover, .k-pager-sm .k-pager-numbers .k-current-page .k-link.k-state-hover': {
                borderColor: theme.palette.text.primary,
            },
            '.k-pager-md .k-pager-numbers.k-state-expanded, .k-pager-sm .k-pager-numbers.k-state-expanded': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
                boxShadow:
                    '0 2px 4px -1px rgba(0,0,0,0.2),0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12)',
            },
            '.k-pager-md .k-pager-numbers.k-state-expanded .k-link:hover, .k-pager-md .k-pager-numbers.k-state-expanded .k-link.k-state-hover, .k-pager-sm .k-pager-numbers.k-state-expanded .k-link:hover, .k-pager-sm .k-pager-numbers.k-state-expanded .k-link.k-state-hover': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-pager-md .k-pager-numbers.k-state-expanded .k-link.k-state-selected, .k-pager-sm .k-pager-numbers.k-state-expanded .k-link.k-state-selected': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-grid-header .k-header>.k-link': {
                color: fade(theme.palette.text.primary, 0.84),
            },
            '.k-grid-footer': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-grid-norecords-template': {
                borderColor: theme.palette.divider,
                backgroundColor: theme.palette.background.light,
            },
            '.k-column-menu-group-header-text': {
                borderColor: theme.palette.text.primary,
                color: theme.palette.text.primary,
            },
            '.k-column-menu .k-menu:not(.k-context-menu)': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-resize-handle:hover .k-resize-handle-inner': {
                backgroundColor: theme.palette.primary.main,
            },
            '.k-grid-header, .k-header, th.k-header, .k-grid-header-wrap, .k-grouping-header, .k-grouping-header .k-group-indicator, .k-grid-footer, .k-grid-footer-wrap, .k-grid-content-locked, .k-grid-footer-locked, .k-grid-header-locked, .k-filter-row>td, .k-filter-row>th': {
                borderColor: theme.palette.divider,
            },
            '.k-grid-header, .k-grouping-header, .k-grid-add-row, .k-grid-footer': {
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-group-footer td, .k-grouping-row td, tbody .k-group-cell': {
                color: theme.palette.text.primary,
            },
            '.k-grid tr': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-grid td': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
            '.k-grid .k-grid-header-sticky, .k-grid .k-grid-content-sticky, .k-grid .k-grid-footer-sticky': {
                borderRightWidth: '0px',
                borderLeftWidth: '0px',
                borderTopWidth: 'thin',
                borderBottomColor: 'rgba(0,0,0,0.12)',
                borderColor: 'rgba(0,0,0,0.12)',
                backgroundColor: '#FFFF',
            },

            '.k-grid .k-grid-header-sticky, .k-grid .k-grid-footer-sticky': {
                borderRightWidth: '1px',
                borderLeftWidth: '1px',
            },
            '.k-grid table tr:hover td': {
                backgroundColor: 'rgb(241, 240, 251) !important',
            },
            '.k-grid .k-master-row .k-grid-content-sticky': {
                borderColor: 'rgba(0,0,0,0.12)',
                borderBottomColor: 'rgba(0,0,0,0.12)',
            },
            '.MuiStepConnector-alternativeLabel': {
                marginTop: '9px',
            },

            '.k-grid .k-grid-content tr:hover': {
                boxShadow: `inset 0 0 2px 0 ${fade(
                    theme.palette.text.primary,
                    0.24
                )}, inset 0 0 4px 0 ${fade(theme.palette.text.primary, 0.12)}`,
            },
            '.k-grid tbody tr:hover, .k-grid tbody tr.k-state-hover': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-grid td.k-state-selected, .k-grid tr.k-state-selected': {
                backgroundColor: 'transparent',
            },
            '.k-grid tr.k-alt': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-grid .k-grouping-row': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-grid .k-grouping-row td': {
                borderBottomWidth: 1,
            },
            '.k-grid .k-grouping-row .k-group-cell': {
                borderTopWidth: 1,
            },
            '.k-grid .k-grid-content-locked .k-group-footer td, .k-grid .k-grid-content-locked .k-group-cell': {
                borderColor: theme.palette.divider,
            },
            '.k-grid .k-grid-content-locked .k-grouping-row+tr td': {
                borderTopColor: theme.palette.divider,
            },
            '.k-grid .k-grid-content-locked .k-state-selected td': {
                backgroundColor: theme.palette.background.default,
            },
            '.k-grid .k-grid-content-locked .k-state-selected.k-alt td': {
                backgroundColor: theme.palette.background.default,
            },
            '.k-grid .k-grid-content-locked .k-state-hover td, .k-grid .k-grid-content-locked tr:hover td': {
                backgroundColor: theme.palette.background.dark,
            },
            '.k-grid .k-grid-content-locked .k-state-selected:hover td, .k-grid .k-grid-content-locked .k-state-selected.k-state-hover td': {
                backgroundColor: theme.palette.background.dark,
            },
            '.k-grid .k-grid-header-locked .k-header': {
                borderBottomColor: theme.palette.divider,
            },
            '.k-grid-header .k-grid-filter:hover, .k-grid-header .k-header-column-menu:hover, .k-grid-header .k-hierarchy-cell .k-icon:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.text.primary,
            },
            '.k-grid-header .k-grid-filter:focus, .k-grid-header .k-grid-filter.k-state-focus, .k-grid-header .k-grid-filter.k-state-border-down, .k-grid-header .k-header-column-menu:focus, .k-grid-header .k-header-column-menu.k-state-focus, .k-grid-header .k-header-column-menu.k-state-border-down, .k-grid-header .k-hierarchy-cell .k-icon:focus, .k-grid-header .k-hierarchy-cell .k-icon.k-state-focus, .k-grid-header .k-hierarchy-cell .k-icon.k-state-border-down': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-grid-header .k-grid-filter.k-state-active, .k-grid-header .k-header-column-menu.k-state-active, .k-grid-header .k-hierarchy-cell .k-icon.k-state-active': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.main,
            },
            '.k-grid-header, .k-header, th.k-header, .k-grid-header-wrap, .k-grouping-header, .k-grouping-header .k-group-indicator, .k-grid td, .k-grid-footer, .k-grid-footer-wrap, .k-grid-content-locked, .k-grid-footer-locked, .k-grid-header-locked, .k-filter-row > td, .k-filter-row > th': {
                borderColor: theme.palette.divider,
                borderLeftColor: 'transparent',
            },
            '.k-grouping-header .k-group-indicator': {
                backgroundColor: theme.palette.background.dark,
            },
            '.k-grid .k-grid-filter, .k-grid .k-header-column-menu, .k-grid .k-hierarchy-cell .k-icon.k-plus': {
                color: fade(theme.palette.text.primary, 0.64),
            },
            '.k-grid-header th.k-grid-header-sticky, .k-grid-header .k-grid-header-sticky.k-sorted': {
                color: '#263238',
                backgroundColor: 'white',
                borderRightColor: 'rgba(0, 0, 0, 0.12)',
                borderLeftColor: 'rgba(0, 0, 0, 0.12)',
            },
            '.k-grid table tr:hover .k-grid-header-sticky, .k-grid table tr:hover .k-grid-footer-sticky, .k-grid table tr.k-state-hover .k-grid-header-sticky, .k-grid table tr.k-state-hover .k-grid-footer-sticky': {
                backgroundColor: 'white',
            },
            '.k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm, .k-grid-header .k-sort-order': {
                color: fade(theme.palette.text.primary, 0.64),
            },
            '.k-grid-footer .k-grid-footer-sticky': {
                borderLeftColor: 'rgba(0,0,0,0.5)',
                borderRightColor: 'rgba(0,0,0,0.5)',
                backgroundColor: theme.palette.background.paper,
            },
            '.k-master-row .k-grid-content-sticky': {
                borderColor: 'rgba(0,0,0,0.5)',
                borderTopColor: 'rgba(0,0,0,0.12)',
                backgroundColor: theme.palette.background.paper,
            },
            '.k-master-row .k-grid-row-sticky': {
                borderTopColor: 'rgba(0,0,0,0.5)',
                borderBottomColor: 'rgba(0,0,0,0.5)',
                backgroundColor: theme.palette.background.paper,
            },
            '.k-master-row.k-alt .k-grid-content-sticky, .k-master-row.k-alt .k-grid-row-sticky': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-master-row.k-state-selected .k-grid-content-sticky, .k-master-row.k-state-selected .k-grid-row-sticky': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-master-row.k-state-selected.k-alt .k-grid-content-sticky, .k-master-row.k-state-selected.k-alt .k-grid-row-sticky': {
                backgroundColor: theme.palette.background.paper,
            },

            '.k-grouping-row .k-grid-content-sticky': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-grouping-row:hover .k-grid-content-sticky, .k-grouping-row.k-state-hover .k-grid-content-sticky': {
                backgroundColor: theme.palette.background.default,
            },
            '.k-master-row:hover .k-grid-content-sticky, .k-master-row:hover .k-grid-row-sticky, .k-master-row.k-state-hover .k-grid-content-sticky, .k-master-row.k-state-hover .k-grid-row-sticky': {
                backgroundColor: 'white',
            },
            '.k-column-list-item:hover, .k-columnmenu-item:hover': {
                color: theme.palette.text.primary,
                backgroundColor: 'rgba(0,0,0,0.04)',
            },
            '.k-columnmenu-item.k-state-selected': {
                color: theme.palette.background.default,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-column-menu .k-menu:not(.k-context-menu) .k-item': {
                color: theme.palette.text.primary,
            },
            '.k-column-menu .k-menu:not(.k-context-menu) .k-item:hover, .k-column-menu .k-menu:not(.k-context-menu) .k-item.k-state-hover': {
                color: theme.palette.text.primary,
                backgroundColor: 'rgba(0,0,0,0.04)',
            },
            '.k-column-menu .k-menu:not(.k-context-menu) .k-item.k-state-selected': {
                color: theme.palette.background.default,
                backgroundColor: theme.palette.background.paper,
                background: 'rgba(0,0,0,0.04)',
            },
            '.k-grid .k-edit-cell': {
                borderBottom: 'rgba(0,0,0,0.12)',
                backgroundColor: theme.palette.background.paper,
                boxShadow: '0 0 5px 3px rgba(0,0,0,0.12)',
            },
            '.k-grid table tr:hover, .k-grid table tr.k-state-hover, .k-grid table td.k-state-focused, .k-grid-content-sticky:hover, .k-grid table th.k-state-focused, .k-grid table th:focus, .k-grid table .k-master-row > td:focus, .k-grid table .k-grouping-row > td:focus, .k-grid table .k-detail-row > td:focus, .k-grid table .k-group-footer > td:focus': {
                backgroundColor: '#F8F7FD !important',
            },
            '.k-daterangepicker-wrap': {
                display: 'none !important',
            },
            '.k-grid table td.k-state-focused.k-grid-header-sticky, .k-grid table td.k-state-focused.k-grid-content-sticky, .k-grid table td.k-state-focused.k-grid-footer-sticky, .k-grid table th.k-state-focused.k-grid-header-sticky, .k-grid table th.k-state-focused.k-grid-content-sticky, .k-grid table th.k-state-focused.k-grid-footer-sticky, .k-grid table th:focus.k-grid-header-sticky, .k-grid table th:focus.k-grid-content-sticky, .k-grid table th:focus.k-grid-footer-sticky, .k-grid table .k-master-row>td:focus.k-grid-header-sticky, .k-grid table .k-master-row>td:focus.k-grid-content-sticky, .k-grid table .k-master-row>td:focus.k-grid-footer-sticky, .k-grid table .k-grouping-row>td:focus.k-grid-header-sticky, .k-grid table .k-grouping-row>td:focus.k-grid-content-sticky, .k-grid table .k-grouping-row>td:focus.k-grid-footer-sticky, .k-grid table .k-detail-row>td:focus.k-grid-header-sticky, .k-grid table .k-detail-row>td:focus.k-grid-content-sticky, .k-grid table .k-detail-row>td:focus.k-grid-footer-sticky, .k-grid table .k-group-footer>td:focus.k-grid-header-sticky, .k-grid table .k-group-footer>td:focus.k-grid-content-sticky, .k-grid table .k-group-footer>td:focus.k-grid-footer-sticky': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-grid[dir="rtl"] th, .k-grid[dir="rtl"] td, .k-rtl .k-grid th, .k-rtl .k-grid td': {
                borderRightColor: 'transparent',
            },
            '.k-grid[dir="rtl"] .k-grid-header thead>tr:not(:only-child)>th, .k-rtl .k-grid .k-grid-header thead>tr:not(:only-child)>th': {
                borderRightColor: 'rgba(0,0,0,0.12)',
            },
            '.k-grid[dir="rtl"] .k-grid-header-locked, .k-grid[dir="rtl"] .k-grid-content-locked, .k-grid[dir="rtl"] .k-grid-header-locked thead>tr>th.k-header, .k-grid[dir="rtl"] .k-grid-content-locked td, .k-rtl .k-grid .k-grid-header-locked, .k-rtl .k-grid .k-grid-content-locked, .k-rtl .k-grid .k-grid-header-locked thead>tr>th.k-header, .k-rtl .k-grid .k-grid-content-locked td': {
                borderLeftColor: 'rgba(0,0,0,0.5)',
                borderRightColor: 'rgba(0,0,0,0.5)',
            },
            '.k-grid[dir="rtl"] .k-grid-header thead>tr:not(:only-child)>.k-grid-header-sticky, .k-grid[dir="rtl"] .k-grid-footer-sticky, .k-rtl .k-grid .k-grid-header thead>tr:not(:only-child)>.k-grid-header-sticky, .k-rtl .k-grid .k-grid-footer-sticky': {
                borderRightColor: 'rgba(0,0,0,0.5)',
                borderLeftColor: 'rgba(0,0,0,0.5)',
            },
            '.k-grid .k-grid-header-locked .k-group-footer:hover td, .k-grid .k-grid-header-locked .k-group-footer.k-state-hover td, .k-grid .k-grid-content-locked .k-group-footer:hover td, .k-grid .k-grid-content-locked .k-group-footer.k-state-hover td, .k-grid .k-grid-header-locked .k-header .k-group-footer:hover td, .k-grid .k-grid-header-locked .k-header .k-group-footer.k-state-hover td, .k-grid .k-grid-content-locked td .k-group-footer:hover td, .k-grid .k-grid-content-locked td .k-group-footer.k-state-hover td': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-grid .k-master-row .k-grid-row-sticky': {
                borderTopColor: 'rgba(0,0,0,0.5)',
                borderBottomColor: 'rgba(0,0,0,0.5)',
            },
            '.k-grid-header .k-sorted, .k-grid-header .k-link:hover': {
                color: theme.palette.text.primary,
            },
            'col.k-sorted, th.k-sorted': {
                backgroundColor: 'transparent',
            },
            '.k-grid-header thead>tr:not(:only-child)>th': {
                borderLeftColor: 'rgba(0,0,0,0.12)',
            },
            '.k-grid-header thead>tr:not(:only-child)>th.k-grid-header-sticky': {
                borderRightColor: 'rgba(0,0,0,0.5)',
                borderLeftColor: 'rgba(0,0,0,0.5)',
            },
            'thead.k-grid-header>tr:not(:only-child)>th': {
                borderLeftColor: 'rgba(0,0,0,0.12)',
            },
            '.k-grouping-header .k-group-indicator .k-link': {
                color: theme.palette.text.primary,
            },
            '.k-grouping-header .k-group-indicator .k-link .k-icon': {
                color: fade(theme.palette.text.primary, 0.64),
            },
            '.k-grid .k-grouping-row .k-icon': {
                marginLeft: -8,
                color: fade(theme.palette.text.primary, 0.64),
            },
            '.k-group-footer td, .k-group-footer .k-grid-footer-sticky': {
                background: theme.palette.background.paper,
            },
            '.k-group-footer:hover td, .k-group-footer.k-state-hover td': {
                backgroundColor: theme.palette.background.paper,
            },
            '.k-drag-clue, .k-grid-drag-clue': {
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                boxShadow:
                    '0 2px 4px -1px rgba(0,0,0,0.2),0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12)',
            },
            '.k-grouping-dropclue, .k-reorder-cue': {
                borderColor: theme.palette.primary.main,
            },
            '.k-column-resizer:hover': {
                color: theme.palette.primary.main,
            },

            '.k-grid-edit-row>td>.k-textbox, .k-grid-edit-row>td>.k-textarea, .k-grid-edit-row>td>.k-widget': {
                backgroundColor: 'rgba(0,0,0,0.07)',
            },
            '.k-listbox .k-list-scroller': {
                borderColor: theme.palette.divider,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.light,
            },
            '.k-listbox .k-drop-hint': {
                borderTopColor: theme.palette.primary.main,
            },
            'li.k-item.k-drag-clue': {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
            },
            '.k-filter': {
                backgroundColor: 'transparent',
                color: theme.palette.text.primary,
            },
            '.k-filter .k-filter-preview-field': {
                color: theme.palette.primary.main,
            },
            '.k-filter .k-filter-preview-operator': {
                color: 'rgba(0,0,0,0.6)',
            },
            '.k-filter .k-filter-item::before, .k-filter .k-filter-toolbar::before, .k-filter .k-filter-lines .k-filter-item:last-child>.k-filter-toolbar::after': {
                backgroundColor: 'rgba(0,0,0,0.12)',
            },
            '.k-grid-header': {
                fontSize: '1em',
                color: theme.palette.text.primary,
                borderBottomWidth: 0,
            },
            '.k-grid-header th': {
                fontWeight: '700',
            },
            '.k-grid-content td': {
                borderBottomWidth: '0',
                borderTopWidth: '1px',
            },
            '.k-master-row > .k-detail-row td': {
                borderTopWidth: '0',
            },
            '.k-detail-row>.k-hierarchy-cell, .k-grid-expanded>.k-hierarchy-cell': {
                borderLeftColor: '#665AD8',
            },
            '.k-grid-custom-primary td:first-child': {
                borderLeftColor: `${theme.palette.primary.main} !important`,
            },
            '.k-grid-custom-secondary td:first-child': {
                borderLeftColor: `${theme.palette.secondary.main} !important`,
            },
            '.k-grid-custom-success td:first-child': {
                borderLeftColor: `${theme.palette.success.main} !important`,
            },
            '.k-grid-custom-warning td:first-child': {
                borderLeftColor: `${theme.palette.warning.main} !important`,
            },
            '.k-grid-custom-error td:first-child': {
                borderLeftColor: `${theme.palette.error.main} !important`,
            },
            '.k-grid-custom-info td:first-child': {
                borderLeftColor: `${theme.palette.info.main} !important`,
            },
            '.k-grid-custom-default td:first-child': {
                borderLeftColor: `${theme.palette.background.grey} !important`,
            },
            '.k-grid-custom-noBackground td:first-child': {
                borderLeftColor: 'none !important',
            },
            '.k-grid th:first-child, .k-grid td:first-child': {
                borderLeftWidth: 'thick',
            },
            '.k-grid th, .k-grid td': {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            '.k-grid-header .k-header > .k-link': {
                marginLeft: -theme.spacing(2),
                marginRight: -theme.spacing(2),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            // Change expand icon
            '.k-i-add:before, .k-i-plus:before, .k-plus:before': {
                content: '"\\E014"',
            },
            // Change collapse icon
            '.k-i-kpi-trend-equal:before, .k-i-minus:before, .k-minus:before': {
                content: '"\\E015"',
            },
            '.k-widget .k-icon.k-i-expand:before': {
                content: '"\\E014"',
            },
            '.k-widget .k-icon.k-i-collapse:before': {
                content: '"\\E015"',
            },
            // Table header styles
            '.k-grid:not(.no-border) .k-grid-header thead > tr > th': {
                borderLeftColor: theme.palette.divider,
                // borderLeftWidth: '1px',
            },
            '.k-grid-header .k-header > .k-link:focus': {
                backgroundColor: theme.palette.background.dark,
            },
            // Hide header
            '.k-grid.hidden .k-header': {
                display: 'none',
            },
            '.k-grid.hidden .k-grid-content': {
                borderTopLeftRadius: theme.shape.borderRadius,
                borderTopRightRadius: theme.shape.borderRadius,
            },
            '.k-grid.hidden .k-grid-content tbody > tr:first-child td': {
                borderTopWidth: 0,
            },
            // Pagination borders
            '.k-grid-pager': {
                borderBottomLeftRadius: theme.shape.borderRadius,
                borderBottomRightRadius: theme.shape.borderRadius,
            },
            // Cell styles
            '.k-grid-header th.highlight, .k-grid-content td.highlight ': {
                backgroundColor: theme.palette.background.dark,
                borderLeft: `1px solid ${theme.palette.divider}`,
                borderRight: `1px solid ${theme.palette.divider}`,
            },
            '.k-grid-header th.highlight': {
                borderRight: 'none',
            },
            // Clickable row
            ".k-grid.row-click .k-grid-content td[role='gridcell']": {
                cursor: 'pointer',
                textOverFlow: 'ellipsis',
            },
            ".k-grid-content td[role='gridcell']": {
                cursor: 'initial',
            },
            //disable headers when checkbox is selected
            '.k-grid.selection-actions .k-grid-header thead > tr:first-child > th:not(.selected-column)': {
                visibility: 'hidden',
            },
            '.k-grid.display-grid-headers .k-grid-header thead > tr:first-child > th:not(.selected-column)': {
                visibility: 'visible',
            },
            // increase the width of first header when checkbox is checked
            '.k-grid.selection-actions .k-grid-header thead > tr:first-child > th.selected-column': {
                minWidth: '280px',
                width: '100%',
                position: 'absolute',
                zIndex: theme.zIndex.drawer,
                backgroundColor: 'inherit',
            },
            '.MuiCheckbox-colorPrimary.Mui-checked': {
                color: '#665AD8',
            },
            '.MuiAutocomplete-option[data-focus="true"]': {
                borderColor: '#F2F2F2',
                backgroundColor: '#EEECF1 !important',
            },
            '.MuiAutocomplete-option[aria-selected="true"]': {
                backgroundColor: '#F2F2F2',
            },
            // Disable filter icon when simple filter is used
            '.k-grid.simple-filter .k-filtercell .k-filtercell-operator .k-dropdown-operator': {
                pointerEvents: 'none',
            },
            // Hide clear filter icon when not selected
            '.k-filtercell .k-filtercell-operator > .k-button:not(.k-clear-button-visible)': {
                display: 'none',
            },
            // Border for multi row cells
            '.k-grid tr td[rowspan]:not(:last-child)': {
                borderRight: `1px solid ${theme.palette.divider}`,
            },
            '.k-grid tr th.k-header:last-child': {
                borderRightWidth: '0px',
            },
            '.k-grid tr td[rowspan]:not(:first-child)': {
                borderLeft: `1px solid ${theme.palette.divider}`,
            },
            '.k-grid-content': {
                backgroundColor: theme.palette.background.paper,
                // Remove scrollbar
                overflowY: 'auto',
            },
            // Custom reset button
            '.k-grid-filters-reset': {
                padding: 0,
                margin: 0,
                border: 'none',
                borderRadius: theme.shape.borderRadius,
                background: 'transparent',
                textDecoration: 'none',
                fontWeight: '500',
                fontSize: '14px',
            },
            '.k-grid-filters-reset:not(:disabled)': {
                color: theme.palette.primary.main,
                cursor: 'pointer',
            },
            '.k-grid-filters-reset:not(:disabled):hover, .k-grid-filters-reset:not(:disabled):active': {
                textDecoration: 'underline',
            },
            // Remove scrollbar empty space
            'div.k-grid-header, div.k-grid-footer': {
                padding: 'unset !important',
            },
            // Display dropdown menu in the Dialog
            '.k-animation-container': {
                zIndex: 10003,
                borderRadius: theme.shape.borderRadius,
            },
            '.k-dialog-wrapper': {
                zIndex: 100,
            },
            '.k-grid a': {
                color: theme.palette.primary.main,
            },
            '.k-datepicker .k-dateinput-wrap .k-input, .k-datepicker .k-picker-wrap .k-input': {
                borderRadius: '0 !important',
            },
            '.k-datetimepicker .k-dateinput-wrap .k-input, .k-datetimepicker .k-picker-wrap .k-input, .k-timepicker .k-dateinput-wrap .k-input, .k-timepicker .k-picker-wrap .k-input': {
                borderRadius: '0 !important',
            },
            '.k-dateinput .k-dateinput-wrap .k-input, .k-dateinput .k-picker-wrap .k-input,': {
                borderRadius: '0 !important',
            },
            '.k-calendar .k-calendar-td.k-state-selected .k-link': {
                backgroundColor: `${theme.palette.primary.main} !important`,
            },
            '.k-window-titlebar': {
                backgroundColor: `${theme.palette.background.default}`,
                borderBottom: '1px solid #0000001f',
            },
        },
    })
);

const KendoStyles: FC = () => {
    useStyles();

    return null;
};

export default KendoStyles;
