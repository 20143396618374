import React, {
    ChangeEvent,
    HTMLAttributes,
    MouseEventHandler,
    ReactNode,
    Ref,
    SyntheticEvent,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Prompt } from 'react-router-dom';
import {
    Box,
    Card,
    // CardActions,
    Container,
    Grid,
    makeStyles,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { camelCase, isEmpty } from 'lodash';

import { Checkbox, NotificationWithDialog, storage } from '../..';
import { Accordion } from '../../components/Accordion';
import { Breadcrumbs, BreadcrumbText } from '../../components/Breadcrumbs';
import { Button, IButtonProps } from '../../components/Button';
import {
    createRecentactivity,
    getUserDetailsByEmail,
    getViewAsync,
} from '../../components/Grid/Kendo/views/api';
import { Label } from '../../components/Label';
import { AppErrorHandler, Page } from '../../components/Page';
import { ITabsProps, Tabs } from '../../components/Tabs';
import { ITheme, useProfile } from '../../core/Providers';
import ModuleInfoContext from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import { PageProviderContext } from '../../core/Providers/PageProviders/PageProvider';
import ScStorage from '../../core/Storage';
import { useDimensions } from '../../hooks';
import { StandardProps, ThemeColorType } from '../../types';
import { componentsAPIInstance } from '../../utils/api/APIInstance';

import AsideContent, { IAsideContentProps } from './AsideContent';
import { isNull } from 'lodash';
interface IPageButtonProps {
    type?: 'submit' | 'reset' | 'button';
    loading?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    iconSeparator?: boolean;
    disabled?: boolean;
    useEllipsis?: boolean;
    buttonTooltip?: string;
}

export interface IGlobalAPIError {
    error: any;
}

export interface IPageLayoutProps
    extends StandardProps<HTMLAttributes<HTMLDivElement>> {
    /**
     * Page title
     */
    title: string;
    /**
     * Breadcrumbs
     */
    breadCrumbs: IPageBreadCrumbProps[];
    /**
     * Default action button
     */
    action?: IButtonProps;
    /**
     * Send custom actions
     */
    actions?: ReactNode;
    /**
     * Page tabs
     */
    pageTabs?: ITabsProps | null;
    /**
     * Determine the max-width of the container.
     * The container width grows with the size of the screen.
     * Set to `false` to disable `maxWidth`.
     */
    container?: 'lg' | boolean;
    /**
     * Fixed panel in page
     */
    sidePanel?: Omit<IAsideContentProps, 'top'>;
    /**
     * to display the notification dialog on tab click
     */
    promptCanChange?: boolean;
    /**
     * dialog title
     */
    dialogTitle?: string;
    /**
     * dialog message
     */
    dialogMessage?: string;
    /**
     * state parameter condition
     */
    isDirty?: any;
    /**
     * setstate parameter condition
     */
    setIsDirty?: any;
    /**
     * onchange function for tab change
     */
    onTabChange?: any;
    /**
     * for setting the current tab
     */
    currentTab?: number | string;
    /**
     * Children component
     */
    children: React.ReactNode;
    /**
     * enable custom views
     */
    hasCustomViews?: boolean;
    /**
     * enable filter section
     */
    enableFilters?: boolean;
    /**
     * filter section
     */
    filters?: React.ReactNode;
    /**
     * filter section primary action text
     */
    filtersPrimaryActionText?: string;
    /**
     * filter section primary action
     */
    filtersPrimaryActionClick?: MouseEventHandler<HTMLButtonElement>;
    /**
     * filter section secondary action text
     */
    filtersSecondaryActionText?: string;
    /**
     * filter section secondary action
     */
    filtersSecondaryActionClick?: MouseEventHandler<HTMLButtonElement>;
    /**
     * Save Text Button Text
     */
    bottomActionButtons?: boolean;
    /**
     * Save Text Button Text
     */
    saveText?: string;
    /**
     * Save Button function call
     */
    onSave?: ((ev?: SyntheticEvent) => void) | null;
    /**
     * Save Button props
     */
    saveButtonProps?: IPageButtonProps;
    /**
     * Cancel Text Button Text
     */
    cancelText?: string;
    /**
     * Cancel Button function call
     */
    onCancel?: ((ev?: SyntheticEvent) => void) | null;
    /**
     * Cancel Button props
     */
    cancelButtonProps?: IPageButtonProps;
    /**
     * Bottom Action Buttons
     */
    bottomActions?: IButtonProps[];
    /**
     * Custom Bottom Action Buttons
     */
    customBottomActions?: ReactNode;
    /**
     * displays the bottom Label
     */
    showBottomLabel?: boolean;
    /**
     * displays the bottom Label
     */
    bottomLabelText?: string;
    /**
     * displays the bottom Label
     */
    bottomLabelColor?: ThemeColorType;
    /**
     * displays the bottom Checkbox
     */
    showBottomCheckBox?: boolean;
    /**
     * displays the bottom CheckBox
     */
    bottomCheckBoxText?: string;
    /**
     * displays the bottom CheckBox
     */
    bottomCheckBoxColor?: 'default' | 'primary' | 'secondary';
    /**
     * bottom CheckBox value
     */
    bottomCheckBoxValue?: boolean;
    /**
     * bottom CheckBox action
     */
    bottomCheckBoxAction?: (event: ChangeEvent<HTMLInputElement>) => void;
    /**
     * this is to save the customView based on the passing name
     */
    customViewPageName?: string;
    /**
     * Cancel Button function call
     */
    leftBottomActions?: ReactNode;
    /**
     * Filters Primary Action Button props
     */
    filtersPrimaryActionProps?: IPageButtonProps;
    /**
     * Filters Secondary Action Button props
     */
    filtersSecondaryActionProps?: IPageButtonProps;
    /**
     * Is form has unsaved changes
     */
    enablePrompt?: boolean;
    /**
     * Prompt message
     */
    promptMessage?: string;
    /**
     * No bottom for sticky footer
     */
    noStickyBottom?: boolean;

    /**
     * click event on onboarding back button
     */
    onBoardBackButtonClickEvent?: React.MouseEventHandler<HTMLButtonElement>;
    /**
     * to display onboarding back button
     */
    displayOnboardingBackButton?: boolean;
}

export type PageLayoutVariant = 'full-width' | 'contained';

export interface IPageBreadCrumbProps {
    /**
     * Breadcrumb title
     */
    title: string;
    /**
     * Navigation path
     * Rendered as text without path
     */
    path?: string;
    /**
     * Active text color
     */
    isActive?: boolean;
}

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        padding: 0,
        height: '100%',
        backgroundColor: theme.palette.background.common,
    },
    container: {
        height: '100%',
    },
    tabSpacer: {
        height: theme.spacing(3),
    },
    contentSpacer: {
        marginBottom: theme.spacing(2),
    },
    pageTitle: {
        marginBottom: theme.spacing(1),
    },
    cardActions: {
        marginTop: '15px',
        padding: 10,
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'sticky',
        bottom: '15px',
        zIndex: 10,
        backgroundColor: theme.palette.common.white,
    },
    cardActionsNoBottom: {
        marginTop: '15px',
        padding: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'sticky',
        bottom: '0px',
        zIndex: 10,
        backgroundColor: theme.palette.common.white,
    },
}));

const PageLayout = ({
    title,
    children,
    className,
    breadCrumbs,
    action,
    actions,
    pageTabs,
    container = false,
    sidePanel,
    promptCanChange,
    dialogTitle,
    dialogMessage,
    isDirty,
    setIsDirty,
    onTabChange,
    currentTab,
    hasCustomViews = false,
    enableFilters = false,
    filters,
    filtersPrimaryActionText = 'Appy',
    filtersPrimaryActionClick,
    filtersSecondaryActionText = 'Clear Filter',
    filtersSecondaryActionClick,
    bottomActionButtons,
    onCancel,
    onSave,
    saveText,
    cancelText,
    bottomActions,
    customBottomActions,
    showBottomLabel,
    bottomLabelText,
    bottomLabelColor = 'info',
    showBottomCheckBox,
    bottomCheckBoxText,
    bottomCheckBoxColor = 'primary',
    bottomCheckBoxValue = false,
    bottomCheckBoxAction,
    customViewPageName,
    saveButtonProps,
    cancelButtonProps,
    leftBottomActions,
    filtersPrimaryActionProps,
    filtersSecondaryActionProps,
    enablePrompt = false,
    promptMessage = 'Are you sure want to discard your changes?',
    noStickyBottom = false,
    displayOnboardingBackButton = false,
    onBoardBackButtonClickEvent,
    ...rest
}: IPageLayoutProps) => {
    const pageElRef: Ref<HTMLDivElement> = useRef(null);
    const { top } = useDimensions({
        ref: pageElRef,
    });
    const classes = useStyles();
    const [defaultViewAttributes, updateDefaultViewAttributes] = useState<any>(
        {}
    );
    const [viewAttributes, updateViewAttributes] = useState<any>({});
    const [isViewStateLoading, updateViewStateLoading] = useState(false);
    const ModulePermissionsContext = useContext(ModuleInfoContext);
    const [canUpdateFilters, setCanUpdateFilters] = useState<boolean>(false);
    const [showWarningPrompt, setShowWarningPrompt] = useState<boolean>(
        enablePrompt
    );
    const [isSharedView, setIsSharedView] = useState<boolean>(false);
    const [isViewSelection, setIsViewSelection] = useState<boolean>(false);
    const [isSystems, setIsSystem] = useState<boolean>(false);
    const { user } = useProfile();
    const isViewClearing = localStorage.getItem('isViewClearing') === 'true';
    const activeDistrict = storage.getItem('activeDistrict');
    const activeDistrictParsed = JSON.parse(activeDistrict ?? '{}');
    const regionId = activeDistrictParsed?.attributes?.regionId?.[0];
    const moduleContext = useContext(ModuleInfoContext);

    const allowedRoutesString = ScStorage().getItem('allowedRoutes');
    const allowedRoutes = JSON.parse(
        allowedRoutesString && allowedRoutesString?.length > 0
            ? allowedRoutesString
            : '[]'
    );

    const checkPageNameIsInRoutes = () => {
        if (allowedRoutes?.length > 0) {
            return (
                allowedRoutes.some((route: any) => {
                    const normalizedRouteName = route.routeName
                        .replace(/\s+/g, '-')
                        .toLowerCase();
                    const normalizedPageName = moduleContext?.pageName.toLowerCase();
                    return normalizedRouteName.includes(normalizedPageName);
                }) || false
            );
        } else {
            return false;
        }
    };
    useEffect(() => {
        ScStorage().removeItem('newSavedViewId');
        updateDefaultViewAttributes({});
        updateViewAttributes({});
        if (
            !isViewStateLoading &&
            hasCustomViews &&
            customViewPageName !== null
        ) {
            updateViewStateLoading(() => true);
            getDefaultAttributesForPage();
        }
    }, [customViewPageName]);
    const currentUrl = window.location.pathname;

    async function getNewViewDetails(id: string) {
        try {
            updateDefaultViewAttributes({});
            updateViewAttributes({});
            await getViewAsync(ModulePermissionsContext.apimUrl, id).then(
                (response: any) => {
                    if (response.data.StatusCode === 200) {
                        if (response.data.PayLoad.isFavorite) {
                            updateDefaultViewAttributes(response.data.PayLoad);
                        } else {
                            updateViewAttributes(response.data.PayLoad);
                        }
                    }
                    updateViewStateLoading(() => false);
                    setCanUpdateFilters(() => true);
                }
            );
        } catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        if (
            !isNull(user) &&
            !isEmpty(moduleContext?.pageName.toString()) &&
            checkPageNameIsInRoutes()
        ) {
            const payload = {
                userId: Number(user?.UserId),
                pageName: moduleContext?.pageName.toString(),
                regionId: Number(regionId),
                url: currentUrl,
            };
            createRecentactivity(ModulePermissionsContext.apimUrl, payload);
        }
    }, []);
    useEffect(() => {
        const id = ScStorage().getItem('newSavedViewId');
        if (id) {
            getNewViewDetails(id);
        }
    }, [ScStorage().getItem('newSavedViewId')]);
    async function getDefaultAttributesForPage() {
        try {
            const activeDistrict = storage.getItem('activeDistrict');
            const activeDistrictParsed = JSON.parse(activeDistrict ?? '{}');
            const regionId = activeDistrictParsed?.attributes?.regionId?.[0];
            updateDefaultViewAttributes({});
            updateViewAttributes({});
            let roles: any[] = [];
            await getUserDetailsByEmail(
                ModulePermissionsContext.apimUrl,
                user?.Email
            ).then((response: any) => {
                if (
                    response.status === 200 &&
                    response.data.UserMgmatUserDetails &&
                    response.data.UserMgmatUserDetails.PayLoad[0]
                ) {
                    roles = response.data.UserMgmatUserDetails.PayLoad[0].roles;
                }
            });
            const defaultPageViewResponse = await componentsAPIInstance.axios({
                url: `${ModulePermissionsContext.apimUrl}/CustomUserViews/v1.0/api/CustomView/GetV1`,
                method: 'POST',
                data: {
                    dataModelName: camelCase(customViewPageName),
                    regionId: regionId,
                    userId: user?.UserId,
                    roleIds: roles?.map((role: any) => role.roleId),
                },
            });
            if (defaultPageViewResponse.data.StatusCode === 200) {
                // eslint-disable-next-line array-callback-return
                if (!isEmpty(defaultPageViewResponse.data.PayLoad)) {
                    const sortedResponse = defaultPageViewResponse.data.PayLoad.sort(
                        (a: any, b: any) =>
                            Number(b.isFavorite) - Number(a.isFavorite)
                    );
                    for (let data of sortedResponse) {
                        if (data.isFavorite && data.isSharedView) {
                            setIsSharedView(true);
                        } else if (data.isFavorite && data.isSystem) {
                            setIsSystem(true);
                        }
                        if (data) {
                            if (data.isFavorite) {
                                updateDefaultViewAttributes(data);
                                break;
                            }
                            // else if (data.isSystem) {
                            //     updateDefaultViewAttributes(data);
                            //     break;
                            // }
                            else {
                                updateDefaultViewAttributes({});
                            }
                        }
                    }
                } else {
                    updateDefaultViewAttributes({});
                }
            }
            updateViewStateLoading(() => false);
            setCanUpdateFilters(() => true);
        } catch (e) {
            updateViewStateLoading(() => false);
        }
    }

    useEffect(() => {
        setShowWarningPrompt(enablePrompt);
        window.onbeforeunload = enablePrompt
            ? () => enablePrompt && !!promptMessage
            : null;
        return () => window.removeEventListener('beforeunload', () => {});
    }, [enablePrompt, promptMessage]);

    const onPageSave = () => {
        if (onSave) {
            onSave();
        }
        setShowWarningPrompt(false);
    };

    const onCancelSave = () => {
        if (onCancel) {
            onCancel();
        }
        setShowWarningPrompt(true);
    };
    const [
        showGoBackToOnBoardingConfirmation,
        setShowGoBackToOnBoardingConfirmation,
    ] = useState<boolean>(false);

    const handleOnBoardBackButtonClickEvent = () => {
        setShowGoBackToOnBoardingConfirmation(true);
    };
    return (
        <>
            <NotificationWithDialog
                message="By returning to the set up screen, you are confirming that you may have completed onboarding on this page. However, you will still be able to return to this page at any time. Are you sure you want to go back?"
                open={showGoBackToOnBoardingConfirmation}
                onClose={() => {
                    setShowGoBackToOnBoardingConfirmation(false);
                }}
                primaryAction={{
                    title: 'Yes',
                    callback: () => {
                        window.location.replace('/system/Setup-Onboarding');
                        setShowGoBackToOnBoardingConfirmation(false);
                    },
                }}
                secondaryAction={{
                    title: 'Cancel',
                    callback: () => {
                        setShowGoBackToOnBoardingConfirmation(false);
                    },
                }}
            />
            <Prompt when={showWarningPrompt} message={promptMessage} />
            <PageProviderContext.Provider
                value={{
                    defaultViewAttributes,
                    viewAttributes,
                    updateDefaultViewAttributes,
                    setViewAttributes: (criteria: any) => {
                        updateViewAttributes(criteria);
                    },
                    pageName: camelCase(ModulePermissionsContext.pageName),
                    currentView: viewAttributes?.viewName,
                    canUpdateFilters: canUpdateFilters,
                    setCanUpdateFilters: (val: boolean) => {
                        setCanUpdateFilters(val);
                    },
                    currentViewId: isViewClearing
                        ? ''
                        : viewAttributes?.id || defaultViewAttributes.id,
                    customViewPageName: camelCase(customViewPageName),
                    isSharedView: isSharedView,
                    setIsSharedView: (val: boolean) => {
                        setIsSharedView(val);
                    },
                    isViewSelection: isViewSelection,
                    setIsViewSelection: (val: boolean) => {
                        setIsViewSelection(val);
                    },
                    isSystem: isSystems,
                    setIsSystem: (val: boolean) => {
                        setIsSystem(val);
                    },
                }}
            >
                <Page
                    className={clsx(classes.root, className)}
                    ref={pageElRef}
                    {...rest}
                >
                    <Container
                        className={classes.container}
                        maxWidth={container ? 'lg' : container}
                        disableGutters
                    >
                        <Grid
                            container
                            direction="row"
                            alignItems="stretch"
                            style={{
                                marginTop: '54px',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="flex-start"
                                    style={{
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    {displayOnboardingBackButton &&
                                        user?.Roles.includes(
                                            'District Onboarding'
                                        ) && (
                                            <>
                                                <Button
                                                    id="onboarding-back-button"
                                                    onClick={() =>
                                                        handleOnBoardBackButtonClickEvent()
                                                    }
                                                    color="secondary"
                                                    dense={true}
                                                >
                                                    Return to Onboarding
                                                </Button>
                                                <br />
                                            </>
                                        )}
                                    <Breadcrumbs>
                                        {breadCrumbs.map((item) => {
                                            if (typeof item.path === 'string') {
                                                return (
                                                    <BreadcrumbText
                                                        label={item.title}
                                                        key={item.title}
                                                        isActive={
                                                            item.isActive ||
                                                            true
                                                        }
                                                    />
                                                );
                                            }
                                            return (
                                                <BreadcrumbText
                                                    label={item.title}
                                                    key={item.title}
                                                    isActive={
                                                        item.isActive || true
                                                    }
                                                />
                                            );
                                        })}
                                    </Breadcrumbs>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                        component={'div'}
                                        className={classes.pageTitle}
                                    >
                                        {title}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Box display="flex" alignItems="flex-start">
                                {/*Custom actions*/}
                                {actions}
                                {/* {ModulePermissionsContext.permissions.canAdd &&
                                    actions} */}
                                {/*Add button*/}
                                {action && (
                                    <Button color="primary" {...action} />
                                )}
                            </Box>
                        </Grid>

                        {pageTabs && (
                            <Tabs
                                value={currentTab}
                                promptCanChange={promptCanChange}
                                dialogTitle={dialogTitle}
                                dialogMessage={dialogMessage}
                                className={classes.contentSpacer}
                                isDirty={isDirty}
                                setIsDirty={setIsDirty}
                                onChange={onTabChange}
                                variant="contained"
                                enableFilters={enableFilters}
                                filters={filters}
                                filtersPrimaryActionText={
                                    filtersPrimaryActionText
                                }
                                filtersPrimaryActionClick={
                                    filtersPrimaryActionClick
                                }
                                filtersSecondaryActionText={
                                    filtersSecondaryActionText
                                }
                                filtersSecondaryActionClick={
                                    filtersSecondaryActionClick
                                }
                                filtersPrimaryActionProps={
                                    filtersPrimaryActionProps
                                }
                                filtersSecondaryActionProps={
                                    filtersSecondaryActionProps
                                }
                                {...pageTabs}
                            />
                        )}
                        {!pageTabs && <Box className={classes.tabSpacer} />}
                        {enableFilters && !pageTabs && (
                            <>
                                <Accordion
                                    title="Filters"
                                    variant="default"
                                    expanded={true}
                                >
                                    <Grid
                                        container={true}
                                        direction="column"
                                        alignItems="stretch"
                                        spacing={2}
                                        style={{
                                            justifyContent: 'flex-start',
                                        }}
                                    >
                                        <Grid item={true}>{filters}</Grid>
                                        <Grid item={true}>
                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                spacing={2}
                                                style={{
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <Grid item={true}>
                                                    <Button
                                                        type="button"
                                                        color="secondary"
                                                        id="secondaryButton"
                                                        label={
                                                            filtersSecondaryActionText
                                                        }
                                                        name="Button"
                                                        onClick={
                                                            filtersSecondaryActionClick
                                                        }
                                                        variant="default"
                                                        {...filtersSecondaryActionProps}
                                                    />
                                                </Grid>
                                                <Grid item={true}>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        id="primaryButton"
                                                        label={
                                                            filtersPrimaryActionText
                                                        }
                                                        name="Button"
                                                        onClick={
                                                            filtersPrimaryActionClick
                                                        }
                                                        variant="default"
                                                        {...filtersPrimaryActionProps}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Accordion>
                            </>
                        )}
                        <AsideContent
                            {...sidePanel}
                            top={top}
                            component={sidePanel?.component ?? null}
                        >
                            <AppErrorHandler>
                                {!isViewStateLoading && children}
                            </AppErrorHandler>
                        </AsideContent>
                    </Container>
                    {bottomActionButtons && (
                        <Card
                            className={
                                noStickyBottom
                                    ? classes.cardActionsNoBottom
                                    : classes.cardActions
                            }
                        >
                            <Grid
                                container={true}
                                direction="row"
                                alignItems="center"
                                style={{
                                    justifyContent: 'space-between',
                                }}
                            >
                                {leftBottomActions && leftBottomActions}
                                {showBottomLabel ? (
                                    <Grid item={true}>
                                        <Label
                                            color={bottomLabelColor}
                                            id="stickyLabel"
                                        >
                                            {bottomLabelText}
                                        </Label>
                                    </Grid>
                                ) : (
                                    <>
                                        {showBottomCheckBox && (
                                            <Grid item={true}>
                                                <Checkbox
                                                    id="stickyCheckBox"
                                                    checked={
                                                        bottomCheckBoxValue
                                                    }
                                                    label={bottomCheckBoxText}
                                                    color={bottomCheckBoxColor}
                                                    onChange={() =>
                                                        bottomCheckBoxAction
                                                    }
                                                    labelPlacement="end"
                                                />
                                            </Grid>
                                        )}
                                    </>
                                )}
                                {bottomActionButtons && (
                                    <>
                                        <Grid item={true}></Grid>
                                        <Grid item={true}>
                                            <Grid
                                                container={true}
                                                spacing={2}
                                                direction="row"
                                                alignItems="center"
                                                // style={{ position: 'fixed' }}
                                                style={{
                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                {customBottomActions &&
                                                    customBottomActions}
                                                {bottomActions &&
                                                    bottomActions?.length > 0 &&
                                                    bottomActions?.map(
                                                        (
                                                            action: IButtonProps
                                                        ) => {
                                                            return (
                                                                <Grid
                                                                    item={true}
                                                                >
                                                                    <Button
                                                                        // id={action?.id}
                                                                        name={
                                                                            action?.name ||
                                                                            action?.label
                                                                        }
                                                                        color={
                                                                            action?.color
                                                                        }
                                                                        onClick={
                                                                            action?.onClick
                                                                        }
                                                                        {...action}
                                                                    >
                                                                        {
                                                                            action?.label
                                                                        }
                                                                    </Button>
                                                                </Grid>
                                                            );
                                                        }
                                                    )}
                                                {onCancel && (
                                                    <Grid item={true}>
                                                        <Button
                                                            id="cancelBtn"
                                                            name="Cancel"
                                                            color="default"
                                                            onClick={
                                                                onCancelSave
                                                            }
                                                            {...cancelButtonProps}
                                                        >
                                                            {cancelText ||
                                                                'Cancel'}
                                                        </Button>
                                                    </Grid>
                                                )}
                                                {onSave && (
                                                    <Grid item={true}>
                                                        <Button
                                                            id="saveBtn"
                                                            name="Save"
                                                            color="primary"
                                                            onClick={onPageSave}
                                                            {...saveButtonProps}
                                                        >
                                                            {saveText || 'Save'}
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Card>
                    )}
                </Page>
            </PageProviderContext.Provider>
        </>
    );
};

export default PageLayout;
