import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';

import { FILTER_MIN_COLUMNS } from './constants';
import { IColumnSettingsProps, IGridColumnProps } from './types';

export interface IColumnSettingsCompProps {
    columns: IGridColumnProps[];
    defaultColumns?: readonly IGridColumnProps[];
    columnSettings?: boolean | IColumnSettingsProps;
    selectedField?: string;
    expandField?: string;
    onColumnChange: (columns: IGridColumnProps[]) => void;
}

const ColumnSettings: FC<IColumnSettingsCompProps> = ({
    columns,
    defaultColumns,
    columnSettings,
    selectedField,
    expandField,
    onColumnChange,
}) => {
    let excludeColumns: string[] = [];
    let minColumns = FILTER_MIN_COLUMNS;

    const [selectedColumnsCount, setSelectedColumnsCount] = useState<number>(0);
    const [
        visibleSelectedColumnsCount,
        setVisibleSelectedColumnsCount,
    ] = useState<number>(0);

    /**
     * Show reset button when any field hidden value has changed
     */
    const [canReset, setCanReset] = useState<boolean>(false);
    useEffect(() => {
        let canReset = false;
        if (defaultColumns?.length) {
            for (let i = 0; i < defaultColumns.length; i++) {
                const defaultColumn = defaultColumns[i];
                const updatedColumn = columns.find((column) => {
                    return column.field === defaultColumn?.field;
                });
                if (defaultColumn?.hidden !== updatedColumn?.hidden) {
                    canReset = true;
                    break;
                }
            }
        }
        setCanReset(canReset);
    }, [columns, defaultColumns]);

    /**
     * Restrict detail and selection columns
     */
    const isColumnRestricted = useCallback(
        (column: IGridColumnProps) => {
            return (
                column.field === expandField || column.field === selectedField
            );
        },
        [expandField, selectedField]
    );

    /**
     * Check if column is disabled
     */
    const isColumnDisabled = useCallback(
        (column: IGridColumnProps) => {
            return (
                isColumnRestricted(column) ||
                (column.field && excludeColumns?.includes(column.field)) ||
                (!column.hidden && visibleSelectedColumnsCount <= minColumns)
            );
        },
        [
            excludeColumns,
            visibleSelectedColumnsCount,
            minColumns,
            isColumnRestricted,
        ]
    );

    useEffect(() => {
        setSelectedColumnsCount(
            columns.filter((column) => {
                return !column.hidden || isColumnRestricted(column);
            })?.length ?? 0
        );

        setVisibleSelectedColumnsCount(
            columns.filter((column) => {
                return !column.hidden && !isColumnRestricted(column);
            })?.length ?? 0
        );
    }, [columns, isColumnRestricted]);

    if (typeof columnSettings === 'object') {
        if (
            columnSettings.minColumns &&
            columnSettings.minColumns > FILTER_MIN_COLUMNS
        ) {
            minColumns = columnSettings.minColumns;
        }
        if (columnSettings.excludeColumns) {
            excludeColumns = columnSettings.excludeColumns;
        }
    }

    const handleColumnChange = (
        column: IGridColumnProps,
        columnId: number,
        value: boolean
    ) => {
        const updatedColumns = columns?.map((column, i) => {
            return columnId === i
                ? {
                      ...column,
                      hidden: !value,
                  }
                : column;
        });
        onColumnChange(updatedColumns);

        /**
         * Emit column change event
         */
        if (typeof columnSettings === 'object' && columnSettings.onChange) {
            columnSettings.onChange({
                column,
                hidden: !value,
                field: column.field,
            });
        }
    };

    const toggleSelectAll = (ev: CheckboxChangeEvent) => {
        // Toggle columns which are not disabled
        let updatedColumns = columns?.map((column) => {
            if (isColumnDisabled(column)) {
                return column;
            } else {
                return {
                    ...column,
                    hidden: !ev.value,
                };
            }
        });
        // Select columns when count is less than min columns
        const selectedCount =
            updatedColumns.filter((column) => {
                return !column.hidden && !isColumnRestricted(column);
            })?.length ?? 0;
        let toSelectCount = minColumns - selectedCount;
        if (toSelectCount > 0) {
            updatedColumns = updatedColumns.map((column) => {
                if (
                    toSelectCount > 0 &&
                    column.hidden &&
                    !isColumnRestricted(column)
                ) {
                    toSelectCount--;
                    return {
                        ...column,
                        hidden: false,
                    };
                }
                return column;
            });
        }
        onColumnChange(updatedColumns);
        /**
         * Emit select all change event
         */
        if (
            typeof columnSettings === 'object' &&
            columnSettings.onSelectAllChange
        ) {
            columnSettings.onSelectAllChange({
                hidden: !ev.value,
            });
        }
    };

    /**
     * Reset columns to initial state
     */
    const resetColumnSettings = () => {
        const updatedColumns = columns.map((column) => {
            const defaultColumn = defaultColumns?.find((col) => {
                return col.field === column?.field;
            });
            if (defaultColumn) {
                return {
                    ...column,
                    hidden: defaultColumn.hidden,
                };
            }
            return column;
        });
        onColumnChange(updatedColumns);
        /**
         * Emit reset event
         */
        if (typeof columnSettings === 'object' && columnSettings.onReset) {
            columnSettings.onReset();
        }
    };

    return (
        <form>
            {defaultColumns?.length ? (
                <Box mb={1.5} mt={0.5}>
                    <button
                        id="resetColumnSettings"
                        type="reset"
                        onClick={resetColumnSettings}
                        className="k-grid-filters-reset"
                        disabled={!canReset}
                        title="reset"
                    >
                        Reset
                    </button>
                </Box>
            ) : (
                ''
            )}
            <Typography variant="h6" component="p" gutterBottom>
                {visibleSelectedColumnsCount === 0 && <>No columns selected</>}
                {visibleSelectedColumnsCount === 1 && <>1 column selected</>}
                {visibleSelectedColumnsCount >= 2 && (
                    <>{visibleSelectedColumnsCount} columns selected</>
                )}
            </Typography>
            <Box my={1.5}>
                <Checkbox
                    checked={selectedColumnsCount === columns.length}
                    label="Select All"
                    onChange={toggleSelectAll}
                />
            </Box>
            {columns.map(
                (column, i) =>
                    column.field !== selectedField &&
                    column.field !== expandField && (
                        <Box key={i} my={1.5}>
                            <Checkbox
                                checked={!column.hidden}
                                label={column.title}
                                disabled={isColumnDisabled(column)}
                                onChange={(ev) =>
                                    handleColumnChange(column, i, ev.value)
                                }
                            />
                        </Box>
                    )
            )}
        </form>
    );
};

export default ColumnSettings;
