import React, { FC, ReactNode, useEffect, useState } from 'react';
import { merge } from 'lodash';

import { Direction } from '../Theme';

import SettingsContext, {
    defaultSettings,
    ISettings,
    storeSettings,
} from './SettingsContext';

interface ISettingsProviderProps {
    settings?: ISettings;
    children?: ReactNode;
    navClosedOnLoadPagesList?: string[];
}

const SettingsProvider: FC<ISettingsProviderProps> = ({
    settings,
    children,
    navClosedOnLoadPagesList = [],
}) => {
    const [currentSettings, setCurrentSettings] = useState<ISettings>(
        defaultSettings
    );
    const [navClosedOnLoad] = useState<string[]>(navClosedOnLoadPagesList);
    const [currentPage, setCurrentPage] = useState<string>('');

    useEffect(function () {
        setCurrentPage(window?.location?.pathname ?? '');
    }, []);

    const handleSaveSettings = (newSettings: ISettings): void => {
        const mergedSettings = merge({}, currentSettings, newSettings);

        setCurrentSettings(mergedSettings);
        storeSettings(mergedSettings);
    };

    useEffect(() => {
        if (settings) {
            setCurrentSettings(settings);
        }
    }, [settings]);

    useEffect(() => {
        document.dir = currentSettings?.direction || Direction.LTR;
    }, [currentSettings]);

    return (
        <SettingsContext.Provider
            value={{
                settings: currentSettings,
                saveSettings: handleSaveSettings,
                navClosedOnLoad,
                currentPage,
                setCurrentPage,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

export default SettingsProvider;
