import { AuthAction, IAuthState } from './auth.model';
import { initialAuthState } from './AuthContext';

const authReducer = (state: IAuthState, action: AuthAction): IAuthState => {
    switch (action.type) {
        case 'INITIALISE': {
            return {
                ...state,
                isInitialised: true,
            };
        }
        case 'SET_AUTH_STATUS': {
            return {
                ...state,
                auth: action.payload.auth,
                error: action.payload.error,
                errorMessage: action.payload.errorMessage,
                isAuthenticated: action.payload.isAuthenticated,
                changeDefaults: false,
            };
        }
        case 'SET_IS_AUTHENTICATED': {
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                changeDefaults: false,
            };
        }
        case 'LOGIN': {
            return {
                ...state,
                isAuthenticated: true,
                changeDefaults: false,
                auth: action.payload.auth,
                error: null,
            };
        }
        case 'FIRST_LOGIN': {
            return {
                ...state,
                changeDefaults: true,
                registeredEmail: action.payload.email,
                firstToken: action.payload.firstToken,
            };
        }
        case 'LOGOUT': {
            return {
                ...initialAuthState,
                isAuthenticated: false,
                isInitialised: state.isInitialised,
                auth: null,
                changeDefaults: false,
                registeredEmail: undefined,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

export default authReducer;
