import { colors as MuiColors } from '@material-ui/core';
import {
    Palette as MuiPalette,
    PaletteColor,
    TypeBackground as MuiTypeBackground,
} from '@material-ui/core/styles/createPalette';

interface ITypeBackground extends MuiTypeBackground {
    light: string;
    dark: string;
    grey: string;
    common: string;
}

export interface IPalette extends MuiPalette {
    tertiary: PaletteColor;
    background: ITypeBackground;
}

export const lightPalette = {
    type: 'light',
    action: {
        active: MuiColors.blueGrey[600],
    },
    background: {
        light: MuiColors.common.white,
        default: '#F4F6F8',
        dark: MuiColors.blueGrey[50],
        paper: MuiColors.common.white,
        grey: '#F4F7FA',
        common: '#F8F7FD',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    primary: {
        main: '#6655D8',
    },
    secondary: {
        main: '#2F4858',
    },
    tertiary: {
        main: '#2F4858',
    },
    success: {
        main: '#5DE6B2',
    },
    warning: {
        main: '#FFAA84',
    },
    error: {
        main: '#FF6666',
    },
    info: {
        main: '#E3E0F8',
        extra: '#FFE927',
    },
    grey: {
        100: '#F4F7FA',
        600: '#737373',
    },
    gray: {
        100: '#F4F7FA',
        600: '#737373',
    },
    text: {
        primary: MuiColors.blueGrey[900],
        secondary: MuiColors.blueGrey[600],
        disabled: MuiColors.blueGrey[400],
    },
    heatmap: {
        purple: {
            100: '#544AB4',
            80: '#756DC2',
            60: '#9892D2',
            40: '#B8B4DE',
            20: '#D9D7EC',
        },
        orange: {
            100: '#F08B46',
            80: '#F2A16A',
            60: '#F6B990',
            40: '#F6CEB2',
            20: '#F8E4D6',
        },
        blue: {
            100: '#459BEA',
            80: '#69AEED',
            60: '#8FC3F2',
            40: '#B2D4F4',
            20: '#D6E7F7',
        },
        green: {
            100: '#3AC191',
            80: '#60CCA6',
            60: '#89DABD',
            40: '#ADE3D0',
            20: '#D4EFE5',
        },
        violetred: {
            100: 'EB4E93',
            80: '#EE70A8',
            60: '#F395BE',
            40: '#F4B5D1',
            20: '#F7D8E5',
        },
    },
    chartColors: {
        purple: {
            main: '#544AB4',
        },
        orange: {
            main: '#F08B46',
        },
        blue: {
            main: '#459BEA',
        },
        green: {
            main: '#3AC191',
        },
        violetred: {
            main: 'EB4E93',
        },
        slateblue: {
            main: '#635ED1',
        },
        chinapink: {
            main: '#DE6FA1',
        },
        turquoise: {
            main: '#1BE0ED',
        },
        orchid: {
            main: '#AD4DCA',
        },
        plum: {
            main: '#DDA0DD',
        },
    },
};

export const darkPalette = {
    type: 'dark',
    action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
    },
    background: {
        light: '#14171C',
        default: '#0C0E12',
        dark: '#06080A',
        paper: '#14171C',
        grey: '#F4F7FA',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    primary: {
        main: '#00796B',
    },
    secondary: {
        main: '#0E509C',
    },
    tertiary: {
        main: '#0E509C',
    },
    success: {
        main: '#5DE6B2',
    },
    warning: {
        main: '#FFAA84',
    },
    error: {
        main: '#FF6666',
    },
    info: {
        main: '#E3E0F8',
        extra: '#FFE927',
    },
    grey: {
        100: '#F4F7FA',
        600: '#737373',
    },
    gray: {
        100: '#F4F7FA',
        600: '#737373',
    },
    text: {
        primary: '#E6E5E8',
        secondary: '#ADB0BB',
        disabled: '#E0E0E0',
    },
    heatmap: {
        purple: {
            100: '#544AB4',
            80: '#756DC2',
            60: '#9892D2',
            40: '#B8B4DE',
            20: '#D9D7EC',
        },
        orange: {
            100: '#F08B46',
            80: '#F2A16A',
            60: '#F6B990',
            40: '#F6CEB2',
            20: '#F8E4D6',
        },
        blue: {
            100: '#459BEA',
            80: '#69AEED',
            60: '#8FC3F2',
            40: '#B2D4F4',
            20: '#D6E7F7',
        },
        green: {
            100: '#3AC191',
            80: '#60CCA6',
            60: '#89DABD',
            40: '#ADE3D0',
            20: '#D4EFE5',
        },
        violetred: {
            100: 'EB4E93',
            80: '#EE70A8',
            60: '#F395BE',
            40: '#F4B5D1',
            20: '#F7D8E5',
        },
    },
    chartColors: {
        purple: {
            main: '#544AB4',
        },
        orange: {
            main: '#F08B46',
        },
        blue: {
            main: '#459BEA',
        },
        green: {
            main: '#3AC191',
        },
        violetred: {
            main: 'EB4E93',
        },
        slateblue: {
            main: '#635ED1',
        },
        chinapink: {
            main: '#DE6FA1',
        },
        turquoise: {
            main: '#1BE0ED',
        },
        orchid: {
            main: '#AD4DCA',
        },
        plum: {
            main: '#DDA0DD',
        },
    },
};

export const perseusPalette = {
    ...lightPalette,
    primary: {
        main: '#665AD8',
    },
    secondary: {
        main: '#04004D',
    },
    tertiary: {
        light: '#C0B9F6',
        main: '#B9B2F5',
        dark: '#A6A0DC',
        contrastText: MuiColors.blueGrey[900],
    },
};

/**
 * Chart colors
 */
export const chartColors = [
    '#544AB4',
    '#F08B46',
    '#459BEA',
    '#3AC191',
    '#EB4E93',
    '#635ED1',
    '#DE6FA1',
    '#1BE0ED',
    '#AD4DCA',
    '#DDA0DD',
];
