import React from 'react';

import { IVerticalModuleIcons } from './index';

const WorkspaceModuleIcon = ({ selected }: IVerticalModuleIcons) => (
    <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0)">
            <path
                d="M6.75 2V8H2.75V2H6.75ZM16.75 10V16H12.75V10H16.75ZM8.75 0H0.75V10H8.75V0ZM18.75 8H10.75V18H18.75V8Z"
                fill={selected}
            />
            <path
                d="M10.75 0V6H18.75V0H10.75ZM16.75 4H12.75V2H16.75V4Z"
                fill={selected}
            />
            <path
                d="M0.75 12V18H8.75V12H0.75ZM6.75 16H2.75V14H6.75V16Z"
                fill={selected}
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect
                    width="40"
                    height="40"
                    fill={selected}
                    transform="translate(0.75)"
                />
            </clipPath>
        </defs>
    </svg>
);

export default WorkspaceModuleIcon;
