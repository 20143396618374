import React, { FC } from 'react';
import {
    CircularProgress,
    InputAdornment,
    makeStyles,
    TextField,
    withStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';

import { SearchIcon } from '../../../assets/icons';

export interface ISearchBoxProps<T> {
    /* Used to determine the string value for a given option. */
    getOptionLabel?: (option: T) => string;
    /* input field label */
    label?: string;
    /* Searching Progress*/
    loading?: boolean;
    /* Input Source List */
    options?: any[];
    id?: string;
    /*any item selected from list */
    onSelection?: (val: any) => void;
    /* on Search box text Change */
    onSearchTextChange?: (e: any) => void;
    placeHolder?: string;
    error?: boolean;
    helperText?: any;
    disabled?: boolean;

    /* The size of the autocomplete */
    size?: 'small' | 'medium';
}

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
})(TextField);

const SearchBox: FC<ISearchBoxProps<any>> = ({
    getOptionLabel,
    label,
    loading,
    options,
    id,
    onSelection,
    onSearchTextChange,
    error,
    helperText,
    placeHolder,
    disabled,
    size,
}) => {
    const styles = makeStyles(() => ({
        background: {
            paddingRight: '3%',
        },
        field: {
            borderRadius: '0px !important',
            backgroundColor: '#ffffff',
            border: '1px solid #ffffff',
        },
    }));

    const classes = styles();

    return (
        <Autocomplete
            autoComplete={true}
            autoHighlight={true}
            className={classes.background}
            getOptionLabel={getOptionLabel}
            size={size ? (size as any) : 'small'}
            options={options ? options : []}
            onChange={(e, val) => (e ? onSelection && onSelection(val) : null)}
            id={id}
            renderInput={(params) => (
                <CssTextField
                    {...params}
                    label={label}
                    variant="outlined"
                    className={classes.field}
                    placeholder={placeHolder}
                    error={error || false}
                    onChange={(e: any) => {
                        onSearchTextChange &&
                            onSearchTextChange(e.target.value);
                    }}
                    helperText={helperText}
                    disabled={disabled || false}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color="primary"
                                        size={16}
                                    />
                                ) : (
                                    <>
                                        {(params.inputProps as any).value &&
                                        (params.inputProps as any).value.trim()
                                            .length > 0 ? (
                                            params.InputProps.endAdornment
                                        ) : (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        )}
                                    </>
                                )}
                            </>
                        ),
                    }}
                />
            )}
            freeSolo={true}
        />
    );
};

export default SearchBox;
