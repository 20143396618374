import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';

import './RoundedBarCharts';

import { chartColors } from '../../../core/Providers';

export interface IBarChartProps {
    /**
     * Used to configure the graph
     */
    data?: ChartData;
    /**
     * Used to configure the graph options
     */
    options?: ChartOptions;
}

const colors = {
    backgroundColor: chartColors,
};

const BarChart = ({ data, options, ...rest }: IBarChartProps) => {
    return (
        <Bar
            type="horizontalBar"
            data={{
                ...data,
                datasets: data?.datasets?.map((dataset: ChartDataSets) =>
                    !dataset.backgroundColor
                        ? {
                              ...dataset,
                              ...colors,
                          }
                        : dataset
                ),
            }}
            options={options}
            {...rest}
        />
    );
};

export default BarChart;
