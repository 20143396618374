import React, { FC } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';

import { ITheme } from '../../../core/Providers';

export interface IDatepickerProps {
    id: string;
    label: string;
    onChange: (e: any) => void;
    defaultValue?: string;
    required?: boolean;
    error?: boolean;
    helperText?: any;
    disabled?: boolean;
}

const useStyles = makeStyles((theme: ITheme) => ({
    textField: {
        marginRight: theme.spacing(1),
        width: '100%',
    },
}));

const Datepicker: FC<IDatepickerProps> = ({
    id,
    label,
    onChange,
    defaultValue,
    required,
    error,
    helperText,
    disabled,
}: IDatepickerProps) => {
    const classes = useStyles();
    return (
        <TextField
            id={id}
            label={label}
            type="date"
            variant="outlined"
            defaultValue={defaultValue || new Date()}
            className={classes.textField}
            InputLabelProps={{
                shrink: true,
            }}
            onChange={(e: any) => onChange(e.target.value)}
            required={required || false}
            error={error || false}
            helperText={helperText}
            disabled={disabled || false}
        />
    );
};

export default Datepicker;
