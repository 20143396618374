import type { ReactNode } from 'react';
import React, { forwardRef, HTMLAttributes } from 'react';

import { StandardProps } from '../../types';

import AppErrorHandler from './AppErrorHandler';

export interface IPageProps
    extends StandardProps<HTMLAttributes<HTMLDivElement>> {
    children?: ReactNode;
    title?: string;
}

const Page = forwardRef<HTMLDivElement, IPageProps>(
    ({ children, title = '', ...rest }, ref) => {
        return (
            <AppErrorHandler>
                <div ref={ref as any} {...rest}>
                    {children}
                </div>
            </AppErrorHandler>
        );
    }
);

export default Page;
