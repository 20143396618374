import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { storage } from '../../../..';
import { useProfile } from '../../../../core/Providers';
import { AutoComplete } from '../../../Inputs';

import { getRolesAsync, getUserGroupsAsync, getUsersAsync } from './api';

export interface IShareView {
    moduleContext: string;
    setSelectedUsersToShare: any;
    selectedUsersToShare: any;
    autoCompleteFlag: any;
    savedViews?: any;
    setReportSharedUsers?: any;
    reportSharedUsers?: any;
    setLoading?: any;
    setDisableShareButton?: any;
    clearValues?: string;
    setClearValues?: any;
    regionId: string;
}

export const ShareViewUtil = ({
    moduleContext,
    setSelectedUsersToShare,
    selectedUsersToShare,
    autoCompleteFlag,
    savedViews,
    setReportSharedUsers,
    reportSharedUsers,
    setLoading,
    setDisableShareButton,
    clearValues,
    setClearValues,
    regionId,
}: IShareView) => {
    const [data, setData] = useState<any>([]);
    // const [selectedUsers, setSelectedUsers] = React.useState<any>([]);
    const [autoCompleteValues, setAutoCompleteValues] = useState<any>([]);
    const [roles, setRoles] = React.useState<any>([]);
    // const [userGroups, setUserGroups] = React.useState<any>([]);
    const { user } = useProfile();
    const usersData = async (groupId: string) => {
        try {
            await getUsersAsync(groupId, moduleContext)
                .then((response: any) => {
                    if (response?.data.StatusCode === 200) {
                        setData(() => {
                            return response.data.PayLoad.filter(
                                (data: any) => data.primeroUserId !== ''
                            ).map((data: any) => {
                                return {
                                    ...data,
                                    description: data.userName,
                                    id: data.userGuid,
                                };
                            });
                        });
                    }
                })
                .catch((e: any) => {
                    console.log('getUsers error', e);
                })
                .then(() => {
                    getRolesAsync(moduleContext, regionId)
                        .then((response: any) => {
                            if (response.data.StatusCode === 200) {
                                if (!isEmpty(response.data.PayLoad)) {
                                    setRoles(response.data.PayLoad);
                                    response.data.PayLoad.map(
                                        // eslint-disable-next-line array-callback-return
                                        (rolesData: any) => {
                                            setData((prevState: any) => [
                                                ...prevState,
                                                {
                                                    ...rolesData,
                                                    id: `${rolesData.roleGuid}[Role]`,
                                                    description: `${rolesData.roleName} (Role)`,
                                                },
                                            ]);
                                        }
                                    );
                                }
                            }
                        })
                        .catch((error: any) => {
                            console.log('getRolesAsync error ', error);
                        });
                })
                .then(() => {
                    getUserGroupsAsync(moduleContext)
                        .then((response: any) => {
                            if (response.data.StatusCode === 200) {
                                if (!isEmpty(response.data.PayLoad)) {
                                    // setUserGroups(response.data.PayLoad);
                                    // response.data.PayLoad.map(
                                    //     // eslint-disable-next-line array-callback-return
                                    //     (userGroups1: any) => {
                                    //         setData((prevState: any) => [
                                    //             ...prevState,
                                    //             {
                                    //                 ...userGroups1,
                                    //                 id: `${userGroups1.groupGuid}[Group]`,
                                    //                 description: `${userGroups1.name} (Group)`,
                                    //             },
                                    //         ]);
                                    //     }
                                    // );
                                }
                            }
                        })
                        .catch((error: any) => {
                            console.log('getUserGroups error ', error);
                        });
                })
                .catch((error: any) => {
                    console.log('error ', error);
                });
        } catch (e) {
            console.log(e);
        }
    };

    /** this useeffect will be execute only once to get the users,
     * roles,groups for currently active district
     **/
    useEffect(() => {
        try {
            const activeDistrictName = storage.getItem('activeDistrict');
            if (activeDistrictName) {
                const activeDistrict = JSON.parse(activeDistrictName);
                usersData(activeDistrict?.groupGuid);
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    React.useEffect(() => {
        if (autoCompleteFlag) {
            setLoading(true);
            const users = data.filter((item: any) =>
                savedViews?.userIds?.includes(parseInt(item.primeroUserId))
            );
            const groups = data.filter((item: any) =>
                savedViews?.groupIds?.includes(item.groupGuid)
            );
            const roles = data.filter((item: any) =>
                savedViews?.roleIds?.includes(item.roleGuid)
            );
            setReportSharedUsers(users.concat(groups, roles));
            setLoading(false);
        }
    }, [savedViews, data]);

    React.useEffect(() => {
        // eslint-disable-next-line array-callback-return
        roles?.map((data: any) => {
            setSelectedUsersToShare((prevState: any) => [
                ...prevState,
                {
                    ...data,
                    id: `${data.roleGuid}[Role]`,
                    description: `${data.roleName} (Role)`,
                },
            ]);
        });
    }, [roles]);
    // React.useEffect(() => {
    //     // eslint-disable-next-line array-callback-return
    //     userGroups?.map((data: any) => {
    //         setSelectedUsersToShare((prevState: any) => [
    //             ...prevState,
    //             {
    //                 ...data,
    //                 id: `${data.groupGuid}[Group]`,
    //                 description: `${data.name} (Group)`,
    //             },
    //         ]);
    //     });
    // }, [userGroups]);

    const handleUserSelect = useCallback((event: any, selectedOptions: any) => {
        if (event != null) {
            event.preventDefault();
            event.stopPropagation();
        }
        const userIds: number[] = [];
        const roleIds: any[] = [];
        const groupIds: any[] = [];
        if (setDisableShareButton) {
            if (selectedOptions.length > 0) {
                setDisableShareButton(false);
            } else {
                setDisableShareButton(true);
            }
        }

        // eslint-disable-next-line array-callback-return
        selectedOptions.map((data: any) => {
            if (data?.id?.includes('Role')) {
                roleIds.push(data.id.replace(/ *\[[^\]]*]/, ''));
                // } else if (data?.id.includes('Group')) {
                //     groupIds.push(data.id.replace(/ *\[[^\]]*]/, ''));
            } else {
                userIds.push(parseInt(data.primeroUserId));
            }
        });
        setSelectedUsersToShare({
            userIds: userIds,
            groupIds: groupIds,
            roleIds: roleIds,
        });
        setAutoCompleteValues(selectedUsersToShare);
        if (setClearValues) {
            setClearValues('1');
        }
    }, []);
    const autoCompleteOptions = () => {
        if (autoCompleteFlag) {
            return data
                ?.filter((filteredData: any) => {
                    return filteredData.description !== user?.Email;
                })
                .filter((filteredData: any) => {
                    return reportSharedUsers?.indexOf(filteredData) === -1;
                });
        } else {
            return data.filter((filteredData: any) => {
                return filteredData.description !== user?.Email;
            });
        }
    };

    return (
        <AutoComplete
            multiple={true}
            id="users-multi-select"
            value={autoCompleteValues}
            key={clearValues ? clearValues : '0'}
            label="Type Name"
            options={autoCompleteOptions()}
            onChange={handleUserSelect}
            getOptionLabel={(option: any) =>
                option.description ? option.description : ''
            }
            getOptionSelected={(option: any, value: any) =>
                option?.userGuid === value.userGuid ||
                option.roleGuid === value.roleGuid ||
                option.groupGuid === value.groupGuid
            }
            OnOptionsChange={(options: any[]) =>
                handleUserSelect(null, options)
            }
        />
    );
};
export default ShareViewUtil;
