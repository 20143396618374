import React, { FC } from 'react';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Divider,
    makeStyles,
    Tooltip,
    Typography,
} from '@material-ui/core';

import { LockIcon } from '../../../assets';
import Label from '../../Label/Label';

import UserAvatar from './UserAvatar';

const classNames = makeStyles(() => {
    return {
        name: {
            margin: '8px 0',
        },
        textRoot: {
            minHeight: 24,
        },
        statusTextRoot: {
            margin: '20px 0',
        },
        statusTextContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        statusText: {
            marginLeft: '25px',
        },
    };
});

interface ITextContent {
    value: string;
}

export interface IUserCardProps {
    name: string;
    imageSrc?: string;
    status?: AccountStatus;
    className?: string;
    isUserCard?: boolean;
    cardIcon?: () => React.ReactNode;
    textContent?: ITextContent[];
    statusText?: string;
    actionButton?: () => React.ReactNode;
    statusId?: string;
    isAccountLocked?: boolean;
    lockIconTooltip?: string;
}

export enum AccountStatus {
    Active = 'active',
    InActive = 'inactive',
}

const UserCard: FC<IUserCardProps> = ({
    children,
    className,
    name,
    imageSrc,
    status,
    isUserCard = true,
    cardIcon,
    textContent,
    statusText,
    actionButton,
    statusId,
    isAccountLocked = false,
    lockIconTooltip,
}) => {
    const styles = classNames();

    return (
        <Card className={className}>
            <CardContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    {isUserCard && (
                        <UserAvatar src={imageSrc || ''} alt={'User Image'} />
                    )}
                    {!isUserCard && cardIcon && <Avatar>{cardIcon()}</Avatar>}
                    {name && (
                        <Typography
                            variant="h3"
                            id="card-title"
                            classes={{
                                root: styles.name,
                            }}
                        >
                            {name}
                        </Typography>
                    )}
                    {textContent &&
                        textContent.length > 0 &&
                        textContent.map(
                            (textItem: ITextContent, index: number) => (
                                <Typography
                                    classes={{
                                        root: styles.textRoot,
                                    }}
                                    color="textSecondary"
                                    key={`${textItem.value}-${index}`}
                                    id={`${
                                        statusId ? statusId : 'status'
                                    }_text_${index}`}
                                >
                                    {textItem.value}
                                </Typography>
                            )
                        )}
                    {statusText && (
                        <div className={styles.statusTextContainer}>
                            <Typography
                                classes={{
                                    root: styles.statusTextRoot,
                                }}
                                className={
                                    isAccountLocked ? styles.statusText : ''
                                }
                            >
                                <Label
                                    color={
                                        status === AccountStatus.Active
                                            ? 'success'
                                            : 'error'
                                    }
                                    id={statusId ? statusId : 'status'}
                                >
                                    {statusText}
                                </Label>
                            </Typography>
                            {isAccountLocked && (
                                <span>
                                    {lockIconTooltip ? (
                                        <Tooltip title={lockIconTooltip}>
                                            <LockIcon />
                                        </Tooltip>
                                    ) : (
                                        <LockIcon />
                                    )}
                                </span>
                            )}
                        </div>
                    )}
                    {actionButton && actionButton()}
                </Box>
            </CardContent>
            {children && (
                <>
                    <Divider />
                    <CardContent>{children}</CardContent>
                </>
            )}
        </Card>
    );
};

export default UserCard;
