import React, {
    ButtonHTMLAttributes,
    DOMAttributes,
    FC,
    MouseEventHandler,
} from 'react';
import { Button as MuiButton, darken, fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import clsx from 'clsx';

import { ITheme } from '../../core/Providers';
import { StandardProps, ThemeColorType } from '../../types';
import { Tooltip } from '../Tooltip';

import './button.scss';

export interface IButtonProps
    extends StandardProps<ButtonHTMLAttributes<HTMLButtonElement>>,
        DOMAttributes<HTMLButtonElement> {
    /**
     * Unique id for button
     */
    id: string;
    /**
     * Text visible in button
     */
    label?: any;
    /**
     * The name of the button, submitted as a pair with the button’s value as part of the form data
     */
    name?: string;
    /**
     * Default behavior of the button
     */
    type?: 'submit' | 'reset' | 'button';
    /**
     * Emit on click
     */
    onClick?: MouseEventHandler<HTMLButtonElement>;
    /**
     * Button color
     */
    color?: ThemeColorType | 'inherit';
    /**
     * Show loading indicator
     */
    loading?: boolean;
    /**
     * Dense variant of button
     */
    dense?: boolean;
    /**
     * Element placed before the children
     */
    startIcon?: React.ReactNode;
    /**
     * Element placed before the children.
     */
    endIcon?: React.ReactNode;
    /**
     * Separate icon with a line on left or right
     */
    iconSeparator?: boolean;
    /**
     * The variant to use
     */
    variant?: ButtonVariantType;
    /**
     * Disable the button
     */
    disabled?: boolean;
    /**
     * If `true`, the button will take up the full width of its container.
     */
    fullWidth?: boolean;
    /**
     * Text align
     */
    textAlign?: 'start' | 'center' | 'end';

    useEllipsis?: boolean;
    /**
     * for buttonGroup variant
     */

    buttonGroupVariant?: 'text' | 'outlined' | 'contained' | undefined;

    /**
     * for buttonGroup color
     */
    buttonGroupColor?: 'default' | 'inherit' | 'primary' | 'secondary';
    /**
     * for buttonGroup css
     */
    buttonGroupclassCss?: any;
    /**
     * Tooltip for button
     */
    buttonTooltip?: string;
    /**
     * button tooltip placement
     */

    buttonToolTipPlacement?:
        | 'bottom'
        | 'left'
        | 'right'
        | 'top'
        | 'bottom-end'
        | 'bottom-start'
        | 'left-end'
        | 'left-start'
        | 'right-end'
        | 'right-start'
        | 'top-end'
        | 'top-start'
        | undefined;
}

type ButtonVariantType = 'default' | 'text';

type ButtonTextColorType =
    | 'text-inherit'
    | 'text-default'
    | 'text-primary'
    | 'text-secondary'
    | 'text-warning'
    | 'text-error'
    | 'text-info';

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        verticalAlign: 'middle',
        height: '40px',
        lineHeight: '24px',
        padding: '8px 16px',
        minWidth: 72,
        maxWidth: '100%',
        overflow: 'hidden',
        borderRadius: 2,
        '&:focus': {
            boxShadow: '0 0 0 0.08rem rgba(0, 123, 255, .6)',
        },
        '&:disabled': {
            cursor: 'not-allowed',
            color: theme.palette.action.disabled,
            opacity: 0.5,
        },
        '&.textAlign-start': {
            justifyContent: 'flex-start',
        },
        '&.textAlign-center': {
            justifyContent: 'center',
        },
        '&.textAlign-end': {
            justifyContent: 'flex-end',
        },
    },
    dense: {
        fontSize: '0.8rem',
        padding: '8px 12px',
        height: '30px',
        lineHeight: '14px',
    },
    inherit: {
        color: 'inherit',
    },
    'text-inherit': {
        color: 'inherit',
    },
    default: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.grey,
        border: `1px solid ${theme.palette.divider}`,
        '&:hover': {
            backgroundColor: theme.palette.background.grey,
        },
        '&:disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
        },
    },
    'text-default': {
        color: theme.palette.text.primary,
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: darken(theme.palette.primary.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
        },
    },
    'text-primary': {
        color: theme.palette.primary.main,
    },
    secondary: {
        backgroundColor: theme.palette.common.white,
        color: darken(theme.palette.primary.main, 0.3),
        borderColor: darken(theme.palette.primary.main, 0.1),
        border: '1px solid',
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.16),
        },
        '&:disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
            borderColor: theme.palette.action.disabledBackground,
        },
    },
    'text-secondary': {
        color: theme.palette.secondary.main,
    },
    tertiary: {
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.getContrastText(theme.palette.tertiary.main),
        '&:hover': {
            backgroundColor: darken(theme.palette.tertiary.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
        },
    },
    'text-tertiary': {
        color: theme.palette.tertiary.main,
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: darken(theme.palette.success.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
        },
    },
    'text-success': {
        color: '#15845A',
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: darken(theme.palette.warning.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
        },
    },
    'text-warning': {
        color: '#D14200',
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.black,
        '&:hover': {
            backgroundColor: darken(theme.palette.error.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
        },
    },
    'text-error': {
        color: '#EB0000',
    },
    info: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.getContrastText(theme.palette.info.main),
        '&:hover': {
            backgroundColor: darken(theme.palette.info.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
        },
    },
    'text-info': {
        color: '#665AD8',
    },
    lightSpinner: {
        color: theme.palette.grey[100],
    },
    darkSpinner: {
        color: theme.palette.secondary.main,
    },
    startIconSeparator: {
        marginLeft: '-8px',
        paddingRight: '4px',
        borderRight: `1px solid`,
    },
    endIconSeparator: {
        marginRight: '-8px',
        paddingLeft: '4px',
        borderLeft: `1px solid`,
    },
    wordWrap: {
        wordBreak: 'break-word',
        textAlign: 'left',
        lineHeight: '1.25',
    },
    buttonChild: {
        textTransform: 'none',
        border: 'none !important',
        borderRight: `1px solid ${theme.palette.divider} !important`,
    },
}));

/**
 * @Button
 * @Component
 * Primeroedge Button
 * @param  children JSX element as button content
 * @param  id string ID of the button
 * @param  name string name of the button
 * @param  className classes to be included externall
 * @param  type string type of button. Default 'button'
 * @param  label string text for button
 * @param  onClick Function click function to be executed
 * @param  color string Optinal Param. Default value is 'default'. Other values: primary, secondary, tertiary, success, warning, error, info
 * @param  loading boolean optional indicated if button need to display loading icon
 * @param  dense boolean optional
 * @param  endIcon JSX optional SVG element needed as end icon
 * @param  startIcon JSX optional SVG element needed as start icon
 * @param  iconSeparator boolean optional
 * @param  variant string Optional. Indicates the type of button. values are 'contained', 'outlined', 'text'
 * @param  disabled boolean Optional. If button needs to be disabled.
 * @param  fullWidth boolean Optional. If button needs to take the full width of the container
 * @param  textAlign string Optional. Where text needs to be aligned. Default is undefined. values are 'center', 'start', 'end'
 * @param  useEllipsis boolean. Optional. If text need to be truncated. Default true
 * @param  buttonGroupVariant string. Optional. Default is 'text'. values are 'text' | 'outlined' | 'contained'
 * @param  buttonGroupColor string. Optional. Default is 'secondary'. Color of the button group.  values are'default' | 'inherit' | 'primary' | 'secondary'
 * @param  buttonGroupclassCss any. Optional button group classnames.
 * @param  buttonTooltip string Optional. Value to provide if button needs tooltip.
 */
const Button: FC<IButtonProps> = ({
    children,
    id,
    name,
    className,
    type = 'button',
    label,
    onClick,
    color = 'default',
    loading,
    dense,
    endIcon,
    startIcon,
    iconSeparator,
    variant = 'default',
    disabled,
    fullWidth,
    textAlign,
    useEllipsis = true,
    buttonGroupVariant = 'text',
    buttonGroupColor = 'secondary',
    buttonGroupclassCss,
    buttonTooltip,
    buttonToolTipPlacement = 'bottom',
    ...rest
}) => {
    const classes = useStyles();
    const textColor = `text-${color}` as ButtonTextColorType;

    return (
        <>
            {disabled ? (
                <Tooltip
                    placement={buttonToolTipPlacement}
                    arrow
                    title={buttonTooltip ?? 'noop'}
                >
                    <MuiButton
                        id={id}
                        onClick={onClick}
                        disableRipple={true}
                        disableElevation={false}
                        className={clsx(
                            classes.root,
                            {
                                [classes[color]]: variant === 'default',
                            },
                            {
                                [classes[textColor]]: variant === 'text',
                            },
                            {
                                [classes.dense]: dense,
                            },
                            `textAlign-${textAlign}`,
                            className
                        )}
                        classes={{
                            ...buttonGroupclassCss,
                            endIcon: iconSeparator
                                ? classes.endIconSeparator
                                : '',
                            startIcon: iconSeparator
                                ? classes.startIconSeparator
                                : '',
                        }}
                        disabled={disabled || loading}
                        name={name}
                        type={type}
                        value={type}
                        color={buttonGroupColor}
                        title={label}
                        aria-label={label}
                        variant={buttonGroupVariant}
                        endIcon={!loading && endIcon}
                        startIcon={!loading && startIcon}
                        disableFocusRipple
                        fullWidth={fullWidth}
                        {...rest}
                    >
                        {loading ? (
                            <CircularProgress
                                size={20}
                                className={
                                    color === 'default' || variant === 'text'
                                        ? classes.darkSpinner
                                        : classes.darkSpinner
                                }
                                aria-label={`${label} progress`}
                            />
                        ) : (
                            <span
                                aria-label="button-child"
                                className={
                                    useEllipsis ? 'ellipsis' : classes.wordWrap
                                }
                            >
                                {children}
                                {/*Show label if children is not sent*/}
                                {!children && label}
                            </span>
                        )}
                    </MuiButton>
                </Tooltip>
            ) : (
                <MuiButton
                    id={id}
                    onClick={onClick}
                    disableRipple={true}
                    disableElevation={false}
                    className={clsx(
                        classes.root,
                        {
                            [classes[color]]: variant === 'default',
                        },
                        {
                            [classes[textColor]]: variant === 'text',
                        },
                        {
                            [classes.dense]: dense,
                        },
                        `textAlign-${textAlign}`,
                        className
                    )}
                    classes={{
                        ...buttonGroupclassCss,
                        endIcon: iconSeparator ? classes.endIconSeparator : '',
                        startIcon: iconSeparator
                            ? classes.startIconSeparator
                            : '',
                    }}
                    disabled={disabled || loading}
                    name={name}
                    type={type}
                    value={type}
                    color={buttonGroupColor}
                    title={label}
                    aria-label={label}
                    variant={buttonGroupVariant}
                    endIcon={!loading && endIcon}
                    startIcon={!loading && startIcon}
                    disableFocusRipple
                    fullWidth={fullWidth}
                    {...rest}
                >
                    {loading ? (
                        <CircularProgress
                            size={20}
                            className={
                                color === 'default' || variant === 'text'
                                    ? classes.darkSpinner
                                    : classes.darkSpinner
                            }
                            aria-label={`${label} progress`}
                        />
                    ) : (
                        <span
                            aria-label="button-child"
                            className={
                                useEllipsis ? 'ellipsis' : classes.wordWrap
                            }
                        >
                            {children}
                            {/*Show label if children is not sent*/}
                            {!children && label}
                        </span>
                    )}
                </MuiButton>
            )}
        </>
    );
};

export default Button;
