import type { FC } from 'react';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Divider,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { useFormik } from 'formik';

import { EyeIcon, EyeOffIcon, LockIcon } from '../../assets';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/Button';
import { IconButton } from '../../components/IconButton';
import { ITheme } from '../../core/Providers';
import useAuth from '../../core/Providers/Auth/useAuth';

import PasswordStrength from './PasswordStrength';

const useStyles = makeStyles((theme: ITheme) => ({
    content: {
        maxWidth: 540,
    },
    errorBox: {
        marginTop: 8,
        display: 'block',
        width: '100%',
    },
    matched: {
        color: theme.palette.primary.main,
    },
    errorText: {
        color: theme.palette.error.main,
    },
    methodIcon: {
        height: 30,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

interface IPasswordPolicies {
    code: string;
    name: string | null;
    policyId: number;
    policyValue: string;
    strengthId: number;
}

const ChangePassword: FC = () => {
    const classes = useStyles();
    const { passwordPolicies, passwordChange, registeredEmail } = useAuth();
    const [error, setError] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [submitted, canBeSubmitted] = useState<boolean>(false);
    const [submitClick, setSubmitClick] = useState<boolean>(false);
    const [passwordStrength, setPasswordStrength] = useState<
        IPasswordPolicies[] | []
    >([]);
    const [minCharacters, setMinCharacters] = useState<number>(8);
    const [upperLowerChar, setUpperLowerChar] = useState<string>('True');
    const [specialChar, setSpecialChar] = useState<number>(0);
    const [numericChar, setNumericChar] = useState<number>(0);
    const [validLength, isValidLength] = useState<boolean>(false);
    const [lowerCaseValid, isLowerCaseValid] = useState<boolean>(false);
    const [isNumber, setIsNumber] = useState<boolean>(false);
    const [isSpecial, setIsSpecial] = useState<boolean>(false);
    const [isSpecialNotSatisfied, setIsSpecialNotSatisfied] = useState<boolean>(
        false
    );
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(
        false
    );
    const passwordPoliciesCallback = useCallback(async () => {
        const policies = await passwordPolicies();
        setPasswordStrength(policies);
    }, [passwordPolicies]);

    useEffect(() => {
        passwordPoliciesCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        passwordStrength?.forEach((policy: IPasswordPolicies) => {
            switch (policy.code) {
                case 'MPL':
                    setMinCharacters(parseInt(policy.policyValue));
                    break;
                case 'RULC':
                    setUpperLowerChar(policy.policyValue);
                    break;
                case 'MSPC':
                    setSpecialChar(parseInt(policy.policyValue));
                    break;
                case 'MNC':
                    setNumericChar(parseInt(policy.policyValue));
                    break;
            }
        });
    }, [passwordStrength]);

    const passwords = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        onSubmit: async (values) => {
            setSubmitClick(true);
            if (values.password !== values.confirmPassword) {
                setError('Passwords did not match.');
                setTimeout(() => {
                    setError(null);
                }, 5000);
            } else {
                if (values.password.length < minCharacters) {
                    setError('Password is too short');
                } else {
                    if (isSpecial && isNumber && lowerCaseValid) {
                        // if (policies.StatusCode === 200) {
                        setError(null);
                        canBeSubmitted(() => true);
                        const updateStatus = passwordChange({
                            password: values.password,
                        });
                        updateStatus
                            .then((status) => {
                                canBeSubmitted(() => false);
                                if (!status) {
                                    setMessage('Failed to set new password.');
                                }
                            })
                            .catch((changeError) => {
                                canBeSubmitted(() => false);
                                console.error(
                                    'Change password error:',
                                    changeError
                                );
                            });
                        // }
                        // else {
                        //     canBeSubmitted(() => false);
                        //     console.error('Change password error:');
                        // }
                    }
                }
            }
        },
    });

    useEffect(() => {
        if (
            passwords.values.password.includes('>') ||
            passwords.values.password.includes('<') ||
            passwords.values.password.includes('%') ||
            passwords.values.confirmPassword.includes('>') ||
            passwords.values.confirmPassword.includes('<') ||
            passwords.values.confirmPassword.includes('%')
        ) {
            setIsSpecial(false);
        }
    }, [passwords.values.password, passwords.values.confirmPassword]);

    useEffect(() => {
        let clearMessage: any = null;
        if (
            passwords.values.password !== passwords.values.confirmPassword &&
            message !== null
        ) {
            setMessage(() => null);
        }
        if (message !== null) {
            clearMessage = setInterval(() => {
                setMessage(() => null);
            }, 5000);
        }

        return () => clearTimeout(clearMessage);
    }, [passwords.values.password, passwords.values.confirmPassword, message]);

    useEffect(() => {
        if (passwords.values.password.length >= minCharacters) {
            isValidLength(true);
        } else {
            isValidLength(false);
        }
    }, [minCharacters, passwords.values.password.length]);

    useEffect(() => {
        const lowerCase = new RegExp('^(?=.*[a-z])(?=.*[A-Z])');
        if (upperLowerChar === 'True') {
            if (lowerCase.test(passwords.values.password)) {
                isLowerCaseValid(true);
            } else {
                isLowerCaseValid(false);
            }
        } else {
            isLowerCaseValid(true);
        }
    }, [passwords.values.password, upperLowerChar]);

    useEffect(() => {
        const validNumber = new RegExp('^(?=.*[0-9])');
        if (numericChar > 0 && validNumber.test(passwords.values.password)) {
            const newNumberRegex = new RegExp('[0-9*]', 'g');
            const numberCount =
                passwords.values.password.match(newNumberRegex)?.length ?? 0;
            setIsNumber(numberCount >= numericChar);
        } else if (numericChar === 0) {
            setIsNumber(true);
        } else {
            setIsNumber(false);
        }
    }, [passwords.values.password, numericChar]);

    useEffect(() => {
        const specialCharacter = new RegExp(
            "(?:[^`!@#$^&*\\-_=+'\\/.,]*[`!@#$^&*\\-_=+'\\/.,?|:;])"
        );
        if (
            specialChar > 0 &&
            specialCharacter.test(passwords.values.password)
        ) {
            const newCharRegex = new RegExp(
                "(?:[^`!@#$^&*\\-_=+'\\/.,]*[`!@#$^&*\\-_=+'\\/.,?|:;])",
                'g'
            );
            const charCount =
                passwords.values.password.match(newCharRegex)?.length ?? 0;
            setIsSpecial(charCount >= specialChar);
        } else if (specialChar === 0) {
            setIsSpecial(true);
        } else {
            setIsSpecial(false);
        }
    }, [passwords.values.password, specialChar]);

    const handleShowPassword = (type: string) => {
        if (type === 'password') {
            setShowPassword((prevState) => !prevState);
        } else {
            setShowConfirmPassword((prevState) => !prevState);
        }
    };
    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
            >
                <Card className={classes.content}>
                    <CardContent>
                        <Typography
                            variant="h3"
                            component="h2"
                            color="primary"
                            gutterBottom
                        >
                            Welcome to SchoolCafé!
                        </Typography>
                        <Divider />
                        <Box mt={2} mb={3}>
                            <Typography color="textPrimary" gutterBottom>
                                Please set a new password before continuing.
                            </Typography>
                        </Box>
                        {registeredEmail && (
                            <Typography color="textPrimary" gutterBottom>
                                {registeredEmail}
                            </Typography>
                        )}
                        <Box flexGrow={1} mt={2}>
                            <form onSubmit={passwords.handleSubmit}>
                                {message && (
                                    <div className={classes.errorBox}>
                                        <Alert
                                            message={message || ''}
                                            color={'error'}
                                        />
                                    </div>
                                )}
                                <TextField
                                    error={!!error}
                                    fullWidth
                                    label="New Password"
                                    id="password"
                                    margin="normal"
                                    name="password"
                                    autoComplete="current-password"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    type={showPassword ? 'text' : 'password'}
                                    value={passwords.values.password}
                                    variant="outlined"
                                    color="secondary"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (
                                            e.target.value.includes('>') ||
                                            e.target.value.includes('<') ||
                                            e.target.value.includes('%')
                                        ) {
                                            setIsSpecialNotSatisfied(false);
                                        } else {
                                            setIsSpecialNotSatisfied(true);
                                        }
                                        setSubmitClick(false);
                                        passwords.handleChange(e);
                                    }}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Show Password"
                                                    size="small"
                                                    color="inherit"
                                                    onClick={() =>
                                                        handleShowPassword(
                                                            'password'
                                                        )
                                                    }
                                                >
                                                    {showPassword ? (
                                                        <EyeIcon />
                                                    ) : (
                                                        <EyeOffIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    error={!!error}
                                    fullWidth
                                    label="Confirm Password"
                                    id="confirmPassword"
                                    margin="normal"
                                    name="confirmPassword"
                                    autoComplete="confirm-password"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    type={
                                        showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    value={passwords.values.confirmPassword}
                                    variant="outlined"
                                    color="secondary"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (
                                            e.target.value.includes('>') ||
                                            e.target.value.includes('<') ||
                                            e.target.value.includes('%')
                                        ) {
                                            setIsSpecialNotSatisfied(false);
                                        } else {
                                            setIsSpecialNotSatisfied(true);
                                        }
                                        setSubmitClick(false);
                                        passwords.handleChange(e);
                                    }}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Show Confirm Password"
                                                    size="small"
                                                    color="inherit"
                                                    onClick={() =>
                                                        handleShowPassword(
                                                            'confirm password'
                                                        )
                                                    }
                                                >
                                                    {showConfirmPassword ? (
                                                        <EyeIcon />
                                                    ) : (
                                                        <EyeOffIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {submitClick &&
                                    passwords.values.password !==
                                        passwords.values.confirmPassword &&
                                    passwords.values.password.length > 0 && (
                                        <div className={classes.errorText}>
                                            Passwords did not match.
                                        </div>
                                    )}
                                {submitClick &&
                                    passwords.values.password ===
                                        passwords.values.confirmPassword &&
                                    passwords.values.password.length > 0 &&
                                    (!validLength ||
                                        !isSpecial ||
                                        !isNumber ||
                                        !isSpecialNotSatisfied ||
                                        !lowerCaseValid) && (
                                        <div className={classes.errorText}>
                                            The password entered does not meet
                                            the below criteria.
                                        </div>
                                    )}

                                <PasswordStrength
                                    minCharacters={minCharacters}
                                    validLength={validLength}
                                    upperLowerChar={upperLowerChar}
                                    lowerCaseValid={lowerCaseValid}
                                    specialChar={specialChar}
                                    isSpecial={isSpecial}
                                    numericChar={numericChar}
                                    isNumber={isNumber}
                                />

                                <Box mt={2}>
                                    <Button
                                        id="password-change-submit"
                                        type="submit"
                                        color="primary"
                                        loading={submitted}
                                        fullWidth
                                    >
                                        Change Password
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default ChangePassword;
