import React, { useContext } from 'react';
import { ShepherdTour, ShepherdTourContext, Step } from 'react-shepherd';
import { CircularProgress, Dialog, makeStyles } from '@material-ui/core';

import { ITheme, useProfile } from '../../core/Providers';

import TourSettingsProcessor from './TourSettingsProcessor';
import { TourStyles } from './TourStyle';
import { ITourPageWrapperProps, PageTourContext, tourOptions } from './types';
import { useTourStepsInitializer } from './useTourStepsInitializer';

const useStyles = makeStyles((theme: ITheme) => ({
    layoverContainer: {
        height: '56px',
        width: '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50px',
        boxShadow: `0px 0px 16px 4px ${theme.palette.primary.dark}`,
    },
}));

/**
 * @component
 * @TourElementWrapper
 * @implements @ITourPageWrapperProps
 * HOC component what wraps the page for the Demo Tour.
 * @param children React Element
 * @param tourName string Optional Tour name on the initial screen.
 * @param steps ISteps[] array of Steps for tour.
 * @param uniqueDisplayEnableElement string ID of html element that can attach to the specific tour.
 */
export const TourPageWrapper = ({
    children,
    tourName = 'Learn SchoolCafe 2.0',
    steps = [],
    uniqueDisplayEnableElement,
}: ITourPageWrapperProps) => {
    const { showLoader, tourSteps, tours } = useTourStepsInitializer({
        steps,
        tourName,
        uniqueDisplayEnableElement,
    });

    const { layoverContainer } = useStyles();
    const filteredTour = tours?.find((tour) => tour.tourname === tourName);
    const { user } = useProfile();

    const districtOnboardingRole = user?.Roles.includes('District Onboarding');
    const isTourNotFinished = !filteredTour?.isFinished;

    const isDistrictOnboardingTour =
        districtOnboardingRole && isTourNotFinished;

    const isWorkspaceTour = tourName === 'Workspace' && isTourNotFinished;
    const isProfilePageTour = tourName === 'Profile Page' && isTourNotFinished;

    return (
        <>
            <TourStyles />
            <PageTourContext.Provider
                value={{
                    steps: [],
                    tourContext: useContext(ShepherdTourContext),
                }}
            >
                <ShepherdTour
                    tourOptions={{
                        ...tourOptions,
                        tourName,
                        defaultStepOptions: {
                            ...(tourOptions?.defaultStepOptions ?? {}),
                            when: {
                                show: function (this: Step) {},
                                hide: function (this: Step) {},
                                complete: function () {},
                                exit: () => {},
                            },
                        },
                    }}
                    steps={tourSteps}
                >
                    <TourSettingsProcessor
                        tourName={tourName}
                        tourContext={ShepherdTourContext}
                        steps={tourSteps}
                    >
                        <>
                            {children}
                            {isDistrictOnboardingTour ||
                                isWorkspaceTour ||
                                (isProfilePageTour && (
                                    <Dialog
                                        aria-labelledby="simple-dialog-title"
                                        open={showLoader}
                                        classes={{ paper: layoverContainer }}
                                    >
                                        <CircularProgress />
                                    </Dialog>
                                ))}
                        </>
                    </TourSettingsProcessor>
                </ShepherdTour>
            </PageTourContext.Provider>
        </>
    );
};

export default TourPageWrapper;
