import React, { FC, ReactNode } from 'react';
import {
    Card as MuiCard,
    CardActions,
    CardContent,
    CardHeader as MuiCardHeader,
    CardProps,
    makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

import { ITheme } from '../../core/Providers/Theme';

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        minWidth: 150,
        minHeight: 150,
    },
    actions: {
        borderTop: '1px solid #CCCCCC',
    },
    default: {
        color: theme.palette.common.black,
        borderBottom: '1px solid #CCCCCC',
    },
    primary: {
        color: theme.palette.primary.main,
        borderBottom: '1px solid #CCCCCC',
    },
    secondary: {
        color: theme.palette.secondary.main,
        borderBottom: '1px solid #CCCCCC',
    },
}));

export interface ICardProps extends CardProps {
    /**
     * Unique id for Checkbox
     */
    id: string;
    /**
     * Title for Card
     */
    title?: string;
    /**
     * Title for Card
     */
    titleColor?: 'default' | 'primary' | 'secondary';
    /**
     * Content for Card
     */
    content?: ReactNode | null;
    /**
     * Actions for Card
     */
    actions?: ReactNode | null;
    /**
     * SubHeader for Card
     */
    subheader?: string;
    /**
     * Avtar for Card
     */
    avtar?: ReactNode;
    /**
     * Title action for Card
     */
    titleAction?: ReactNode;
    /**
     * Custom Title for Card
     */
    customTitle?: ReactNode | null;
}

const Card: FC<ICardProps> = ({
    id,
    className,
    title = '',
    titleColor = 'default',
    content,
    actions,
    subheader,
    avtar,
    titleAction,
    customTitle,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <MuiCard id={id} className={clsx(classes.root, className)} {...rest}>
            {(title !== '' || customTitle) && (
                <MuiCardHeader
                    avatar={avtar}
                    action={titleAction}
                    title={customTitle ? customTitle : title}
                    subheader={subheader}
                    className={classes[titleColor]}
                />
            )}
            {content && <CardContent>{content}</CardContent>}
            {actions && (
                <CardActions className={classes.actions}>{actions}</CardActions>
            )}
        </MuiCard>
    );
};

export default Card;
