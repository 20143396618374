import React, { FC } from 'react';
import { makeStyles, TextField } from '@material-ui/core';

import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import Select from '../../../components/Inputs/Select/Select';
import { IFilters } from '../../../types/searchFilters';

interface IRenderFilterProps {
    filter: IFilters;
    onFilterChange: (val: any) => void;
    value?: any;
}

const styles = makeStyles(() => ({
    textField: {
        width: '100%',
    },
}));

const RenderFilter: FC<any> = ({
    filter,
    onFilterChange,
    value,
}: IRenderFilterProps) => {
    const { filterType } = filter;
    let SelectedInput: FC;
    const inputClasees = styles();
    switch (filterType) {
        case 'select':
            SelectedInput = () => (
                <Select
                    id={filter.id}
                    options={filter.options || []}
                    label={filter.label}
                    onChange={onFilterChange}
                    value={value !== null ? value : undefined}
                />
            );
            break;
        case 'date':
            SelectedInput = () => (
                <DatePicker
                    id={filter.id}
                    label={filter.label}
                    onChange={onFilterChange}
                    defaultValue={value}
                />
            );
            break;
        default:
            SelectedInput = () => (
                <TextField
                    variant={'outlined'}
                    id={filter.id}
                    label={filter.label}
                    title="filters"
                    onChange={onFilterChange}
                    value={value !== null ? value : ''}
                    classes={{
                        root: inputClasees.textField,
                    }}
                />
            );
            break;
    }
    return <SelectedInput />;
};

export default RenderFilter;
