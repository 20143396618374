import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { delay, isNil, isNull, isString } from 'lodash';

import { getEnvironment } from '../../core/environments';
import useAuth from '../../core/Providers/Auth/useAuth';
import ModuleInfoContext, {
    IModuleInfoContextValue,
} from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import ScStorage from '../../core/Storage';
import {
    isRememberMeEnabled,
    isUserProfileImageExists,
} from '../../core/Store/persist';
import { IUser } from '../../types/user';
import { convertDataToString } from '../../utils/file/imageToString';
import { ModuleName } from '../../utils/modules/moduleName';

import SmartAccount from './SmartAccount';
interface IAccountProps {
    user?: IUser | null;
    apimUrl: string;
    subscriptionKey: string;
}

const Account = ({ user, apimUrl, subscriptionKey }: IAccountProps) => {
    const { moduleName, activeDistrict } = useContext<IModuleInfoContextValue>(
        ModuleInfoContext
    );
    const { logout, isAuthenticated } = useAuth();
    const [userImageUrl, setUserImageUrl] = useState<string>('');
    const validationCollectionFieldForOnboarding = ScStorage().getItem(
        'validationCollectionFieldForOnboarding'
    );

    useEffect(() => {
        if (isAuthenticated) {
            if (user?.Image && user?.Image !== '') {
                const fileURL = `${apimUrl}/dataexchange/v1.0/api/FileStorage/Download?featureName=user&fileName=${user?.Image}`;

                Axios.get(fileURL, {
                    responseType: 'blob',
                    headers: {
                        Environment: getEnvironment(),
                        'Ocp-Apim-Subscription-Key': subscriptionKey,
                        Authorization: `Bearer ${ScStorage().getItem(
                            'accessToken'
                        )}`,
                        'Content-Type': `application/octet-stream`,
                    },
                })
                    .then((resp) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers['content-type'],
                        });
                        const dwnldlink = window.URL.createObjectURL(blob);
                        setUserImageUrl(dwnldlink);
                        validateAndStoreProfileImage(blob);
                    })
                    .catch((ex) => {
                        console.info(ex);
                    });
            }
        }
    }, [user?.Image, apimUrl]);

    const handleLogout = async (): Promise<void> => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const navigateToProfile = () => {
        if (
            !user?.Roles?.includes('District Onboarding') &&
            validationCollectionFieldForOnboarding !== 'RedirectToProfilePage'
        ) {
            const activeDist = !isNull(activeDistrict)
                ? isString(activeDistrict)
                    ? JSON.parse(activeDistrict)
                    : activeDistrict
                : null;
            if (activeDist?.attributes?.onboardingCompleted?.[0] == 'false') {
                window.location.replace('/system/setup-onboarding');
            } else {
                window.location.replace('/workspace/profile');
            }
        }
    };

    const storeProfileImage = async function (imageBlob: any) {
        if (imageBlob) {
            const imageAsString = await convertDataToString(imageBlob);
            if (!isNil(imageAsString)) {
                ScStorage().setItem('rememberMeProfileImage', imageAsString);
            }
        }
    };

    const validateAndStoreProfileImage = (imageBlob: any) => {
        if (moduleName === ModuleName.Workspace) {
            const isRememberMe = isRememberMeEnabled();
            const isUserProfileImageAvailable = isUserProfileImageExists();
            if (isRememberMe && !isUserProfileImageAvailable && imageBlob) {
                delay(() => storeProfileImage(imageBlob), 2000);
            }
        }
    };

    // const onMultiUserSignIn = function () {
    //     window?.dispatchEvent(new CustomEvent('multi-user-signin'))
    // };

    return (
        <SmartAccount
            handleLogout={handleLogout}
            navigateToProfile={navigateToProfile}
            userImageUrl={userImageUrl}
            user={user}
        />
    );
};

export default Account;
