import React, { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
    ChartColor,
    ChartLegendOptions,
    ChartOptions,
    ChartPoint,
    Scriptable,
} from 'chart.js';
import Chart from 'chart.js';
import { merge } from 'lodash';
import { Moment } from 'moment';

export interface IProgressDoughnutChartProps {
    /**
     * Used to configure the graph
     */
    data: IChartData;
    /**
     * used to configure the legend
     */
    legend?: ChartLegendOptions;
    /**
     * used to configure graph options
     */
    options?: ChartOptions;
    /**
     * text to display inside the donut
     */
    text?: string;
    /**
     * Font size and family
     */
    font?: string;
    /**
     * Cutout Percentage of the chart
     */
    cutoutPercentage?: number;

    showDataLabels?: boolean;
    /**
     * inside text color
     */
    insideTextColor?: ChartColor;
}

export interface IChartData {
    /**
     * Label to display on the popup
     */
    labels?: Array<
        | string
        | string[]
        | number
        | number[]
        | Date
        | Date[]
        | Moment
        | Moment[]
    >;
    /**
     * to setup data and other options
     */
    datasets: IChartDataSets[];
}

export interface IChartDataSets {
    backgroundColor?: ChartColor | ChartColor[] | Scriptable<ChartColor>;
    borderColor?: ChartColor | ChartColor[] | Scriptable<ChartColor>;
    data: Array<number | null | undefined | number[]> | ChartPoint[];
    hoverBackgroundColor?: ChartColor | ChartColor[] | Scriptable<ChartColor>;
    hoverBorderColor?: ChartColor | ChartColor[] | Scriptable<ChartColor>;
    hoverBorderWidth?: number | number[] | Scriptable<number>;
    label?: string;
}

const ProgressDoughnut: FC<IProgressDoughnutChartProps> = ({
    text,
    data,
    options,
    legend,
    font = 'bold 18px Roboto',
    cutoutPercentage = 80,
    showDataLabels,
    insideTextColor = '#000',
    ...rest
}) => {
    const pluginService = {
        beforeDraw: (chart: any) => {
            const width = chart.chart.width,
                height = chart.chart.height,
                ctx = chart.chart.ctx;
            ctx.restore();
            ctx.font = font;
            ctx.fillStyle = insideTextColor;
            if (text) {
                const text1 = chart.chart.config.data.datasets[0].data[0] + '%',
                    textX = Math.round(
                        (width - ctx.measureText(text1).width) / 2
                    ),
                    textY = height / 3;
                ctx.fillText(text1, textX, textY);

                const textx1 = Math.round(
                        (width - ctx.measureText(text).width) / 2
                    ),
                    textY1 = height / 2.3;
                ctx.fillStyle = insideTextColor || '#000';
                ctx.fillText(text, textx1, textY1);
            } else {
                const text1 = chart.chart.config.data.datasets[0].data[0] + '%';
                const textX = Math.round(
                    (width - ctx.measureText(text1).width) / 2
                );
                const textY = height / 2.2;
                ctx.fillText(text1, textX, textY);
            }
            ctx.save();
        },
    };
    if (!showDataLabels) {
        let c = Chart.defaults.global.plugins?.datalabels;
        if (c) {
            c.display = false;
        }
    }

    const shadowed = {
        beforeDatasetsDraw: function (chart: any) {
            chart.ctx.shadowColor = 'rgba(0,0,0,0.4)';
            chart.ctx.shadowBlur = 10;
            chart.ctx.shadowOffsetY = 2;
            chart.ctx.shadowOffsetX = 0;
        },
    };

    return (
        <Doughnut
            data={{
                ...data,
                datasets: data?.datasets?.map((dataset: IChartDataSets) => {
                    const val: any = dataset.data || 0;
                    const datasetData = {
                        data: [val[0], 100 - val[0]],
                        borderWidth: 0,
                        backgroundColor: dataset.backgroundColor
                            ? dataset.backgroundColor
                            : ['#26C6DA', '#EEEEEE'],
                    };
                    return merge(dataset, datasetData);
                }),
            }}
            options={{
                ...options,
                cutoutPercentage: cutoutPercentage,
                legend: {
                    position: 'bottom',
                    align: 'center',
                    textDirection: 'ltr',
                },
            }}
            {...rest}
            plugins={[pluginService, shadowed]}
        />
    );
};

export default ProgressDoughnut;
