import React, { useState } from 'react';
import { Box, InputAdornment, TextField } from '@material-ui/core';

import { EyeIcon, EyeOffIcon, LockIcon, MailIcon } from '../../assets/icons';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/Button';
import { IconButton } from '../../components/IconButton';
import { useAuth } from '../../core/Providers';

export default function MultiUserLogin() {
    const { login } = useAuth();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [values, setValues] = useState<{
        email: string;
        password: string;
    }>({
        email: '',
        password: '',
    });

    const handleChange = function (field: string) {
        return (event: any) => {
            setValues({
                ...values,
                [field]: event.target.value,
            });
        };
    };

    const handleShowPassword = function () {
        setShowPassword(!showPassword);
    };

    const onMultiUserLogin = async function () {
        sessionStorage.setItem('mutli-user', '1');
        login(values.email, values.password);
    };
    return (
        <Box>
            <Alert
                message={'Impersonations are valid only for one browser tab'}
                color={'error'}
            />
            <TextField
                fullWidth
                autoFocus
                label="Email"
                id="email"
                margin="normal"
                name="email"
                autoComplete="email"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                onChange={handleChange('email')}
                type="text"
                value={values.email}
                variant="outlined"
                color="primary"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <MailIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                fullWidth
                label="Password"
                id="password"
                margin="normal"
                name="password"
                autoComplete="current-password"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                onChange={handleChange('password')}
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                variant="outlined"
                color="primary"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <LockIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="Show Password"
                                size="small"
                                color="inherit"
                                onClick={handleShowPassword}
                            >
                                {showPassword ? <EyeIcon /> : <EyeOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                id="multiUser-logg-in"
                onClick={onMultiUserLogin}
                color="primary"
                label="multi-user login"
            >
                Impersonation Login
            </Button>
        </Box>
    );
}
