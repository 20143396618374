import React, {
    forwardRef,
    Fragment,
    ReactElement,
    Ref,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { CSVLink } from 'react-csv';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import {
    AggregateDescriptor,
    DataResult,
    process,
    State,
} from '@progress/kendo-data-query';
import { setGroupIds } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridExpandChangeEvent,
    GridNoRecords,
    GridRowProps,
    GridToolbar,
} from '@progress/kendo-react-grid';
import {
    GridDataStateChangeEvent,
    GridHeaderSelectionChangeEvent,
    GridSelectionChangeEvent,
} from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import { GridFilterOperators } from '@progress/kendo-react-grid/dist/npm/interfaces/GridFilterOperators';
import { GridHeaderCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps';
import clsx from 'clsx';
import { endOfDay } from 'date-fns/fp';
import {
    cloneDeep,
    isEqual,
    keyBy,
    keys,
    merge,
    pick,
    pickBy,
    values,
} from 'lodash';

import {
    DownloadIcon,
    ExcelIcon,
    HideFiltersIcon,
    MinusIcon,
    PdfIcon,
    PlusIcon,
    ShowFiltersIcon,
} from '../../../assets/icons';
import { MAX_WAIT_TIME } from '../../../core/constants';
import { ITheme, PageProviderContext } from '../../../core/Providers';
import ScStorage from '../../../core/Storage';
import useDimensions from '../../../hooks/useDimensions';
import { sleep, waitUntil } from '../../../utils';
import { Button } from '../../Button';
import { IMenuItems, Menu } from '../../Menu';
import { SlidingDrawer } from '../../SlidingDrawer';

import { ColumnsAndViews } from './ColumnsAndViews';
import { ColumnsAndViewsTitle, TitleType } from './ColumnsAndViewsTitle';
import ColumnSettings from './ColumnSettings';
import {
    EXPAND_FIELD,
    HIDE_EXPAND_FIELD,
    PAGE_SIZE,
    PAGE_SIZES,
} from './constants';
import CustomDateFilter from './CustomDateFilter';
import CustomDateTimeFilter from './CustomDateTimeFilter';
import {
    ExpandCollapseCell,
    ExpandCollapseHeaderCell,
} from './ExpandCollapseCell';
import {
    defaultFilterOperators,
    simpleFilterOperators,
} from './filterOperators';
import { HeaderCell } from './HeaderCell';
import KGridHeaderMask from './KGridHeaderMask';
import MoreFilters from './MoreFilters';
import {
    IGridCellProps,
    IGridColumnProps,
    IGridDataState,
    IGridExpandChangeEvent,
    IKGridProps,
    RowData,
} from './types';
import { ViewsSelection } from './ViewsSelection';

import './loading.scss';

const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        grid: {
            borderRadius: 2,
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            minHeight: 40,
            padding: '8px 16px',
            borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.light,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            '& > * + *:not(:last-child)': {
                marginRight: theme.spacing(1),
            },
        },
    })
);

const KGrid = (
    {
        title,
        className,
        data,
        columns,
        defaultColumns,
        primaryField,
        loading,
        pageable = false,
        pageSize,
        pageSizes,
        sortable = false,
        sort,
        filterable = false,
        filter,
        simpleFilter,
        filterOperators,
        groupable,
        groupableWithFooter,
        group,
        selectedField,
        onSelectionChange,
        onHeaderSelectionChange,
        fileExport,
        columnSettings,
        moreFilters,
        detail,
        expandField = EXPAND_FIELD,
        expandMultiple = true,
        hideExpandField = HIDE_EXPAND_FIELD,
        toolbarOptions,
        rowClick,
        onRowClick,
        changeRowBackgroundColor = false,
        rowBackgroundColor = '#F1F0FB',
        headerStyles = [],
        total,
        take,
        skip = 0,
        onDataStateChange,
        onColumnStateChange,
        processedData,
        cellRender,
        headerCellRender,
        expandAll = false,
        onExpandChange,
        onExpandAllChange,
        selectionActions,
        customNoRecordsText,
        showViews = false,
        showColumns = true,
        onViewSelection,
        customTitle,
        groupsProcessedData,
        enableRowPrefixColor = false,
        customRowRenderer = undefined,
        showHideFilters = false,
        displayIconOrTextForShowHideFilters = 'TEXT',
        onClearView,
        displayClearViewButton = false,
        ...rest
    }: IKGridProps<RowData>,
    ref: any
) => {
    const classes = useStyles();
    const elementRef: Ref<HTMLDivElement> = useRef(null);
    const gridDimensions = useDimensions({
        ref: elementRef,
        onResize: true,
    });
    const gridLoadingRef = useRef<typeof loading | null>(null);
    const [hasSelectedItem, setHasSelectedItem] = useState<boolean>(false);
    const pageContext = useContext(PageProviderContext);
    const [csvData, setCSVData] = useState<any>([]);
    const [filteredCSVdata, setFilteredCSVData] = useState<any>([]);

    const [columnsNotHidden, setColumnsNotHidden] = useState<any>({});

    const [hasSelectedOneItem, setHasSelectedOneItem] = useState<boolean>(
        false
    );
    const [selectedColumnViewType, setSelectedColumnViewType] = useState<
        TitleType
    >(moreFilters ? TitleType.FILTERS : TitleType.COLUMNS);
    const [aggregates, setAggregates] = useState<AggregateDescriptor[]>([]);

    useEffect(() => {
        gridLoadingRef.current = loading;
    }, [loading]);

    /**
     * Column settings panel
     */
    const [isColumnSettingsPanelOpen, setColumnSettingsPanelState] = useState<
        boolean
    >(false);
    const openColumnSettingsPanelState = () => {
        setColumnSettingsPanelState(true);
        localStorage.removeItem('isViewClearing');
    };
    const closeColumnSettingsPanel = () => {
        setColumnSettingsPanelState(false);
        if (typeof columnSettings === 'object' && columnSettings.onClose) {
            columnSettings.onClose();
        }
    };

    /**
     * Column/field search
     */
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const toggleColumnSearch = () => {
        setShowFilters((prevState) => !prevState);
    };

    /**
     * More filters panel
     */
    const [isMoreFiltersPanelOpen, setMoreFiltersPanelState] = useState<
        boolean
    >(false);

    // const openMoreFiltersPanelState = () => setMoreFiltersPanelState(true);

    const saveMoreFiltersPanel = () => {
        setMoreFiltersPanelState(false);
        if (moreFilters?.onSave) {
            moreFilters.onSave();
        }
    };

    useEffect(() => {
        localStorage.removeItem('isViewClearing');
    }, []);

    const closeMoreFiltersPanel = () => {
        setMoreFiltersPanelState(false);
        if (moreFilters?.onClose) {
            moreFilters.onClose();
        }
    };

    const resetMoreFiltersPanel = () => {
        if (moreFilters?.onReset) {
            moreFilters.onReset();
        }
    };

    /**
     * Update grid filters
     */
    const [gridFilterOperators, setGridFilterOperators] = useState<
        GridFilterOperators
    >();
    useEffect(() => {
        if (filterOperators) {
            setGridFilterOperators(filterOperators);
        } else if (simpleFilter) {
            setGridFilterOperators(simpleFilterOperators);
        } else {
            setGridFilterOperators(defaultFilterOperators);
        }
    }, [filterOperators, simpleFilter]);

    /**
     * Applies the specified operation descriptors to the data
     * Ignore on server side rendering as data is rendered by default
     */
    const processData = useCallback(
        (input: RowData[], state: State) => {
            return processedData ? process(input, {}) : process(input, state);
        },
        [processedData]
    );

    /**
     * Format data
     * Add fields
     */
    const formatData = useCallback(
        (data: RowData[]) => {
            if (!data) {
                return [];
            }

            let newData = cloneDeep(data);

            // Add selected attribute
            if (selectedField) {
                newData = newData.map((dataItem) =>
                    Object.assign(
                        {
                            [selectedField]: false,
                        },
                        dataItem
                    )
                );
            }
            // Add expand field attribute
            if (detail) {
                newData = newData.map((dataItem) =>
                    Object.assign(
                        {
                            [expandField]: false,
                        },
                        dataItem
                    )
                );
            }
            return newData;
        },
        [selectedField, expandField, detail]
    );

    /**
     * Data
     */
    const [formattedData, setFormattedData] = useState<RowData[]>(
        formatData(data)
    );

    /**
     * Update formatted data on data change
     */
    useEffect(() => {
        if (pageContext.setIsViewSelection) {
            pageContext.setIsViewSelection(false);
        }
        setFormattedData(formatData(data));
    }, [formatData, data]);

    /**
     * Expand collapse all
     */
    const [gridExpandAll, setGridExpandAll] = useState<boolean | null>(null);

    /**
     * Update data on expand all change
     */
    useEffect(() => {
        if (expandAll && typeof gridExpandAll === 'boolean') {
            setFormattedData((prevState) => {
                return prevState.map((item) => {
                    return Object.assign(item, {
                        [expandField]: gridExpandAll,
                    });
                });
            });
        }
    }, [gridExpandAll, setFormattedData, expandField, expandAll]);

    /**
     * Toggle expand collapse
     */
    const handleExpandAllChange = () => {
        setGridExpandAll((prevState) => {
            // Emit event
            if (onExpandAllChange) {
                onExpandAllChange({
                    value: !prevState,
                });
            }
            return !prevState;
        });
    };
    /**
     * Expand collapse row
     */
    const handleRowExpand = useCallback(
        (ev: IGridExpandChangeEvent) => {
            setFormattedData((prevState) => {
                return prevState.map((item) => {
                    if (primaryField) {
                        if (item[primaryField] === ev.dataItem[primaryField]) {
                            return ev.dataItem;
                        } else if (!expandMultiple) {
                            // Collapse all other detail rows
                            item[expandField] = false;
                            return item;
                        }
                    }
                    return item;
                });
            });
            // Emit expand change event
            if (onExpandChange) {
                onExpandChange(ev);
            }
        },
        [
            primaryField,
            expandField,
            expandMultiple,
            onExpandChange,
            setFormattedData,
        ]
    );

    /**
     * Get width value from input column prop
     */
    const getColumnPropWidth = useCallback(
        (
            allColumns: IGridColumnProps[],
            findColumn: IGridColumnProps
        ): string | number | undefined => {
            for (let i = 0; i < allColumns.length; i++) {
                if (
                    (allColumns[i].field &&
                        allColumns[i].field === findColumn.field) ||
                    (allColumns[i].title &&
                        allColumns[i].title === findColumn.title)
                ) {
                    return allColumns[i].width;
                }

                const columnChildren = allColumns[i].children;
                if (columnChildren) {
                    let width = getColumnPropWidth(columnChildren, findColumn);
                    if (width) {
                        return width;
                    }
                }
            }
            return;
        },
        []
    );

    /**
     * Format column widths
     */
    const updateColumnWidths = useCallback(
        (newColumns: IGridColumnProps[]) => {
            return newColumns?.map((newColumn) => {
                // Do not calculate if invalid element width or hidden columns
                if (!gridDimensions?.width || newColumn.hidden) {
                    return newColumn;
                }

                // Get percentage value from input columns props
                const propColumnWidth = getColumnPropWidth(columns, newColumn);

                if (
                    typeof propColumnWidth === 'string' &&
                    propColumnWidth.endsWith('%')
                ) {
                    const currentWidthPercentage = parseInt(propColumnWidth);
                    if (currentWidthPercentage > 0) {
                        const convertedPx = Math.round(
                            (gridDimensions.width / 100) *
                                currentWidthPercentage
                        );
                        if (convertedPx > 0) {
                            newColumn.width = convertedPx;
                        }
                    }
                }

                // Update children width
                if (newColumn.children) {
                    newColumn.children = updateColumnWidths(newColumn.children);
                }

                return newColumn;
            });
        },
        [gridDimensions, getColumnPropWidth, columns]
    );

    /**
     * Add header tooltips
     */
    const addColumnTooltips = useCallback(
        (newColumns: IGridColumnProps[]) => {
            return newColumns.map((newColumn) => {
                if (
                    !newColumn.headerCell &&
                    newColumn.field !== selectedField
                ) {
                    return Object.assign(newColumn, {
                        headerCell: (props: GridHeaderCellProps) => (
                            <HeaderCell
                                tooltip={newColumn.tooltip}
                                {...props}
                            />
                        ),
                    });
                }
                return newColumn;
            });
        },
        [selectedField]
    );

    /**
     * Update filter cell
     * Use custom date picker
     */
    const updateColumnFilterCell = useCallback(
        (newColumns: IGridColumnProps[]) => {
            return newColumns.map((newColumn) => {
                if (!newColumn.filterCell && newColumn.filter === 'date') {
                    return Object.assign(
                        {
                            filterCell: CustomDateFilter,
                        },
                        newColumn
                    );
                } else if (
                    !newColumn.filterCell &&
                    newColumn.filter === 'datetime'
                ) {
                    return Object.assign(
                        {
                            filterCell: CustomDateTimeFilter,
                        },
                        newColumn
                    );
                }
                return newColumn;
            });
        },
        []
    );

    const [defaultPageSize] = useState<number>(take || pageSize || PAGE_SIZE);

    const initialGridState: State = {
        skip: skip,
        take: pageable ? defaultPageSize : undefined,
        sort: sort || [],
        filter: filter,
        group: group,
    };
    const [gridState, setGridState] = useState<{
        state: State;
        data: DataResult;
    }>({
        state: initialGridState,
        data: processData(formattedData, initialGridState),
    });

    useImperativeHandle(ref, () => ({
        gridRef: () => kGridRef,
    }));

    /**
     * Process Data with Groups when grouping is enabled
     */
    const processWithGroups = useCallback(
        (data: any[], dataState: State) => {
            const groups = dataState.group;
            if (groups) {
                groups.map((group) => (group.aggregates = aggregates));
                dataState.group = groups;
            }
            const newDataState = process(data, dataState);

            setGroupIds({
                data: newDataState.data,
                group: dataState.group,
            });

            if (groupsProcessedData) {
                groupsProcessedData(newDataState);
            }
            // setGridState({
            //     ...gridState,
            //     data: newDataState,
            // });
        },
        [aggregates]
    );

    useEffect(() => {
        if (groupable && groupableWithFooter) {
            const selectedAggregates: AggregateDescriptor[] = [];

            if (columns?.length > 0) {
                columns.forEach((column: IGridColumnProps) => {
                    selectedAggregates.push({
                        field: column.field ? column.field : '',
                        aggregate: column.aggregate ? column.aggregate : 'sum',
                    });

                    return column;
                });

                setAggregates(selectedAggregates);
                processWithGroups(data, gridState?.state);
            }
        }
    }, [groupable, groupableWithFooter, columns, gridState, data]);

    /**
     * Update grid state on change
     */
    const handleGridStateChange = useCallback(
        (newState: State) => {
            setGridState({
                state: newState,
                data: processData(formattedData, newState),
            });
        },
        [formattedData, processData]
    );

    /**
     * Update state on partial change
     */
    const handlePartialStateChange = useCallback(
        (newState: Partial<State>) => {
            setGridState((prevState) => {
                newState = Object.assign(prevState.state, newState);
                return {
                    state: newState,
                    data: processData(formattedData, newState),
                };
            });
        },
        [formattedData, processData]
    );
    /**
     * Emit selection change on grid changes
     */
    const emitSelectionChange = useCallback(
        (dataChange: RowData[]) => {
            if (onSelectionChange) {
                onSelectionChange(dataChange);
            }
        },
        [onSelectionChange]
    );

    /**
     * Handle select all checkbox selection
     */
    const handleSelectAllClick = useCallback(
        (event: any) => {
            if (selectedField) {
                const newData = gridState.data.data.map((item) => {
                    item[selectedField] = event.target.checked;
                    return item;
                });
                const mergedData = values(
                    merge(
                        keyBy(formattedData, primaryField),
                        keyBy(newData, primaryField)
                    )
                );
                if (groupable) {
                    mergedData.pop();
                }
                const d = mergedData.map((data) => {
                    return {
                        ...data,
                        Selected: event.target.checked,
                    };
                });
                setFormattedData(d);
                emitSelectionChange(d);
                if (onHeaderSelectionChange) {
                    onHeaderSelectionChange(event);
                }
            }
        },
        [
            selectedField,
            gridState.data.data,
            formattedData,
            primaryField,
            groupable,
            emitSelectionChange,
            onHeaderSelectionChange,
        ]
    );

    /**
     * Update selected field
     */
    const updateSelectedColumn = useCallback(
        (newColumns: IGridColumnProps[]) => {
            return newColumns?.map((column) => {
                if (selectedField && column.field === selectedField) {
                    return Object.assign(
                        {
                            field: selectedField,
                            width: '50px',
                            filterable: false,
                            sortable: false,
                            reorderable: false,
                            groupable: false,
                            headerSelectionValue: false,
                            headerClassName: 'selected-column',
                            headerCell: () => (
                                <KGridHeaderMask
                                    hasSelectedItem={hasSelectedItem}
                                    hasSelectedOneItem={hasSelectedOneItem}
                                    onSelectAll={handleSelectAllClick}
                                    selectionActions={selectionActions}
                                    title={column.title}
                                    width={column.width}
                                />
                            ),
                        },
                        column
                    );
                }
                return column;
            });
        },
        [
            selectedField,
            selectionActions,
            hasSelectedItem,
            hasSelectedOneItem,
            handleSelectAllClick,
        ]
    );

    /**
     * Update selected field
     */
    const addExpandCollapseColumn = useCallback(
        (newColumns: IGridColumnProps[]) => {
            const hasExpandField = newColumns?.find(
                (column: IGridColumnProps) =>
                    column.field?.toLowerCase() === expandField?.toLowerCase()
            );
            if (!hasExpandField) {
                newColumns.unshift({
                    field: expandField,
                    headerCell: ExpandCollapseHeaderCell,
                    cell: (cellProps: GridCellProps) => {
                        return (
                            <ExpandCollapseCell
                                onExpandChange={handleRowExpand}
                                expandField={expandField}
                                hideExpandField={hideExpandField}
                                {...cellProps}
                            />
                        );
                    },
                    width: '40px',
                    filterable: false,
                    sortable: false,
                    reorderable: false,
                    groupable: false,
                });
            }
            return newColumns;
        },
        [expandField, hideExpandField, handleRowExpand]
    );

    /**
     * Update grid data on formattedData change
     */
    const handleGridDataChange = useCallback(() => {
        setGridState((prevState) => ({
            state: prevState.state,
            data: processData(formattedData, prevState.state),
        }));
    }, [formattedData, processData]);

    useEffect(() => {
        /**
         * Collapse expand details when all rows
         */
        gridState.data.data?.map((categoryItem) => {
            if (
                categoryItem.items?.length > 0 &&
                expandField &&
                categoryItem.items.every(
                    (item: RowData) => item[expandField] === false
                )
            ) {
                categoryItem[expandField] = false;
                return categoryItem;
            }
            return categoryItem;
        });

        /**
         * Update expand all state when data items are expanded
         */
        // if (expandAll) {
        //     const allItemsExpanded = gridState.data.data.every(
        //         (item) => item[hideExpandField] || item[expandField]
        //     );
        //     if (allItemsExpanded) {
        //         setGridExpandAll(true);
        //     } else {
        //         const allItemsCollapsed = gridState.data.data.every(
        //             (item) => item[hideExpandField] || !item[expandField]
        //         );
        //         if (allItemsCollapsed) {
        //             setGridExpandAll(false);
        //         }
        //     }
        // }
    }, [gridState, detail, expandAll, expandField, hideExpandField]);

    /**
     * Update onDataStateChange
     */
    const handleDataStateChange = (ev: GridDataStateChangeEvent) => {
        // Reset to first page on sort, grouping, filter
        if (
            !isEqual(ev.dataState.sort, gridState.state.sort) ||
            !isEqual(ev.dataState.filter, gridState.state.filter) ||
            !isEqual(ev.dataState.group, gridState.state.group)
        ) {
            ev.dataState.skip = 0;
        }

        const tempEv = Object.assign(ev);

        if (
            tempEv.syntheticEvent?.type === 'Date' &&
            tempEv.data.filter?.filters?.length > 0 &&
            tempEv.dataState.filter &&
            ev.dataState.filter
        ) {
            ev.dataState.filter.filters.push({
                field: tempEv.data.filter.filters[0].field,
                operator: 'lte',
                value: endOfDay(tempEv.data.filter.filters[0].value),
            });
        }

        // Update grid state
        handleGridStateChange(ev.dataState);

        // Emit event
        if (onDataStateChange) {
            if (groupable && groupableWithFooter) {
                processWithGroups(data, ev.dataState);
            }
            onDataStateChange(ev);
        }
    };

    /**
     * Update grid state on skip change
     */
    useEffect(() => {
        handlePartialStateChange({
            skip: skip,
        });
    }, [skip, handlePartialStateChange]);

    /**
     * Update grid state on take and page size change
     */
    useEffect(() => {
        handlePartialStateChange({
            take: pageable ? take || pageSize || PAGE_SIZE : undefined,
        });
    }, [pageable, take, pageSize, handlePartialStateChange]);

    /**
     * Update grid state on sort change
     */
    useEffect(() => {
        handlePartialStateChange({
            sort: sort,
        });
    }, [sort, handlePartialStateChange]);

    /**
     * Update grid state on group change
     */
    useEffect(() => {
        handlePartialStateChange({
            group: group,
        });
    }, [group, handlePartialStateChange]);

    /**
     * Update grid state on filter change
     */
    useEffect(() => {
        handlePartialStateChange({
            filter: filter,
        });
    }, [filter, handlePartialStateChange]);

    /**
     * Process grid data on formatted data change
     */
    useEffect(() => {
        handleGridDataChange();
    }, [handleGridDataChange]);

    /**
     * Update on data changes
     */
    useEffect(() => {
        // Reset to first page on data change
        if (gridState.data.total !== formattedData.length) {
            handlePartialStateChange({
                skip,
            });
        }
    }, [
        skip,
        handlePartialStateChange,
        formattedData.length,
        gridState.data.total,
    ]);

    /**
     * Group expand collapse
     */
    const handleGroupExpandChange = useCallback(
        (ev: GridExpandChangeEvent) => {
            ev.dataItem[expandField] = ev.value;
            ev.dataItem.items?.forEach((item: RowData) => {
                item[expandField] = ev.value;
            });

            // Update state to render updated value
            setGridState((prevState) => {
                return {
                    state: prevState.state,
                    data: prevState.data,
                };
            });

            // Emit expand change event
            if (onExpandChange) {
                onExpandChange(ev);
            }
        },
        [expandField, onExpandChange]
    );

    /**
     * Custom expand collapse
     * Grouping + ExpandCollapseCell has issue working together
     * onExpandChange={} is required for grouping, should be empty for custom expand
     */
    const [hasDefaultExpandChange] = useState<object | undefined>(
        !!groupable
            ? {
                  onExpandChange: handleGroupExpandChange,
              }
            : undefined
    );

    /**
     * Show warning if HideRowDetail is used along with groupable
     */
    useEffect(() => {
        if (
            hasDefaultExpandChange &&
            data?.some((dataItem) => dataItem[hideExpandField])
        ) {
            console.warn(
                `${hideExpandField} option will not work when 'groupable' is enabled.`
            );
        }
    }, [data, hideExpandField, hasDefaultExpandChange]);

    /**
     * Format columns
     */
    const formatColumns = useCallback(
        (columns: IGridColumnProps[]) => {
            // Add selected column
            let newColumns: IGridColumnProps[] = cloneDeep(columns);

            // Format widths
            newColumns = updateColumnWidths(newColumns);

            // Add tooltips
            newColumns = addColumnTooltips(newColumns);

            // Update selected field
            if (selectedField) {
                newColumns = updateSelectedColumn(newColumns);
            }

            // Update filter cell
            newColumns = updateColumnFilterCell(newColumns);

            // Add expand column
            if (!!detail && !hasDefaultExpandChange) {
                newColumns = addExpandCollapseColumn(newColumns);
            }
            return newColumns;
        },
        [
            selectedField,
            detail,
            hasDefaultExpandChange,
            updateColumnWidths,
            updateSelectedColumn,
            updateColumnFilterCell,
            addColumnTooltips,
            addExpandCollapseColumn,
        ]
    );

    /**
     * Columns
     */
    const [formattedColumns, setFormattedColumns] = useState<
        IGridColumnProps[]
    >(formatColumns(columns));

    /**
     * Save initial columns state
     */
    const [defaultColumnsState, setDefaultColumnsState] = useState<
        readonly IGridColumnProps[]
    >(Object.freeze(columns));

    useEffect(() => {
        if (defaultColumns) {
            setDefaultColumnsState(defaultColumns);
        }
    }, [defaultColumns]);

    /**
     * Format columns on column props change
     */
    useEffect(() => {
        setFormattedColumns(formatColumns(columns));
    }, [columns, formatColumns]);

    /**
     * Updating the pagination on delete
     */
    useEffect(() => {
        const dataTotal = total || gridState.data.total;
        if (
            gridState.state.take &&
            gridState.state.skip &&
            dataTotal >= gridState.state.take &&
            dataTotal <= gridState.state.skip
        ) {
            let newSkip = gridState.state.skip - gridState.state.take;
            handlePartialStateChange({
                skip: newSkip,
            });
        }
    }, [total, gridState, handlePartialStateChange]);

    /**
     * Update header select all value
     */
    useEffect(() => {
        setFormattedColumns((prevState) => {
            return prevState?.map((column) => {
                if (selectedField && column.field === selectedField) {
                    return Object.assign(column, {
                        headerSelectionValue:
                            gridState.data.data?.length > 0 &&
                            gridState.data.data.findIndex(
                                (dataItem) => !dataItem[selectedField]
                            ) === -1,
                    });
                }
                return column;
            });
        });
    }, [gridState.data.data, selectedField, setFormattedColumns]);

    /**
     * Emit event on column values change
     */
    useEffect(() => {
        if (onColumnStateChange) {
            // Get all props used in columns
            const allUsedKeys = columns.reduce((acc, col) => {
                Object.keys(col).forEach((key) => acc.add(key));
                return acc;
            }, new Set<string>());

            // Create new columns based on used props
            const updatedColumnValues = formattedColumns.map((column) => {
                return pickBy(column, (_, key) => allUsedKeys.has(key));
            });

            // Emit event
            if (!isEqual(columns, updatedColumnValues)) {
                onColumnStateChange({
                    columns: updatedColumnValues,
                });
            }
        }
    }, [formattedColumns, columns, onColumnStateChange]);

    /**
     * Emit event when selection has changed
     */
    const handleSelectionChange = (ev: GridSelectionChangeEvent) => {
        if (selectedField && primaryField) {
            setFormattedData((prevData) => {
                const newData = prevData.map((item) => {
                    if (item[primaryField] === ev.dataItem[primaryField]) {
                        item[selectedField] = !ev.dataItem[selectedField];
                    }
                    return item;
                });
                emitSelectionChange(newData);
                return newData;
            });
        } else {
            console.error('primaryField is required attribute for selection');
        }
    };

    /**
     * Update the checkbox status to display delete button
     */

    useEffect(() => {
        if (
            selectedField &&
            gridState.data.data?.length &&
            gridState.data.data.every((e) => e[selectedField] === true)
        ) {
            setHasSelectedItem(true);
        } else if (
            selectedField &&
            gridState.data.data?.length &&
            groupable &&
            gridState.data.data.every(
                (e: any) =>
                    e?.items &&
                    e?.items?.every((item: any) => item[selectedField] === true)
            )
        ) {
            setHasSelectedItem(true);
        } else {
            setHasSelectedItem(false);
        }
        if (
            selectedField &&
            gridState.data.data?.length &&
            gridState.data.data.some((e) => e[selectedField] === true)
        ) {
            setHasSelectedOneItem(true);
        } else {
            setHasSelectedOneItem(false);
        }
    }, [gridState.data, groupable, selectedField]);

    /**
     * Emit event when header selection has changed
     */
    const handleHeaderSelectionChange = (
        ev: GridHeaderSelectionChangeEvent
    ) => {
        if (selectedField) {
            const checked = (ev.syntheticEvent.target as any)?.checked;
            const newData = gridState.data.data.map((item) => {
                item[selectedField] = checked;
                return item;
            });
            const mergedData = values(
                merge(
                    keyBy(formattedData, primaryField),
                    keyBy(newData, primaryField)
                )
            );
            setFormattedData(mergedData);
            emitSelectionChange(mergedData);
            if (onHeaderSelectionChange) {
                onHeaderSelectionChange(ev);
            }
        } else {
            console.error('primaryField is required attribute for selection');
        }
    };

    /**
     * Excel Export
     */
    const excelExportRef: Ref<any> = useRef(null);
    const kGridRef: Ref<any> = useRef(null);

    const fileExportRef = useRef<typeof fileExport | null>(null);

    useEffect(() => {
        fileExportRef.current = fileExport;
    }, [fileExport]);

    //@ts-ignore
    const shiftOrderIndex0toTop = (key: any, value: any) => (a: any, b: any) =>
        //@ts-ignore
        (b[key] === value) - (a[key] === value);

    const exportToCSV = async () => {
        let exportColumns: IGridColumnProps[] | any = formattedColumns;
        setCSVData([]);
        // Exclude columns from export
        const excludeColumns = fileExportRef.current?.excludeColumns || [];

        // Do not export expand field
        excludeColumns.push(expandField);

        // Include hidden columns
        if (fileExportRef.current?.includeColumns) {
            exportColumns = exportColumns.map((column: IGridColumnProps) => {
                if (
                    column.field &&
                    fileExportRef.current?.includeColumns?.includes(
                        column.field
                    )
                ) {
                    column.hidden = false;
                }
                return column;
            });
        }
        // Remove exclude columns
        exportColumns = exportColumns?.filter(
            (column: IGridColumnProps) =>
                column.field && !excludeColumns?.includes(column.field)
        );
        // Optionally wait until all data is received
        let canExport: boolean | null | undefined | void = true;
        if (
            typeof fileExport?.csv === 'object' &&
            typeof fileExport.csv.willExport === 'function'
        ) {
            try {
                canExport = await fileExport.csv.willExport();
            } catch (err) {
                console.error('CSV Export Failed', err);
            }
        }

        try {
            // Adding minimum wait time for loading to start in Redux async flow
            await sleep(250);
            // Wait until loading is done
            await waitUntil(() => gridLoadingRef.current !== true, {
                maxWaitTime: 10 * MAX_WAIT_TIME,
            });
        } catch (err) {
            console.error(err);
        }
        // Export data
        if (canExport === false) {
            // false is an option to cancel data export
            console.error('CSV Export Cancelled');
        } else {
            // Data - Export or Grid Data
            const exportData =
                typeof fileExportRef.current?.csv === 'object' &&
                fileExportRef.current?.csv?.data
                    ? fileExportRef.current?.csv?.data
                    : data;
            // Export all data or page data
            let pageState: Partial<IGridDataState> = {};
            if (!fileExportRef.current?.pageable) {
                pageState = {
                    skip: 0,
                    take: exportData?.length,
                };
            }
            // Process data
            const exportProcessedData = processData(exportData, {
                ...gridState.state,
                ...pageState,
                // Export data without grouping
                group: undefined,
            });
            //changing the orderIndex from 0 to 1 for csv column display order purpose
            const newColumnsArray = exportColumns.map((data: any) => {
                if (data?.orderIndex === 0) {
                    return {
                        ...data,
                        orderIndex: 1,
                    };
                } else if (data?.orderIndex > 0) {
                    return {
                        ...data,
                        orderIndex: data.orderIndex + 1,
                    };
                } else {
                    return data;
                }
            });
            //push data to array1 if columnsArray contains orderIndex
            const array1: any = [];
            //push data to array2 if columnsArray doesn't contains orderIndex
            const array2: any = [];
            // eslint-disable-next-line array-callback-return
            newColumnsArray?.map((prevdata: IGridColumnProps) => {
                if (prevdata && prevdata.orderIndex) {
                    if (prevdata.orderIndex > 0) {
                        array1.push(prevdata);
                    }
                } else {
                    array2.push(prevdata);
                }
            });
            //cort array1 based on orderIndex
            const newArray = array1
                .sort((a: any, b: any) => a.orderIndex - b.orderIndex)
                .concat(array2);

            newArray?.map((prevdata: IGridColumnProps) => {
                if (!prevdata.hidden) {
                    setColumnsNotHidden((prevState: any) => {
                        return {
                            ...prevState,
                            [`${prevdata.field}`]: null,
                        };
                    });
                }
            });

            if (exportProcessedData.data.length > 0) {
                setCSVData(exportProcessedData);
            }
        }
    };
    useEffect(() => {
        if (csvData.data && csvData.data.length > 0) {
            setFilteredCSVData(
                csvData.data.map((newData: any) => {
                    return pick(newData, keys(columnsNotHidden));
                })
            );
        }
    }, [columnsNotHidden, csvData]);

    useEffect(() => {
        if (filteredCSVdata.length > 0) {
            document.getElementById('downloadCSV')?.click();
        }
    }, [filteredCSVdata]);
    const handleExcelExport = async () => {
        let exportColumns: IGridColumnProps[] = formattedColumns;

        // Exclude columns from export
        const excludeColumns = fileExportRef.current?.excludeColumns || [];

        // Do not export expand field
        excludeColumns.push(expandField);

        // Include hidden columns
        if (fileExportRef.current?.includeColumns) {
            exportColumns = exportColumns.map((column) => {
                if (
                    column.field &&
                    fileExportRef.current?.includeColumns?.includes(
                        column.field
                    )
                ) {
                    column.hidden = false;
                }
                return column;
            });
        }

        // Remove exclude columns
        exportColumns = exportColumns
            ?.filter(
                (column) =>
                    column.field && !excludeColumns?.includes(column.field)
            )
            .sort(shiftOrderIndex0toTop('orderIndex', 0));
        // Optionally wait until all data is received
        let canExport: boolean | null | undefined | void = true;
        if (
            typeof fileExport?.excel === 'object' &&
            typeof fileExport.excel.willExport === 'function'
        ) {
            try {
                canExport = await fileExport.excel.willExport();
            } catch (err) {
                console.error('Excel Export Failed', err);
            }
        }

        try {
            // Adding minimum wait time for loading to start in Redux async flow
            await sleep(250);
            // Wait until loading is done
            await waitUntil(() => gridLoadingRef.current !== true, {
                maxWaitTime: 10 * MAX_WAIT_TIME,
            });
        } catch (err) {
            console.error(err);
        }

        // Export data
        if (canExport === false) {
            // false is an option to cancel data export
            console.error('Excel Export Cancelled');
        } else {
            // Data - Export or Grid Data
            const exportData =
                typeof fileExportRef.current?.excel === 'object' &&
                fileExportRef.current?.excel?.data
                    ? fileExportRef.current?.excel?.data
                    : data;
            // Export all data or page data
            let pageState: Partial<IGridDataState> = {};
            if (!fileExportRef.current?.pageable) {
                pageState = {
                    skip: 0,
                    take: exportData?.length,
                };
            }
            // Process data
            const exportProcessedData = processData(exportData, {
                ...gridState.state,
                ...pageState,
                // Export data without grouping
                group: undefined,
            });

            // Save
            if (
                typeof fileExportRef.current?.excel === 'object' &&
                fileExportRef.current?.excel.columns?.length
            ) {
                // Use provided excel column props
                excelExportRef?.current?.save(
                    exportProcessedData,
                    fileExportRef.current?.excel.columns
                );
            } else {
                excelExportRef?.current?.save(
                    exportProcessedData,
                    exportColumns
                );
            }
        }
    };
    /**
     * Custom cell render
     */
    const handleCellRender = (
        td: ReactElement<HTMLTableCellElement> | null,
        props: IGridCellProps
    ) => {
        // Use custom cellRender
        if (cellRender) {
            return cellRender(td, props);
        }
        // Fix issue with empty cell while grouping
        if (td && td.props.children && props.rowType === 'groupHeader') {
            const cellProps = Object.assign({}, td.props);
            if (cellProps.colSpan) {
                cellProps.colSpan -= 1;
            }
            return React.cloneElement(td, cellProps);
        }
        return td;
    };
    /**
     * Column filter
     */
    const handleColumnChange = (newColumns: IGridColumnProps[]) => {
        const updatedColumns = updateColumnWidths(newColumns);
        setFormattedColumns(updatedColumns);
    };
    const itemChange = (e: any) => {
        let newData = gridState.data.data.map((item) => {
            if (item.ProductID === e.dataItem.ProductID) {
                item[e.field || ''] = e.value;
            }
            return item;
        });
        setFormattedData(newData);
    };
    const getcustomClassName = (prefixColor: string) => {
        let className = '';
        switch (prefixColor) {
            case 'primary':
                className = 'k-grid-custom-primary';
                break;
            case 'secondary':
                className = 'k-grid-custom-secondary';
                break;
            case 'success':
                className = 'k-grid-custom-success';
                break;
            case 'warning':
                className = 'k-grid-custom-warning';
                break;
            case 'error':
                className = 'k-grid-custom-error';
                break;
            case 'info':
                className = 'k-grid-custom-info';
                break;
            case 'default':
                className = 'k-grid-custom-default';
                break;
            default:
                className = 'k-grid-custom-noBackground';
                break;
        }
        return className;
    };
    /**
     * this function will trigger when user try to change something on the grid
     */
    const onRowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps
    ) => {
        const selected = props.dataItem[`${selectedField}`];
        const bgColor = {
            backgroundColor:
                (changeRowBackgroundColor && selected) ||
                props.dataItem['expanded']
                    ? rowBackgroundColor
                    : '',
        };
        const trProps: any = {
            style: bgColor,
            className: clsx(
                trElement.props.className,
                props.dataItem['expanded']
                    ? 'k-grid-expanded'
                    : enableRowPrefixColor
                    ? getcustomClassName(props.dataItem['rowPrefixColor'])
                    : ''
            ),
        };
        return React.cloneElement(
            trElement,
            {
                ...trProps,
            },
            trElement.props.children
        );
    };
    const [currentViewName, setCurrentViewName] = useState<string>('');
    useEffect(() => {
        if (localStorage.getItem('isViewClearing') !== 'true') {
            setCurrentViewName(
                pageContext?.currentView ||
                    pageContext?.viewAttributes?.viewName ||
                    pageContext?.viewAttributes?.currentViewName ||
                    pageContext?.defaultViewAttributes?.viewName
            );
        } else {
            setCurrentViewName('');
        }
    }, [
        ScStorage().getItem('newSavedViewId'),
        pageContext.viewAttributes,
        pageContext.defaultViewAttributes,
    ]);
    const downloadFileMenuContext: any = () => {
        let menuItems: IMenuItems[] = [];
        if (fileExport?.excel) {
            menuItems.push({
                menuItem: 'EXCEL',
                onClick: handleExcelExport,
                icon: <ExcelIcon />,
            });
        }
        if (fileExport?.csv) {
            menuItems.push({
                menuItem: 'CSV',
                onClick: exportToCSV,
                icon: <DownloadIcon />,
            });
        }
        if (fileExport?.pdf) {
            menuItems.push({
                menuItem: 'PDF',
                onClick: fileExport.pdf.downloadPDFAction,
                icon: <PdfIcon />,
            });
        }
        return menuItems;
    };

    const ToolbarOptions = toolbarOptions || Fragment;
    return (
        <div
            ref={elementRef}
            className={clsx('k-grid-wrapper', {
                'loading-mask disable-select': loading,
            })}
        >
            {fileExport && fileExport.excel && (
                <ExcelExport
                    ref={excelExportRef}
                    fileName={fileExport.fileName}
                    {...fileExport.excel}
                />
            )}
            {fileExport && fileExport.csv && filteredCSVdata.length > 0 && (
                <CSVLink
                    id="downloadCSV"
                    hidden={true}
                    data={filteredCSVdata}
                    filename={fileExport.fileName}
                    headers={fileExport?.csvHeadres}
                />
            )}
            <Grid
                editField="inEdit"
                onItemChange={itemChange}
                ref={kGridRef}
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    lineHeight: '16px',
                }}
                className={clsx(
                    classes.grid,
                    className,
                    headerStyles?.join(' '),
                    {
                        'row-click': Boolean(rowClick),
                    },
                    {
                        'simple-filter': simpleFilter,
                    },
                    {
                        'selection-actions':
                            Boolean(selectionActions) &&
                            (hasSelectedItem || hasSelectedOneItem),
                    },
                    {
                        'display-grid-headers': !Boolean(selectionActions),
                    }
                )}
                data={gridState.data}
                sortable={
                    sortable
                        ? Object.assign(
                              {
                                  allowUnsort: true,
                              },
                              sortable
                          )
                        : sortable
                }
                filterable={
                    showHideFilters
                        ? showFilters &&
                          (data?.length > 0 ||
                              processedData ||
                              !!gridState.state.filter?.filters)
                        : filterable &&
                          (data?.length > 0 ||
                              processedData ||
                              !!gridState.state.filter?.filters)
                }
                filterOperators={gridFilterOperators}
                // ToDo: Groupable is disabled for Release 1. Add back after release.
                groupable={{
                    enabled: false,
                    footer:
                        groupable && groupableWithFooter
                            ? 'visible'
                            : undefined,
                }}
                pageable={
                    pageable &&
                    (total || data?.length) > defaultPageSize &&
                    gridState.data?.total
                        ? {
                              pageSizes:
                                  pageSizes === true ? PAGE_SIZES : pageSizes,
                          }
                        : false
                }
                total={total}
                detail={detail}
                expandField={expandField}
                {...hasDefaultExpandChange}
                selectedField={selectedField}
                onSelectionChange={handleSelectionChange}
                onHeaderSelectionChange={handleHeaderSelectionChange}
                onDataStateChange={handleDataStateChange}
                onRowClick={onRowClick}
                cellRender={handleCellRender}
                rowRender={customRowRenderer ?? onRowRender}
                headerCellRender={headerCellRender}
                {...gridState.state}
                {...rest}
            >
                {customNoRecordsText ? (
                    <GridNoRecords>{`${customNoRecordsText}`}</GridNoRecords>
                ) : (
                    <GridNoRecords>No records available</GridNoRecords>
                )}
                {(title ||
                    customTitle ||
                    expandAll ||
                    fileExport?.excel ||
                    columnSettings ||
                    moreFilters ||
                    filterable ||
                    toolbarOptions) && (
                    <GridToolbar>
                        <Box
                            className={clsx(
                                'k-grid-toolbar-content',
                                classes.toolbar
                            )}
                        >
                            {title && (
                                <Typography
                                    variant="h5"
                                    component="p"
                                    color="textPrimary"
                                    align="center"
                                    style={{
                                        marginRight: '16px',
                                    }}
                                >
                                    {title}
                                </Typography>
                            )}
                            {customTitle && customTitle}
                            <Box flexGrow={1} aria-hidden="true" />
                            <ToolbarOptions />
                            {expandAll && detail && (
                                <Button
                                    label="expand-all"
                                    id="gridExpandAll"
                                    onClick={handleExpandAllChange}
                                    dense
                                    startIcon={
                                        gridExpandAll ? (
                                            <MinusIcon
                                                aria-label="expand"
                                                size={18}
                                            />
                                        ) : (
                                            <PlusIcon
                                                aria-label="collapse"
                                                size={18}
                                            />
                                        )
                                    }
                                >
                                    {gridExpandAll
                                        ? 'Collapse All'
                                        : 'Expand All'}
                                </Button>
                            )}
                            {(fileExport?.excel || fileExport?.csv) && (
                                <Menu
                                    id="Menu"
                                    icon={<DownloadIcon size={25} />}
                                    menuContent={downloadFileMenuContext()}
                                    PaperProps={{
                                        style: {
                                            marginTop: '40px',
                                        },
                                    }}
                                    buttonProps={{
                                        color: 'primary',
                                        id: 'button',
                                    }}
                                />
                            )}
                            {showHideFilters && !showViews && (
                                <Button
                                    id="showhidefilters"
                                    onClick={toggleColumnSearch}
                                    buttonToolTipPlacement="bottom"
                                    dense
                                    style={{
                                        minWidth: '55px',
                                        lineHeight: '13px',
                                    }}
                                    label={
                                        showFilters
                                            ? 'Hide Filters'
                                            : 'Show FIlters'
                                    }
                                >
                                    {showFilters ? (
                                        displayIconOrTextForShowHideFilters ===
                                        'TEXT' ? (
                                            'Hide Filters'
                                        ) : (
                                            <HideFiltersIcon />
                                        )
                                    ) : displayIconOrTextForShowHideFilters ===
                                      'TEXT' ? (
                                        'Show Filters'
                                    ) : (
                                        <ShowFiltersIcon />
                                    )}
                                </Button>
                            )}
                            {currentViewName && <b>View: </b>}
                            <span
                                style={{
                                    marginRight: '10px',
                                }}
                            >
                                {currentViewName}
                            </span>
                            {columnSettings && (
                                <ColumnsAndViews
                                    openColumnSettingsPanelState={
                                        openColumnSettingsPanelState
                                    }
                                    showViews={showViews}
                                    showHideFilters={showHideFilters}
                                    showFilters={showFilters}
                                    toggleColumnSearch={toggleColumnSearch}
                                    onClearView={onClearView}
                                    displayClearViewButton={
                                        displayClearViewButton
                                    }
                                />
                            )}
                        </Box>
                    </GridToolbar>
                )}
                {/*ToDo: Improve nested children rendering. Remove any type and fix filter*/}
                {formattedColumns?.map((columnProps: any, idx) => {
                    return (
                        !columnProps.hidden && (
                            <GridColumn
                                key={idx}
                                {...columnProps}
                                children={columnProps.children?.reduce(
                                    (
                                        acc: ReactElement[],
                                        childColumnProps: any,
                                        cIdx: number
                                    ) => {
                                        if (!childColumnProps.hidden) {
                                            acc.push(
                                                <GridColumn
                                                    key={`${idx}-${cIdx}`}
                                                    {...childColumnProps}
                                                    children={childColumnProps.children?.reduce(
                                                        (
                                                            scAcc: ReactElement[],
                                                            subChildColumnProps: any,
                                                            scIdx: number
                                                        ) => {
                                                            if (
                                                                !subChildColumnProps.hidden
                                                            ) {
                                                                scAcc.push(
                                                                    <GridColumn
                                                                        key={`${idx}-${cIdx}-${scIdx}`}
                                                                        {...subChildColumnProps}
                                                                    />
                                                                );
                                                            }
                                                            return scAcc;
                                                        },
                                                        [] as ReactElement<
                                                            IGridColumnProps
                                                        >[]
                                                    )}
                                                />
                                            );
                                        }
                                        return acc;
                                    },
                                    [] as ReactElement<IGridColumnProps>[]
                                )}
                            />
                        )
                    );
                })}
            </Grid>
            {/* Columns Filter Panel*/}
            <SlidingDrawer
                title={
                    <ColumnsAndViewsTitle
                        selectedType={selectedColumnViewType}
                        onTitleSelection={(title: TitleType) => {
                            setSelectedColumnViewType(title);
                        }}
                        showViews={showViews}
                        showColumns={showColumns}
                        showFilters={moreFilters ? true : false}
                    />
                }
                open={isColumnSettingsPanelOpen}
                onCloseIconClick={closeColumnSettingsPanel}
                onBackdropClick={closeColumnSettingsPanel}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    },
                }}
                padding="0 16px 8px 16px"
            >
                {selectedColumnViewType === TitleType.FILTERS && (
                    <>
                        {moreFilters?.onReset && (
                            <Box mb={1.5} mt={0.5}>
                                <button
                                    aria-label="reset"
                                    id="resetFilters"
                                    type="reset"
                                    onClick={resetMoreFiltersPanel}
                                    className="k-grid-filters-reset"
                                >
                                    Reset
                                </button>
                            </Box>
                        )}
                        {moreFilters?.children}
                        <MoreFilters moreFilters={moreFilters} />
                    </>
                )}
                {selectedColumnViewType === TitleType.COLUMNS && (
                    <ColumnSettings
                        columns={formattedColumns || []}
                        defaultColumns={defaultColumnsState}
                        columnSettings={columnSettings}
                        selectedField={selectedField}
                        expandField={expandField}
                        onColumnChange={handleColumnChange}
                    />
                )}
                {selectedColumnViewType === TitleType.VIEWS && (
                    <ViewsSelection
                        onViewSelection={onViewSelection}
                        setShowFilters={setShowFilters}
                    />
                )}
            </SlidingDrawer>
            {/*More Filters Panel*/}
            <SlidingDrawer
                title="Filters"
                open={isMoreFiltersPanelOpen}
                primaryAction={{
                    label: 'Apply',
                    onClick: moreFilters?.onSave
                        ? saveMoreFiltersPanel
                        : undefined,
                }}
                onCloseIconClick={closeMoreFiltersPanel}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    },
                }}
                padding="0 16px 8px 16px"
            >
                {moreFilters?.onReset && (
                    <Box mb={1.5} mt={0.5}>
                        <button
                            aria-label="reset"
                            id="resetFilters"
                            type="reset"
                            onClick={resetMoreFiltersPanel}
                            className="k-grid-filters-reset"
                        >
                            Reset
                        </button>
                    </Box>
                )}
                {moreFilters?.children}
                <MoreFilters moreFilters={moreFilters} />
            </SlidingDrawer>
        </div>
    );
};

export default forwardRef(KGrid);
