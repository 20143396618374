import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { remove } from 'lodash';

import { AccessDeniedIcon } from '../../assets/icons/icons';
import useProfile from '../../core/Providers/Auth/Profile/useProfile';
import ModuleInfoContext from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import { IPermission, IResources, Scope } from '../../types/user';
import { ModuleName, ModulePath } from '../../utils';
import { Button } from '../Button';
import LoadingScreen from '../Loaders/LoadingScreen';

const styles = makeStyles({
    loaderRoot: {
        display: 'flex',
        alignItems: 'center',
        height: '90vh',
        justifyContent: 'center',
        width: '100%',
    },
});

export interface IPagePermissionsProps {
    children: React.ReactElement;
    level: 'Module' | 'Page';
    parentViewPermissions: (page: string, resource: Scope[]) => void;
}

const PagePermissions = ({
    children,
    level,
    parentViewPermissions,
}: IPagePermissionsProps) => {
    const classes = styles();
    const { user } = useProfile();
    const { moduleName, permissions } = useContext(ModuleInfoContext);
    const [loading, setLoadingStatus] = useState(true);
    const [invalidPermission, setInvalidPermission] = useState(false);
    const location = useLocation();

    const traversePathForPermissions = useCallback(
        (paths: string[], resources?: IResources[]): boolean => {
            let isVisible = false;
            if (resources && resources.length > 0) {
                const outerResource = resources.filter(
                    (resource: IResources) => {
                        return (
                            resource.resourceName
                                .replace(/\s+/g, '-')
                                .toLowerCase() === paths[0].toLowerCase()
                        );
                    }
                );

                if (paths.length === 1) {
                    if (outerResource && outerResource.length > 0) {
                        parentViewPermissions(
                            paths[0],
                            outerResource[0].scopes
                        );
                        if (
                            outerResource[0].scopes &&
                            outerResource[0].scopes.length > 0 &&
                            outerResource[0].scopes.includes('View')
                        ) {
                            isVisible = true;
                        }
                    }
                } else if (
                    outerResource &&
                    outerResource.length > 0 &&
                    outerResource[0].scopes &&
                    outerResource[0].scopes.includes('View') &&
                    paths.slice(1, paths.length).length >= 1
                ) {
                    isVisible = traversePathForPermissions(
                        paths.slice(1, paths.length),
                        resources
                    );
                } else if (
                    outerResource &&
                    outerResource.length > 0 &&
                    outerResource[0].scopes &&
                    outerResource[0].scopes.includes('View') &&
                    paths?.length === 0
                ) {
                    isVisible = true;
                } else {
                    isVisible = false;
                }
            }
            return isVisible;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handlePagesAndSubpages = useCallback(
        (paths: string[]) => {
            let canShowView = false;
            const pathSliced = paths.slice(1, paths.length);
            const selectedModule:
                | IPermission[]
                | undefined = user?.RolesPermissions.filter(
                (permission: IPermission) => {
                    if (pathSliced[0] === 'Custom-Reports') {
                        return (
                            permission.moduleName.toLowerCase() === 'reports'
                        );
                    } else {
                        return (
                            permission.moduleName.toLowerCase() ===
                            moduleName.toLowerCase()
                        );
                    }
                }
            );
            if (level === 'Page') {
                if (selectedModule && selectedModule.length > 0) {
                    if (paths.length > 1) {
                        canShowView = traversePathForPermissions(
                            pathSliced,
                            selectedModule[0].resources
                        );
                    } else {
                        if (
                            selectedModule[0].moduleName ===
                            ModuleName.Workspace
                        ) {
                            canShowView = true;
                        } else {
                            canShowView = permissions.canView;
                        }
                    }
                }
            }

            return canShowView;
        },
        [level, moduleName, user, traversePathForPermissions]
    );

    useEffect(() => {
        if (
            moduleName !== '' &&
            user &&
            user.RolesPermissions &&
            user.RolesPermissions.length > 0
        ) {
            setLoadingStatus(() => true);
            const paths = remove(
                location.pathname.split('/'),
                (pathName: string) => pathName !== ''
            );

            const canShowPage = handlePagesAndSubpages(paths);
            if (canShowPage) {
                setLoadingStatus(() => false);
                setInvalidPermission(() => false);
            } else {
                setInvalidPermission(() => true);
                setLoadingStatus(() => false);
            }
        } else {
            setLoadingStatus(() => false);
            setInvalidPermission(() => true);
        }
    }, [user, moduleName, location, location.pathname, handlePagesAndSubpages]);

    const navigateToWorkspace = (): void => {
        window.location.replace(ModulePath.Workspace);
    };

    if (loading) {
        return (
            <Box id={'loader-permissions'} className={classes.loaderRoot}>
                <LoadingScreen
                    loading={loading}
                    label={'Checking for permissions...'}
                />
            </Box>
        );
    } else {
        return (
            <>
                {invalidPermission && (
                    <Box id="invalid-permission" p={2}>
                        <Grid
                            container={true}
                            direction="column"
                            alignContent="center"
                            alignItems="center"
                            spacing={2}
                            style={{
                                marginTop: 150,
                                justifyContent: 'center',
                            }}
                        >
                            <Grid item={true}>
                                <AccessDeniedIcon size="300" />
                            </Grid>
                            <Grid item={true}>
                                <Typography
                                    variant="h4"
                                    component="p"
                                    align={'center'}
                                    gutterBottom={true}
                                >
                                    Access Denied
                                </Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography
                                    variant="body1"
                                    component="p"
                                    align={'center'}
                                >
                                    Looks like you do not have access to view
                                    page
                                </Typography>
                            </Grid>
                            <Grid item={true}>
                                <Button
                                    id="backToHome"
                                    color="secondary"
                                    label="Back to Home"
                                    name="Button"
                                    type="button"
                                    variant="default"
                                    aria-label="Back to home"
                                    onClick={navigateToWorkspace}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {!invalidPermission && children}
            </>
        );
    }
};

export default PagePermissions;
