/**
 * Persist storage
 */
const storage = {
    setItem: (key: string, value: string): string => {
        return storage.set(key, value);
    },
    set: (key: string, value: string): string => {
        localStorage[key] = value;
        return localStorage[key];
    },
    getItem: (key: string): string => {
        return storage.get(key);
    },
    get: (key: string): string => {
        return localStorage[key];
    },
    setObject: (key: string, value: Object): string => {
        localStorage[key] = JSON.stringify(value);
        return localStorage[key];
    },
    getObject: (key: string): any => {
        const value = storage.get(key);
        try {
            return JSON.parse(value);
        } catch (e) {
            return;
        }
    },
    clear: (): void => {
        return localStorage.clear();
    },
    removeItem: (key: string): void => {
        return storage.remove(key);
    },
    remove: (key: string): void => {
        return localStorage.removeItem(key);
    },
};

export default storage;
