import { APIMEnvironments } from '../types/api-request';

/**
 * API Management Environment
 */
export const APIM_ENVIRONMENT: APIMEnvironments = 'Development';

/**
 * API Management Subscription Key
 */
export const APIM_SUBSCRIPTION_KEY = 'd18c709b0df24f158ae79e4afe8af406';

/**
 * API Management URL's
 */
export const API_USER_MANAGEMENT = `${process.env.apimBase}/UserManagement/v1.0`;
export const API_WORKSPACE = `${process.env.apimBase}/workspace/v1.0`;
export const API_ADMINISTRATION = `${process.env.apimBase}/administration/v1.0`;
export const API_DATA_EXCHANGE = `${process.env.apimBase}/dataexchange/v1.0`;
export const API_SHARED_UTILITIES = `${process.env.apimBase}/sharedutilities/v1.0`;
export const API_MENU_PLANNING = `${process.env.apimBase}/menuplanning/v1.0`;
export const API_POINT_OF_SERVICE = `${process.env.apimBase}/pointofservice/v1.0`;
export const APP_INSIGHTS_INSTRUMENTATION =
    process.env.appInsightsInstrumentation;
export const APP_INSIGHTS_INGESTION = process.env.appInsightsIngestion;
export const APP_INSIGHTS_LIVE_ENDPOINT = process.env.appInsightsLiveEndpoint;
