import { camelCase } from 'lodash';

import { componentsAPIInstance } from '../../../../utils/api/APIInstance';

export async function getRolesAsync(apimUrl: string, regionId: string) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'GET',
            url: `${apimUrl}/UserManagement/v1.0//api/Roles/GetRolesByRegionAsync?regionId=${regionId}`,
        });
        return response;
    } catch (error) {
        console.log('error', error);
        return [];
    }
}
export async function getUsersAsync(groupId: string, apimUrl: string) {
    try {
        if (groupId && apimUrl) {
            const response = await componentsAPIInstance.axios({
                method: 'GET',
                url: `${apimUrl}/UserManagement/v1.0/api/Groups/GetUsersByGroup?groupGuid=${groupId}`,
            });
            return response;
        } else {
            return null;
        }
    } catch (error) {
        return [];
    }
}

export async function getUserGroupsAsync(apimUrl: string) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'GET',
            url: `${apimUrl}/UserManagement/v1.0/api/Groups/GetUserGroups`,
        });
        return response;
    } catch (e) {
        return [];
    }
}

export async function getUserDetailsByEmail(
    apimUrl: string,
    email: string | undefined
) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'GET',
            url: `${apimUrl}/UserManagement/v1.0/api/Users/GetUserDetailsByEmail?email=${email}`,
        });
        return response;
    } catch (e) {
        return [];
    }
}

export async function updateView(
    viewId: string,
    customViewPageName: string,
    url: string,
    requestData: {}
) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'PATCH',
            url: `${url}/customuserviews/v1.0/api/CustomView/Update/${viewId}?DataModelName=${customViewPageName}`,
            data: requestData,
        });
        return response;
    } catch (e) {
        //TODO: error handling
        return null;
    }
}

export async function saveViewAsync(apimUrl: string, requestData: {}) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'POST',
            url: `${apimUrl}/CustomUserViews/v1.0/api/CustomView/Create`,
            data: requestData,
        });
        return response;
    } catch (e) {
        return null;
    }
}

export async function getViewAsync(apimUrl: string, viewID: string) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'GET',
            url: `${apimUrl}/CustomUserViews/v1.0/api/CustomView/GetById/${viewID}`,
        });
        return response;
    } catch (e) {
        return null;
    }
}

export async function checkViewNameAsync(
    apimUrl: string,
    viewName: string,
    customViewPageName: any
) {
    try {
        const data = await componentsAPIInstance.axios({
            method: 'GET',
            url: `${apimUrl}/CustomUserViews/v1.0/api/CustomView/IsViewNameAllowed/${viewName}/${camelCase(
                customViewPageName
            )}`,
        });
        if (data.data.StatusCode === 200) {
            return data.data.PayLoad;
        }
    } catch (e) {
        return false;
    }
}

export async function getViewsAsync(
    apimUrl: string,
    customViewPageName: any,
    roles: string[] | undefined,
    regionId: string,
    userId: string | undefined
) {
    try {
        const response = await componentsAPIInstance.axios({
            url: `${apimUrl}/CustomUserViews/v1.0/api/CustomView/GetV1`,
            method: 'POST',
            data: {
                dataModelName: camelCase(customViewPageName),
                regionId: regionId,
                userId: userId,
                roleIds: roles?.map((role: any) => role.roleId),
            },
        });
        return response;
    } catch (e) {
        return [];
    }
}

export async function deleteView(apimUrl: string, viewId: string) {
    const response = await componentsAPIInstance.axios({
        url: `${apimUrl}/CustomUserViews/v1.0/api/CustomView/Delete/${viewId}`,
        method: 'DELETE',
    });
    return response;
}

export async function getSettingRolesV1(apimUrl: string, regionId: string) {
    try {
        const response = await componentsAPIInstance.axios({
            url: `${apimUrl}/administration/v1.0/api/SystemSettings/${regionId}/SettingValue/ROLESV1`,
            method: 'GET',
        });
        return response;
    } catch (e) {
        return [];
    }
}

export async function createRecentactivity(apimUrl: string, payload: any) {
    try {
        const response = await componentsAPIInstance.axios({
            url: `${apimUrl}/administration/v1.0/api/RecentActivity/CreateRecentActivity`,
            method: 'PUT',
            data: payload,
        });
        return response;
    } catch (e) {
        return [];
    }
}
