import { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { useProfile } from '../../../core/Providers';
import ModuleInfoContext from '../../../core/Providers/ModuleInfo/ModuleInfoContext';
import ScStorage from '../../../core/Storage';
import { storage } from '../../../core/Store';
import {
    createFavoriteAsync,
    getFavoritesByUserIdAsync,
    updateFavoritesOrderAsync,
} from '../Favorites/FavoritesAPI';

export const useFavorites = () => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [favoriteName, setFavoriteName] = useState('');
    const [loadingOnSave, setLoadingOnSave] = useState(false);
    const [data, setData] = useState([]);
    const [successAlertMessage, setSuccessAlertMessage] = useState<String>('');
    const [errorAlertMessage, setErrorAlertMessage] = useState<String>('');
    const { user } = useProfile();
    const activeDistrict = storage.getItem('activeDistrict');
    const activeDistrictParsed = JSON.parse(activeDistrict ?? '{}');
    const regionId = activeDistrictParsed?.attributes?.regionId?.[0];
    const moduleContext = useContext(ModuleInfoContext);
    const [editingIndex, setEditingIndex] = useState(0);
    const [editedName, setEditedName] = useState('');
    const [disableSaveBtn, setDisableSaveBtn] = useState(true);
    const [items, setItems] = useState(data);
    const [isTextBoxDisabled, setIsTextBoxDisabled] = useState(false);

    useEffect(() => {
        setItems(data);
        isCheckFavoriteAdded();
        setFavoriteName(moduleContext?.pageName);
        if (
            (!isEmpty(favoriteName) || favoriteName !== '') &&
            !isTextBoxDisabled
        ) {
            setDisableSaveBtn(false);
        }
    }, [data, isTextBoxDisabled]);

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return;

        const reorderedItems = Array.from(items);
        const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, reorderedItem);
        setItems(reorderedItems);
        onUpdateFavoritesOrder(reorderedItems);
    };

    const handleNameChange = (event: any) => {
        if (!isEmpty(event.target.value) || event.target.value !== '') {
            setDisableSaveBtn(false);
        }
        setFavoriteName(event.target.value);
    };
    const handleOpen = (): void => {
        const currentUrl = window.location.pathname;
        const isFavorite = items.some(
            (favorite: any) => favorite.url === currentUrl
        );
        if (isFavorite) {
            setIsTextBoxDisabled(true);
        }
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
        setFavoriteName('');
        setEditingIndex(0);
    };

    useEffect(() => {
        getFavoritesByUserIdDetails(Number(user?.UserId), Number(regionId));
    }, [successAlertMessage, isOpen]);

    useEffect(() => {
        if (favoriteName.trim() === '' || isEmpty(favoriteName.trim())) {
            setDisableSaveBtn(true);
        }
    }, [favoriteName]);

    async function getFavoritesByUserIdDetails(
        userId: number,
        regionId: number
    ) {
        try {
            await getFavoritesByUserIdAsync(
                moduleContext?.apimUrl,
                userId,
                regionId
            ).then((response: any) => {
                if (response.data.StatusCode === 200) {
                    setData(response.data.PayLoad);
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    async function onUpdateFavoritesOrder(reorderedItems: any) {
        try {
            const updatedFavoritesOrder = reorderedItems.map(
                (item: any, index: any) => ({
                    id: item.id,
                    displayorder: index + 1,
                })
            );

            await updateFavoritesOrderAsync(
                moduleContext?.apimUrl,
                updatedFavoritesOrder
            );
        } catch (e) {
            console.error('Error in updating order', e);
            handleClose();
            setLoadingOnSave(false);
        }
    }
    const currentUrl = window.location.pathname;
    const isCheckFavoriteAdded = () => {
        const isFavorite = data.some((favorite: any) => {
            return favorite.url === currentUrl;
        });
        setIsTextBoxDisabled(isFavorite);
    };
    async function onViewSave() {
        try {
            setLoadingOnSave(true);

            const payload = {
                id: 0,
                userId: Number(user?.UserId),
                moduleName: moduleContext?.moduleName.toString(),
                regionId: Number(regionId),
                favoriteName: favoriteName,
                url: currentUrl,
                displayorder: 0,
                createdBy: user?.UserId,
                createdDate: new Date(),
                modifiedBy: null,
                modifiedDate: null,
                isDeleted: false,
            };
            await createFavoriteAsync(moduleContext?.apimUrl, payload).then(
                (response: any) => {
                    if (
                        response.data.StatusCode === 200 &&
                        response.data.Description === 'Success!'
                    ) {
                        setSuccessAlertMessage('Successfully Done!');
                        getFavoritesByUserIdDetails(
                            Number(user?.UserId),
                            Number(regionId)
                        );
                        setLoadingOnSave(false);
                        handleClose();
                    }
                }
            );
        } catch (e) {
            console.error('Error saving Views', e);
            handleClose();
            setErrorAlertMessage('Error saving view!');
            setLoadingOnSave(false);
        }
    }
    const handleRowClick = (url: any, id: any) => {
        if (editingIndex !== id) {
            const baseUrl = window.location.origin;
            const sanitizedUrl = url.startsWith('/') ? url : `/${url}`;
            window.open(`${baseUrl}${sanitizedUrl}`, '_blank');
        }
    };

    const handleEditedNameChange = (e: any) => {
        setEditedName(e.target.value);
    };

    const allowedRoutesString = ScStorage().getItem('allowedRoutes');

    const allowedRoutes = JSON.parse(
        allowedRoutesString && allowedRoutesString?.length > 0
            ? allowedRoutesString
            : '[]'
    );
    const checkPageNameIsInRoutes = () => {
        if (allowedRoutes?.length > 0) {
            return (
                allowedRoutes.some((route: any) => {
                    const normalizedRouteName = route.routeName
                        .replace(/\s+/g, '-')
                        .toLowerCase();
                    const normalizedPageName = moduleContext?.pageName.toLowerCase();
                    return normalizedRouteName.includes(normalizedPageName);
                }) || false
            );
        } else {
            return false;
        }
    };
    return {
        successAlertMessage,
        errorAlertMessage,
        loadingOnSave,
        disableSaveBtn,
        editingIndex,
        favoriteName,
        editedName,
        moduleContext,
        isOpen,
        data,
        handleNameChange,
        handleOpen,
        handleRowClick,
        handleEditedNameChange,
        setSuccessAlertMessage,
        setErrorAlertMessage,
        setEditingIndex,
        setEditedName,
        handleClose,
        onViewSave,
        items,
        handleOnDragEnd,
        isTextBoxDisabled,
        isPageNameExistsInRoutes: checkPageNameIsInRoutes(),
    };
};
