import { ModuleName } from './moduleName';

export const ModulePath = {
    Default: '/',
    [ModuleName.Workspace]: '/',
    [ModuleName.Inspections]: '/inspections',
    [ModuleName.Accountability]: '/accountability',
    [ModuleName.Eligibility]: '/eligibility',
    [ModuleName.Inventory]: '/inventory',
    [ModuleName.ItemManagement]: '/item-management',
    [ModuleName.AccountManagement]: '/account-management',
    [ModuleName.System]: '/system',
    [ModuleName.Platform]: '/platform',
    [ModuleName.MenuPlanning]: '/menu-planning',
    [ModuleName.FoodDistribution]: '/',
    [ModuleName.Reviews]: '/',
    [ModuleName.Production]: '/production',
    [ModuleName.Insights]: '/insights',
    [ModuleName.Reports]: '/',
};

export const getModulePath = (moduleName: string): string => {
    return ModulePath[moduleName as ModuleName] ?? ModulePath.Default;
};
