import React, {
    FC,
    MouseEventHandler,
    ReactEventHandler,
    ReactNode,
} from 'react';
import {
    BackdropProps,
    Box,
    Grid,
    IconButton as MuiIconButton,
    makeStyles,
    Typography,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';

import { ArrowLeftIcon, CloseIcon } from '../../assets';
import { ITheme } from '../../core/Providers';
import { StandardProps } from '../../types/standardProps';
import Button, { IButtonProps } from '../Button/Button';
import { IconButton } from '../IconButton';
import { AppLoader } from '../Loaders';

export interface ISlidingDrawerProps extends StandardProps<HTMLDivElement> {
    /**
     * If `true`, the drawer is open.
     */
    open?: boolean;
    /**
     * Drawer title
     */
    title?: string | ReactNode | null;
    /**
     * Primary action
     */
    primaryAction?: Partial<IButtonProps> | null;
    /**
     * Send custom actions
     */
    actions?: ReactNode | null;
    /**
     * Check if there is close icon
     */
    onCloseIconClick?: (() => void) | null;
    /**
     * Width of drawer
     */
    width?: 'sm' | 'md' | 'lg' | 'xl';
    /**
     * Callback fired when backdrop is clicked
     */
    onBackdropClick?: ReactEventHandler<{}>;
    /**
     * Custom backdrop styles
     */
    BackdropProps?: Partial<BackdropProps>;
    /**
     * Add padding to content
     */
    padding?: string | number;
    /**
     * Show content loading
     */
    loading?: boolean;
    /**
     * Show Back Button
     */
    enableBackButton?: boolean;
    /**
     * Back Button color
     */
    backButtonColor?: 'default' | 'inherit' | 'primary' | 'secondary';
    /**
     * Back Button click action
     */
    onBackButtonClick?: MouseEventHandler<HTMLButtonElement>;
    /**
     * hide closeButton on drawer when set to false
     */
    displayXIcon?: boolean;

    /**
     * Enable close by pressing Esc button
     */
    enableCloseByEscape?: boolean;
    /**
     * Fullscreen height
     */
    isViewHeight?: boolean;
}

const useStyles = makeStyles((theme: ITheme) => ({
    title: {
        padding: '8px 16px',
        wordBreak: 'break-all',
    },
    titleWitButton: {
        wordBreak: 'break-all',
    },
    drawer: {
        minWidth: '320px',
        [theme.breakpoints.down('xs')]: {
            minWidth: '280px',
        },
    },
    sm: {
        width: '25%',
    },
    md: {
        width: '50%',
    },
    lg: {
        width: '75%',
    },
    xl: {
        width: '100%',
    },
    content: {
        height: 'calc(100% - 56px - 100px)',
        overflowY: 'auto',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexWrap: 'nowrap',
        padding: '10px 16px',
        '& > :not(:last-child)': {
            marginRight: theme.spacing(1.5),
        },
        borderTop: '1px solid #CCCCCC',
    },
    drawerTitle: {
        borderBottom: '1px solid #CCCCCC',
    },
    buttonTile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
}));

const SlidingDrawer: FC<ISlidingDrawerProps> = ({
    children,
    open = true,
    title,
    width = 'sm',
    primaryAction,
    onCloseIconClick,
    onBackdropClick,
    BackdropProps,
    padding = '8px 16px',
    actions,
    loading = false,
    enableBackButton = false,
    backButtonColor = 'primary',
    onBackButtonClick,
    displayXIcon = true,
    enableCloseByEscape = false,
    isViewHeight = false,
    ...rest
}) => {
    const styles = useStyles();

    const onCloseByBackDropClick = function (
        e: any,
        reason: 'backdropClick' | 'escapeKeyDown'
    ) {
        if (reason === 'backdropClick') {
            onBackdropClick?.(e);
        } else if (reason === 'escapeKeyDown' && enableCloseByEscape) {
            onBackdropClick?.(e);
        }
    };

    return (
        <Drawer
            anchor="right"
            open={open}
            classes={{
                paper: clsx(styles.drawer, styles[width]),
            }}
            // onBackdropClick={onBackdropClick}
            onClose={onCloseByBackDropClick}
            disableEnforceFocus={true}
            BackdropProps={BackdropProps}
            {...rest}
            style={{
                top: !isViewHeight ? 56 : 0,
            }}
        >
            {title && (
                <>
                    <Box
                        display="flex"
                        style={{
                            justifyContent: 'space-between',
                        }}
                        alignItems="center"
                        minHeight={48}
                        className={styles.drawerTitle}
                    >
                        {enableBackButton ? (
                            <Grid
                                container={true}
                                spacing={2}
                                className={styles.buttonTile}
                            >
                                <MuiIconButton
                                    id="back-button"
                                    color={backButtonColor}
                                    onClick={onBackButtonClick}
                                    type="button"
                                    area-label="back"
                                    area-labelledby="back"
                                >
                                    <label hidden>back</label>
                                    <ArrowLeftIcon />
                                </MuiIconButton>
                                <Typography
                                    className={styles.titleWitButton}
                                    variant="h5"
                                    component="p"
                                >
                                    {title}
                                </Typography>
                            </Grid>
                        ) : (
                            <Typography
                                className={styles.title}
                                variant="h5"
                                component="p"
                            >
                                {title}
                            </Typography>
                        )}
                        {onCloseIconClick && displayXIcon && (
                            <IconButton
                                id="closeColumnSettings"
                                aria-label="Close"
                                onClick={onCloseIconClick}
                                style={{
                                    padding: '8px',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Box>
                </>
            )}
            <Box className={styles.content} p={padding}>
                {loading ? <AppLoader /> : children}
            </Box>
            {(primaryAction || actions) && (
                <div className={styles.actions}>
                    {primaryAction && (
                        <Button
                            id="primaryButton"
                            type="submit"
                            color="primary"
                            name="save"
                            label="Save"
                            dense={true}
                            {...primaryAction}
                        />
                    )}
                    {actions}
                </div>
            )}
        </Drawer>
    );
};

export default SlidingDrawer;
