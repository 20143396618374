import React, { useContext, useEffect, useRef, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { isNull } from 'lodash';

import { getEnvironment } from '../../../core/environments';
import { AppInfoContext } from '../../../core/Providers';
import PushNotification from '../PushNotification';

const MessageComponent: React.FC = () => {
    const hubConnectionRef = useRef<null | signalR.HubConnection>(null);
    const context = useContext(AppInfoContext);
    const [messages, setMessages] = useState<string[]>([]);
    const [notificationKey, setNotificationKey] = useState<number>(0);
    const createConnection = function () {
        if (isNull(hubConnectionRef.current)) {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(
                    `wss://${
                        context.apimUrl.split('//')[1]
                    }/${getEnvironment()}/SchoolCafeNotificationHub?subscription-key=${
                        context.subscriptionKey
                    }`,
                    {
                        transport: signalR.HttpTransportType.WebSockets,
                        skipNegotiation: true,
                    }
                )
                .withAutomaticReconnect([0, 2000, 10000, 30000])
                .build();
            connection.on('UserInfo', (...message: any) => {
                setMessages(message);
                showNotification('Notification', message);
                setNotificationKey((prevKey: number) => prevKey + 1);
            });
            connection
                .start()
                .then(() => console.log('SignalR connection established'))
                .catch((err) => {
                    console.error('SignalR connection error:', err);
                });
            connection.onclose((event) => {
                console.error(
                    'WebSocket closed with status code:',
                    event,
                    'Reason:',
                    event
                );
            });

            hubConnectionRef.current = connection;
        }
    };

    useEffect(() => {
        createConnection();
        return () => {
            hubConnectionRef?.current
                ?.stop()
                .then(() => console.log('SignalR connection stopped'));
        };
    }, []);

    const showNotification = (title: string, message: string) => {
        if (Notification.permission === 'granted') {
            new Notification(title, { body: message });
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    new Notification(title, { body: message?.[0] ?? '' });
                }
            });
        }
    };
    return <PushNotification key={notificationKey} messages={messages} />;
};

export default MessageComponent;
