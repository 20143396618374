import React, { FC, ReactNode, SyntheticEvent, useEffect } from 'react';
import {
    Box,
    Collapse,
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import {
    AlertCircleIcon,
    AlertTriangleIcon,
    CheckCircleIcon,
    InfoIcon,
    XIcon,
} from '../../assets';
import { ITheme } from '../../core/Providers';
import { StandardProps, ThemeColor, ThemeColorType } from '../../types';

export interface IAlertProps extends StandardProps<HTMLDivElement> {
    /**
     * Message to display
     */
    message: string | ReactNode;
    /**
     * Default state of alert
     */
    open?: boolean;
    /**
     * Use custom alert icon
     */
    icon?: ReactNode;
    /**
     * The main color for the alert
     */
    color?: ThemeColorType;
    /**
     * The action to display. It renders after the message, at the end of the alert.
     */
    action?: ReactNode;
    /**
     * Callback fired when the component requests to be closed.
     */
    onClose?: ((event: SyntheticEvent) => void) | null;
    /**
     * The variant to use
     */
    variant?: AlertVariantType;
    /**
     * Hide icon
     */
    hideIcon?: boolean;
}

export type AlertVariantType = 'default' | 'filled';

type FilledColorType =
    | 'filled-default'
    | 'filled-primary'
    | 'filled-secondary'
    | 'filled-tertiary'
    | 'filled-success'
    | 'filled-warning'
    | 'filled-error'
    | 'filled-info';

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        width: '100%',
        padding: '6px 16px',
        fontSize: '0.875rem',
        minHeight: '48px',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        backgroundColor: theme.palette.background.paper,
        minWidth: '288px',
        lineHeight: 1,
    },
    content: {
        flex: 1,
    },
    message: {
        fontWeight: 500,
    },
    default: {
        color: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    primary: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    secondary: {
        color: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    tertiary: {
        color: theme.palette.tertiary.main,
        border: `1px solid ${theme.palette.tertiary.main}`,
    },
    success: {
        color: '#15845A',
        border: '1px solid #15845A',
    },
    warning: {
        color: '#D14200',
        border: '1px solid #D14200',
    },
    error: {
        color: '#EB0000',
        border: '1px solid #EB0000',
    },
    info: {
        color: '#665AD8',
        border: '1px solid #665AD8',
    },
    'filled-default': {
        color: 'white',
        backgroundColor: theme.palette.grey[600],
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    'filled-primary': {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    'filled-secondary': {
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    'filled-tertiary': {
        color: theme.palette.getContrastText(theme.palette.tertiary.main),
        backgroundColor: theme.palette.tertiary.main,
        border: `1px solid ${theme.palette.tertiary.main}`,
    },
    'filled-success': {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.success.main,
        border: `1px solid ${theme.palette.success.main}`,
    },
    'filled-warning': {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.warning.main,
        border: `1px solid ${theme.palette.warning.main}`,
    },
    'filled-error': {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
    },
    'filled-info': {
        color: theme.palette.common.black,
        backgroundColor: theme.palette.info.main,
        border: `1px solid ${theme.palette.info.main}`,
    },
}));

const Alert: FC<IAlertProps> = ({
    children,
    className,
    style,
    message,
    icon,
    open = true,
    color = 'default',
    variant,
    action,
    onClose,
    hideIcon = false,
    ...rest
}) => {
    const classes = useStyles();
    const filledColor = `filled-${color}` as FilledColorType;

    const [isOpen, setOpen] = React.useState(open);

    useEffect(() => {
        setOpen(open);
    }, [open]);

    const handleClose = (ev: SyntheticEvent) => {
        setOpen(false);
        if (typeof onClose === 'function') {
            onClose(ev);
        }
    };

    let defaultIcon;
    switch (color) {
        case ThemeColor.primary:
            defaultIcon = <CheckCircleIcon />;
            break;
        case ThemeColor.secondary:
            defaultIcon = <InfoIcon />;
            break;
        case ThemeColor.tertiary:
            defaultIcon = <InfoIcon />;
            break;
        case ThemeColor.success:
            defaultIcon = <CheckCircleIcon />;
            break;
        case ThemeColor.warning:
            defaultIcon = <AlertTriangleIcon />;
            break;
        case ThemeColor.error:
            defaultIcon = <AlertCircleIcon />;
            break;
        case ThemeColor.info:
            defaultIcon = <InfoIcon />;
            break;
    }

    return (
        <Collapse in={isOpen} {...rest}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                className={clsx(
                    classes.root,
                    [color],
                    classes[color],
                    {
                        [classes[filledColor]]: variant === 'filled',
                    },
                    className
                )}
                style={style}
            >
                {(icon || defaultIcon) && !hideIcon && (
                    <Box mr={1}>{icon || defaultIcon}</Box>
                )}
                <Grid item className={classes.content}>
                    <Box mr={1}>
                        {typeof message === 'string' ? (
                            <Typography
                                variant="body2"
                                className={classes.message}
                            >
                                {message}
                            </Typography>
                        ) : (
                            message
                        )}
                        {children}
                    </Box>
                </Grid>
                {action && (
                    <Grid item>
                        <Box mr={1}>{action}</Box>
                    </Grid>
                )}
                {onClose && (
                    <Grid item>
                        <IconButton
                            aria-label="Close Alert"
                            size="small"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <XIcon />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
        </Collapse>
    );
};

export default Alert;
