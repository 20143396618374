import React, { FC } from 'react';
import {
    CalendarNavigationItem,
    CalendarNavigationItemProps,
} from '@progress/kendo-react-dateinputs';

const CustomNavigationItem: FC<CalendarNavigationItemProps> = (props) => {
    return (
        <CalendarNavigationItem {...props}>
            {props?.value.getMonth() === 0
                ? `Jan - ${props?.value?.getFullYear()}`
                : `${props?.text}`}
        </CalendarNavigationItem>
    );
};

export default CustomNavigationItem;
