import React, { FC, SyntheticEvent } from 'react';
import { GridFilterCellProps } from '@progress/kendo-react-grid/dist/es/interfaces/GridFilterCellProps';
import clsx from 'clsx';

import { DateTimePicker, IDateTimePickerChangeEvent } from '../../DateTime';

const CustomDateTimeFilter: FC<GridFilterCellProps> = ({ onChange, value }) => {
    const handleOnChange = (ev: IDateTimePickerChangeEvent) => {
        onChange({
            value: ev.target.value,
            operator: 'eq',
            syntheticEvent: ev.syntheticEvent,
        });
    };
    const handleClear = (ev: SyntheticEvent) => {
        onChange({
            value: '',
            operator: '',
            syntheticEvent: ev,
        });
    };
    return (
        <div className="k-filtercell">
            <div className="k-filtercell-wrapper">
                <DateTimePicker value={value} onChange={handleOnChange} />
                <div
                    className="k-filtercell-operator"
                    style={{
                        marginLeft: '-3px',
                        marginTop: '5px',
                    }}
                >
                    <button
                        className={clsx('k-button k-button-icon', {
                            'k-clear-button-visible': Boolean(value),
                        })}
                        title="Clear"
                        aria-label="Clear"
                        type="button"
                        onClick={handleClear}
                    >
                        <span className="k-icon k-i-filter-clear" />
                    </button>
                </div>
            </div>
        </div>
    );
};
export default CustomDateTimeFilter;
