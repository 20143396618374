import { createContext } from 'react';

import { Themes, ThemeType } from '../../../types/theme';
import ScStorage from '../../Storage';
import { Direction } from '../Theme/theme';

export interface ISettings {
    theme?: ThemeType;
    direction?: Direction;
    responsiveFontSizes?: boolean;
}

export interface ISettingsContextValue {
    settings: ISettings;
    saveSettings: (update: ISettings) => void;
    navClosedOnLoad: string[];
    currentPage: string;
    setCurrentPage: (page: string) => void;
}

export const defaultSettings: ISettings = {
    direction: Direction.LTR,
    responsiveFontSizes: true,
    theme: Themes.Perseus,
};

export const restoreSettings = (): ISettings | null => {
    let settings = null;

    try {
        const storedData: string | null = ScStorage().getItem('ui_settings');
        if (storedData) {
            settings = JSON.parse(storedData);
        }
    } catch (err) {
        console.error(err);
    }

    return settings;
};

export const storeSettings = (settings: ISettings): void => {
    ScStorage().setItem('ui_settings', JSON.stringify(settings));
};

const SettingsContext = createContext<ISettingsContextValue>({
    settings: defaultSettings,
    saveSettings: () => {},
    navClosedOnLoad: [],
    currentPage: '',
    setCurrentPage: () => {},
});

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
