import React, { FC } from 'react';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@material-ui/core/AccordionSummary';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { AccordionVariant } from './Accordion';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 56,
        padding: '0 16px 0 8px',
        borderRadius: theme.shape.borderRadius,
        '&$expanded': {
            minHeight: 56,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
    default: {},
    info: {
        backgroundColor: 'rgba(0, 0, 0, .08)',
    },
}));

export interface IAccordionSummaryPropsProps extends AccordionSummaryProps {
    variant: AccordionVariant;
}

const AccordionSummary: FC<IAccordionSummaryPropsProps> = ({
    variant,
    ...rest
}) => {
    const classes = useStyles();
    return (
        <MuiAccordionSummary
            classes={{
                root: clsx(classes.root, classes[variant]),
                content: classes.content,
                expanded: classes.expanded,
            }}
            {...rest}
        />
    );
};

export default AccordionSummary;
