import { orderBy } from 'lodash';

import { IPermission } from '../../../../types/user';
import { ModuleName } from '../../../../utils';

import { UserProfileAction } from './profile.model';
import { IUserContextValue } from './ProfileContext';

const profileReducer = (
    state: IUserContextValue,
    action: UserProfileAction
): IUserContextValue => {
    switch (action.type) {
        case 'SET_USER_PROFILE': {
            let user = action.payload.user;
            if (user?.RolesPermissions?.length > 0) {
                const rolesPermissions = user.RolesPermissions
                    // Remove unknown modules
                    .filter((permission: IPermission) => {
                        return parseInt(permission.moduleId) >= 0;
                    })
                    // Add display order
                    .map((permission: IPermission) => {
                        switch (permission.moduleName) {
                            case ModuleName.Workspace:
                                return {
                                    ...permission,
                                    displayOrder: 1,
                                };
                            case ModuleName.Accountability:
                                return {
                                    ...permission,
                                    displayOrder: 2,
                                };
                            case ModuleName.Eligibility:
                                return {
                                    ...permission,
                                    displayOrder: 3,
                                };
                            case ModuleName.AccountManagement:
                                return {
                                    ...permission,
                                    displayOrder: 4,
                                };
                            case ModuleName.ItemManagement:
                                return {
                                    ...permission,
                                    displayOrder: 5,
                                };
                            case ModuleName.Inventory:
                                return {
                                    ...permission,
                                    displayOrder: 6,
                                };
                            case ModuleName.MenuPlanning:
                                return {
                                    ...permission,
                                    displayOrder: 7,
                                };
                            case ModuleName.Production:
                                return {
                                    ...permission,
                                    displayOrder: 8,
                                };
                            case ModuleName.Insights:
                                return {
                                    ...permission,
                                    displayOrder: 9,
                                };
                            case ModuleName.Reports:
                                return {
                                    ...permission,
                                    displayOrder: 10,
                                };
                            case ModuleName.System:
                                return {
                                    ...permission,
                                    displayOrder: 11,
                                };
                            default:
                                return permission;
                        }
                    });
                // Sort in order - POS, Eligibility, Account Management, Item Management, System
                // JIRA: https://primeroedgenext.atlassian.net/browse/NXT-12558
                user.RolesPermissions = orderBy(
                    rolesPermissions,
                    'displayOrder'
                );
            }
            return {
                ...state,
                user: user,
            };
        }
        case 'SET_USER_PROFILE_STATUS': {
            return {
                ...state,
                status: action.payload.status,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

export default profileReducer;
