import { isArray, isNil, isNull, sortBy } from 'lodash';

import ScStorage from '../../core/Storage';

import {
    ISteps,
    ITourElementButtonType,
    ITourStatusRegisterType,
    ITourStepsFromAPI,
    ITourStepStatusRegisterType,
    TourStatusEnum,
} from './types';

/**
 * @function
 * @getTourEntry
 * Function that returns the tour from localStorage based on tourName
 *
 * @returns tour ITourStatusRegisterType
 */
export const getTourEntry = function (tourName: string) {
    const tourStatuses: ITourStatusRegisterType[] = JSON.parse(
        ScStorage()?.getItem('demo-tours') || '[]'
    );
    return tourStatuses?.find?.(
        ({ tourname }: ITourStatusRegisterType) => tourname === tourName
    );
};

/**
 * @function
 * @resetEntry
 * Function that resets the tour object in localStorage based on tourName
 */
export const resetEntry = function (tourName: string) {
    const tours: ITourStatusRegisterType[] = JSON.parse(
        ScStorage()?.getItem('demo-tours') || '[]'
    );
    const modifiedTours = tours?.filter((tour) => tour.tourname !== tourName);
    ScStorage()?.setItem?.('demo-tours', JSON.stringify(modifiedTours));
};

/**
 * @function
 * @createTourEntry
 * Function that create a tour entry in the localStorage.
 */
export const createTourEntry = function (
    tourName: string,
    steps: ITourStepStatusRegisterType[]
) {
    const isTourAvailable = getTourEntry(tourName);
    const tourStatuses: ITourStatusRegisterType[] = JSON.parse(
        ScStorage()?.getItem('demo-tours') || '[]'
    );
    if (isNil(isTourAvailable)) {
        tourStatuses.push({
            tourname: tourName,
            isDisabled: false,
            isExited: false,
            isFinished: false,
            disabledAt: -1,
            steps: steps,
        });
    } else {
        const index = tourStatuses?.findIndex(
            ({ tourname }: ITourStatusRegisterType) => tourname === tourName
        );
        tourStatuses[index] = {
            tourname: tourName,
            isDisabled: false,
            isExited: false,
            isFinished: false,
            disabledAt: -1,
            steps: steps,
        };
    }
    ScStorage()?.setItem?.('demo-tours', JSON.stringify(tourStatuses));
};

/**
 * @function
 * @isTourDisabled
 * Utility function to determine if the tour is disabled.
 */
export const isTourDisabled = function (tourName: string) {
    const currentTour = getTourEntry(tourName);
    return currentTour?.isDisabled;
};

/**
 * @function
 * @setTourDisabled
 * Utility function to set the tour to disabled status.
 */
export const setTourDisabled = function (tourName: string, stepIndex: number) {
    const currentTour = getTourEntry(tourName);
    if (currentTour) {
        currentTour.isDisabled = true;
        currentTour.disabledAt = stepIndex;
        saveTour(currentTour);
    }
};

/**
 * @function
 * @setTourFinished
 * Utility function to set the tour to finished status.
 */
export const setTourFinished = function (tourName: string, stepName: string) {
    const currentTour = getTourEntry(tourName);
    if (currentTour) {
        const stepIndex =
            currentTour?.steps.findIndex?.(
                ({ name }: ITourStepStatusRegisterType) => name === stepName
            ) ?? -1;
        currentTour.isDisabled = true;
        currentTour.disabledAt = stepIndex;
        currentTour.isFinished = true;
        currentTour.steps.forEach((step: ITourStepStatusRegisterType) => {
            step.status = TourStatusEnum.FINISH;
        });
        saveTour(currentTour);
    }
};

/**
 * @function
 * @setTourFinished
 * Utility function to set the tour to exit status when exit action is clicked.
 */
export const setTourExited = function (tourName: string) {
    const currentTour = getTourEntry(tourName);
    if (currentTour) {
        currentTour.isExited = true;
        saveTour(currentTour);
    }
};

/**
 * @function
 * @saveTour
 * Utility function to save the tour in localStorage.
 */
export const saveTour = function (currentTour: ITourStatusRegisterType) {
    const tourStatuses: ITourStatusRegisterType[] = JSON.parse(
        ScStorage()?.getItem('demo-tours') || '[]'
    );
    tourStatuses.forEach((tour: ITourStatusRegisterType) => {
        if (tour.tourname === currentTour.tourname) {
            tour.disabledAt = currentTour.disabledAt;
            tour.isDisabled = currentTour.isDisabled;
            tour.steps = currentTour.steps;
        }
    });
    ScStorage()?.setItem('demo-tours', JSON.stringify(tourStatuses));
};

/**
 * @function
 * @updateTourStatus
 * Utility function to update the tour status in localStorage.
 */
export const updateTourStatus = function (
    tourName: string,
    stepName: string,
    status: TourStatusEnum,
    stepStatus?: TourStatusEnum
) {
    const currentTour = getTourEntry(tourName);
    const stepIndex =
        currentTour?.steps.findIndex?.(
            ({ name }: ITourStepStatusRegisterType) => name === stepName
        ) ?? -1;
    currentTour?.steps?.[stepIndex] &&
        (currentTour.steps[stepIndex].status = stepStatus ?? status);
    if (!isNil(currentTour)) {
        if (
            status === TourStatusEnum.EXIT ||
            status === TourStatusEnum.FINISH ||
            status === TourStatusEnum.SKIPPED
        ) {
            currentTour.isExited = true;
            currentTour.isDisabled = true;
            currentTour.disabledAt = stepIndex;
        }
        saveTour(currentTour);
    }
};

/**
 * @function
 * @isTourAvailable
 * Utility function to check if a tour is available in an array of tours.
 */
export const isTourAvailable = (
    tours: ITourStatusRegisterType[] | null,
    tourName: string
) =>
    isArray(tours)
        ? tours?.find((tour: any) => tour.tourname === tourName)
        : null;

/**
 * @function
 * @isAnyStepSkipped
 * Utility function to check if any step in the tour has been skipped.
 */
export const isAnyStepSkipped = function ({
    steps,
}: ITourStatusRegisterType): boolean {
    if (steps && steps.length > 0) {
        const skippedSteps = steps.filter(
            ({ status }: ITourStepStatusRegisterType) =>
                status === TourStatusEnum.SKIPPED
        );
        return skippedSteps.length > 0;
    }
    return false;
};

const buttonTexts: string[] = [
    'start',
    'finish',
    'next',
    'skip',
    'previous',
    'exit',
];

const findButtonType = (buttonText: string): string =>
    buttonTexts.filter((button: string) =>
        buttonText.toLowerCase().includes(button)
    )?.[0];

/**
 * Map of Classes for different Action buttons in Tours
 */
const buttonClassesMap: { [key: string]: string } = {
    start:
        'shepherd-button-action shepard-button-primary shepherd-button-primary',
    finish:
        'shepherd-button-action shepard-button-finish shepherd-button-primary',
    next:
        'shepherd-button-action shepard-button-primary shepherd-button-primary',
    skip:
        'shepherd-button-action shepard-button-secondary shepherd-button-secondary',
    previous:
        'shepherd-button-action shepard-button-secondary shepherd-button-secondary',
    exit:
        'shepherd-button-action shepard-button-secondary shepherd-button-secondary',
};

const buttonPriority: { [key: string]: number } = {
    start: 1,
    finish: 2,
    next: 2,
    skip: 3,
    previous: 1,
    exit: 3,
};

const baseTourButton = function (
    label: string | undefined | null,
    type: string
) {
    if (label && type) {
        return {
            label,
            text: label,
            classes: buttonClassesMap[type] ?? 'shepherd-button-action',
            secondary: undefined,
            disabled: undefined,
            priority: buttonPriority[type],
        };
    }
    return null;
};

/**
 * @function
 * @createTourStepButtons
 * Create navigation buttons for Tour Step
 */
const createTourStepButtons = function (
    source: ITourStepsFromAPI
): ITourElementButtonType[] {
    const fullButtonsList = [
        baseTourButton(
            source.leftButton,
            findButtonType(source.leftButton?.toLowerCase() ?? '')
        ),
        baseTourButton(
            source.mainButton,
            findButtonType(source.mainButton?.toLowerCase() ?? '')
        ),
        baseTourButton(
            source.rightButton,
            findButtonType(source.rightButton?.toLowerCase() ?? '')
        ),
    ];
    const buttons = fullButtonsList.filter((item) => !isNull(item));
    if (buttons?.length > 0) {
        return sortBy(buttons, 'priority').map(
            ({ label, text, classes }: any) => ({
                label,
                text,
                classes,
            })
        );
    } else {
        return [];
    }
};

/**
 * @function
 * @createListContent
 * Utility function to convert text content into list content
 */
const createListContent = function (content: string, shepherdTourId: number) {
    const contentList = content
        .split('EOL')
        .map((item: string) => item?.trim())
        .filter((item: string) => item?.length > 0);

    if (contentList.length > 1) {
        let htmlContent = '<ol>';
        contentList.forEach((item: string) => {
            htmlContent += `<li>${item}</li>`;
        });
        return `${htmlContent}</ol>`;
    }
    if (shepherdTourId === 35) {
        return `<div id="content-with-image" 
    style="display: flex;flex-direction: column;gap: 15px;
    justify-content: center;">
    <div>${contentList[0]}.</div>
    <div style="display: flex; justify-content: center;">
        <img src="https://perseusnonprodstorage.blob.core.windows.net/svgassets/topbar-help-icon.PNG" style="max-width: 100%; height: auto;">
    </div>
</div>`;
    } else if (shepherdTourId === 36) {
        return `<div id="content-with-image" 
    style="display: flex;flex-direction: column;gap: 15px;
    justify-content: center;">
    <div>${contentList[0]}.</div>
    <div style="display: flex; justify-content: center;">
        <img src="https://perseusnonprodstorage.blob.core.windows.net/svgassets/topbar-search.PNG" style="max-width: 100%; height: auto;">
    </div>
</div>`;
    }
    return `${contentList[0]}.`;
};

/**
 * @function
 * @createDetailedSteps
 * Utility function to transform Steps from API into Shephard Tour steps
 */
export const createDetailedSteps = function (
    source: ITourStepsFromAPI[],
    target: ISteps[]
) {
    const currentTarget = target.map((item: ISteps) => {
        return {
            ...item,
            buttons: [],
            text: '',
            title: '',
        };
    });
    source?.forEach((item: ITourStepsFromAPI, index: number) => {
        currentTarget.forEach((targetItem: ISteps) => {
            if (targetItem.id === `step${index}`) {
                targetItem.title = item.heading;
                targetItem.text = createListContent(
                    item.description,
                    item.shepherdTourId
                );
                targetItem.buttons = createTourStepButtons(item);
            }
        });
    });

    return currentTarget;
};
