import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
    ApplicationInsights,
    ITelemetryItem,
} from '@microsoft/applicationinsights-web';

import {
    APP_INSIGHTS_INGESTION,
    APP_INSIGHTS_INSTRUMENTATION,
    APP_INSIGHTS_LIVE_ENDPOINT,
} from '../../api-constants';

/**
 * AppInsights Implemetation
 * @environment APP_INSIGHTS_INSTRUMENTATION
 * @environment APP_INSIGHTS_INGESTION
 */
class AppInsightsImpl {
    private _ai: ApplicationInsights;
    private _reactPlugin: ReactPlugin;

    constructor() {
        this._reactPlugin = new ReactPlugin();
        this._ai = new ApplicationInsights({
            config: {
                connectionString: `InstrumentationKey=${
                    APP_INSIGHTS_INSTRUMENTATION ??
                    'c3d3eef3-4f79-44fd-a419-2c2c5a77d752'
                };IngestionEndpoint=${
                    APP_INSIGHTS_INGESTION ??
                    'https://eastus2-3.in.applicationinsights.azure.com'
                };LiveEndpoint=${
                    APP_INSIGHTS_LIVE_ENDPOINT ??
                    'https://eastus2.livediagnostics.monitor.azure.com'
                }`,
                enableAutoRouteTracking: true,
                extensions: [this._reactPlugin],
                maxBatchInterval: 0,
                loggingLevelConsole: 1,
                loggingLevelTelemetry: 1,
                disableFetchTracking: true,
                disableAjaxTracking: true,
            },
        });
        this._ai.loadAppInsights();
    }

    getAi() {
        return this._ai;
    }

    getPlugin() {
        return this._reactPlugin;
    }

    /**
     * @getAiInsights
     * @params moduleRole ai.cloud.role which is needed for cloud role name where logs need to be pushed.
     */
    getAiInsights(moduleRole: string) {
        this._ai.addTelemetryInitializer((envelope: ITelemetryItem) => {
            envelope.tags = envelope.tags || [];
            envelope.tags.push({
                'ai.cloud.role': moduleRole,
            });
        });
        return this._ai.appInsights;
    }

    flush() {
        this._ai.flush();
    }

    get() {
        return {
            ai: this._ai,
            reactPlugin: this._reactPlugin,
        };
    }
}

export default new AppInsightsImpl();
