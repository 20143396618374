import React, { FC, InputHTMLAttributes } from 'react';
import { TextField as MuiTextField } from '@material-ui/core';
import { FormikProps } from 'formik';

interface ITextFieldProps {
    title: string;
    type?: InputHTMLAttributes<unknown>['type'];
    formikState: FormikProps<any>;
}

export const TextField: FC<ITextFieldProps> = ({
    title,
    type,
    formikState,
}) => {
    return (
        <MuiTextField
            type={type}
            name={title}
            placeholder={title}
            aria-label={title}
            onBlur={formikState.handleBlur}
            onChange={formikState.handleChange}
            value={formikState.values[title]}
            error={Boolean(
                formikState.touched[title] && formikState.errors[title]
            )}
            helperText={formikState.touched[title] && formikState.errors[title]}
            size="small"
            variant="outlined"
            style={{
                width: '100%',
            }}
        />
    );
};
