import { ModuleName } from '../../utils';

//SYSTEM
const SYSTEM_FRESHWORKS_SCRIPT_SRC =
    'https://widget.freshworks.com/widgets/22000000436.js';
const SYSTEM_WIDGET_SCRIPT = `window.fwSettings={
	'widget_id':22000000436
	};
	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;

//ACCOUNTABILITY
const ACCOUNTABILITY_FRESHWORKS_SCRIPT_SRC =
    'https://widget.freshworks.com/widgets/22000000426.js';
const ACCOUNTABILITY_WIDGET_SCRIPT = `window.fwSettings={
	'widget_id':22000000426
	};
	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;

//ELIGIBILITY
const ELIGIBILITY_FRESHWORKS_SCRIPT_SRC =
    'https://widget.freshworks.com/widgets/22000000427.js';
const ELIGIBILITY_WIDGET_SCRIPT = `window.fwSettings={
	'widget_id':22000000427
	};
	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;

//ACCOUNT MANAGEMENT
const ACCOUNT_MANAGEMENT_FRESHWORKS_SCRIPT_SRC =
    'https://widget.freshworks.com/widgets/22000000429.js';
const ACCOUNT_MANAGEMENT_WIDGET_SCRIPT = `window.fwSettings={
	'widget_id':22000000429
	};
	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() `;

//ITEM MANAGEMENT
const ITEM_MANAGEMENT_FRESHWORKS_SCRIPT_SRC =
    'https://widget.freshworks.com/widgets/22000000430.js';
const ITEM_MANAGEMENT_WIDGET_SCRIPT = `window.fwSettings={
	'widget_id':22000000430
	};
	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;

//INVENTORY
const INVENTORY_FRESHWORKS_SCRIPT_SRC =
    'https://widget.freshworks.com/widgets/22000000431.js';
const INVENTORY_WIDGET_SCRIPT = `window.fwSettings={
	'widget_id':22000000431
	};
	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;

//MENU PLANNING
const MENU_PLANNING_FRESHWORKS_SCRIPT_SRC =
    'https://widget.freshworks.com/widgets/22000000432.js';
const MENU_PLANNING_WIDGET_SCRIPT = `window.fwSettings={
	'widget_id':22000000432
	};
	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;

//PRODUCTION
const PRODUCTION_FRESHWORKS_SCRIPT_SRC =
    'https://widget.freshworks.com/widgets/22000000433.js';
const PRODUCTION_WIDGET_SCRIPT = `window.fwSettings={
	'widget_id':22000000433
	};
	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;

//INSIGHTS
const INSIGHTS_FRESHWORKS_SCRIPT_SRC =
    'https://widget.freshworks.com/widgets/22000000434.js';
const INSIGHTS_WIDGET_SCRIPT = `window.fwSettings={
	'widget_id':22000000434
	};
	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;

export const getWidgetScript: (
    moduleName: string
) => {
    script: string;
    widget: string;
} | null = (moduleName: string) => {
    let scripts: {
        script: string;
        widget: string;
    } | null = null;
    switch (moduleName.toLocaleLowerCase()) {
        case ModuleName.System.toLowerCase():
            scripts = {
                script: SYSTEM_FRESHWORKS_SCRIPT_SRC,
                widget: SYSTEM_WIDGET_SCRIPT,
            };
            break;
        case ModuleName.Inventory.toLowerCase():
            scripts = {
                script: INVENTORY_FRESHWORKS_SCRIPT_SRC,
                widget: INVENTORY_WIDGET_SCRIPT,
            };
            break;
        case ModuleName.Accountability.toLowerCase():
            scripts = {
                script: ACCOUNTABILITY_FRESHWORKS_SCRIPT_SRC,
                widget: ACCOUNTABILITY_WIDGET_SCRIPT,
            };
            break;
        case ModuleName.Eligibility.toLowerCase():
            scripts = {
                script: ELIGIBILITY_FRESHWORKS_SCRIPT_SRC,
                widget: ELIGIBILITY_WIDGET_SCRIPT,
            };
            break;
        case ModuleName.AccountManagement.toLowerCase():
            scripts = {
                script: ACCOUNT_MANAGEMENT_FRESHWORKS_SCRIPT_SRC,
                widget: ACCOUNT_MANAGEMENT_WIDGET_SCRIPT,
            };
            break;
        case ModuleName.ItemManagement.toLowerCase():
            scripts = {
                script: ITEM_MANAGEMENT_FRESHWORKS_SCRIPT_SRC,
                widget: ITEM_MANAGEMENT_WIDGET_SCRIPT,
            };
            break;
        case ModuleName.MenuPlanning.toLowerCase():
            scripts = {
                script: MENU_PLANNING_FRESHWORKS_SCRIPT_SRC,
                widget: MENU_PLANNING_WIDGET_SCRIPT,
            };
            break;
        case ModuleName.Production.toLowerCase():
            scripts = {
                script: PRODUCTION_FRESHWORKS_SCRIPT_SRC,
                widget: PRODUCTION_WIDGET_SCRIPT,
            };
            break;
        case ModuleName.Insights.toLowerCase():
            scripts = {
                script: INSIGHTS_FRESHWORKS_SCRIPT_SRC,
                widget: INSIGHTS_WIDGET_SCRIPT,
            };
            break;
        case ModuleName.Workspace.toLowerCase():
            scripts = {
                script: INSIGHTS_FRESHWORKS_SCRIPT_SRC,
                widget: INSIGHTS_WIDGET_SCRIPT,
            };
            break;
        default:
            break;
    }

    return scripts;
};
