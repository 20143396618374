import React, { useEffect, useRef, useState } from 'react';
import {
    Badge,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Popover,
    SvgIcon,
    Theme,
    Tooltip,
    Typography,
} from '@material-ui/core';

import { BellIcon, CloseIcon } from '../../../assets/icons';
import { componentsAPIInstance } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
    popover: {
        width: 420,
    },
    icon: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    listItemHover: {
        '&:hover': {
            backgroundColor: 'rgb(241, 240, 251) !important',
        },
        cursor: 'pointer',
    },
    listItem: {
        maxHeight: '370px',
        overflowY: 'auto',
    },
    moreButton: {
        marginTop: theme.spacing(2),
    },
}));

interface IMessagesProps {
    apimUrl: string;
    subscriptionKey: string;
}

const Notifications = ({ apimUrl, subscriptionKey }: IMessagesProps) => {
    const classes = useStyles();
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [messagesList, setMessagesList] = useState<any[]>([]);
    const [showAll, setShowAll] = useState<boolean>(false);

    useEffect(() => {
        if (apimUrl && subscriptionKey) {
            getMessages()
                .then((response) => {
                    if (response) {
                        const messages = response.data.messages;
                        if (messages && messages.length > 0) {
                            const unreadMessages = messages
                                .filter((data: any) => !data.isRead)
                                .sort(
                                    (a: any, b: any) =>
                                        Date.parse(b.createdOn) -
                                        Date.parse(a.createdOn)
                                );
                            setMessagesList(unreadMessages);
                        }
                    } else {
                        setMessagesList([]);
                    }
                })
                .catch(() => {
                    console.log('error getting messages ');
                });
        }
    }, [apimUrl]);

    const getMessages = async () => {
        try {
            const response = await componentsAPIInstance.axios({
                method: 'GET',
                url: `${apimUrl}/workspace/v1.0/api/list-messages?pageSize=${100}&pageNumber=${1}&sortColumn=id`,
            });
            return response;
        } catch (e) {
            return null;
        }
    };

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleShowAll = (flag: boolean): void => {
        setShowAll(flag);
    };

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton
                    ref={ref}
                    color="inherit"
                    onClick={handleOpen}
                    aria-label="Notifications"
                >
                    <Badge
                        badgeContent={messagesList.length ?? ''}
                        color="secondary"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <SvgIcon>
                            <BellIcon />
                        </SvgIcon>
                    </Badge>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                classes={{
                    paper: classes.popover,
                }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                {messagesList.length === 0 ? (
                    <Box p={2}>
                        <Typography variant="h6" color="textPrimary">
                            No new notifications.
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <List disablePadding className={classes.listItem}>
                            <ListItem divider>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="h5"
                                        color="textPrimary"
                                    >
                                        Notifications
                                    </Typography>
                                    <IconButton
                                        style={{
                                            marginLeft: '265px',
                                            padding: '0',
                                        }}
                                        onClick={handleClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </ListItem>
                            {messagesList
                                .slice(0, showAll ? messagesList.length : 3)
                                .map((message: any) => {
                                    return (
                                        <ListItem
                                            divider
                                            key={message.id}
                                            className={classes.listItemHover}
                                        >
                                            <ListItemText
                                                primary={message.subject}
                                                primaryTypographyProps={{
                                                    variant: 'subtitle2',
                                                    color: 'textPrimary',
                                                }}
                                                secondary={
                                                    <Typography
                                                        component="div"
                                                        variant="body2"
                                                        color="textSecondary"
                                                        style={{
                                                            display:
                                                                '-webkit-box',
                                                            WebkitLineClamp: 2,
                                                            WebkitBoxOrient:
                                                                'vertical',
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                                'ellipsis',
                                                        }}
                                                    >
                                                        {message.content.replace(
                                                            /<[^>]*>/g,
                                                            ''
                                                        )}
                                                    </Typography>
                                                }
                                                onClick={() => {
                                                    localStorage.setItem(
                                                        'mailToOpenData',
                                                        JSON.stringify(message)
                                                    );
                                                    window.location.href =
                                                        '/workspace/inbox';
                                                }}
                                            />
                                        </ListItem>
                                    );
                                })}
                        </List>
                        {messagesList.length > 3 &&
                            (!showAll ? (
                                <Button
                                    className={classes.moreButton}
                                    onClick={() => handleShowAll(true)}
                                    color="primary"
                                >
                                    Show More
                                </Button>
                            ) : (
                                <Button
                                    className={classes.moreButton}
                                    onClick={() => handleShowAll(false)}
                                    color="primary"
                                >
                                    Show Less
                                </Button>
                            ))}
                    </>
                )}
            </Popover>
        </>
    );
};

export default Notifications;
