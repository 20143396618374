import React from 'react';

export default function SCLoader(): JSX.Element {
    return (
        <>
            <div id="preLoader" className="pre-loader">
                <div className="box">
                    <div className="path"></div>
                    <div className="path"></div>
                    <div className="path"></div>
                    <div className="path"></div>
                    <div className="path"></div>
                    <div className="path"></div>
                </div>
            </div>
            <style>{`
            .pre-loader {
                width: 100%;
                height: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center
            }

            .pre-loader .box {
                width: 50px;
                height: 50px;
                font-size: 6px;
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center
            }

            .pre-loader .box .path {
                position: absolute;
                border-radius: 50%;
                border-style: solid;
                -webkit-animation: animateTurn 1.4s linear infinite;
                animation: animateTurn 1.4s linear infinite
            } 

            .pre-loader .box .path:nth-child(odd) {
                color: #665AD8;
                -webkit-animation-direction: normal;
                animation-direction: normal
            }

            .pre-loader .box .path:nth-child(even) {
                color: #04004D;
                animation-direction: reverse
            }

            .pre-loader .box .path:nth-child(1) {
                width: 100%;
                height: 100%;
                border-width: .3em 0 0 .3em;
                border-color: transparent transparent transparent currentColor;
                -webkit-animation-duration: 1.6s;
                animation-duration: 1.6s
            }

            .pre-loader .box .path:nth-child(2) {
                width: 100%;
                height: 100%;
                border-width: .3em .3em 0 0;
                border-color: transparent currentColor transparent transparent;
                -webkit-animation-duration: 1.9s;
                animation-duration: 1.9s
            }

            .pre-loader .box .path:nth-child(3) {
                width: 72%;
                height: 72%;
                border-width: 0 .3em .3em 0;
                border-color: transparent currentColor transparent transparent;
                -webkit-animation-duration: 1.8s;
                animation-duration: 1.8s
            }

            .pre-loader .box .path:nth-child(4) {
                width: 72%;
                height: 72%;
                border-width: 0 0 .3em .3em;
                border-color: transparent transparent transparent currentColor;
                -webkit-animation-duration: 2s;
                animation-duration: 2s
            }

            .pre-loader .box .path:nth-child(5) {
                width: 44%;
                height: 44%;
                border-width: .3em 0 0 .3em;
                border-color: transparent transparent transparent currentColor;
                -webkit-animation-duration: 2.1s;
                animation-duration: 2.1s
            }

            .pre-loader .box .path:nth-child(6) {
                width: 44%;
                height: 44%;
                border-width: .3em .3em 0 0;
                border-color: transparent currentColor transparent transparent;
                -webkit-animation-duration: 1.7s;
                animation-duration: 1.7s
            }

            @-webkit-keyframes animateTurn {
                to {
                    -webkit-transform: rotate(1turn);
                    transform: rotate(1turn)
                }
            }

            @keyframes animateTurn {
                to {
                    -webkit-transform: rotate(1turn);
                    transform: rotate(1turn)
                }
            }
        `}</style>
        </>
    );
}
