import { MutableRefObject, useLayoutEffect, useState } from 'react';

export interface IDimensionObject {
    width: number | null;
    height: number | null;
    top: number | null;
    bottom: number | null;
    left: number | null;
    right: number | null;
}

export interface IUseDimensionsProps {
    ref: MutableRefObject<HTMLElement | null>;
    onResize?: boolean;
    onScroll?: boolean;
}

const initialDimensions = {
    width: null,
    height: null,
    top: null,
    bottom: null,
    left: null,
    right: null,
};

const getDimensionObject = (node: HTMLElement): IDimensionObject => {
    try {
        const rect = node.getBoundingClientRect();
        return {
            width: rect.width,
            height: rect.height,
            top: rect.top,
            bottom: rect.bottom,
            left: rect.left,
            right: rect.right,
        };
    } catch (e) {
        console.warn(e);
        return initialDimensions;
    }
};

/**
 * @hook
 * useDimensions
 * Provides dimensions of an HTML element as {height, width, top, bottom, left, right}
 *
 * @param ref Element ref
 * @param onResize boolean optional if the element needs to be responsive
 * @param onScroll boolean optional if elements is scrollable
 *
 * @returns {height, width, top, bottom, left, right}
 *
 * ``typescript
 * import {useDimensions} from '@primeroedge/ui-components';
 *
 * const elementRef = useRef(null);
 * const {height, width} = useDimensions({ref: elementRef});
 * ```
 */
const useDimensions = ({
    ref,
    onResize = false,
    onScroll = false,
}: IUseDimensionsProps): IDimensionObject => {
    const [dimensions, setDimensions] = useState<IDimensionObject>(
        initialDimensions
    );

    useLayoutEffect(() => {
        const measure = () => {
            window.requestAnimationFrame(() => {
                if (ref?.current) {
                    setDimensions(getDimensionObject(ref.current));
                }
            });
        };

        if (ref) {
            measure();
            if (onResize) {
                window.addEventListener('resize', measure);
            }
            if (onScroll) {
                window.addEventListener('scroll', measure);
            }
        }

        return () => {
            if (onResize) {
                window.removeEventListener('resize', measure);
            }
            if (onScroll) {
                window.removeEventListener('scroll', measure);
            }
        };
    }, [ref, onResize, onScroll]);

    return dimensions;
};

export default useDimensions;
