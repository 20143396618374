import React, { FC, ReactElement } from 'react';
import Box from '@material-ui/core/Box';

import { StandardProps } from '../../types/standardProps';

export interface IAccordionGroupProps extends StandardProps<HTMLDivElement> {
    /**
     * ToDo: Allow multiple accordion items simultaneously
     */
    // multiple?: boolean;
    showButton?: boolean;
    /**
     * Send custom component
     */
    action?: ReactElement;
}

const AccordionGroup: FC<IAccordionGroupProps> = ({ children, ...rest }) => {
    return <Box {...rest}>{children}</Box>;
};

export default AccordionGroup;
