import { useLocation } from 'react-router';

/**
 * Functional hook to enable bypass for functionality
 *  const canAllow = systemByPass(
 *    !!(
 *        env &&
 *        ['production', 'sales', ''].includes(
 *          env?.toLowerCase?.() ?? ''
 *        )
 *    ),
 *    URL_PARAM_VALUE
 *  );
 */
const useSystemByPass = function () {
    const location = useLocation();
    const canAllow = function (
        preCondition: boolean,
        settingname: string,
        checkQueryParamOnly?: string
    ) {
        const conditionValue = new URLSearchParams(location.search).get(
            'bypass'
        );
        return (
            preCondition ||
            conditionValue === settingname ||
            checkQueryParamOnly === conditionValue
        );
    };

    return canAllow;
};

export default useSystemByPass;
