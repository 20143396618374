import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import {
    CalendarProps,
    DateInputCustomFormatPlaceholder,
    DatePicker as KDatePicker,
    DatePickerChangeEvent,
    DatePickerProps,
} from '@progress/kendo-react-dateinputs';
import { DateFormatOptions } from '@progress/kendo-react-intl';

import CustomCalendar from './CustomCalendar';

export interface IDatePickerProps extends DatePickerProps {
    /**
     * Gets the value of the DatePicker.
     */
    value?: Date | null;
    /**
     * Default date values to display
     */
    defaultValue?: Date;
    /**
     * Fires each time the user selects a part of a date
     */
    onChange?: (event: IDatePickerChangeEvent) => void;
    /**
     * Sets the default state of the DatePicker upon render
     */
    defaultShow?: boolean;
    /**
     * Determines whether the DatePicker is disabled
     */
    disabled?: boolean;
    /**
     * Specifies the focused date of the DatePicker
     */
    focusedDate?: Date;
    /**
     * Specifies the date format which is used for formatting the value of the DateInput
     */
    format?: string | DateFormatOptions;
    /**
     * Identifies the element(s) which will label the component.
     */
    areaLabelledBy?: string;

    /**
     * placeholder for the date input field
     */
    formatPlaceholder?:
        | 'wide'
        | 'narrow'
        | 'short'
        | 'formatPattern'
        | DateInputCustomFormatPlaceholder;
    /**
     * required attribute.
     */
    required?: boolean;
    /**
     * Show hide week numbers.
     */
    weekNumber?: boolean;
    /**
     * Width of the calander input field
     */
    width?: number;
    /**
     * show the calendar popup
     */
    show?: boolean;
    /**
     * Specifies the `name` property of the `input` DOM element.
     */
    name?: string;
    /**
     * Specifies the label of the date picker
     */
    label?: string;
    /**
     * Enables the customization or the override of the default Calendar which is rendered by the DatePicker
     */
    calendar?: React.ComponentType<CalendarProps<any>>;
}

export interface IDatePickerChangeEvent extends DatePickerChangeEvent {}

const DatePicker: FC<IDatePickerProps> = ({
    value,
    defaultValue,
    onChange,
    defaultShow,
    disabled,
    focusedDate,
    format,
    min,
    max,
    areaLabelledBy,
    formatPlaceholder,
    required,
    show,
    weekNumber,
    width,
    name,
    label,
    calendar,
    ...rest
}) => {
    return (
        <Box aria-label="date-picker" className="k-datetime-picker">
            <p aria-label={label} className="k-date-time-label">
                {label}
            </p>

            <KDatePicker
                value={value}
                title="date-picker"
                defaultValue={defaultValue}
                onChange={onChange}
                defaultShow={defaultShow}
                disabled={disabled}
                focusedDate={focusedDate}
                format={format || 'MM/dd/yyyy'}
                min={min}
                max={max}
                ariaLabelledBy={areaLabelledBy}
                formatPlaceholder={formatPlaceholder}
                required={required}
                show={show}
                weekNumber={weekNumber}
                width={width}
                name={name}
                valid={value !== null || value !== undefined}
                calendar={calendar ?? CustomCalendar}
                {...rest}
            />
        </Box>
    );
};

export default DatePicker;
