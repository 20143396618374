import React, { FC, useEffect, useMemo, useState } from 'react';
import {
    Avatar,
    Box,
    FormHelperText,
    Grid,
    makeStyles,
    TextField,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Link from '@material-ui/core/Link/Link';
import Typography from '@material-ui/core/Typography/Typography';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { EyeIcon, EyeOffIcon, LockIcon } from '../../assets';
import { Button } from '../../components/Button';
import { IconButton } from '../../components/IconButton';
import { ITheme, useAuth } from '../../core/Providers';
import { storage } from '../../core/Store';
import useIsMounted from '../../hooks/useIsMounted';

export interface ILoginFormRemembered {
    forgotPassword: () => void;
    onDifferentUser: () => void;
    rememberedUser?: {
        [key: string]: string;
    };
}

const useStyles = makeStyles((theme: ITheme) => ({
    forgotPasswordSection: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    errorMessage: {
        display: 'flex',
        justifyContent: 'center',
        margin: '12px 0',
    },
    signInDiffUser: {
        textAlign: 'center',
    },
    avatar: {
        backgroundColor: '#F2F2F2',
        color: theme.palette.primary.main,
        width: 100,
        height: 100,
        fontSize: 50,
    },
    avatarImg: {
        width: '95%',
        height: '95%',
        objectFit: 'fill',
    },
}));

const LoginFormRemembered: FC<ILoginFormRemembered> = (
    { forgotPassword, onDifferentUser },
    props
) => {
    const classes = useStyles();
    const { login, error, errorMessage } = useAuth();
    const isMountedRef = useIsMounted();
    const [showError, canShowError] = useState<boolean>(false);
    const [errorDescription, setDescription] = useState<string>(
        'Email or password might be incorrect. Please try again.'
    );
    const [user, setUser] = useState<any>({});
    const [showPassword, setShowPassword] = useState(false);
    const [userFullName, setUserFullName] = useState<string>('');

    useEffect(() => {
        try {
            const rememberedUser = JSON.parse(
                storage.getItem('rememberedUser')
            );
            setUser(rememberedUser);
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        if (user) {
            setUserFullName(`${user?.firstName} ${user?.lastName}`);
        }
    }, [user]);

    useEffect(() => {
        if (error) {
            if (error === '20') {
                setDescription(errorMessage);
            }
            canShowError(true);
        }
    }, [error, showError, errorMessage]);

    const handleShowPassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    const profileImage = useMemo(
        function () {
            const image = storage.getItem('rememberMeProfileImage');
            return image;
        },
        [user]
    );

    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
            >
                {profileImage && (
                    <Avatar
                        className={classes.avatar}
                        classes={{ img: classes.avatarImg }}
                        alt="User Profile Image"
                        id={'user-profile-image'}
                        src={profileImage}
                    />
                )}
                {!profileImage && (
                    <Avatar className={classes.avatar}>
                        {userFullName
                            ?.replace(/\s*(\S)\S*/g, '$1')
                            .toUpperCase()}
                    </Avatar>
                )}
            </Box>

            <Formik
                initialValues={{
                    email: user.email,
                    password: '',
                    submit: null,
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string()
                        .max(255)
                        .required('Password is required'),
                })}
                onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                ) => {
                    try {
                        await login(user.email, values.password);

                        if (isMountedRef()) {
                            setStatus({
                                success: true,
                            });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        if (isMountedRef()) {
                            setStatus({
                                success: false,
                            });
                            setErrors({
                                submit: err?.message,
                            });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <form noValidate onSubmit={handleSubmit} {...props}>
                        <Typography
                            variant="h3"
                            component="h2"
                            align="center"
                            gutterBottom
                        >
                            Welcome back, {user.firstName}!
                        </Typography>
                        <TextField
                            error={Boolean(
                                touched.password &&
                                    (errors.password || showError)
                            )}
                            fullWidth
                            helperText={touched.password && errors.password}
                            label="Password"
                            id="password"
                            margin="normal"
                            name="password"
                            autoComplete="current-password"
                            autoCorrect="off"
                            autoCapitalize="off"
                            spellCheck="false"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type={showPassword ? 'text' : 'password'}
                            value={values.password}
                            variant="outlined"
                            color="primary"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Show Password"
                                            size="small"
                                            color="inherit"
                                            onClick={handleShowPassword}
                                        >
                                            {showPassword ? (
                                                <EyeOffIcon />
                                            ) : (
                                                <EyeIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {errors.submit && (
                            <FormHelperText error>
                                {errors.submit}
                            </FormHelperText>
                        )}
                        <Box mt={1} className={classes.forgotPasswordSection}>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={forgotPassword}
                                underline="hover"
                                color="textSecondary"
                                type="button"
                            >
                                Forgot Password?
                            </Link>
                        </Box>
                        <Box mt={2}>
                            <Grid
                                container={true}
                                spacing={2}
                                direction="column"
                                style={{
                                    justifyContent: 'center',
                                }}
                                alignItems="stretch"
                            >
                                <Grid item={true}>
                                    <Button
                                        id="btnSignIn"
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        type="submit"
                                        loading={isSubmitting}
                                    >
                                        Sign In
                                    </Button>
                                </Grid>
                                <Grid item={true}>
                                    <Button
                                        id="btnSignInDifferentUser"
                                        onClick={onDifferentUser}
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                        Sign in as different user
                                    </Button>
                                </Grid>

                                {showError && (
                                    <div className={classes.errorMessage}>
                                        <Typography
                                            id={'error-message'}
                                            color={'error'}
                                        >
                                            {errorDescription}
                                        </Typography>
                                    </div>
                                )}
                            </Grid>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default LoginFormRemembered;
