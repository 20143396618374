import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';

import { addTrailingSlash, waitUntil } from '../../utils';
import loadJS from '../../utils/scripts/loadJS';

export enum ViewOptions {
    MENU_ONLY = 'menuonly',
    MENU_TOOLBAR = 'menutoolbar',
    MENU_TOOLBAR_TASKBAR = 'menutoolbartaskbar',
    NONE = 'none',
    TOOLBAR_ONLY = 'toolbaronly',
    VIEW_ONLY = 'viewonly',
}

export enum ControllerType {
    Dashboard = 'Dashboard',
    Scorecard = 'Scorecard',
    Report = 'Report',
    Shortlink = 'Shortlink',
    Slideshow = 'Slideshow',
    SmallMultiple = 'SmallMultiple',
}

export interface IDundasBIProps {
    url: string;
    fileSystemId?: string;
    controllerType?: keyof typeof ControllerType;
    viewOptions?: ViewOptions;
    parameterValues?: any[];
}

const DundasBI = ({
    url,
    controllerType,
    fileSystemId,
    viewOptions = ViewOptions.VIEW_ONLY,
    parameterValues,
}: IDundasBIProps) => {
    /**
     * Initialize dundas script
     */
    useEffect(() => {
        loadJS(`${addTrailingSlash(url)}Scripts/Embed/dundas.embed.min.js`);
    }, [url]);

    useEffect(() => {
        (async () => {
            try {
                // Wait until dundas library is loaded
                await waitUntil(() => !!(window as any).dundas);

                // ToDo: Remove login
                const loginCredentials = {
                    accountName: 'anon_user',
                    password: 'P@$$w0rd1#',
                };
                const responseHandler = (logonResponse: any) => {
                    const dundasApp = (window as any).dundas?.embed?.create(
                        document.getElementById('dundas-bi-layout'),
                        {
                            dundasBIUrl: url,
                            fileSystemId: fileSystemId,
                            viewOptions: viewOptions,
                            controllerType: controllerType,
                            logonTokenId: logonResponse?.logOnToken,
                            parameterValues: parameterValues ?? [],
                        }
                    );
                    dundasApp.load();
                };

                (window as any).dundas?.embed?.logon?.getLogonToken(
                    url,
                    loginCredentials,
                    responseHandler
                );
            } catch (e) {
                console.error('Failed to load dundas', e);
            }
        })();
    }, [url, fileSystemId, viewOptions, controllerType, parameterValues]);

    return <Box id="dundas-bi-layout" width="100%" height="100%" />;
};

export default DundasBI;
