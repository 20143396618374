import React, {
    ButtonHTMLAttributes,
    DOMAttributes,
    FC,
    MouseEventHandler,
} from 'react';
import { darken, fade, IconButton as MuiButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { ITheme } from '../../core/Providers';
import { StandardProps, ThemeColorType } from '../../types';
import { Tooltip } from '../Tooltip';

export interface IIconButtonProps
    extends StandardProps<ButtonHTMLAttributes<HTMLButtonElement>>,
        DOMAttributes<HTMLButtonElement> {
    /**
     * Unique id for button
     */
    id?: string;
    /**
     * The name of the button, submitted as a pair with the button’s value as part of the form data
     */
    name?: string;
    /**
     * Default behavior of the button
     */
    type?: 'submit' | 'reset' | 'button';
    /**
     * Emit on click
     */
    onClick?: MouseEventHandler<HTMLButtonElement>;
    /**
     * Icon Button color
     */
    color?: ThemeColorType | 'inherit';

    /**
     * The size of the IconButton
     */
    size?: 'small' | 'medium';
    /**
     * If given, uses a negative margin to counteract the padding on one
     * side (this is often helpful for aligning the left or right
     * side of the icon with content above or below, without ruining the border
     * size and shape).
     */
    edge?: 'start' | 'end' | false;
    /**
     * Disable the button
     */
    disabled?: boolean;
    /**
     * Tooltip for button
     */
    buttonTooltip?: string;

    /**
     * tooltip text to set. default value is "button"
     */
    buttonTooltipText?: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
    inherit: {
        color: 'inherit',
    },
    default: {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.background.dark,
        },
        '&:disabled': {
            backgroundColor: fade(theme.palette.background.default, 0.24),
        },
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: darken(theme.palette.primary.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: fade(theme.palette.primary.main, 0.24),
        },
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: darken(theme.palette.secondary.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: fade(theme.palette.secondary.main, 0.24),
        },
    },
    tertiary: {
        backgroundColor: theme.palette.tertiary.main,
        color: theme.palette.getContrastText(theme.palette.tertiary.main),
        '&:hover': {
            backgroundColor: darken(theme.palette.tertiary.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: fade(theme.palette.tertiary.main, 0.24),
        },
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: darken(theme.palette.success.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: fade(theme.palette.success.main, 0.24),
        },
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: darken(theme.palette.warning.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: fade(theme.palette.warning.main, 0.24),
        },
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: darken(theme.palette.error.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: fade(theme.palette.error.main, 0.24),
        },
    },
    info: {
        backgroundColor: theme.palette.info.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: darken(theme.palette.info.main, 0.08),
        },
        '&:disabled': {
            backgroundColor: fade(theme.palette.info.main, 0.24),
        },
    },
}));

const IconButton: FC<IIconButtonProps> = ({
    children,
    id,
    name,
    className,
    type = 'button',
    onClick,
    size,
    color = 'default',
    disabled,
    buttonTooltip,
    buttonTooltipText = '',
    ...rest
}) => {
    const classes = useStyles();

    return (
        <>
            {disabled ? (
                <Tooltip arrow title={buttonTooltip || ''}>
                    <MuiButton
                        id={id}
                        onClick={onClick}
                        title={buttonTooltipText}
                        size={size}
                        className={clsx(
                            {
                                [classes[color]]: color,
                            },
                            className
                        )}
                        name={name}
                        type={type}
                        disabled={disabled}
                        {...rest}
                    >
                        {children}
                    </MuiButton>
                </Tooltip>
            ) : (
                <MuiButton
                    id={id}
                    onClick={onClick}
                    title={buttonTooltipText}
                    size={size}
                    className={clsx(
                        {
                            [classes[color]]: color,
                        },
                        className
                    )}
                    name={name}
                    type={type}
                    disabled={disabled}
                    {...rest}
                >
                    {children}
                </MuiButton>
            )}
        </>
    );
};

export default IconButton;
