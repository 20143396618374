import React, { FC, ReactNode } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

import { ITheme } from '../../../core/Providers';
import { StandardProps } from '../../../types/standardProps';
import { TextAlignType, TextColorType } from '../../../types/theme';

export interface IPageAlertProps extends StandardProps<HTMLDivElement> {
    /**
     * Display Icon
     */
    icon?: ReactNode;
    /**
     * Message to display
     */
    title?: string | ReactNode;
    /**
     * Color of title text
     */
    titleTextColor?: TextColorType;
    /**
     * Message to display
     */
    message?: string | ReactNode;
    /**
     * Color of message text
     */
    messageTextColor?: TextColorType;
    /**
     * Alignment of text
     */
    textAlign?: TextAlignType;
    /**
     * Page alert style variant
     */
    variant?: 'contained' | 'transparent';
}

const useStyles = makeStyles((theme: ITheme) => ({
    contained: {
        padding: theme.spacing(2),
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
    },
    transparent: {
        padding: theme.spacing(2),
        backgroundColor: 'transparent',
    },
    icon: {
        color: fade(theme.palette.text.secondary, 0.28),
    },
}));

const PageAlert: FC<IPageAlertProps> = ({
    icon,
    children,
    title,
    message,
    titleTextColor = 'textPrimary',
    messageTextColor = 'textSecondary',
    textAlign = 'center',
    variant = 'contained',
}) => {
    const classes = useStyles();

    return (
        <Box className={classes[variant]}>
            {icon && (
                <Box
                    display="flex"
                    justifyContent="center"
                    className={classes.icon}
                >
                    {icon}
                </Box>
            )}
            {title && (
                <Typography
                    variant="h4"
                    component="p"
                    align={textAlign}
                    color={titleTextColor}
                    gutterBottom={true}
                >
                    {title}
                </Typography>
            )}
            {message && (
                <Typography
                    variant="body1"
                    component="p"
                    align={textAlign}
                    color={messageTextColor}
                >
                    {message}
                </Typography>
            )}
            {children}
        </Box>
    );
};

export default PageAlert;
