import { createContext } from 'react';

export interface IPageProviderContextValue {
    pageName: string;
    currentView: string;
    defaultViewAttributes: any;
    updateDefaultViewAttributes?: (data: any) => void;
    viewAttributes: any;
    setViewAttributes: (criteria: any) => void;
    canUpdateFilters?: boolean;
    setCanUpdateFilters?: (val: boolean) => void;
    currentViewId?: string;
    customViewPageName?: string;
    isSharedView?: boolean;
    setIsSharedView?: (value: boolean) => void;
    isViewSelection?: boolean;
    setIsViewSelection?: (value: boolean) => void;
    isSystem?: boolean;
    setIsSystem?: (value: boolean) => void;
}

export const PageProviderContext = createContext<IPageProviderContextValue>({
    pageName: '',
    currentView: '',
    defaultViewAttributes: null,
    updateDefaultViewAttributes: () => {},
    viewAttributes: null,
    setViewAttributes: () => {},
    canUpdateFilters: false,
    setCanUpdateFilters: () => {},
    currentViewId: '',
    customViewPageName: '',
    isSharedView: false,
    setIsSharedView: () => {},
    isViewSelection: false,
    setIsViewSelection: () => {},
    isSystem: false,
    setIsSystem: () => {},
});

export default PageProviderContext;
