import {
    DEFAULT_TIMEOUT,
    MAX_WAIT_TIME,
    MIN_TIMEOUT,
} from '../../core/constants';

interface IWaitUntilOptions {
    /**
     * Interval at which condition should be checked
     * Default - 100ms
     */
    interval?: number;
    /**
     * Maximum wait time for the condition to be true
     * Default - 1minute
     */
    maxWaitTime?: number;
}

/**
 * Wait until a give condition is true
 */
const waitUntil = (
    conditionFunc: () => boolean,
    { interval, maxWaitTime }: IWaitUntilOptions = {}
): Promise<string> => {
    if (interval === 0 || (interval && interval < MIN_TIMEOUT)) {
        console.warn(`waitUntil interval cannot be below ${MIN_TIMEOUT}ms`);
    }
    const wuInterval =
        interval && interval >= MIN_TIMEOUT ? interval : DEFAULT_TIMEOUT;
    const wuMaxWaitTime =
        maxWaitTime === 0 || (maxWaitTime && maxWaitTime > 0)
            ? maxWaitTime
            : MAX_WAIT_TIME;
    let wuTotalWaitTime = 0;

    const poll = (
        resolve: (message?: string) => void,
        reject: (message?: string) => void
    ) => {
        if (wuTotalWaitTime >= wuMaxWaitTime) {
            reject(`Failed to resolve in ${wuMaxWaitTime}ms`);
        } else if (conditionFunc()) {
            resolve(`Resolved in ${wuTotalWaitTime}ms`);
        } else {
            setTimeout(() => {
                wuTotalWaitTime += wuInterval;
                return poll(resolve, reject);
            }, wuInterval);
        }
    };
    return new Promise(poll);
};

export default waitUntil;
