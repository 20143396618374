import React, { Component } from 'react';

import { ErrorPage } from '../../layouts';

import ErrorCapture from './ErrorCapture';

export interface IErrorHandlerProps {
    children: React.ReactNode;
    disableLogging?: boolean;
}

/**
 * @AppErrorHandler
 * Custom component that handles web errors. Logging to App Insights.
 * @param children React Page
 * @param disableLogging optional parameter to disable logging.
 * @returns Wrapper component with page content.
 */
class AppErrorHandler extends Component {
    state = {
        error: '',
        errorInfo: '',
        hasError: false,
    };

    static getDerivedStateFromError(error: any) {
        return {
            hasError: true,
            error,
        };
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({
            error,
            errorInfo,
        });
    }

    render() {
        const { hasError } = this.state;
        if (hasError) {
            return <ErrorPage />;
        }
        return <ErrorCapture>{this.props.children}</ErrorCapture>;
    }
}

export default AppErrorHandler;
