import { isNil } from 'lodash';

export default function ScStorage() {
    if (isNil(sessionStorage.getItem('mutli-user'))) {
        return localStorage;
    }

    return sessionStorage;
}

export const promptValidityCheck = function (currentValue: boolean) {
    if (ScStorage().getItem('accessToken')) {
        return currentValue;
    }

    return false;
};
