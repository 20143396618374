import React, { FC, useEffect, useState } from 'react';
import {
    Box,
    FormHelperText,
    makeStyles,
    Switch,
    TextField,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Link from '@material-ui/core/Link/Link';
import Typography from '@material-ui/core/Typography/Typography';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { EyeIcon, EyeOffIcon, LockIcon, MailIcon } from '../../assets';
import { Button } from '../../components/Button';
import { IconButton } from '../../components/IconButton';
import { useAuth } from '../../core/Providers';
import useIsMounted from '../../hooks/useIsMounted';

export interface ILoginForm {
    forgotPassword: () => void;
    onRememberMe: (checked: boolean) => void;
}

const useStyles = makeStyles(() => ({
    rememberMe: {
        paddingLeft: 14,
        display: 'flex',
        justifyContent: 'space-between',
    },
    rememberMeLabel: {
        marginLeft: 5,
        paddingTop: 5,
        fontSize: '0.875rem',
    },
    errorMessage: {
        display: 'flex',
        justifyContent: 'center',
        margin: '12px 0',
    },
}));

const LoginForm: FC<ILoginForm> = ({ forgotPassword, onRememberMe }, props) => {
    const classes = useStyles();
    const { login, error, errorMessage } = useAuth();
    const isMountedRef = useIsMounted();
    const [showError, canShowError] = useState<boolean>(false);
    const [errorDescription, setDescription] = useState<string>(
        'Email or password might be incorrect. Please try again.'
    );
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (error) {
            if (error === '20') {
                setDescription(errorMessage);
            }
            canShowError(true);
        }
    }, [error, showError, errorMessage]);

    const handleRememberMe = () => {
        setRememberMe(!rememberMe);
        onRememberMe && onRememberMe(!rememberMe);
    };

    const handleShowPassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                submit: null,
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string().max(255).required('Email is required'),
                password: Yup.string()
                    .max(255)
                    .required('Password is required'),
            })}
            onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
            ) => {
                try {
                    await login(values.email, values.password);

                    if (isMountedRef()) {
                        setStatus({
                            success: true,
                        });
                        setSubmitting(false);
                    }
                } catch (err) {
                    if (isMountedRef()) {
                        setStatus({
                            success: false,
                        });
                        setErrors({
                            submit: err.message,
                        });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <form noValidate onSubmit={handleSubmit} {...props}>
                    <TextField
                        error={Boolean(
                            touched.email && (errors.email || showError)
                        )}
                        fullWidth
                        autoFocus
                        helperText={touched.email && errors.email}
                        label="Email"
                        id="email"
                        margin="normal"
                        name="email"
                        autoComplete="email"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.email}
                        variant="outlined"
                        color="primary"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MailIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        error={Boolean(
                            touched.password && (errors.password || showError)
                        )}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        id="password"
                        margin="normal"
                        name="password"
                        autoComplete="current-password"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        variant="outlined"
                        color="primary"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Show Password"
                                        size="small"
                                        color="inherit"
                                        onClick={handleShowPassword}
                                    >
                                        {showPassword ? (
                                            <EyeIcon />
                                        ) : (
                                            <EyeOffIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {errors.submit && (
                        <FormHelperText error>{errors.submit}</FormHelperText>
                    )}
                    <Box mt={1} className={classes.rememberMe}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={rememberMe}
                                    onChange={handleRememberMe}
                                    name="remember-me"
                                    color="primary"
                                />
                            }
                            label="Remember Me"
                            classes={{
                                label: classes.rememberMeLabel,
                            }}
                        />
                        <Link
                            component="button"
                            variant="body2"
                            onClick={forgotPassword}
                            underline="hover"
                            color="textSecondary"
                            type="button"
                        >
                            Forgot Password?
                        </Link>
                    </Box>
                    <Box mt={2}>
                        <Button
                            id="btnSignIn"
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            type="submit"
                            loading={isSubmitting}
                            label="Sign In"
                        />
                        {showError && (
                            <div className={classes.errorMessage}>
                                <Typography
                                    id={'error-message'}
                                    color={
                                        errorDescription.includes(
                                            'Your Customer Success mentor'
                                        )
                                            ? 'primary'
                                            : 'error'
                                    }
                                >
                                    {errorDescription}
                                </Typography>
                            </div>
                        )}
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default LoginForm;
