import React, { FC } from 'react';

import { ISvgIconProps, SvgIcon } from '../SvgIcon';

import { ReactComponent as SchoolCafe } from './SchoolCafe.svg';
import { ReactComponent as SchoolCafeIcon } from './SchoolCafe_Icon.svg';

export const AppLogo: FC<ISvgIconProps> = ({ title, ...rest }) => (
    <SvgIcon title={title || 'SchoolCafé'} {...rest}>
        <SchoolCafe />
    </SvgIcon>
);

export const AppIcon: FC<ISvgIconProps> = ({ title, ...rest }) => (
    <SvgIcon title={title || 'SchoolCafé'} {...rest}>
        <SchoolCafeIcon />
    </SvgIcon>
);
