import { componentsAPIInstance } from './APIInstance';

export async function getSystemSetting(
    settingValue: string,
    apimUrl: string
): Promise<any> {
    try {
        const response = await componentsAPIInstance.axios({
            url: `${apimUrl}/administration/v1.0/api/SystemSettings/${settingValue}`,
            method: 'GET',
        });

        return response;
    } catch (e) {
        console.log('Error getting system setting value');
        throw 'Error';
    }
}

export async function getChatSettings(
    apimUrl: string,
    regionId: string | number,
    isProd: boolean
): Promise<any> {
    try {
        if (isProd) {
            const response = await componentsAPIInstance.axios({
                url: `${apimUrl}/administration/v1.0/api/SystemSettings/${regionId}/SettingValue/ENBLCHAT`,
                method: 'GET',
            });

            return response;
        }
        return {
            data: {
                PayLoad: '0',
            },
        };
    } catch (e) {
        console.log('Error getting system setting value');
        throw 'Error';
    }
}
