import React, { FC } from 'react';
import { Calendar, CalendarProps } from '@progress/kendo-react-dateinputs';

import CustomNavigationItem from './CustomNavigationItem';

const CustomCalendar: FC<CalendarProps> = (props) => {
    return <Calendar {...props} navigationItem={CustomNavigationItem} />;
};

export default CustomCalendar;
