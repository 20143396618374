import { Method } from 'axios';

import Logger from './Providers/logger/Logger';
import { ErrorSeverity } from './Providers/logger/types';

/**
 * @AppInsights
 * Class implementation to create AppInsights connection and send logs to Azure.
 * Initialization of AppInsightsProvider is established using createConnection(moduleName)
 * method.
 * @returns a constructor that exposes
 * @method createConnection
 * @method createTrace
 * @method createEvent
 * @method createException
 */
class AppInsightsProvider extends Logger {
    private _module: string = '';

    constructor() {
        super();
    }

    /**
     * @createConnection
     * Create App Insights Connection
     */
    createConnection(moduleName: string) {
        this._module = moduleName;
    }

    /**
     * @createTrace
     * Create Trace for Components/Functional errors
     * @param {
     *  message: string;
     *  severity: ErrorSeverity.ERROR,
     *  page: string,
     *  detail: any,
     *  time: Date Object,
     *  component: React.Component Name
     * }
     */
    createTrace({
        message,
        severityLevel,
        page,
        detail,
        time = Date.now(),
        component = 'PageLayout',
    }: {
        message: string;
        severityLevel: ErrorSeverity;
        page: string;
        detail: any;
        time?: any;
        component?: string;
    }) {
        console.log('log trace', page);
        this.logComponentTrace({
            component,
            time,
            page,
            moduleName: this._module,
            severity: severityLevel,
            error: message,
            description: detail,
        });
    }

    /**
     * @createEvent
     * Create Trace for event errors
     * @param {
     *  eventName: string;
     *  pageName: string;
     *  api: {
     *      url: '/users',
     *      method: 'POST'
     *  }
     * }
     */
    createEvent({
        eventName,
        eventDescription,
        pageName,
        api = undefined,
    }: {
        eventName: string;
        eventDescription?: string;
        pageName: string;
        api?: {
            url?: string;
            method?: Method | undefined;
        };
    }) {
        this.logException({
            api,
            moduleName: this._module,
            page: pageName,
            error: eventName,
            severity: ErrorSeverity.ERROR,
            time: Date.now(),
            description: eventDescription,
        });
    }

    /**
     * @createEvent
     * Create Trace for exception errors
     * @param {
     *       exception: string;
     *       exceptionMsg?: string | undefined;
     *       severity?: ErrorSeverity;
     *       page: string;
     *       api?: {
     *           method?: Method | undefined;
     *           url?: string | undefined;
     *       } | undefined;
     *       time?: any;
     *   }
     */
    createException({
        exception,
        exceptionMsg,
        page,
        api = undefined,
        time = Date.now(),
    }: {
        exception: string;
        exceptionMsg?: string | undefined;
        severity?: ErrorSeverity;
        page: string;
        api?:
            | {
                  method?: Method | undefined;
                  url?: string | undefined;
              }
            | undefined;
        time?: any;
    }) {
        this.logException({
            error: exception,
            description: exceptionMsg,
            page,
            api,
            severity: ErrorSeverity.ERROR,
            time,
            moduleName: this._module,
        });
    }
}

export default new AppInsightsProvider();
