import { useCallback, useEffect, useRef } from 'react';

/**
 * @hooks
 * Hook to determine when the component is mounted.
 * @returns () => true/false function that returns true when mounted and false when unmounted.
 *
 * ```typescript
 * import {useIsMounted} from '@primeroedge/ui-components';
 * const hasMounted = useIsMounted();
 *
 * if (hasMounted()) {
 *  code to run when mounted
 * }
 * ```
 */
const useIsMounted = (): (() => boolean) => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    return useCallback(() => isMounted.current, []);
};

export default useIsMounted;
