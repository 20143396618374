import React, { FC } from 'react';
import { useHistory } from 'react-router';
import {
    Box,
    Card,
    CardContent,
    Divider,
    Link,
    makeStyles,
    Typography,
} from '@material-ui/core';

import { Alert } from '../../components/Alert';
import { Button } from '../../components/Button';

export interface ILinkExpiredProps {}

const useStyles = makeStyles(() => ({
    content: {
        maxWidth: 540,
    },
}));

const LinkExpired: FC<ILinkExpiredProps> = () => {
    const classes = useStyles();
    const history = useHistory();

    const backToHome = () => {
        history.push('/');
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
        >
            <Card className={classes.content}>
                <CardContent>
                    <Typography
                        variant="h3"
                        component="h2"
                        color="primary"
                        gutterBottom
                    >
                        Link has expired
                    </Typography>
                    <Divider />
                    <Box my={2}>
                        <Alert
                            color="error"
                            message="Sorry, this link has expired. Please request a new link from
                    your administrator. You can contact them using the information below:"
                        />
                    </Box>
                    <Box my={4}>
                        <Typography variant="body1" gutterBottom>
                            Please contact district administrator
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            (281) 453-8500
                        </Typography>
                        <Typography variant="body1">
                            <Link
                                href="mailto:edgeadmin@primeroedge.com"
                                variant="body2"
                            >
                                edgeadmin@primeroedge.com
                            </Link>
                        </Typography>
                    </Box>
                    <Box>
                        <Button
                            id="returnToHomePage"
                            color="primary"
                            onClick={backToHome}
                            fullWidth
                        >
                            Return to SchoolCafe Login
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default LinkExpired;
