import React from 'react';

import { IVerticalModuleIcons } from './index';

const MenuPlanningModuleIcon = ({ selected }: IVerticalModuleIcons) => (
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0)">
            <path
                d="M6.95996 5.98999V8.98999H6.45996V5.98999H5.45996V8.98999H4.95996V5.98999H3.95996V8.98999C3.95996 9.38781 4.118 9.76935 4.3993 10.0507C4.68061 10.332 5.06214 10.49 5.45996 10.49V14.04H6.45996V10.49C6.85779 10.49 7.23932 10.332 7.52062 10.0507C7.80193 9.76935 7.95996 9.38781 7.95996 8.98999V5.98999H6.95996ZM8.95996 7.98999V10.99H9.95996V13.99H10.96V5.98999C10.4295 5.98999 9.92082 6.2007 9.54575 6.57578C9.17067 6.95085 8.95996 7.45956 8.95996 7.98999Z"
                fill={selected === 'white' ? selected : '#04004D'}
            />
            <path
                d="M19.54 12.5999L18.39 11.4499C18.2514 11.3097 18.0863 11.1983 17.9044 11.1222C17.7224 11.0462 17.5272 11.0071 17.33 11.0071C17.1328 11.0071 16.9375 11.0462 16.7556 11.1222C16.5736 11.1983 16.4085 11.3097 16.27 11.4499L10.96 16.7399V19.9899H14.23L19.52 14.6999C19.7999 14.4235 19.9591 14.0475 19.9628 13.6541C19.9666 13.2606 19.8146 12.8817 19.54 12.5999ZM13.54 18.4599H12.54V17.4599L15.96 13.9899L16.96 14.9899L13.54 18.4599Z"
                fill={selected === 'white' ? selected : '#04004D'}
            />
            <path
                d="M8.95996 17.99H1.95996V1.98999H10.96V4.98999H13.96V10.99L15.96 8.98999V3.98999L11.96 -0.0100098H1.95996C1.42953 -0.0100098 0.92082 0.200704 0.545747 0.575777C0.170675 0.950849 -0.0400391 1.45956 -0.0400391 1.98999V17.99C-0.0400391 18.5204 0.170675 19.0291 0.545747 19.4042C0.92082 19.7793 1.42953 19.99 1.95996 19.99H8.95996V17.99Z"
                fill={selected}
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="19.98" height="20.01" fill={selected} />
            </clipPath>
        </defs>
    </svg>
);

export default MenuPlanningModuleIcon;
