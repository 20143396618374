import type { FC } from 'react';
import React, { ReactNode, useState } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { ModalProps } from '@material-ui/core/Modal';

import { TextColorType, ThemeColorType } from '../../types';
import Button from '../Button/Button';

export interface INotificationWithDialogProps {
    title?: string | ReactNode;
    message?: string | ReactNode;
    open?: boolean;
    color?: ThemeColorType;
    primaryAction?: {
        title: string;
        callback: () => void;
    };
    secondaryAction?: {
        title: string;
        callback: () => void;
    };
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    /**
     * Callback fired when the component requests to be closed.
     */
    onClose?: ModalProps['onClose'];
}

const useStyles = makeStyles(() => ({
    title: {
        padding: '16px 16px 8px 16px',
    },
    content: {
        padding: '8px 16px',
    },
    actions: {
        minWidth: 280,
    },
}));

const NotificationWithDialog: FC<INotificationWithDialogProps> = ({
    open = true,
    color = 'primary',
    title,
    message,
    primaryAction,
    secondaryAction,
    maxWidth,
    onClose,
}) => {
    const classes = useStyles();

    const [isOpen, setOpen] = useState<boolean>(open);

    React.useEffect(() => {
        setOpen(open);
    }, [open]);

    const handlePrimaryAction = (): void => {
        if (primaryAction) {
            primaryAction.callback();
        }
        setOpen(false);
    };

    const handleSecondaryAction = (): void => {
        if (secondaryAction) {
            secondaryAction.callback();
        }
        setOpen(false);
    };

    const getTextColor = (color: ThemeColorType): TextColorType => {
        switch (color) {
            case 'primary':
                return 'primary';
            case 'secondary':
                return 'secondary';
            case 'tertiary':
                return 'secondary';
            case 'error':
                return 'error';
            default:
                return 'inherit';
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <Dialog
            open={open}
            maxWidth={maxWidth}
            aria-label="alert-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            onClose={onClose}
        >
            {title && (
                <DialogTitle
                    id="alert-dialog-title"
                    disableTypography
                    className={classes.title}
                >
                    <Typography
                        variant="h4"
                        color={getTextColor(color)}
                        component="h2"
                    >
                        {title}
                    </Typography>
                </DialogTitle>
            )}
            {message && (
                <DialogContent className={classes.content}>
                    <div id="alert-dialog-description">
                        {typeof message === 'string' ? (
                            <Typography
                                variant="body1"
                                color="textPrimary"
                                gutterBottom
                            >
                                {message}
                            </Typography>
                        ) : (
                            message
                        )}
                    </div>
                </DialogContent>
            )}
            <DialogActions className={classes.actions}>
                {secondaryAction && (
                    <Box mr={1}>
                        <Button
                            id="nSecondaryAction"
                            variant="text"
                            onClick={handleSecondaryAction}
                        >
                            {secondaryAction.title}
                        </Button>
                    </Box>
                )}
                {primaryAction && (
                    <Button
                        id="nPrimaryAction"
                        color={color}
                        variant="text"
                        onClick={handlePrimaryAction}
                    >
                        {primaryAction.title}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default NotificationWithDialog;
