import React, { FC } from 'react';
import { Paper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

import { IKGridProps, RowData } from '../../components/Grid';
import KGrid from '../../components/Grid/Kendo/KGrid';
import { AppLoader } from '../../components/Loaders';
import { ITabsProps } from '../../components/Tabs';
import { ITheme } from '../../core/Providers';
import { IFilters } from '../../types/searchFilters';
import { IPageLayoutProps, PageLayout } from '../PageLayout';

import SearchFilterTable from './SearchFilterTable';

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FAFAFF',
    },
    cardRoot: {
        margin: '24px 0',
    },
    cardPadding: {
        padding: theme.spacing(2),
    },
}));

export interface ITableSearchLayoutProps extends IPageLayoutProps {
    filters?: IFilters[];
    filterOnSubmit: (val: any) => void;
    hideFilters?: boolean;
    /**
     * Add spacing to grid
     */
    gridPadding: boolean;
    kGridData?: IKGridProps<RowData>;
    footerText?: string | null;
    isDataLoading?: boolean;
    pageTabs?: ITabsProps | null;
    initialSearchFieldsValues?: { [name: string]: any };
    displayOnboardingBackButton?: boolean;
}

const TableSearchLayout: FC<ITableSearchLayoutProps> = ({
    title,
    breadCrumbs,
    action,
    actions,
    filters,
    filterOnSubmit,
    hideFilters,
    gridPadding,
    kGridData,
    footerText,
    isDataLoading,
    pageTabs,
    initialSearchFieldsValues,
    displayOnboardingBackButton = false,
}) => {
    const classes = useStyles();

    return (
        <PageLayout
            title={title}
            breadCrumbs={breadCrumbs}
            action={action}
            actions={actions}
            pageTabs={pageTabs}
            displayOnboardingBackButton={displayOnboardingBackButton}
        >
            {!hideFilters && filters && (
                <SearchFilterTable
                    filters={filters}
                    onSubmit={filterOnSubmit}
                    footerText={footerText}
                    initialValues={initialSearchFieldsValues || undefined}
                />
            )}
            <Paper
                aria-label="paper"
                className={clsx({
                    [classes.cardRoot]: !Boolean(pageTabs),
                    [classes.cardPadding]: gridPadding,
                })}
                elevation={1}
            >
                {kGridData && !isDataLoading && <KGrid {...kGridData} />}
                {isDataLoading && <AppLoader />}
            </Paper>
        </PageLayout>
    );
};

export default TableSearchLayout;
