import React, { FC, ReactNode, SyntheticEvent } from 'react';
import {
    Box,
    ListItem as MuiListItem,
    ListItemSecondaryAction,
    makeStyles,
    Typography,
} from '@material-ui/core';
import clsx from 'clsx';

interface IListItemProps {
    title: ReactNode;
    subTitle?: ReactNode;
    action?: ReactNode;
    info?: ReactNode;
    onClick?: (event: SyntheticEvent) => void;
}

const useStyles = makeStyles(() => ({
    root: {
        height: 52,
    },
    title: {
        minWidth: 80,
    },
    action: {
        paddingLeft: 8,
    },
}));

const ListItem: FC<IListItemProps> = ({
    title,
    subTitle,
    action,
    info,
    onClick,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <MuiListItem
            role="button"
            dense={true}
            button={true}
            classes={{ root: classes.root }}
            onClick={onClick}
            {...rest}
        >
            <Box
                display="flex"
                width="100%"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="nowrap"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    flexWrap="nowrap"
                    className={clsx('ellipsis', classes.title)}
                >
                    {title && (
                        <Typography variant="h6" component="p" noWrap={true}>
                            {title}
                        </Typography>
                    )}
                    {subTitle && (
                        <Typography
                            variant="caption"
                            component="p"
                            color="textSecondary"
                            noWrap={true}
                        >
                            {subTitle}
                        </Typography>
                    )}
                </Box>
                <Box>{info}</Box>
            </Box>
            {action && (
                <ListItemSecondaryAction>{action}</ListItemSecondaryAction>
            )}
        </MuiListItem>
    );
};

export default ListItem;
