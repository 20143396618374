import React from 'react';

// import { ModulePath } from '../../../utils';
import { AppLogo } from '../../../assets';
// import { makeStyles } from '@material-ui/core';
// import { Button } from '../../Button';
import { ThemeColorType } from '../../../types/theme';

// const useStyles = ({ color }: { color: TitleColor }) =>
//     makeStyles(() => ({
//         appLogo: {
//             padding: '4px 4px 0 4px',
//             color: color,
//             minWidth: 128,
//             '&:focus': {
//                 boxShadow: '0 0 0 0.08rem white',
//             },
//             '&:disabled': {
//                 color: color,
//             },
//         },
//     }));

export interface IProjectTitle {
    color?: TitleColor;
    disabled?: boolean;
}

type TitleColor = ThemeColorType | 'white' | 'inherit';
// { color = 'inherit', disabled }: IProjectTitle
const ProjectTitle = () => {
    // const classes = useStyles({ color })();

    // const [isWorkspacePage] = useState(
    //     window.location.pathname === ModulePath.Workspace
    // );

    // const navigateToWorkspace = (): void => {
    //     window.location.replace(ModulePath.Workspace);
    // };

    return (
        // <Button
        //     id="appLogo"
        //     type="button"
        //     className={classes.appLogo}
        //     variant="text"
        //     disabled={disabled || isWorkspacePage}
        //     onClick={navigateToWorkspace}
        // >
        //     <AppLogo size={{ width: 120, height: 36 }} />
        // </Button>
        <AppLogo
            size={{
                width: 220,
                height: 36,
            }}
        />
    );
};

export default ProjectTitle;
