import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';

import { Button } from '../../../components/Button';
import { IFilters } from '../../../types/searchFilters';

import RenderFilter from './FiltersGroupElement';

interface ISearchFilterTable {
    filters: IFilters[];
    onSubmit: (val: any) => void;
    footerText?: string | null;
    initialValues?: { [name: string]: any };
}

const useStyles = makeStyles({
    root: {
        margin: '12px 0 0 0',
    },
    cardContent: {
        padding: 0,
        '&:last-child': {
            padding: 0,
        },
    },
    title: {
        marginBottom: '10px',
    },
    gridRow: {
        padding: 0,
    },
    gridContainer: {
        margin: 0,
        alignItems: 'center',
        width: '100%',
        minHeight: '75px',
    },
    formElementRoot: {
        padding: '16px 0',
    },
    submitContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    submitContainerEnded: {
        display: 'flex',
        justifyContent: 'space-evenly',
    },
});

const SearchFilterTable: FC<ISearchFilterTable> = ({
    filters,
    onSubmit,
    footerText,
    initialValues,
}: ISearchFilterTable) => {
    const classes = useStyles();
    const [filterForm, updateFilterForm] = useState<{ [name: string]: any }>(
        {}
    );

    useEffect(() => {
        if (
            filters &&
            filters.length > 0 &&
            filters.length !== Object.keys(filterForm).length
        ) {
            const formObject: { [name: string]: any } = {};
            filters.forEach((filter: IFilters) => {
                if (initialValues && initialValues[filter.name]) {
                    formObject[filter.name] = initialValues[filter.name];
                } else {
                    formObject[filter.name] = null;
                }
            });
            updateFilterForm(() => ({
                ...filterForm,
                ...formObject,
            }));
        }
    }, [filters, filterForm, initialValues]);

    const onFilterChange = (field: string) => (value: any) => {
        updateFilterForm(() => ({
            ...filterForm,
            [field]: value,
        }));
    };

    const onReset = () => {
        const resetFields = Object.keys(filterForm).reduce(
            (fieldsObject: { [name: string]: any }, key: string) => {
                fieldsObject[key] = null;
                return fieldsObject;
            },
            {}
        );
        updateFilterForm(() => ({
            ...resetFields,
        }));
    };

    return (
        <div className={classes.root}>
            <Typography
                variant="h6"
                classes={{
                    root: classes.title,
                }}
            >
                Search by:
            </Typography>
            <Card>
                <CardContent
                    classes={{
                        root: classes.cardContent,
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        classes={{
                            root: classes.gridContainer,
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            lg={11}
                            md={12}
                            classes={{
                                root: classes.gridRow,
                            }}
                        >
                            <Grid
                                container
                                spacing={4}
                                classes={{
                                    root: classes.gridContainer,
                                }}
                            >
                                {filters &&
                                    filters.map(
                                        (filter: IFilters, index: number) => {
                                            if (index <= 3) {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        md={4}
                                                        lg={3}
                                                        key={`search_input_field_${index}`}
                                                        classes={{
                                                            root:
                                                                classes.formElementRoot,
                                                        }}
                                                    >
                                                        <RenderFilter
                                                            filter={filter}
                                                            onFilterChange={onFilterChange(
                                                                filter.name
                                                            )}
                                                            value={
                                                                filterForm[
                                                                    filter.name
                                                                ] || undefined
                                                            }
                                                        />
                                                    </Grid>
                                                );
                                            }
                                            return null;
                                        }
                                    )}
                                {filters && filters.length <= 3 && (
                                    <Grid
                                        item
                                        xs={6}
                                        md={4}
                                        lg={3}
                                        classes={{
                                            root: classes.submitContainer,
                                        }}
                                    >
                                        <Button
                                            id="search"
                                            name={'Filter Search'}
                                            type={'button'}
                                            color={'primary'}
                                            label={'Search'}
                                            onClick={() => onSubmit(filterForm)}
                                        />
                                        <Button
                                            id="reset"
                                            name={'Filter Reset'}
                                            type={'button'}
                                            color={'default'}
                                            label={'reset'}
                                            onClick={onReset}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            {filters && filters.length > 3 && (
                                <Grid
                                    container
                                    spacing={4}
                                    classes={{
                                        root: classes.gridContainer,
                                    }}
                                >
                                    {filters &&
                                        filters.map(
                                            (
                                                filter: IFilters,
                                                index: number
                                            ) => {
                                                if (index > 3) {
                                                    return (
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            md={4}
                                                            lg={3}
                                                            key={`search_input_field_${index}`}
                                                            classes={{
                                                                root:
                                                                    classes.formElementRoot,
                                                            }}
                                                        >
                                                            <RenderFilter
                                                                filter={filter}
                                                                onFilterChange={onFilterChange(
                                                                    filter.name
                                                                )}
                                                                value={
                                                                    filterForm[
                                                                        filter
                                                                            .name
                                                                    ] ||
                                                                    undefined
                                                                }
                                                            />
                                                        </Grid>
                                                    );
                                                }
                                                return null;
                                            }
                                        )}
                                    <Grid
                                        item
                                        xs={6}
                                        md={4}
                                        lg={3}
                                        classes={{
                                            root: classes.submitContainerEnded,
                                        }}
                                    >
                                        <Button
                                            id="search"
                                            name={'Filter Search'}
                                            type={'button'}
                                            color={'primary'}
                                            label={'Search'}
                                            onClick={() => onSubmit(filterForm)}
                                        />
                                        <Button
                                            id="reset"
                                            name={'Filter Reset'}
                                            type={'button'}
                                            color={'default'}
                                            label={'reset'}
                                            onClick={onReset}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {footerText && (
                        <Typography variant="body2">{footerText}</Typography>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default SearchFilterTable;
