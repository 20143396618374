import { createContext } from 'react';

import { ITourStatusRegisterType } from '../../../components/Tour';
import { IGroup } from '../../../types';

export interface IModuleInfoContextValue {
    moduleName: string;
    permissions: IPageLevelPermissions;
    pageName: string;
    apimUrl: string;
    resetErrorBoundry: Function | null;
    tours: ITourStatusRegisterType[] | null;
    updateTours: Function | null;
    isNavExpanded: boolean;
    activeDistrict: string | IGroup | null;
    showHelp: boolean;
    routes: IPageRoute[];
    setShowHelp: (show: boolean) => void;
    setIsNavExpanded: (isExpanded: boolean) => void;
    setActiveDistrict: (district: IGroup) => void;
    subscriptionKey: string;
    setBannerSettings: (args: {
        audioPing: boolean;
        bannerDuration: number;
        bannerLocation: string;
        showBanner: boolean;
    }) => void;
}

export interface IPageLevelPermissions {
    canView: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canAdd: boolean;
}

export interface IPageRoute {
    alias: string;
    moduleName: string;
    routeId: string;
    routeName: string;
    routePath: string;
    searchPage: string;
}

const ModuleInfoContext = createContext<IModuleInfoContextValue>({
    moduleName: '',
    pageName: '',
    permissions: {
        canAdd: true,
        canDelete: true,
        canEdit: true,
        canView: true,
    },
    apimUrl: '',
    resetErrorBoundry: null,
    tours: null,
    updateTours: null,
    isNavExpanded: true,
    activeDistrict: null,
    showHelp: false,
    routes: [],
    setShowHelp: () => {},
    setIsNavExpanded: () => {},
    subscriptionKey: '',
    setActiveDistrict: () => {},
    setBannerSettings: () => {},
});

export default ModuleInfoContext;
