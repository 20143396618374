import React, { createContext } from 'react';
import { ShepherdOptionsWithType, Step, Tour } from 'react-shepherd';

/**
 * @interface
 * @IPageTourContextType
 * Tour context injected manually
 */
export interface IPageTourContextType {
    steps: any[];
    tourContext: Tour | null;
}

/**
 * Initla stage of the PageTourContext
 */
const PageTourContextInitialState = {
    steps: [],
    tourContext: null,
};

/**
 * @interface
 * @IAttachToType
 * type of attachment of the modal on the element during tour
 */
export interface IAttachToType {
    element: string;
    on: 'top' | 'bottom' | 'left' | 'right';
}

/**
 * @interface
 * @ITourElementButtonType
 * Interface to describe the structure of the tour actions
 */
export interface ITourElementButtonType {
    classes: string;
    text: string;
    type?: 'back' | 'cancel' | 'next';
    disabled?: boolean;
    label?: string;
    secondary?: boolean;
    action?: (this: Tour) => void;
}

/**
 * @interface
 * @ISteps
 * Interface to describe the structure of the steps in the tour
 */
export interface ISteps {
    id: string;
    attachTo: IAttachToType;
    beforeShowPromise?: () => Promise<any>;
    buttons: ITourElementButtonType[];
    title: string;
    text: string | string[];
    highlightClass?: string;
    scrollTo?: boolean;
    cancelIcon?: {
        enabled: boolean;
    };
    when?: {
        show: (this: Step) => void;
        hide: (this: Step) => void;
    };
}

/**
 * @interface
 * @ITourPageWrapperProps
 * Prop type for @TourElementWrapper
 */
export interface ITourPageWrapperProps {
    tourName: string;
    children: React.ReactElement;
    steps?: ISteps[];
    page?: string;
    uniqueDisplayEnableElement: string;
}
export interface ITourElementWrapperProps {
    autoStart?: boolean;
    isDone?: boolean;
    children: React.ReactElement;
    step?: ShepherdOptionsWithType;
    tourContext: React.Context<Tour | null>;
}

/**
 * @context
 * @PageTourContext
 * @implements @IPageTourContextType
 */
export const PageTourContext = createContext<IPageTourContextType>(
    PageTourContextInitialState
);

/**
 * @constant
 * @tourOptions
 * Default Tour Options
 */
export const tourOptions: Tour.TourOptions = {
    exitOnEsc: true,
    useModalOverlay: true,
    defaultStepOptions: {
        arrow: false,
        cancelIcon: {
            enabled: false,
        },
    },
};

/**
 * @enum
 * @TourStatusEnum
 * Enum for the status of the step
 */
export enum TourStatusEnum {
    STARTED = 'STARTED',
    SKIPPED = 'SKIPPED',
    EXIT = 'EXIT',
    NEXT = 'NEXT',
    FINISH = 'FINISH',
    BACK = 'BACK',
    NOT_VISITED = 'NOT_VISITED',
    VISITED = 'VISITED',
    NEW = 'NEW',
}

/**
 * @interface
 * @ITourProgressType
 * Custom event to update after action button is clicked on the tour step.
 */
export interface ITourProgressType {
    tourName: string;
    status: TourStatusEnum;
    step: string;
}

/**
 * @interface
 * @ITourStepStatusRegisterType
 * Type for the step progress stored in user's information using Administration API
 */
export interface ITourStepStatusRegisterType {
    name: string;
    status: TourStatusEnum;
}

/**
 * @interface
 * @ITourStatusRegisterType
 * Type for the tour progress stored in user's information using Administration API
 */
export interface ITourStatusRegisterType {
    tourname: string;
    steps: ITourStepStatusRegisterType[];
    isFinished: boolean;
    isExited: boolean;
    isDisabled: boolean;
    disabledAt: number;
}

/**
 * @interface
 * @ITourStepsFromAPI
 * Type for the tour progress stored in user's information using Administration API
 */
export interface ITourStepsFromAPI {
    createdBy: number;
    createdOn: string;
    description: string;
    displayOrder: number;
    heading: string;
    leftButton: string | null;
    mainButton: string | null;
    modifiedBy: number;
    modifiedOn: string;
    path: string;
    rightButton: string | null;
    screenName: string;
    shepherdTourId: number;
}
