import React, { FC, ReactNode } from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';

export interface ITooltipProps extends TooltipProps {
    /**
     * The content of the Tooltip
     */
    title: string | NonNullable<ReactNode>;
    /**
     * The placement/position of the Tooltip
     * "bottom-end","bottom-start","bottom","left-end","left-start","left","right-end","right-start","right","top-end","top-start","top"
     */
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    /**
     * The id of the Tooltip (Optional parameter)
     */
    id?: string;
    /**
     * The arrow of the Tooltip (Optional parameter - defaulted to true)
     */
    arrow?: boolean;
}

const Tooltip: FC<ITooltipProps> = ({
    title,
    placement,
    arrow = true,
    id,
    children,
    ...rest
}) => {
    if (title !== 'noop') {
        return (
            <MuiTooltip
                arrow={arrow}
                id={id}
                placement={placement}
                title={title}
                {...rest}
            >
                <div>{children}</div>
            </MuiTooltip>
        );
    }
    return <>{children}</>;
};

export default Tooltip;
