import React, { FC } from 'react';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { AccordionDetailsProps } from '@material-ui/core/AccordionDetails/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { AccordionVariant } from './Accordion';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    default: {},
    info: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

export interface IAccordionDetailsProps extends AccordionDetailsProps {
    variant: AccordionVariant;
}

const AccordionDetails: FC<IAccordionDetailsProps> = ({ variant, ...rest }) => {
    const classes = useStyles();
    return (
        <MuiAccordionDetails
            classes={{
                root: clsx(classes.root, [classes[variant]]),
            }}
            {...rest}
        />
    );
};

export default AccordionDetails;
