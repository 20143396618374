import { GridFilterOperators } from '@progress/kendo-react-grid/dist/npm/interfaces/GridFilterOperators';

/**
 * Kendo Grid filter operators
 */
export const defaultFilterOperators: GridFilterOperators = {
    text: [
        {
            text: 'grid.filterContainsOperator',
            operator: 'contains',
        },
        {
            text: 'grid.filterNotContainsOperator',
            operator: 'doesnotcontain',
        },
        {
            text: 'grid.filterEqOperator',
            operator: 'eq',
        },
        {
            text: 'grid.filterNotEqOperator',
            operator: 'neq',
        },
        {
            text: 'grid.filterStartsWithOperator',
            operator: 'startswith',
        },
        {
            text: 'grid.filterEndsWithOperator',
            operator: 'endswith',
        },
        {
            text: 'grid.filterIsEmptyOperator',
            operator: 'isempty',
        },
        {
            text: 'grid.filterIsNotEmptyOperator',
            operator: 'isnotempty',
        },
    ],
    numeric: [
        {
            text: 'grid.filterEqOperator',
            operator: 'eq',
        },
        {
            text: 'grid.filterNotEqOperator',
            operator: 'neq',
        },
        {
            text: 'grid.filterGteOperator',
            operator: 'gte',
        },
        {
            text: 'grid.filterGtOperator',
            operator: 'gt',
        },
        {
            text: 'grid.filterLteOperator',
            operator: 'lte',
        },
        {
            text: 'grid.filterLtOperator',
            operator: 'lt',
        },
    ],
    date: [
        {
            text: 'grid.filterEqOperator',
            operator: 'eq',
        },
        {
            text: 'grid.filterNotEqOperator',
            operator: 'neq',
        },
        {
            text: 'grid.filterAfterOrEqualOperator',
            operator: 'gte',
        },
        {
            text: 'grid.filterAfterOperator',
            operator: 'gt',
        },
        {
            text: 'grid.filterBeforeOperator',
            operator: 'lt',
        },
        {
            text: 'grid.filterBeforeOrEqualOperator',
            operator: 'lte',
        },
    ],
    boolean: [
        {
            text: 'grid.filterEqOperator',
            operator: 'eq',
        },
    ],
};

/**
 * Simple filters with contains and equal
 */
export const simpleFilterOperators: GridFilterOperators = {
    text: [
        {
            text: 'grid.filterContainsOperator',
            operator: 'contains',
        },
    ],
    numeric: [
        {
            text: 'grid.filterEqOperator',
            operator: 'eq',
        },
    ],
    date: [
        {
            text: 'grid.filterEqOperator',
            operator: 'eq',
        },
    ],
    boolean: [
        {
            text: 'grid.filterEqOperator',
            operator: 'eq',
        },
    ],
};
