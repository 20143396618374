import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
    Divider,
    Grid,
    IconButton,
    List,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';

import { StarOutlineIcon } from '../../../assets';
import ListItem from '../../ListItem';
import { Notification } from '../../Notifications';
import { SlidingDrawer } from '../../SlidingDrawer';
import { useFavorites } from '../hooks/useFavorites';

import { FavoriteActions } from './FavoriteActions';

const Favorites = () => {
    const {
        isOpen,
        loadingOnSave,
        errorAlertMessage,
        favoriteName,
        editedName,
        disableSaveBtn,
        editingIndex,
        moduleContext,
        successAlertMessage,
        isTextBoxDisabled,
        handleNameChange,
        handleOpen,
        handleRowClick,
        handleEditedNameChange,
        setSuccessAlertMessage,
        setErrorAlertMessage,
        setEditingIndex,
        setEditedName,
        handleClose,
        onViewSave,
        items,
        handleOnDragEnd,
        isPageNameExistsInRoutes,
    } = useFavorites();
    return (
        <>
            {successAlertMessage !== '' && (
                <Notification
                    color="success"
                    duration={5000}
                    message={successAlertMessage}
                    onClose={() => setSuccessAlertMessage('')}
                    open
                    variant="filled"
                />
            )}
            {errorAlertMessage !== '' && (
                <Notification
                    color="error"
                    duration={5000}
                    message={errorAlertMessage}
                    onClose={() => setErrorAlertMessage('')}
                    open
                    variant="filled"
                />
            )}
            {isPageNameExistsInRoutes ? (
                <Tooltip title="Favorites">
                    <IconButton
                        color="inherit"
                        aria-label="favorite"
                        onClick={handleOpen}
                    >
                        <SvgIcon>
                            <StarOutlineIcon />
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            ) : (
                ''
            )}
            <SlidingDrawer
                title={'Favorites'}
                open={isOpen}
                primaryAction={{
                    label: 'Save',
                    onClick: onViewSave,
                    loading: loadingOnSave,
                    disabled: disableSaveBtn,
                }}
                onCloseIconClick={handleClose}
                onBackdropClick={handleClose}
            >
                <Typography variant="h5" style={{ padding: '5px 0 10px 0' }}>
                    My Favorites
                </Typography>
                <Divider />
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="favorites">
                        {(provided) => (
                            <List
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {items.map((favorite: any, index: any) => (
                                    <Draggable
                                        key={favorite.id}
                                        draggableId={favorite.id.toString()}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <ListItem
                                                    onClick={() => {
                                                        handleRowClick(
                                                            favorite.url,
                                                            favorite.id
                                                        );
                                                    }}
                                                    title={
                                                        editingIndex ===
                                                        favorite.id ? (
                                                            <TextField
                                                                name="favoriteName"
                                                                id="edit-favorite-Name"
                                                                value={
                                                                    editedName
                                                                }
                                                                variant="outlined"
                                                                onChange={(
                                                                    event: any
                                                                ) =>
                                                                    handleEditedNameChange(
                                                                        event
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            <Tooltip
                                                                title={
                                                                    favorite.url
                                                                }
                                                            >
                                                                <span
                                                                    style={{
                                                                        color:
                                                                            'rgb(102,90,216)',
                                                                    }}
                                                                >
                                                                    {
                                                                        favorite.favoriteName
                                                                    }
                                                                </span>
                                                            </Tooltip>
                                                        )
                                                    }
                                                    action={
                                                        <FavoriteActions
                                                            apimUrl={
                                                                moduleContext?.apimUrl
                                                            }
                                                            favoriteId={
                                                                favorite.id
                                                            }
                                                            favoriteName={
                                                                favorite.favoriteName
                                                            }
                                                            setSuccessAlertMessage={
                                                                setSuccessAlertMessage
                                                            }
                                                            setErrorAlertMessage={
                                                                setErrorAlertMessage
                                                            }
                                                            handleClose={
                                                                handleClose
                                                            }
                                                            setEditingIndex={
                                                                setEditingIndex
                                                            }
                                                            setEditedName={
                                                                setEditedName
                                                            }
                                                            editedName={
                                                                editedName
                                                            }
                                                            favoriteURL={
                                                                favorite.url
                                                            }
                                                        />
                                                    }
                                                />
                                                <Divider />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
                <Grid item>
                    <TextField
                        fullWidth
                        autoFocus
                        label="Name"
                        id="name"
                        margin="normal"
                        name="email"
                        autoComplete="name"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        onChange={handleNameChange}
                        type="text"
                        value={favoriteName}
                        variant="outlined"
                        color="primary"
                        required
                        disabled={isTextBoxDisabled}
                    />
                </Grid>
            </SlidingDrawer>
        </>
    );
};
export default Favorites;
