import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import {
    darken,
    IconButton,
    ListItem,
    makeStyles,
    useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { groupBy } from 'lodash';

import { ChevronLeftIcon, ChevronRightIcon } from '../../../assets/icons';
import { ITheme, useSettings } from '../../../core/Providers';
import ModuleInfoContext, {
    IModuleInfoContextValue,
} from '../../../core/Providers/ModuleInfo/ModuleInfoContext';
import { storage } from '../../../core/Store';
import { getModuleIcon, ModuleName, ModuleNameType } from '../../../utils';
import { Tooltip } from '../../Tooltip';

export enum HoverStates {
    ENTER = 'Enter',
    LEAVE = 'Leave',
}
export interface IModulesProps {
    navList?: IPermissionModel[];
    moduleName?: ModuleName | ModuleNameType;
    shoudlExpand: (isHover?: HoverStates) => void;
    onHoverExpand: () => void;
    onNavigation: (name: string) => void;
    expanded: boolean;
    broadcastMessageExists: boolean;
    expandNavigationonClick: () => void;
    navigateOption: string | undefined;
}

export interface IPermissionModel {
    name: string;
    path?: string;
    id: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        placeContent: 'baseline',
        padding: '0px 0px 150px',
        maxWidth: '64px',
        borderRight: '1px solid rgba(114, 102, 219, 0.25)',
        borderTop: '1px solid rgba(114, 102, 219, 0.25)',
        height: '100%',
        overflow: 'auto',
        marginTop: '-1px',
    },
    link: {
        height: '52px',
        color: theme.palette.getContrastText(theme.palette.primary.main),
        textTransform: 'none',
        fontSize: '2rem',
        fontWeight: 'normal',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    collapsedIcon: {
        position: 'absolute',
        left: '82%',
        display: 'block',
        border: '1px solid',
        height: '25px',
        width: '25px',
        padding: '0px',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        zIndex: 999,
        top: '94px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    collapsedIconBroadcast: {
        position: 'absolute',
        left: '82%',
        display: 'block',
        border: '1px solid',
        height: '25px',
        width: '25px',
        padding: '0px',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        zIndex: 999,
        top: '94px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    expandedIcon: {
        position: 'absolute',
        left: '96%',
        display: 'block',
        border: '1px solid',
        height: '25px',
        width: '25px',
        padding: '0px',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        zIndex: 999,
        top: '94px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    expandedIconBroadcast: {
        position: 'absolute',
        left: '96%',
        display: 'block',
        border: '1px solid',
        height: '25px',
        width: '25px',
        padding: '0px',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        zIndex: 999,
        top: '94px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    moduleItemIconButton: {
        padding: '0px !important',
        height: '30px',
        width: '30px',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: darken(theme.palette.tertiary.main, 0.08),
        },
    },
    moduleItemIconButtonSelected: {
        padding: '0px !important',
        height: '30px',
        width: '30px',
        background: '#665AD8',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: darken(theme.palette.tertiary.main, 0.08),
        },
    },
    tooltip: {
        background: 'black',
        color: 'white',
        marginLeft: '-8px',
    },
    tooltipArrow: {
        background: 'black',
    },
    arrow: {
        color: 'black',
    },
    districtLogo: {
        fontSize: '2rem',
        fontWeight: 'bold',
        color: '#04004D',
        display: 'flex',
        padding: '0px 17px',
        marginTop: '-42px',
        marginLeft: '4px',
        position: 'absolute',
    },
}));

const Modules = ({
    navList,
    moduleName,
    shoudlExpand,
    onHoverExpand,
    onNavigation,
    expanded,
    broadcastMessageExists,
    expandNavigationonClick,
    navigateOption,
}: IModulesProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();
    const { navClosedOnLoad } = useSettings();
    const { routes } = useContext<IModuleInfoContextValue>(ModuleInfoContext);
    const groupedRoutes = groupBy(routes ?? [], 'moduleName');

    const ModuleItem: React.FC<{
        name: string;
        onClick: () => void;
        id: string;
    }> = ({ name, onClick }) => {
        const ModuleIcon = getModuleIcon(name as ModuleName);
        return (
            <Tooltip
                arrow={true}
                placement="right"
                title={name}
                classes={{
                    tooltip: classes.tooltip,
                    tooltipArrow: classes.tooltipArrow,
                    arrow: classes.arrow,
                }}
            >
                <ListItem
                    button
                    onClick={() => {
                        if (navigateOption === 'ON CLICK') {
                            onClick();
                        }
                    }}
                    onMouseEnter={() => {
                        if (navigateOption === 'ON HOVER') {
                            onClick();
                        }
                    }}
                    className={clsx(classes.link, {
                        active: moduleName === name,
                    })}
                >
                    <IconButton
                        id={moduleName}
                        type="button"
                        title={moduleName}
                        size="medium"
                        classes={{
                            root:
                                moduleName === name
                                    ? classes.moduleItemIconButtonSelected
                                    : classes.moduleItemIconButton,
                        }}
                    >
                        <ModuleIcon
                            selected={
                                moduleName === name
                                    ? 'white'
                                    : theme.palette.primary.main
                            }
                        />
                    </IconButton>
                </ListItem>
            </Tooltip>
        );
    };

    const classNames = () => {
        let className;
        if (expanded) {
            className = !broadcastMessageExists
                ? classes.expandedIcon
                : classes.expandedIconBroadcast;
        } else {
            className = !broadcastMessageExists
                ? classes.collapsedIcon
                : classes.collapsedIconBroadcast;
        }

        return className;
    };
    const activeDistrictName = storage.getItem('activeDistrict');
    const activeDistrict = JSON.parse(activeDistrictName ?? '{}');

    return (
        <>
            <IconButton
                color="primary"
                aria-label="module list expansion"
                id="module-list-expansion-icon"
                onClick={(e: any) => {
                    e.stopPropagation();
                    !navClosedOnLoad.includes(location.pathname) &&
                        shoudlExpand();
                }}
                type="button"
                className={classNames()}
            >
                {!expanded && (
                    <ChevronRightIcon
                        style={{
                            marginLeft: '3px',
                        }}
                    />
                )}
                {expanded && (
                    <ChevronLeftIcon
                        style={{
                            marginLeft: '-1px',
                        }}
                    />
                )}
            </IconButton>
            <div className={classes.districtLogo}>
                {activeDistrict?.name ? activeDistrict?.name?.charAt(0) : ''}
            </div>
            <nav
                className={classes.root}
                onMouseEnter={
                    navigateOption === 'ON HOVER' ||
                    navClosedOnLoad.includes(location.pathname)
                        ? onHoverExpand
                        : undefined
                }
                onClick={() => {
                    if (
                        navigateOption === 'ON CLICK' &&
                        !navClosedOnLoad.includes(location.pathname)
                    ) {
                        expandNavigationonClick();
                    }
                }}
            >
                {navList?.map((navItem: IPermissionModel) => {
                    if (
                        Object.keys(groupedRoutes)?.length > 0 &&
                        Object.keys(groupedRoutes).includes(navItem.name)
                    ) {
                        return (
                            <ModuleItem
                                key={navItem.id}
                                id={`${navItem.name}Module`}
                                name={navItem.name}
                                onClick={onNavigation.bind(null, navItem.name)}
                            />
                        );
                    }
                    return null;
                })}
            </nav>
        </>
    );
};

export default Modules;
