import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { NotificationWithActions } from '../Notifications';

import { IPushNotificationProps } from './types';

const PushNotification: React.FC<IPushNotificationProps> = ({ messages }) => {
    const [open, setOpen] = useState(true);
    const bannerSettings = JSON.parse(
        localStorage.getItem('bannerSettings') ?? '{}'
    );

    useEffect(() => {
        if (!isEmpty(messages)) {
            if (bannerSettings?.audioPing) {
                const audio = new Audio(
                    'https://perseusnonprodstorage.blob.core.windows.net/svgassets/ding.mp3'
                );
                audio.play();
            }

            setOpen(true); // Ensure the banner is open when new messages arrive
            const timer = setTimeout(() => {
                setOpen(false);
            }, bannerSettings.bannerDuration * 1000);

            return () => clearTimeout(timer); // Return a cleanup function
        }
        return undefined;
    }, [messages, bannerSettings?.audioPing, bannerSettings.bannerDuration]);

    if (!open || isEmpty(messages)) {
        return null;
    }

    return (
        <NotificationWithActions
            message={messages.join('\n')}
            open={open}
            positionDirecton={bannerSettings.bannerLocation}
            primaryAction={{
                callback: () => {
                    setOpen(false);
                },
                title: 'Okay',
            }}
            title="You have a new notification"
        />
    );
};

export default PushNotification;
