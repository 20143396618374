import React, { FC } from 'react';
import { LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

import { ITheme } from '../../core/Providers';
import { StandardProps } from '../../types/standardProps';

export interface ILoadingScreenProps extends StandardProps<HTMLDivElement> {
    /**
     * Open/close state of loading screen
     */
    loading?: boolean;
    /**
     * Display text
     */
    label?: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        padding: theme.spacing(2),
        width: '100%',
    },
    progress: {
        width: '50%',
        minWidth: '160px',
    },
    progressBar: {
        backgroundColor: theme.palette.text.primary,
    },
    progressBarColorSecondary: {
        backgroundColor: fade(theme.palette.text.primary, 0.4),
    },
    textCenter: {
        textAlign: 'center',
    },
}));

const LoadingScreen: FC<ILoadingScreenProps> = ({
    loading = true,
    label = 'Loading...',
    ...rest
}) => {
    const classes = useStyles();

    if (!loading) {
        return null;
    }

    return (
        <div className={classes.root} {...rest}>
            <div className={classes.progress}>
                <LinearProgress
                    aria-label="loading"
                    title="loading"
                    color="secondary"
                    classes={{
                        colorSecondary: classes.progressBarColorSecondary,
                        bar: classes.progressBar,
                    }}
                />
                <Typography
                    classes={{
                        root: classes.textCenter,
                    }}
                    variant="h6"
                    color="textSecondary"
                    component="p"
                >
                    {label}
                </Typography>
            </div>
        </div>
    );
};

export default LoadingScreen;
