import type { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        '@global': {
            '*': {
                margin: 0,
                padding: 0,
                boxSizing: 'border-box',
                '&::before': {
                    boxSizing: 'border-box',
                },
                '&::after': {
                    boxSizing: 'border-box',
                },
            },
            html: {
                '-webkit-font-smoothing': 'antialiased',
                '-moz-osx-font-smoothing': 'grayscale',
                height: '100%',
                width: '100%',
                fontSize: '16px',
            },
            body: {
                height: '100%',
                width: '100%',
                fontFamily: 'Roboto, "Helvetica Neue", Arial, sans-serif',
            },
            button: {
                fontFamily: 'inherit',
            },
            '#root': {
                height: '100%',
                width: '100%',
            },
            code: {
                display: 'block',
                fontSize: '0.875em',
                wordWrap: 'break-word',
            },
            pre: {
                whiteSpace: 'pre-wrap',
            },
        },
    })
);

const GlobalStyles: FC = () => {
    useStyles();

    return null;
};

export default GlobalStyles;
