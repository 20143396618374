import React, { FC, Fragment, useCallback, useState } from 'react';
import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { Pager, PagerProps } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
    extendDataItem,
    filterBy,
    mapTree,
    orderBy,
    TreeList,
    TreeListDataStateChangeEvent,
    TreeListExpandChangeEvent,
    TreeListItemChangeEvent,
    TreeListPageChangeEvent,
    TreeListRowClickEvent,
    TreeListRowProps,
    TreeListSelectionChangeEvent,
    TreeListToolbar,
    treeToFlat,
} from '@progress/kendo-react-treelist';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';

import { DownloadIcon, ITheme, SearchIcon } from '../../..';
import { Button } from '../../Button';

import ITreeListProps from './ITreeListProps';
import MyCommandCell from './MyCommandCell';
interface IAppState {
    data: any[];
    dataState: any;
    expanded: number[];
    skip: number;
    take: number;
    inEdit: any;
    selected: any[];
}
const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        grid: {
            borderRadius: 4,
            boxShadow: theme.shadows[1],
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            minHeight: 40,
            padding: '8px 16px',
            width: '100%',
            backgroundColor: theme.palette.background.light,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            '& > * + *:not(:last-child)': {
                marginRight: theme.spacing(1),
            },
        },
    })
);

const KTreeList: FC<ITreeListProps> = ({
    columns = [],
    data,
    subItemsField,
    expandField,
    onExpandChange,
    sort = [],
    expanded = [],
    filter = [],
    toolbarOptions,
    title,
    fileExport,
    pageSizes,
    pageSize,
    resizable = false,
    skip = 0,
    take = 8,
    total = 2,
    editable,
    onEdit,
    onSave,
    onCancel,
    editFieldColumnTitle = 'Actions',
    filterable = false,
    style,
    onSelectionChange,
    onHeaderSelectionChange,
    onRowClick,
    primaryField,
    onItemChange,
    onDataStateChange,
    onPageChange,
    editField = '',
}) => {
    const subItemsFieldName: string = subItemsField as string;
    const expandFieldName: string = expandField as string;
    const SELECTED_FIELD: string = 'selected';
    const classes = useStyles();

    const [state, setState] = React.useState<IAppState>({
        data: data ? [...data] : [],
        dataState: {
            sort: sort,
            filter: filter,
        },
        expanded: expanded,
        selected: [],
        skip: skip,
        take: take,
        inEdit: [],
    });
    const enterEdit = (dataItem: any) => {
        setState({
            ...state,
            inEdit: [
                ...state.inEdit,
                extendDataItem(dataItem, subItemsFieldName),
            ],
        });
        if (onEdit) {
            onEdit(dataItem);
        }
    };
    const save = (dataItem: any) => {
        const { inEdit, ...itemToSave } = dataItem;
        setState({
            ...state,
            data: mapTree(state.data, subItemsFieldName, (item) =>
                item[primaryField] === itemToSave[primaryField]
                    ? itemToSave
                    : item
            ),
            inEdit: state.inEdit.filter((item: any) => {
                return item[primaryField] !== itemToSave[primaryField];
            }),
        });
        if (onSave) {
            onSave(dataItem);
        }
    };

    const cancel = (editedItem: any) => {
        setState((prevState) => {
            const { inEdit, data } = prevState;
            return {
                ...prevState,
                data: mapTree(data, subItemsFieldName, (item) =>
                    item.id === editedItem.id
                        ? inEdit.find(
                              (i: any) => i[primaryField] === item[primaryField]
                          )
                        : item
                ),
                inEdit: inEdit.filter((i: any) => i.id !== editedItem.id),
            };
        });
        if (onCancel) {
            onCancel(editedItem);
        }
    };
    const CommandCell: any = MyCommandCell(enterEdit, save, cancel, editField);
    const [editableState] = useState(editable);
    const [hideColumns, setHideColumns] = React.useState(false);
    const toggleColumnSearch = () => {
        setHideColumns((prevState) => !prevState);
    };
    /**
     * Format columns
     */
    const formatColumns = useCallback(
        (columns: any[]) => {
            let newColumns: any[] = cloneDeep(columns);
            if (editableState) {
                newColumns = [
                    ...newColumns,
                    {
                        cell: CommandCell,
                        title: editFieldColumnTitle,
                    },
                ];
            }
            if (hideColumns) {
                newColumns = newColumns.map((column) => {
                    return {
                        ...column,
                        filterCell: null,
                    };
                });
            }
            return newColumns;
        },
        [CommandCell, editFieldColumnTitle, editableState, hideColumns]
    );

    React.useEffect(() => {
        setFormattedColumns(formatColumns(columns));
    }, [hideColumns]);

    const [formattedColumns, setFormattedColumns] = useState<any[]>(
        formatColumns(columns)
    );

    const onItemChangeEvent = (event: TreeListItemChangeEvent) => {
        const field: any = event.field;
        setState({
            ...state,
            data: mapTree(state.data, subItemsFieldName, (item) =>
                item[primaryField] === event.dataItem[primaryField]
                    ? extendDataItem(item, subItemsFieldName, {
                          [field]: event.value,
                      })
                    : item
            ),
        });
        if (onItemChange) {
            onItemChange(event);
        }
    };
    const handleDataStateChangeEvent = (
        event: TreeListDataStateChangeEvent
    ) => {
        setState({
            ...state,
            dataState: event.dataState,
        });
        if (onDataStateChange) {
            onDataStateChange(event);
        }
    };
    const table = React.useRef();
    const addExpandField = (dataTree: any) => {
        const expanded: number[] = state.expanded;
        return mapTree(dataTree, subItemsFieldName, (item) =>
            extendDataItem(item, subItemsFieldName, {
                [expandFieldName]: expanded.includes(item[primaryField]),
                selected: state.selected.includes(item[primaryField]),
                [editField]: Boolean(
                    state.inEdit.find(
                        (i: any) => i[primaryField] === item[primaryField]
                    )
                ),
            })
        );
    };

    const onExpandChangeEV = (e: TreeListExpandChangeEvent) => {
        setState({
            ...state,
            expanded: e.value
                ? state.expanded.filter((id) => id !== e.dataItem[primaryField])
                : [...state.expanded, e.dataItem[primaryField]],
        });
        if (onExpandChange) {
            onExpandChange(e);
        }
    };

    const onPageChangeEvent = (event: TreeListPageChangeEvent) => {
        const { skip, take } = event;
        setState({
            ...state,
            skip,
            take,
        });
        if (onPageChange) {
            onPageChange(event);
        }
    };

    const onSelectionChangeEvent = (event: TreeListSelectionChangeEvent) => {
        const selected = event.dataItem.selected
            ? state.selected.filter((x) => x !== event.dataItem[primaryField])
            : [...state.selected, event.dataItem[primaryField]];
        if (onSelectionChange) {
            onSelectionChange(selected);
        }
        setState({
            ...state,
            selected,
        });
    };

    const rowClick = (event: TreeListRowClickEvent) => {
        if (onRowClick) {
            onRowClick(event);
        }
    };
    const onHeaderSelectionChangeEvent = (event: any) => {
        const checked = event.syntheticEvent.target.checked;
        const selected: any[] = [];
        if (checked) {
            mapTree(state.data, subItemsFieldName, (item) => {
                selected.push(item[primaryField]);
                return item;
            });
        }
        setState({
            ...state,
            selected,
        });
        if (onHeaderSelectionChange) {
            onHeaderSelectionChange(event);
        }
    };

    const processData = () => {
        let { data, dataState } = state;
        let filteredData: any[] = filterBy(
            data,
            dataState.filter,
            subItemsFieldName
        );
        let sortedData: any[] = orderBy(
            filteredData,
            dataState.sort,
            subItemsFieldName
        );
        return addExpandField(sortedData);
    };
    const onRowRender = (
        trElement: React.ReactElement<HTMLTableRowElement>,
        props: TreeListRowProps
    ) => {
        const bgColor = {
            backgroundColor: props.dataItem['expanded'] ? '#F1F0FB' : '',
        };
        const trProps: any = {
            style: bgColor,
            className: props.dataItem['expanded'] ? 'k-tree-list-expanded' : '',
        };
        return React.cloneElement(
            trElement,
            {
                ...trProps,
            },
            trElement.props.children
        );
    };
    let _export: ExcelExport | null;
    const exportToExcel = () => {
        if (_export != null) {
            _export.save(
                treeToFlat(processData(), expandFieldName, subItemsFieldName),
                formattedColumns
            );
        }
    };

    const ToolbarOptions = toolbarOptions || Fragment;
    const TreeListPager = (props: PagerProps) => {
        return (
            <Pager
                {...props}
                previousNext={true}
                buttonCount={2}
                pageSizes={pageSizes}
                total={total}
            />
        );
    };

    return (
        <ExcelExport ref={(exporter) => (_export = exporter)} hierarchy={true}>
            <TreeList
                style={style}
                selectedField={SELECTED_FIELD}
                onSelectionChange={onSelectionChangeEvent}
                onHeaderSelectionChange={onHeaderSelectionChangeEvent}
                editField={editField}
                expandField={expandFieldName}
                subItemsField={subItemsField}
                onExpandChange={onExpandChangeEV}
                sortable={{
                    mode: 'multiple',
                }}
                ref={table}
                tableProps={{
                    ref: table,
                }}
                resizable={resizable}
                {...state.dataState}
                data={processData()}
                onDataStateChange={(ev) => handleDataStateChangeEvent(ev)}
                columns={formattedColumns}
                headre
                onPageChange={onPageChangeEvent}
                pager={TreeListPager}
                pageSize={pageSize}
                skip={state.skip}
                take={state.take}
                rowRender={onRowRender}
                onRowClick={rowClick}
                dataItemKey={primaryField}
                onItemChange={onItemChangeEvent}
                toolbar={
                    <TreeListToolbar>
                        <Box
                            className={clsx(
                                'k-grid-toolbar-content',
                                classes.toolbar
                            )}
                        >
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                style={{
                                    justifyContent: 'space-between',
                                }}
                                alignItems="center"
                            >
                                <Grid item>
                                    {title && (
                                        <Typography
                                            variant="h5"
                                            component="p"
                                            color="textPrimary"
                                            align="center"
                                        >
                                            {title}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        style={{
                                            justifyContent: 'flex-end',
                                        }}
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Grid item>
                                            <ToolbarOptions />
                                        </Grid>
                                        {fileExport?.excel && (
                                            <Grid item>
                                                <Button
                                                    id="excelExport"
                                                    label="Excel"
                                                    onClick={exportToExcel}
                                                    dense
                                                    startIcon={
                                                        <DownloadIcon
                                                            size={16}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        )}
                                        {filterable && (
                                            <Grid item>
                                                <Button
                                                    label="columnSearch"
                                                    id="columnSearch"
                                                    onClick={toggleColumnSearch}
                                                    dense
                                                    startIcon={
                                                        <SearchIcon size={16} />
                                                    }
                                                >
                                                    Field Search
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </TreeListToolbar>
                }
            ></TreeList>
        </ExcelExport>
    );
};
export default KTreeList;
