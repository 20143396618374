import { ITourStepsFromAPI } from '../../components/Tour';
import { componentsAPIInstance } from '../../utils/api/APIInstance';

export const fetchAllTours = async function (
    url: string
): Promise<ITourStepsFromAPI[] | null> {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'GET',
            url: `${url}/administration/v1.0/api/ShepherdTour/AllShepherdTourAsync`,
        });
        if (response.data.StatusCode === 200) {
            return response.data.PayLoad;
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
};

export const saveTours = async function ({
    url,
    userId,
    toursData,
    refetchCallback,
}: {
    url: string;
    userId: string;
    toursData: any;
    logger?: Function;
    refetchCallback?: Function;
}) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'POST',
            url: `${url}/administration/v1.0/api/User/${userId}`,
            data: { shepherdTour: JSON.stringify(toursData) },
        });
        if (response.status === 200) {
            if (refetchCallback) {
                await refetchTours({
                    url,
                    userId,
                    callback: refetchCallback,
                });
            }
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log('error in saving tours', error);
        return -1;
    }
};

export const refetchTours = async function ({
    url,
    userId,
    callback,
}: {
    url: string;
    userId: string;
    callback: Function;
    logger?: Function;
}) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'GET',
            url: `${url}/administration/v1.0/api/User/${userId}`,
        });
        callback?.(
            response.data.PayLoad.shepherdTour?.length > 0
                ? JSON.parse(response.data.PayLoad.shepherdTour)
                : null
        );
        return null;
    } catch (error) {
        console.log('error in saving tours', error);
        return -1;
    }
};

export const createTaskForFinishedTour = async function ({
    url,
    title,
    description,
    dueDate,
}: {
    url: string;
    description: string;
    dueDate: string;
    title: string;
}) {
    try {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = description;
        // Find the img tag
        const divElements = tempElement.querySelectorAll(
            '#content-with-image div'
        );
        // Check if img tag exists
        const textContent = Array.from(divElements)
            .map((div) => div.textContent)
            .join('\n');

        const response = await componentsAPIInstance.axios({
            method: 'POST',
            url: `${url}/workspace/v1.0/api/create-task`,
            data: {
                title,
                description: textContent ? textContent : description,
                dueDate,
                status: 'Done',
            },
        });
        return response.data.statusCode;
    } catch (e) {
        return null;
    }
};
