import { enumValues } from '../types';

export enum ModuleName {
    Workspace = 'Workspace',
    Inspections = 'Inspections',
    Accountability = 'Accountability',
    Eligibility = 'Eligibility',
    Inventory = 'Inventory',
    ItemManagement = 'Item Management',
    AccountManagement = 'Account Management',
    System = 'System',
    MenuPlanning = 'Menu Planning',
    FoodDistribution = 'Food Distribution',
    Reviews = 'Reviews',
    Platform = 'Platform',
    Production = 'Production',
    Insights = 'Insights',
    Reports = 'Reports',
}

export type ModuleNameType = keyof typeof ModuleName;

export const getModuleName = (name: ModuleNameType): ModuleName => {
    return ModuleName[name] ?? name;
};

export const isModuleName = (name: any): boolean => {
    return enumValues(ModuleName).includes(name);
};
