import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ClickAwayListener, IconButton, makeStyles } from '@material-ui/core';
import { delay, isEmpty, isNull } from 'lodash';

import { HelpCircleIcon } from '../../assets/icons';
import ModuleInfoContext, {
    IModuleInfoContextValue,
} from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import { getSystemSetting } from '../../utils/api/APIUtils';

import { getWidgetScript } from './helpScripts';

const scriptType = 'text/javascript';

const helpButtonStyles = makeStyles(() => ({
    standAlone: {
        height: 20,
        width: 20,
        padding: 0,
    },
}));

export default function HelpDocumentation({
    isStandAlone = false,
}: {
    isStandAlone?: boolean;
}) {
    const classes = helpButtonStyles();
    const [canLaunchHelpWidget, setCanLaunchHelpWidget] = useState<boolean>(
        false
    );
    const [freshWorkScriptSrc, setFreshWorkScriptSrc] = useState<string>('');
    const [embedWidget, setEmbedWidget] = useState<any>();
    const { moduleName, apimUrl } = useContext<IModuleInfoContextValue>(
        ModuleInfoContext
    );

    const checkIfHelpWidgetIsEnabled = function () {
        getSystemSetting('HELPWIDGET', apimUrl)
            .then((response) => {
                if (response) {
                    setCanLaunchHelpWidget(
                        response.data?.PayLoad === '1' || false
                    );
                }
            })
            .catch(() => {
                console.log('Error getting helpwidget setting');
            });
    };

    useEffect(
        function () {
            if (moduleName?.length > 0) {
                const scripts = getWidgetScript(moduleName);
                if (!isNull(scripts)) {
                    setFreshWorkScriptSrc(scripts.script);
                    setEmbedWidget(scripts.widget);

                    delay(function () {
                        (window as any)?.FreshworksWidget?.('hide');
                    }, 850);
                }
            }
        },
        [moduleName]
    );

    useEffect(function () {
        checkIfHelpWidgetIsEnabled();
    }, []);

    return (
        <>
            <ClickAwayListener
                onClickAway={function () {
                    (window as any)?.FreshworksWidget?.('hide');
                }}
            >
                <IconButton
                    id="help-widget-button"
                    classes={{
                        root: isStandAlone ? classes.standAlone : '',
                    }}
                    onClick={() => {
                        if (canLaunchHelpWidget) {
                            (window as any)?.FreshworksWidget?.('open');
                            delay(function () {
                                (document.getElementById(
                                    'launcher-frame'
                                ) as any).contentDocument.querySelector(
                                    'div.frame-content'
                                ).style = 'visibility: hidden';
                            }, 800);
                        }
                    }}
                    aria-label="USDA Documentation"
                >
                    <HelpCircleIcon size={'30'} color={'white'} />
                </IconButton>
            </ClickAwayListener>
            {!isEmpty(freshWorkScriptSrc) && (
                <Helmet
                    async
                    defer
                    script={[
                        {
                            src: freshWorkScriptSrc,
                            type: scriptType,
                        },
                    ]}
                ></Helmet>
            )}
            {!isEmpty(embedWidget) && (
                <Helmet
                    script={[
                        {
                            type: scriptType,
                            innerHTML: embedWidget,
                        },
                    ]}
                ></Helmet>
            )}
        </>
    );
}
