import React from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { ModuleIcon, SearchIcon } from '../../assets';

import useTopBarPageSearch from './hooks/useTopBarPageSearch';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#5644C3',
        '& svg': {
            color: 'white',
        },
    },
    groupUl: {
        paddingLeft: '6px',
        marginBottom: '1px solid #e4e4e4',
    },
    groupLabel: {
        fontSize: '16px',
        fontWeight: 'bolder',
        color: '#263238',
    },
    option: {
        fontSize: '14px',
        fontWeight: 'normal',
        marginLeft: '10px',
        color: '#000',
    },
    inputRoot: {
        padding: '0px !important',
        '& .MuiOutlinedInput-root': {
            paddingLeft: '8px',
        },
    },
}));

const TopBarPageSearch = function ({
    isStandAlone = false,
}: {
    isStandAlone?: boolean;
}) {
    const { root, groupLabel, groupUl, option, inputRoot } = useStyles();

    const {
        data,
        input,
        selectedPage,
        onSearchChange,
        onSelect,
    } = useTopBarPageSearch();

    return (
        <Autocomplete
            key={`${JSON.stringify(selectedPage)}`}
            id={'topbar-page-search'}
            options={data}
            autoHighlight
            groupBy={(option) => option.moduleName}
            getOptionLabel={(option) => option.routeName}
            onChange={onSelect as any}
            noOptionsText={'No results found'}
            classes={{ groupLabel, groupUl, option, inputRoot }}
            defaultValue={selectedPage as any}
            onInputChange={function (
                event: any,
                newInputValue: any,
                reason: any
            ) {
                if (reason.toLowerCase().includes('clear')) {
                    onSearchChange({
                        target: { value: '' },
                    } as React.ChangeEvent<HTMLInputElement>);
                    return newInputValue;
                } else {
                    event?.focus?.();
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={isStandAlone ? 'Modules' : ''}
                    placeholder={
                        isStandAlone
                            ? 'Jump to any module or page'
                            : 'Quick Search'
                    }
                    value={input}
                    classes={{ root: !isStandAlone ? root : '' }}
                    style={{ color: 'white' }}
                    onChange={onSearchChange}
                    variant="outlined"
                    size="small"
                    InputProps={{
                        ...params.InputProps,
                        style: { color: isStandAlone ? '#5644C3' : 'white' },
                        startAdornment: (
                            <React.Fragment>
                                {params.InputProps.startAdornment}
                                <div
                                    style={{
                                        padding: '7px',
                                        color: 'white',
                                        display: 'flex',
                                    }}
                                >
                                    {isStandAlone ? (
                                        <ModuleIcon color="#5644C3" />
                                    ) : (
                                        <SearchIcon
                                            color={
                                                isStandAlone
                                                    ? '#5644C3'
                                                    : 'white'
                                            }
                                        />
                                    )}
                                </div>
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default TopBarPageSearch;
