import React, { useContext, useState } from 'react';
import { IconButton, SvgIcon, Tooltip } from '@material-ui/core';

import {
    CheckCircleFilledIcon,
    Edit2Icon,
    Trash2Icon,
    XIcon,
} from '../../../assets';
import { useProfile } from '../../../core/Providers';
import ModuleInfoContext from '../../../core/Providers/ModuleInfo/ModuleInfoContext';
import { storage } from '../../../core/Store';
import { NotificationWithDialog } from '../../Notifications';

import { deleteFavoriteAsync, updateFavoriteAsync } from './FavoritesAPI';

type FavoriteActionsProps = {
    apimUrl: any;
    favoriteId: number;
    favoriteName: any;
    editedName: string;
    setErrorAlertMessage: (message: string) => void;
    setSuccessAlertMessage: (message: string) => void;
    handleClose: () => void;
    setEditingIndex?: any;
    setEditedName?: any;
    favoriteURL?: any;
};

export const FavoriteActions: React.FC<FavoriteActionsProps> = ({
    apimUrl,
    favoriteId,
    favoriteName,
    editedName,
    setErrorAlertMessage,
    setSuccessAlertMessage,
    handleClose,
    setEditingIndex,
    setEditedName,
    favoriteURL,
}) => {
    const [openDialogBox, setOpenDialogBox] = useState(false);
    const { user } = useProfile();
    const activeDistrict = storage.getItem('activeDistrict');
    const activeDistrictParsed = JSON.parse(activeDistrict ?? '{}');
    const regionId = activeDistrictParsed?.attributes?.regionId?.[0];
    const [isrowEditable, setIsRowEditable] = useState(false);
    const moduleContext = useContext(ModuleInfoContext);

    const handleDelete = () => {
        setOpenDialogBox(true);
    };
    const handleEdit = (favoriteId: number, favoriteName: any) => {
        setIsRowEditable(true);
        setEditingIndex(favoriteId);
        setEditedName(favoriteName);
    };

    async function handleRemoveFavorite(favoriteId: number) {
        try {
            await deleteFavoriteAsync(apimUrl, favoriteId).then(
                (response: any) => {
                    if (response.data.StatusCode === 200) {
                        handleClose();
                        setSuccessAlertMessage('Successfully Deleted!');
                    }
                }
            );
        } catch (e) {
            console.log(e);
            setErrorAlertMessage('Error in deleting!');
            handleClose();
        }
    }

    async function updateFavoriteName(
        favoriteId: number,
        editedName: string,
        favoriteURL: any
    ) {
        try {
            const payload = {
                id: favoriteId,
                userId: Number(user?.UserId),
                moduleName: moduleContext?.moduleName.toString(),
                regionId: Number(regionId),
                favoriteName: editedName,
                url: favoriteURL,
                displayorder: 0,
                createdBy: null,
                createdDate: null,
                modifiedBy: user?.UserId,
                modifiedDate: new Date(),
                isDeleted: false,
            };

            await updateFavoriteAsync(apimUrl, payload).then(
                (response: any) => {
                    if (response.data.StatusCode === 200) {
                        handleClose();
                        setSuccessAlertMessage('Successfully Done');
                    }
                    setEditingIndex(0);
                }
            );
        } catch (e) {
            console.log(e);
            setErrorAlertMessage('Error in updating!');
            handleClose();
        }
    }

    const closeRemoveFavorite = () => {
        setOpenDialogBox(false);
    };

    const handleClickCrossIcon = () => {
        setEditingIndex(0);
        setIsRowEditable(false);
    };
    return (
        <>
            <NotificationWithDialog
                open={openDialogBox}
                onClose={() => {
                    setOpenDialogBox(false);
                }}
                title="Remove File"
                message="Are you sure you want to remove this page from your favorites?"
                primaryAction={{
                    title: 'yes',
                    callback: () => handleRemoveFavorite(favoriteId),
                }}
                secondaryAction={{
                    title: 'No',
                    callback: closeRemoveFavorite,
                }}
                color="primary"
            />

            {isrowEditable ? (
                <>
                    <IconButton
                        type="button"
                        name="editFavorite"
                        color="primary"
                        id="editFavoriteName"
                        onClick={() =>
                            updateFavoriteName(
                                favoriteId,
                                editedName,
                                favoriteURL
                            )
                        }
                        style={{ padding: '7px' }}
                    >
                        <CheckCircleFilledIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="Edit"
                        onClick={handleClickCrossIcon}
                        style={{ padding: '7px' }}
                    >
                        <SvgIcon>
                            <XIcon />
                        </SvgIcon>
                    </IconButton>
                </>
            ) : (
                <Tooltip title="Edit">
                    <IconButton
                        color="inherit"
                        aria-label="Edit"
                        onClick={() => handleEdit(favoriteId, favoriteName)}
                    >
                        <SvgIcon>
                            <Edit2Icon />
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            )}

            <Tooltip title="Delete">
                <IconButton
                    color="inherit"
                    aria-label="Delete"
                    onClick={handleDelete}
                >
                    <SvgIcon>
                        <Trash2Icon />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </>
    );
};
