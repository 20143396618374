import React, { FC } from 'react';
import { IconButton, Link, Tooltip } from '@material-ui/core';

import { InfoIcon } from '../../../assets/icons';

import { IGridHeaderCellProps } from './types';

export const HeaderCell: FC<IGridHeaderCellProps> = ({
    children,
    title,
    tooltip,
    onClick,
}) => {
    const TooltipComp = () => {
        if (!tooltip) {
            return null;
        }
        return (
            <Tooltip title={tooltip}>
                <IconButton size="small" aria-label={tooltip}>
                    <InfoIcon size={20} />
                </IconButton>
            </Tooltip>
        );
    };

    return (
        <>
            {Boolean(onClick) ? (
                <Link className="k-link" href="/#" onClick={onClick}>
                    <span title={title}>{title}</span>
                    <TooltipComp />
                    {children}
                </Link>
            ) : (
                <>
                    <span title={title}>{title}</span>
                    <TooltipComp />
                    {children}
                </>
            )}
        </>
    );
};

export default HeaderCell;
