import React from 'react';
import { LinearProgress, makeStyles, withStyles } from '@material-ui/core';
const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        width: '100%',
    },
    container: {
        display: 'flex',
        lineHeight: '12px',
        justifyContent: 'center',
        fontWeight: 500,
    },
    progressBar: {
        width: '100%',
        marginLeft: 8,
        marginRight: 8,
    },
    span: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 5,
    },
});

export interface ITaskProgressBarProps {
    id: string;
    stepValue: number;
    totalSteps: number;
}

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
    },
}))(LinearProgress);

const TaskProgressBar = ({
    id,
    stepValue,
    totalSteps,
}: ITaskProgressBarProps) => {
    const classes = useStyles();
    const percentage = () => {
        if (stepValue <= totalSteps) return (100 * stepValue) / totalSteps;
        else return 100;
    };
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                {stepValue}{' '}
                <BorderLinearProgress
                    id={id}
                    variant="determinate"
                    value={percentage()}
                    className={classes.progressBar}
                />{' '}
                {totalSteps}
            </div>
            <span className={classes.span}>
                {stepValue} out of {totalSteps} tasks completed
            </span>
        </div>
    );
};

export default TaskProgressBar;
