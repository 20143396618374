import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import {
    Box,
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import { UploadDocIcon } from '../../../assets/icons';
import { API_DATA_EXCHANGE } from '../../../core/api-constants';
import { ITheme } from '../../../core/Providers';
import { ResponseStatus } from '../../../types/response';
import { componentsAPIInstance, sizeToBytes } from '../../../utils';
import bytesToSize from '../../../utils/file/bytesToSize';
import Button from '../../Button/Button';
import { NotificationWithDialog } from '../../Notifications';

import FilePreview from './FilePreview';
import ImagePreview from './ImagePreview';
import { FileErrorCode, IFileDropzoneProps, IFileProps } from './types';

const useStyles = makeStyles((theme: ITheme) => ({
    contained: {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
    },
    transparent: {},
    dropzoneContainer: {
        margin: '12px',
    },
    dropzone: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        outline: 'none',
        '&:hover': {
            opacity: 0.64,
            cursor: 'pointer',
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus': {
            opacity: 0.64,
            backgroundColor: theme.palette.action.hover,
        },
    },
    disableDropzone: {
        cursor: 'not-allowed',
        pointerEvents: 'none',
        userSelect: 'none',
        opacity: theme.palette.action.disabledOpacity,
    },
    dragActive: {
        backgroundColor: theme.palette.action.active,
        opacity: 0.64,
    },
    content: {
        flex: 1,
        padding: theme.spacing(2),
    },
    info: {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        borderTop: `1px solid ${theme.palette.divider}`,
        '& > * + *': {
            marginRight: theme.spacing(2),
        },
    },
}));

const FileDropzone: FC<IFileDropzoneProps> = ({
    className,
    files,
    minSize,
    maxSize,
    maxFiles = 20,
    downloadFiles = true,
    removeFiles = true,
    disabled,
    acceptedFileTypes,
    uniqueFileNames,
    onFilesChange,
    variant = 'contained',
    preview = 'file',
    previewPlacement = 'auto',
    title,
    upload,
    infoPlacement = 'bottom',
    ...rest
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

    const [stateFiles, setStateFiles] = useState<IFileProps[]>([]);
    const [maxSizeBytes, setMaxSizeBytes] = useState<number | undefined>(
        undefined
    );
    const [disableDropzone, setDisableDropzone] = useState<boolean>();
    const [remainingFilesLimit, setRemainingFilesLimit] = useState<number>(0);
    const [hasReachedLimit, setHasReachedLimit] = useState<boolean>(false);
    const [hasFileError, setHasFileError] = useState<string | null>(null);
    const [hasDuplicateFiles, setHasDuplicateFiles] = useState<boolean>(false);
    const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
    const [removeFileIndex, setRemoveFileIndex] = useState<number | null>(null);
    const [hasRemoveAll, setHasRemoveAll] = useState<boolean>(false);

    useEffect(() => {
        if (files) {
            setStateFiles(files);
            if (!Array.isArray(files)) {
                console.error('Invalid files', files);
            }
        } else {
            setStateFiles([]);
        }
    }, [files]);

    useEffect(() => {
        if (disabled) {
            setDisableDropzone(true);
        } else {
            setRemainingFilesLimit(maxFiles - stateFiles.length);
            // Disable dropzone when limit is reached
            setDisableDropzone(stateFiles?.length >= maxFiles);
        }
    }, [disabled, maxFiles, stateFiles]);

    useEffect(() => {
        if (typeof maxSize === 'number') {
            setMaxSizeBytes(maxSize);
        } else if (maxSize) {
            setMaxSizeBytes(sizeToBytes(maxSize) ?? undefined);
        }
    }, [maxSize]);

    const handleFileUpload = useCallback(async () => {
        if (!upload?.url) {
            return;
        }
        const pendingFiles: IFileProps[] = [];

        // Update file status to loading
        setStateFiles((prevState) => {
            let updatedFiles = prevState.map((prevFile) => {
                if (prevFile.status === ResponseStatus.Initial) {
                    pendingFiles.push(prevFile);
                    return Object.assign(prevFile, {
                        status: ResponseStatus.Pending,
                    });
                }
                return prevFile;
            });

            // Emit files change
            if (onFilesChange) {
                setTimeout(() =>
                    onFilesChange({
                        files: updatedFiles,
                    })
                );
            }

            return updatedFiles;
        });

        try {
            const formData = new FormData();
            pendingFiles.forEach((file) => formData.append('file', file));

            const response = await componentsAPIInstance.axios({
                method: upload.method || 'POST',
                url:
                    upload.url || `${API_DATA_EXCHANGE}/api/FileStorage/Upload`,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...upload.headers,
                },
            });

            // Update file status to success
            setStateFiles((prevState) => {
                const updatedFiles = prevState.map((prevFile) => {
                    if (
                        pendingFiles?.find(
                            (file) => file.id && file.id === prevFile.id
                        )
                    ) {
                        return Object.assign(prevFile, {
                            status: ResponseStatus.Success,
                        });
                    }
                    return prevFile;
                });

                // Emit files change
                if (onFilesChange) {
                    setTimeout(() =>
                        onFilesChange({
                            files: updatedFiles,
                        })
                    );
                }

                return updatedFiles;
            });

            // Emit output
            if (upload.onSuccess) {
                upload.onSuccess(response?.data);
            }
        } catch (err) {
            // Update file status to error
            setStateFiles((prevState) => {
                const updatedFiles = prevState.map((prevFile) => {
                    if (
                        pendingFiles?.find(
                            (file) => file.id && file.id === prevFile.id
                        )
                    ) {
                        return Object.assign(prevFile, {
                            status: ResponseStatus.Error,
                        });
                    }
                    return prevFile;
                });

                // Emit files change
                if (onFilesChange) {
                    setTimeout(() =>
                        onFilesChange({
                            files: updatedFiles,
                        })
                    );
                }

                return updatedFiles;
            });

            // Emit output
            if (upload.onError) {
                upload.onError(err);
            }
        }
    }, [upload, onFilesChange]);

    const handleDrop = useCallback(
        (acceptedFiles: File[]) => {
            // Check for duplicate file names
            let containsDuplicates = false;
            if (uniqueFileNames === true) {
                acceptedFiles.forEach((acceptedFile) => {
                    stateFiles.forEach((stateFile) => {
                        if (
                            stateFile.name &&
                            acceptedFile.name &&
                            stateFile.name?.trim()?.toLowerCase() ===
                                acceptedFile.name?.trim()?.toLowerCase()
                        ) {
                            containsDuplicates = true;
                        }
                    });
                });
            }

            if (containsDuplicates) {
                setHasDuplicateFiles(true);
            } else if (acceptedFiles?.length > remainingFilesLimit) {
                setHasReachedLimit(true);
            } else {
                // ToDo: Check duplicateFileNames
                const currentFiles = stateFiles;
                const newFiles =
                    acceptedFiles?.map((file) =>
                        Object.assign(file, {
                            id: uuidv4(),
                            bytes: file.size,
                            status: ResponseStatus.Initial,
                        })
                    ) ?? [];
                setStateFiles((prevFiles) => {
                    return [...prevFiles, ...newFiles];
                });
                // Emit file change
                if (onFilesChange) {
                    onFilesChange({
                        add: newFiles,
                        files: [...currentFiles, ...newFiles],
                    });
                }

                handleFileUpload();
            }
        },
        [
            remainingFilesLimit,
            stateFiles,
            onFilesChange,
            uniqueFileNames,
            handleFileUpload,
        ]
    );

    const handleDropRejected = useCallback(
        (fileRejections: FileRejection[]) => {
            if (fileRejections?.length > remainingFilesLimit) {
                setHasReachedLimit(true);
            } else if (fileRejections?.length) {
                const errorObj = fileRejections[0].errors[0];
                if (errorObj) {
                    if (
                        maxSizeBytes &&
                        errorObj.code === FileErrorCode.FileTooLarge
                    ) {
                        setHasFileError(
                            `File is larger than ${bytesToSize(maxSizeBytes)}`
                        );
                    } else {
                        setHasFileError(errorObj.message);
                    }
                }
            }
        },
        [remainingFilesLimit, maxSizeBytes]
    );

    const handleRemove = (id: number) => {
        setRemoveFileIndex(id);
    };

    const handleRemoveFileConfirm = () => {
        if (!!removeFileIndex || removeFileIndex === 0) {
            setStateFiles((prevState) => {
                const removedFile = prevState[removeFileIndex];
                const filteredFiles = [
                    ...prevState.slice(0, removeFileIndex),
                    ...prevState.slice(removeFileIndex + 1),
                ];
                // Emit file change
                if (onFilesChange) {
                    onFilesChange({
                        files: filteredFiles,
                        remove: [removedFile],
                    });
                }
                return filteredFiles;
            });
            setRemoveFileIndex(null);
        }
    };

    const handleRemoveAll = () => {
        if (stateFiles.some((file) => file.status === ResponseStatus.Pending)) {
            setIsFileUploading(true);
        } else {
            setHasRemoveAll(true);
        }
    };

    const handleRemoveAllConfirm = () => {
        const currentFiles = files;
        setStateFiles([]);
        setHasRemoveAll(false);
        // Emit file change
        if (onFilesChange) {
            onFilesChange({
                files: [],
                remove: currentFiles,
            });
        }
    };

    const closeLimitNotification = () => setHasReachedLimit(false);

    const closeFileErrorNotification = () => setHasFileError(null);

    const closeDuplicateFilesNotification = () => setHasDuplicateFiles(false);

    const closeUploadingFileNotification = () => setHasReachedLimit(false);

    const closeRemoveFileNotification = () => setRemoveFileIndex(null);

    const closeRemoveAllNotification = () => setHasRemoveAll(false);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        minSize: minSize,
        maxSize: maxSizeBytes,
        maxFiles: maxFiles,
        disabled: disableDropzone,
        accept: acceptedFileTypes,
        onDropRejected: handleDropRejected,
    });

    return (
        <div className={clsx(classes[variant], className)} {...rest}>
            <Box
                display="flex"
                flexDirection={
                    previewPlacement === 'bottom' ||
                    (isExtraSmall && previewPlacement === 'auto')
                        ? 'column'
                        : 'row'
                }
            >
                <Box flex={1} className={classes.dropzoneContainer}>
                    <div
                        className={clsx({
                            [classes.dropzone]: true,
                            [classes.dragActive]: isDragActive,
                            [classes.disableDropzone]: disableDropzone,
                        })}
                        {...getRootProps()}
                        aria-disabled={disableDropzone}
                    >
                        <input {...getInputProps()} />
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            className={classes.content}
                        >
                            <UploadDocIcon size={150} color="#9E9E9E" />
                            <Box mt={2} mb={1}>
                                {title}
                                {!title && (
                                    <>
                                        <Typography
                                            color="textPrimary"
                                            variant="h5"
                                            component="p"
                                            align="center"
                                            gutterBottom={true}
                                        >
                                            Drag & Drop
                                            {maxFiles === 1
                                                ? ' File '
                                                : ' Files '}
                                            Here
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                            component="p"
                                            align="center"
                                        >
                                            OR
                                        </Typography>
                                    </>
                                )}
                            </Box>
                            <Button
                                id="selectFiles"
                                color="secondary"
                                dense
                                tabIndex={-1}
                                disabled={disableDropzone}
                            >
                                {`Select ${maxFiles === 1 ? 'File' : 'Files'}`}
                            </Button>
                        </Box>
                        {preview === 'none' && stateFiles?.length > 0 && (
                            <Typography
                                color="textPrimary"
                                variant="h5"
                                component="p"
                                align="center"
                                gutterBottom={true}
                            >
                                {stateFiles.length}
                                {stateFiles.length === 1 ? ' File ' : ' Files '}
                                Selected
                            </Typography>
                        )}
                        {(maxSizeBytes || acceptedFileTypes?.length) &&
                            infoPlacement === 'bottom' && (
                                <Box className={classes.info}>
                                    <Box>
                                        {acceptedFileTypes?.length &&
                                            acceptedFileTypes.length > 0 && (
                                                <Typography
                                                    variant="body2"
                                                    component="p"
                                                    color="textSecondary"
                                                >
                                                    Accepted file types:{' '}
                                                    {acceptedFileTypes?.join(
                                                        ', '
                                                    )}{' '}
                                                </Typography>
                                            )}
                                    </Box>
                                    <Box>
                                        {maxSizeBytes && (
                                            <Typography
                                                variant="body2"
                                                component="p"
                                                color="textSecondary"
                                            >
                                                Max file size:{' '}
                                                {bytesToSize(maxSizeBytes)}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            )}
                    </div>
                </Box>
                {(preview === 'file' || preview === 'image') &&
                    stateFiles?.length > 0 && (
                        <Box
                            flex={1}
                            width={
                                previewPlacement === 'bottom' ||
                                (isExtraSmall && previewPlacement === 'auto')
                                    ? 'auto'
                                    : '0'
                            }
                        >
                            {preview === 'file' && (
                                <FilePreview
                                    files={stateFiles}
                                    downloadFiles={downloadFiles}
                                    removeFiles={removeFiles}
                                    onRemove={handleRemove}
                                    onRemoveAll={handleRemoveAll}
                                />
                            )}
                            {preview === 'image' && (
                                <ImagePreview
                                    files={stateFiles}
                                    downloadFiles={downloadFiles}
                                    removeFiles={removeFiles}
                                    onRemove={handleRemove}
                                    hideThumbnails={maxFiles === 1}
                                />
                            )}
                        </Box>
                    )}
            </Box>
            <NotificationWithDialog
                open={hasReachedLimit}
                onClose={closeLimitNotification}
                title="File Error"
                message={`
                    Maximum number of files exceeded.
                    ${
                        remainingFilesLimit === 1
                            ? `You cannot select more than 1 file.`
                            : ''
                    }
                    ${
                        remainingFilesLimit > 1
                            ? `You cannot select more than ${remainingFilesLimit} files.`
                            : ''
                    }
                `}
                primaryAction={{
                    title: 'Close',
                    callback: closeLimitNotification,
                }}
                color="primary"
            />
            <NotificationWithDialog
                open={hasDuplicateFiles}
                onClose={closeDuplicateFilesNotification}
                title="File Error"
                message="This file is already added. Duplicate file names are not allowed."
                primaryAction={{
                    title: 'Close',
                    callback: closeDuplicateFilesNotification,
                }}
                color="primary"
            />
            <NotificationWithDialog
                open={!!hasFileError}
                onClose={closeLimitNotification}
                title="File Error"
                message={hasFileError}
                primaryAction={{
                    title: 'Close',
                    callback: closeFileErrorNotification,
                }}
                color="primary"
            />
            <NotificationWithDialog
                open={isFileUploading}
                onClose={closeUploadingFileNotification}
                title="Upload In Progress"
                message="Cannot remove files while upload is in progress."
                primaryAction={{
                    title: 'Close',
                    callback: closeUploadingFileNotification,
                }}
                color="primary"
            />
            <NotificationWithDialog
                open={!!removeFileIndex || removeFileIndex === 0}
                onClose={closeRemoveFileNotification}
                title="Remove File"
                message="Are you sure you want to remove this file?"
                primaryAction={{
                    title: 'Confirm',
                    callback: handleRemoveFileConfirm,
                }}
                secondaryAction={{
                    title: 'Cancel',
                    callback: closeRemoveFileNotification,
                }}
                color="primary"
            />
            <NotificationWithDialog
                open={hasRemoveAll}
                onClose={closeRemoveAllNotification}
                title="Remove All"
                message="Are you sure you want to remove all files?"
                primaryAction={{
                    title: 'Confirm',
                    callback: handleRemoveAllConfirm,
                }}
                secondaryAction={{
                    title: 'Cancel',
                    callback: closeRemoveAllNotification,
                }}
                color="primary"
            />
        </div>
    );
};

export default FileDropzone;
