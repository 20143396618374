import React from 'react';

import { IVerticalModuleIcons } from './index';

const ItemManagementModuleIcon = ({ selected }: IVerticalModuleIcons) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.32 13.8682L13.6389 11.0282L14.9747 9.61822L16.3105 11.0282L19.6642 7.48822L21 8.90822L16.32 13.8682Z"
            fill={selected === 'white' ? selected : '#04004D'}
        />
        <path
            d="M18.1579 3.98826H14.1979C13.9988 3.4091 13.6355 2.90872 13.1575 2.5551C12.6795 2.20148 12.11 2.01178 11.5263 2.01178C10.9426 2.01178 10.3731 2.20148 9.8951 2.5551C9.41712 2.90872 9.05388 3.4091 8.85474 3.98826H4.89474C4.39222 3.98826 3.91029 4.19897 3.55496 4.57404C3.19962 4.94912 3 5.45782 3 5.98826V19.9883C3 20.5187 3.19962 21.0274 3.55496 21.4025C3.91029 21.7775 4.39222 21.9883 4.89474 21.9883H8.85474V19.9883H4.89474V5.98826H6.78947V7.98826H16.2632V5.98826H18.1579V3.98826ZM11.5263 5.98826C11.3389 5.98826 11.1558 5.92961 11 5.81973C10.8442 5.70984 10.7228 5.55367 10.6511 5.37094C10.5794 5.18821 10.5606 4.98715 10.5972 4.79317C10.6337 4.59919 10.7239 4.421 10.8564 4.28115C10.9889 4.1413 11.1577 4.04606 11.3415 4.00747C11.5253 3.96889 11.7157 3.98869 11.8889 4.06438C12.062 4.14006 12.2099 4.26824 12.314 4.43269C12.4181 4.59714 12.4737 4.79048 12.4737 4.98826C12.4737 5.25347 12.3739 5.50783 12.1962 5.69536C12.0185 5.8829 11.7776 5.98826 11.5263 5.98826Z"
            fill={selected}
        />
        <path
            d="M7.53786 10.171C7.5567 10.0766 7.64143 10.0105 7.7356 10.0294C7.75444 10.0294 8.03695 10.086 8.38538 10.4164C8.39479 10.1521 8.45129 9.84999 8.63963 9.64231C8.91272 9.34023 9.65663 9.04758 9.73197 9.00982C9.78847 8.99094 9.84499 9.00038 9.89207 9.0287C9.93916 9.06646 9.96739 9.11366 9.96739 9.1703C9.96739 9.25526 9.94857 10.0199 9.75082 10.407C9.49656 10.8978 8.86562 11.0583 8.67729 11.0961C8.63962 11.1055 8.6208 11.1055 8.6208 11.1055C8.57371 11.115 8.52662 11.0961 8.48895 11.0678C8.4607 11.0583 8.44187 11.0394 8.42304 11.0111C8.04636 10.4542 7.67911 10.3692 7.66969 10.3692C7.57552 10.3503 7.51903 10.2654 7.53786 10.171ZM10.1746 15.2875C10.0616 15.4102 9.81673 15.5801 9.48714 15.5801C9.34589 15.5801 9.1858 15.5518 9.0163 15.4763C8.95038 15.448 8.73378 15.3724 8.60195 15.3724C8.47011 15.3724 8.25353 15.448 8.18762 15.4763C8.01811 15.5518 7.86745 15.5801 7.7262 15.5801C7.39661 15.5801 7.15177 15.4196 7.03877 15.2875C5.72983 14.098 6.02174 12.446 6.04057 12.3705C6.17241 11.8135 6.42667 11.4359 6.80334 11.2377C7.31185 10.9639 7.85803 11.1244 7.95219 11.1622C8.4042 11.2471 8.59254 11.2471 8.59254 11.2471H8.60195H8.61137C8.61137 11.2471 8.80913 11.2471 9.25172 11.1622C9.34589 11.1338 9.89206 10.9639 10.4006 11.2377C10.7772 11.4359 11.0315 11.8135 11.1633 12.3611C11.1916 12.4366 11.4835 14.098 10.1746 15.2875ZM7.65086 11.7474C7.61319 11.6436 7.49077 11.587 7.38718 11.6247C7.09526 11.7286 6.7186 12.0118 6.54909 12.7103C6.52084 12.8236 6.58675 12.9369 6.69975 12.9558C6.71858 12.9558 6.728 12.9652 6.74683 12.9652C6.841 12.9652 6.92576 12.8991 6.9446 12.8047C7.04818 12.3799 7.24593 12.1062 7.52844 12.0023C7.64144 11.9646 7.68853 11.8513 7.65086 11.7474Z"
            fill={selected === 'white' ? selected : '#04004D'}
        />
        <path
            d="M17.1983 21.4033C17.4984 21.5704 17.6652 21.771 17.6652 21.9883H15.3308H12.9964C12.9964 21.771 13.1465 21.5871 13.4132 21.42C10.8288 21.1525 9.39478 20.2499 9.39478 19.1801H15.1974H21C20.9833 20.2165 19.6494 21.1024 17.1983 21.4033Z"
            fill={selected === 'white' ? selected : '#04004D'}
        />
        <path
            d="M18.8251 16.7415C19.3657 16.4861 20.2403 17.0698 19.9382 17.9818V18H15.0088H10C10 17.8358 10.0318 17.6717 10.0954 17.5075C10.318 16.9056 10.8587 16.5773 11.3834 16.705C11.3834 16.6503 11.3675 16.5955 11.3675 16.5408C11.3675 16.5226 11.2721 16.0119 11.9399 15.9207C12.1148 15.8842 12.3216 16.1395 12.4011 15.793C12.4806 15.4647 12.9576 15.2275 13.212 15.5559C13.2597 15.6106 13.3233 15.1546 13.6572 15.0451C13.9276 14.9539 14.2615 14.9722 14.4682 15.3735C14.4841 15.3735 15.0406 14.5527 15.5495 15.4647C15.8675 15.1911 16.1855 15.2823 16.3445 15.5376C16.7261 15.1911 17.2509 15.0816 17.712 15.337C18.0459 15.5194 18.0459 15.72 18.1731 16.0848C18.3162 16.176 18.6979 16.4496 18.8251 16.7415Z"
            fill={selected}
        />
    </svg>
);

export default ItemManagementModuleIcon;
