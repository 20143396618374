import { createContext } from 'react';

import { ResponseStatus } from '../../../../types/response';
import { IUser } from '../../../../types/user';

export interface IUserContextValue {
    getUserProfile: () => Promise<void>;
    user: IUser | null;
    status: ResponseStatus;
}

export const initialUserState: IUserContextValue = {
    user: null,
    status: ResponseStatus.Initial,
    getUserProfile: () => Promise.resolve(),
};

const ProfileContext = createContext<IUserContextValue>(initialUserState);

export default ProfileContext;
