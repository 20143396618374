import React, { FC, MouseEventHandler, ReactNode } from 'react';
import { Box, FormControlLabel, makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import { ITheme } from '../../../core/Providers';

export interface IKGridHeaderMask {
    hasSelectedItem: boolean;
    hasSelectedOneItem: boolean;
    onSelectAll: MouseEventHandler<HTMLButtonElement>;
    selectionActions?: ReactNode;
    title?: string;
    width?: string | number;
}

const useStyles = ({ width }: { width: string | number }) =>
    makeStyles((theme: ITheme) => ({
        checkbox: {
            padding: '0',
            marginLeft: '-4px',
        },
        actions: {
            margin: '-32px 0 0 50px',
            overflow: 'hidden',
        },
        label: {
            marginLeft: 0,
            '& > span': {
                fontSize: '1em',
                fontWeight: 500,
                marginRight: theme.spacing(0.5),
            },
        },
        withLabelActions: {
            margin: `-32px 0 0 ${Number(
                width.toString().replace(/\D/g, '')
            )}px`,
            overflow: 'hidden',
        },
    }));

const KGridHeaderMask: FC<IKGridHeaderMask> = ({
    hasSelectedItem,
    hasSelectedOneItem,
    onSelectAll,
    selectionActions,
    title,
    width = '50px',
}) => {
    const classes = useStyles({
        width,
    })();
    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        id={title}
                        onClick={onSelectAll}
                        checked={hasSelectedItem}
                        color="primary"
                        className={classes.checkbox}
                        inputProps={{
                            'aria-label': 'Select All',
                        }}
                    />
                }
                label={title}
                className={classes.label}
            />

            {Boolean(selectionActions) &&
                (hasSelectedItem || hasSelectedOneItem) && (
                    <Box
                        className={
                            title ? classes.withLabelActions : classes.actions
                        }
                    >
                        {selectionActions}
                    </Box>
                )}
        </div>
    );
};

export default KGridHeaderMask;
