import React, {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import { ModuleName, Notification, storage } from '../../..';
import { AppLoader, CloseIcon } from '../../..';
import {
    CheckIcon,
    MoreVerticalIcon,
    StarIcon,
    StarOutlinedCurvedIcon,
} from '../../../assets/icons/icons';
import { ITheme, useProfile } from '../../../core/Providers';
import ModuleInfoContext from '../../../core/Providers/ModuleInfo/ModuleInfoContext';
import { PageProviderContext } from '../../../core/Providers/PageProviders/PageProvider';
import ScStorage from '../../../core/Storage';
import { Alert } from '../../Alert';
import { IconButton } from '../../IconButton';
import { IMenuItems, Menu } from '../../Menu';
import { NotificationWithDialog } from '../../Notifications';
import { SlidingDrawer } from '../../SlidingDrawer';
import { Tooltip } from '../../Tooltip';

import {
    checkViewNameAsync,
    deleteView,
    getUserDetailsByEmail,
    getViewsAsync,
    updateView,
} from './views/api';
import { filterViewsWithDuplicateIds } from './views/utils';
import ShareView from './ShareView';
export interface ISavedViews {
    id: string;
    filterCriteria?: any;
    selectedColumns?: any;
    viewName: string;
    userId: number;
    dataModelName: string;
    regionId: number;
    sortBy?: string;
    sortOrder?: number;
    isFavorite: boolean;
    isEditMode?: boolean;
    isSystem?: boolean;
    isSharedView?: boolean;
    customViewShare?: any;
    isMenuOpen?: boolean;
    updateCustomViewShareRequest?: any;
    sharedViewType: 'user' | 'role';
}
interface IViewIdentification {
    content: string;
    additionalClass: string;
    title: string;
}
interface IErrorType {
    showError: boolean;
    errorMessage: string;
}
interface IViewsSelection {
    onViewSelection?: (selectedView: any) => void;
    onViewsEdit?: () => void;
    setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
}

const styles = makeStyles((theme: ITheme) => ({
    starFilled: {
        fill: theme.palette.primary.main,
    },
    tableRoot: {
        marginTop: '20px',
    },
    header: {
        fontSize: '13px',
    },
    smallRoot: {
        width: '30%',
    },
    dataRoot: {
        padding: '8px',
    },
    dataHeadBody: {
        fontSize: '10px',
        paddingLeft: 0,
    },
    buttonColumnRoot: {
        display: 'flex',
        paddingTop: '7px',
        gap: '8px',
    },
    viewNameSpan: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '96px',
        display: 'inline-block',
    },
    viewNameBlock: {
        textAlign: 'left',
        overflow: 'hidden',
    },
    iconButton: {
        padding: '0px',
    },
    smallIcon: {
        height: '24px',
        width: '24px',
        color: theme.palette.common.black,
    },
    usersIconSmall: {
        width: '22px',
        height: '17px',
        color: theme.palette.common.black,
    },
    smallIconSelected: {
        height: '24px',
        width: '24px',
    },
    tableRow: {
        '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: 'rgb(241, 240, 251)',
        },
    },
    sharedViewTextColor: {
        color: '#665AD8',
    },
    menuButton: {
        marginTop: '0px',
    },
    disabledActions: {
        pointerEvents: 'none',
        opacity: 0.2,
    },
    avatarText: {
        width: '33px',
        height: '33px',
        borderRadius: '50%',
        backgroundColor: '#3AC191',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '5px',
        fontSize: '17px',
        color: '#fff',
        fontWeight: 'bold',
    },
    roleAvatar: {
        backgroundColor: '#DE6FA1',
    },
    userAvatar: {
        backgroundColor: '#F08B46',
    },
    systemAvatar: {
        backgroundColor: '#AD4DCA',
    },
    mineAvatar: {
        backgroundColor: '3AC191',
    },
}));

interface IDeletingView {
    viewId: string;
    viewName: string;
    sharedViewType: string;
}
export const ViewsSelection = ({
    onViewSelection,
    setShowFilters,
}: IViewsSelection) => {
    const classes = styles();
    const moduleContext = useContext(ModuleInfoContext);
    const pageContext = useContext(PageProviderContext);
    const [savedViews, setSavedViews] = useState<ISavedViews[]>([]);
    const [enableShareView, setEnableShareView] = useState<boolean>(false);
    const [error, setError] = useState<IErrorType>({
        showError: false,
        errorMessage: '',
    });
    const [message, setMessage] = useState<string>('');
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [sharedView, setSharedView] = useState<ISavedViews>();
    const [viewToDelete, setViewToDelete] = useState<IDeletingView>({
        viewId: '',
        viewName: '',
        sharedViewType: '',
    });
    const [newViewName, setNewViewName] = useState<string>('');
    const [isViewNameAllowed, setIsViewNameAllowed] = useState<string>('');
    const [initialData, setInitialData] = useState<ISavedViews[]>([]);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const customViewPageName = pageContext.customViewPageName!;
    const { user } = useProfile();
    const isCustomerSupport = user?.Roles?.includes('Customer Support');
    const functionPermissions = user?.RolesPermissions.find(
        (item: any) => item.moduleName === ModuleName.System
    )?.functions;
    const canDeleteSharedRoleView = functionPermissions
        ?.find(
            (item: any) => item.resourceName === 'Can Delete Role Based Views'
        )
        ?.scopes[0].includes('Enabled');
    const sortViewsBySystemView = (data: ISavedViews[]) => {
        setSavedViews(() =>
            data?.sort(function (x: ISavedViews) {
                return x.isSystem ? -1 : 1;
            })
        );
        setInitialData(() =>
            data?.sort(function (x: ISavedViews) {
                return x.isSystem ? -1 : 1;
            })
        );
    };
    const [loading, setLoading] = useState<boolean>(false);

    async function getInitialViews() {
        setLoading(true);
        let roles: any[] = [];
        await getUserDetailsByEmail(moduleContext.apimUrl, user?.Email).then(
            (response: any) => {
                if (
                    response.status === 200 &&
                    response.data.UserMgmatUserDetails &&
                    response.data.UserMgmatUserDetails.PayLoad[0]
                ) {
                    roles = response.data.UserMgmatUserDetails.PayLoad[0].roles;
                }
            }
        );

        const activeDistrict = storage.getItem('activeDistrict');
        const activeDistrictParsed = JSON.parse(activeDistrict ?? '{}');
        const regionId = activeDistrictParsed?.attributes?.regionId?.[0];

        await getViewsAsync(
            moduleContext.apimUrl,
            customViewPageName,
            roles,
            regionId,
            user?.UserId
        )
            .then((response: any) => {
                if (response.data.StatusCode === 200) {
                    sortViewsBySystemView(response.data.PayLoad);
                    setLoading(false);
                    setNewViewName('');
                } else {
                    setError(() => ({
                        showError: true,
                        errorMessage: `Failed to fetch saved views.`,
                    }));
                }
            })
            .catch((e: any) => {
                console.log('error fetching views', e);
                setError(() => ({
                    showError: true,
                    errorMessage: `Failed to fetch saved views.`,
                }));
            })
            .finally(() => {
                setLoading(false);
            });
    }
    const onViewSelectionEvent = useCallback(
        (event: any, selectedView: any) => {
            if (!isEditMode) {
                event.preventDefault();
                event.stopPropagation();
                ScStorage().removeItem('newSavedViewId');
                const filters = JSON.parse(selectedView.filterCriteria);
                if (
                    filters &&
                    (filters?.gridColumnfilters?.length > 0 ||
                        filters?.pageFilters?.filters?.length > 0)
                ) {
                    setShowFilters(true);
                }
                if (pageContext.setIsViewSelection) {
                    pageContext.setIsViewSelection(true);
                }
                pageContext.setViewAttributes({
                    ...selectedView,
                    currentViewId: selectedView.id.replace('=U', ''),
                    currentViewName: selectedView.name,
                });
                pageContext.currentView = selectedView?.viewName;
                pageContext.currentViewId = selectedView?.id;
                if (pageContext.setIsSystem) {
                    pageContext.setIsSystem(selectedView?.isSystem);
                }
                if (pageContext.setIsSharedView) {
                    pageContext.setIsSharedView(selectedView.isSharedView);
                }
                if (onViewSelection) {
                    onViewSelection(selectedView);
                }
                if (pageContext.setCanUpdateFilters) {
                    pageContext.setCanUpdateFilters(true);
                }
            }
        },
        [onViewSelection, isEditMode]
    );
    function deleteSavedView() {
        ScStorage().removeItem('newSavedViewId');
        return async () => {
            const currentViewId =
                pageContext.viewAttributes.id ||
                pageContext.viewAttributes.currentViewId;
            await deleteView(moduleContext.apimUrl, viewToDelete.viewId)
                .then((response: any) => {
                    if (
                        response.data.StatusCode === 200 &&
                        response.data.PayLoad
                    ) {
                        setMessage('View deleted successfully');
                        const viewsAfterDelete = savedViews.filter(
                            (view: ISavedViews) =>
                                view.id !== viewToDelete.viewId
                        );
                        sortViewsBySystemView(viewsAfterDelete);
                        if (
                            isEmpty(viewsAfterDelete) ||
                            viewToDelete.viewId === currentViewId
                        ) {
                            window.location.reload();
                        }
                    }
                })
                .catch((e: any) => {
                    console.log('unable to delete view ', e);
                    setError(() => ({
                        showError: true,
                        errorMessage: `Unable to delete ${viewToDelete.viewName}.`,
                    }));
                });
            setShowDialog(false);
        };
    }

    const onViewShare = (ev: any, view: ISavedViews) => {
        ev.preventDefault();
        ev.stopPropagation();
        ScStorage().removeItem('newSavedViewId');
        setSharedView(view);
        setEnableShareView((prevState) => !prevState);
    };

    const customMenu = (view: ISavedViews) => {
        const menuContent: (IMenuItems | undefined)[] = [
            viewIdentificationParams(view).content === 'M'
                ? {
                      menuItem: 'Edit',
                      onClick: (event: any) => {
                          onEditView(event, view);
                      },
                  }
                : undefined,
            viewIdentificationParams(view).content === 'M' ||
            ((viewIdentificationParams(view).content === 'R' ||
                viewIdentificationParams(view).content === 'U') &&
                canDeleteSharedRoleView) ||
            (viewIdentificationParams(view).content === 'G' &&
                isCustomerSupport)
                ? {
                      menuItem: 'Delete',
                      onClick: (event: any) => {
                          event.preventDefault();
                          event.stopPropagation();
                          setViewToDelete({
                              viewId: view.id,
                              viewName: view.viewName,
                              sharedViewType: view.sharedViewType,
                          });
                          setShowDialog(() => true);
                      },
                  }
                : undefined,
            {
                menuItem: 'Share',
                onClick: (ev: any) => {
                    ev.preventDefault();
                    onViewShare(ev, view);
                },
            },
        ];

        return (
            <Menu
                PaperProps={{
                    style: {
                        padding: '0px !important',
                    },
                }}
                buttonProps={{
                    color: 'primary',
                    id: 'button',
                    style: {
                        padding: '0px !important',
                    },
                }}
                className={classes.menuButton}
                style={{
                    padding: '0px',
                }}
                icon={
                    <Tooltip title="More" arrow={false}>
                        <MoreVerticalIcon />
                    </Tooltip>
                }
                id="Menu"
                menuContent={menuContent.filter(Boolean) as IMenuItems[]}
            />
        );
    };

    const onEditView = (
        ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        view: ISavedViews,
        action?: string
    ) => {
        ev.preventDefault();
        ev.stopPropagation();
        ScStorage().removeItem('newSavedViewId');
        setNewViewName('');
        if (action === 'cancel') {
            setIsEditMode(false);
            setSavedViews(() => {
                return initialData.map((savedView) => {
                    if (view.id === savedView.id) {
                        return {
                            ...savedView,
                            isEditMode: false,
                        };
                    }
                    return savedView;
                });
            });
        } else {
            setIsEditMode(true);
            setSavedViews(() => {
                return savedViews.map((savedView) => {
                    if (view.id === savedView.id) {
                        return {
                            ...savedView,
                            isEditMode: !savedView.isEditMode,
                        };
                    }
                    return savedView;
                });
            });
        }
    };
    async function setAsFavorite(editedView: ISavedViews) {
        const requestData = {
            isFavorite: !editedView.isFavorite,
            viewName: editedView.viewName,
        };
        await updateView(
            editedView.id,
            customViewPageName,
            moduleContext.apimUrl,
            requestData
        )
            .then((response: any) => {
                if (response.data.StatusCode === 200 && response.data.PayLoad) {
                    getInitialViews();
                    const editedViews = savedViews.map((view: ISavedViews) => {
                        if (view.id === editedView.id) {
                            view.isFavorite = true;
                        } else {
                            view.isFavorite = false;
                        }
                        return view;
                    });
                    sortViewsBySystemView(editedViews);
                }
            })
            .catch(() => {
                setError(() => ({
                    showError: true,
                    errorMessage: `Unable to set ${editedView.viewName} as favorite view.`,
                }));
            });
    }

    async function onSave(ev: any, view: ISavedViews) {
        ev.preventDefault();
        ev.stopPropagation();
        setIsEditMode(false);
        await updateView(
            view.id,
            customViewPageName,
            moduleContext.apimUrl,
            view
        )
            .then((response: any) => {
                if (response.data.StatusCode === 200) {
                    setMessage('View updated successfully');
                    getInitialViews();
                    setNewViewName('');
                    window.location.reload();
                }
            })
            .catch(() => {
                setError(() => ({
                    showError: true,
                    errorMessage: `Error updating view`,
                }));
            })
            .finally(() => onEditView(ev, view));
    }

    useEffect(() => {
        getInitialViews();
    }, []);

    useEffect(() => {
        let timeOutRef: any = undefined;
        if (error.showError) {
            timeOutRef = setTimeout(() => {
                setError(() => ({
                    showError: false,
                    errorMessage: '',
                }));
                clearTimeout(timeOutRef);
            }, 5000);
        }
        return () => {
            if (timeOutRef) {
                clearTimeout(timeOutRef);
            }
        };
    }, [error.showError]);

    const [wrongViewName, setWrongViewName] = useState<boolean>(false);
    const [
        displayViewNameLengthError,
        setDisplayViewNameLengthError,
    ] = useState<boolean>(false);

    const onInputChange = (
        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        view: ISavedViews
    ) => {
        e.preventDefault();
        e.stopPropagation();
        setIsViewNameAllowed('');
        if (
            /^[ A-Za-z0-9-]*$/.test(e.target.value) &&
            /^\S*$/.test(e.target.value)
        ) {
            setWrongViewName(false);
            if (e.target.value.length <= 50) {
                setNewViewName(e.target.value);
                setDisplayViewNameLengthError(false);
                const value = e.target.value;
                const { id } = view;
                const newRows = savedViews.map((view) => {
                    if (view.id === id) {
                        return {
                            ...view,
                            viewName: value,
                        };
                    }
                    return view;
                });
                setSavedViews(newRows);
            } else {
                setIsViewNameAllowed('');
                setDisplayViewNameLengthError(true);
            }
        } else {
            if (e.target.value.length <= 50) {
                setIsViewNameAllowed('');
                setWrongViewName(true);
            } else {
                setIsViewNameAllowed('');
                setDisplayViewNameLengthError(true);
            }
        }
    };
    async function checkViewName() {
        setIsViewNameAllowed('');
        if (isEmpty(newViewName)) {
            setIsViewNameAllowed('false');
        } else {
            await checkViewNameAsync(
                moduleContext.apimUrl,
                newViewName,
                customViewPageName
            ).then((response: any) => {
                setIsViewNameAllowed(() => response);
            });
        }
    }
    useEffect(() => {
        if (newViewName) {
            const timeoutId = setTimeout(() => {
                checkViewName();
            }, 1000);
            return () => clearTimeout(timeoutId);
        }
        return () => {};
    }, [newViewName]);
    const onCancel = (e: any, view: ISavedViews) => {
        onEditView(e, view, 'cancel');
    };

    const viewNameBlock = (view: ISavedViews) => {
        return (
            <Tooltip
                title={view.viewName}
                interactive
                disableHoverListener={view.viewName.length < 12}
                style={{
                    fontSize: '14px',
                }}
            >
                <div
                    className="wrapper"
                    style={{
                        width: '117px',
                    }}
                >
                    <div
                        className="sub-wrapper"
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            textAlign: 'left',
                        }}
                    >
                        <div
                            className="final-wrapper"
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                maxWidth: '100%',
                            }}
                        >
                            {/* {view.isSystem && (
                                <div
                                    className="icon"
                                    style={{
                                        paddingRight: '5px',
                                        float: 'right',
                                        marginTop: '-3px',
                                    }}
                                >
                                    <VisibilityOutlineIcon size={20} />
                                </div>
                            )} */}
                            {/* {view.isSharedView && (
                                <div
                                    className="icon"
                                    style={{
                                        paddingRight: '5px',
                                        float: 'right',
                                        marginTop: '-3px',
                                    }}
                                >
                                    <UsersIcon
                                        className={classes.usersIconSmall}
                                        style={{
                                            position: 'relative',
                                        }}
                                    />
                                </div>
                            )} */}
                            <div
                                className="text"
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    paddingRight: '5px',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {view.viewName}
                            </div>
                        </div>
                    </div>
                </div>
            </Tooltip>
        );
    };

    const viewIdentificationParams = (
        view: ISavedViews
    ): IViewIdentification => {
        let content = 'M'; // Default content
        let additionalClass = classes.mineAvatar;
        let title = 'Mine';
        if (view.isSharedView) {
            if (view.sharedViewType === 'role') {
                content = 'R';
                additionalClass = classes.roleAvatar;
                title = 'Role';
            } else if (view.sharedViewType === 'user') {
                content = 'U';
                additionalClass = classes.userAvatar;
                title = 'User';
            }
        } else if (view.isSystem) {
            content = 'G';
            title = 'Global';
            additionalClass = classes.systemAvatar;
        }
        return { content, additionalClass, title };
    };
    const displayViewIdentificationIcon = (view: ISavedViews) => {
        const viewIdentificationObj = viewIdentificationParams(view);

        return (
            <span
                title={viewIdentificationObj.title}
                className={`${classes.avatarText} ${viewIdentificationObj.additionalClass}`}
            >
                {viewIdentificationObj.content}
            </span>
        );
    };

    const id = ScStorage().getItem('newSavedViewId')
        ? ScStorage().getItem('newSavedViewId')
        : pageContext.viewAttributes.currentViewId;
    return (
        <>
            {pageContext.isViewSelection && (
                <Notification
                    color="success"
                    duration={5000}
                    message={
                        <span>
                            You switched your view to{' '}
                            <b>{pageContext.viewAttributes?.currentViewName}</b>
                        </span>
                    }
                    onClose={() => {}}
                    open
                    variant="filled"
                    style={{
                        marginTop: '-55px',
                    }}
                />
            )}
            {showDialog && (
                <NotificationWithDialog
                    message={
                        'Deleting this view affects all other users who share it.'
                    }
                    primaryAction={{
                        callback: deleteSavedView(),
                        title: 'Delete',
                    }}
                    secondaryAction={{
                        callback: () => {
                            setShowDialog(false);
                        },
                        title: 'Cancel',
                    }}
                    title={`Delete "${viewToDelete.viewName}"`}
                />
            )}
            {error.showError && (
                <Alert
                    color="error"
                    message={error.errorMessage}
                    onClose={() =>
                        setError(() => ({
                            showError: false,
                            errorMessage: '',
                        }))
                    }
                    open
                    style={{
                        marginTop: 10,
                    }}
                />
            )}
            {message !== '' && (
                <Notification
                    color="success"
                    duration={5000}
                    message={message}
                    onClose={() => setMessage(() => '')}
                    open
                    variant="filled"
                />
            )}
            {isViewNameAllowed === 'false' && (
                <Notification
                    color="error"
                    duration={5000}
                    message="View name already exists!"
                    onClose={() => setIsViewNameAllowed(() => '')}
                    open
                    variant="filled"
                />
            )}
            {wrongViewName && (
                <Notification
                    color="error"
                    duration={5000}
                    message="Only alphanumeric characters and a hyphen (“-”) allowed (spaces not allowed)"
                    onClose={() => setWrongViewName(() => false)}
                    open
                    variant="filled"
                />
            )}
            {displayViewNameLengthError && (
                <Notification
                    color="error"
                    duration={5000}
                    message="View name should be <= 50 characters"
                    onClose={() => setDisplayViewNameLengthError(() => false)}
                    open
                    variant="filled"
                />
            )}
            {!enableShareView ? (
                <Table
                    classes={{
                        root: classes.tableRoot,
                    }}
                    aria-label="views selection"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell
                                padding="none"
                                variant="head"
                                align="left"
                            >
                                <span
                                    style={{
                                        display: 'none',
                                    }}
                                >
                                    selected
                                </span>
                            </TableCell>
                            <TableCell
                                padding="none"
                                variant="head"
                                align="left"
                                classes={{
                                    head: classes.header,
                                }}
                                style={{
                                    width: '42%',
                                }}
                            >
                                VIEW NAME
                            </TableCell>
                            <TableCell
                                padding="none"
                                variant="head"
                                align="center"
                                classes={{
                                    head: classes.header,
                                }}
                                style={{
                                    width: '49%',
                                }}
                            >
                                SET AS FAVORITE
                            </TableCell>
                            <TableCell
                                padding="none"
                                variant="head"
                                align="center"
                                classes={{
                                    head: classes.header,
                                }}
                                style={{
                                    width: '20%',
                                }}
                            >
                                ACTIONS
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {savedViews.length > 0 && (
                        <TableBody>
                            {filterViewsWithDuplicateIds(savedViews)?.map(
                                (view: ISavedViews, index: number) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            onClick={(ev: any) =>
                                                onViewSelectionEvent(ev, view)
                                            }
                                            selected={id === view.id}
                                            className={classes.tableRow}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <TableCell
                                                component="td"
                                                scope="row"
                                                align="left"
                                                classes={{
                                                    root: classes.dataRoot,
                                                    body: classes.dataHeadBody,
                                                }}
                                                style={{
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {displayViewIdentificationIcon(
                                                    view
                                                )}
                                            </TableCell>

                                            <TableCell
                                                component="td"
                                                scope="row"
                                                align="left"
                                                classes={{
                                                    root: classes.dataRoot,
                                                    body: classes.dataHeadBody,
                                                }}
                                                style={{
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {!view.isEditMode ? (
                                                    viewNameBlock(view)
                                                ) : (
                                                    <TextField
                                                        value={view.viewName}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                        }}
                                                        onChange={(event) => {
                                                            onInputChange(
                                                                event,
                                                                view
                                                            );
                                                        }}
                                                    ></TextField>
                                                )}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                classes={{
                                                    root: classes.dataRoot,
                                                }}
                                                component="td"
                                                scope="row"
                                            >
                                                <Tooltip
                                                    title="Favorite"
                                                    arrow={false}
                                                >
                                                    <IconButton
                                                        color="default"
                                                        id="views-menu-dropdown"
                                                        onClick={(ev) => {
                                                            ev.preventDefault();
                                                            ev.stopPropagation();
                                                            setAsFavorite(view);
                                                        }}
                                                        type="button"
                                                        className={clsx(
                                                            classes.iconButton,
                                                            view.isFavorite
                                                                ? classes.smallIconSelected
                                                                : classes.smallIcon
                                                        )}
                                                        aria-label="views menu dropdown"
                                                    >
                                                        {view.isFavorite ? (
                                                            <StarIcon
                                                                className={
                                                                    classes.starFilled
                                                                }
                                                            />
                                                        ) : (
                                                            <StarOutlinedCurvedIcon />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>

                                            <TableCell
                                                align="right"
                                                component="td"
                                                scope="row"
                                                classes={{
                                                    root: classes.dataRoot,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    {!view.isEditMode ? (
                                                        customMenu(view)
                                                    ) : (
                                                        <>
                                                            {' '}
                                                            <Tooltip
                                                                title="Cancel"
                                                                arrow={false}
                                                            >
                                                                <IconButton
                                                                    color="default"
                                                                    id="views-menu-dropdown"
                                                                    aria-label="views menu dropdown"
                                                                    onClick={(
                                                                        ev
                                                                    ) =>
                                                                        onCancel(
                                                                            ev,
                                                                            view
                                                                        )
                                                                    }
                                                                    type="button"
                                                                    className={
                                                                        classes.iconButton
                                                                    }
                                                                >
                                                                    <CloseIcon
                                                                        className={
                                                                            classes.smallIcon
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title="Save"
                                                                arrow={false}
                                                            >
                                                                <IconButton
                                                                    color="default"
                                                                    id="views-menu-dropdown"
                                                                    aria-label="views menu dropdown"
                                                                    onClick={(
                                                                        ev
                                                                    ) =>
                                                                        onSave(
                                                                            ev,
                                                                            view
                                                                        )
                                                                    }
                                                                    type="button"
                                                                    className={
                                                                        classes.iconButton
                                                                    }
                                                                    disabled={
                                                                        isEmpty(
                                                                            newViewName
                                                                        )
                                                                            ? true
                                                                            : isViewNameAllowed ===
                                                                                  'true' &&
                                                                              !wrongViewName &&
                                                                              !displayViewNameLengthError
                                                                            ? false
                                                                            : true
                                                                    }
                                                                >
                                                                    <CheckIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            )}
                        </TableBody>
                    )}
                    {loading && (
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    colSpan={4}
                                    style={{
                                        textAlign: 'center',
                                    }}
                                    component="td"
                                >
                                    <AppLoader size={2} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                    {savedViews.length === 0 && !loading && (
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    colSpan={4}
                                    component="td"
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    No views available
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            ) : (
                <SlidingDrawer
                    title={
                        <span className={classes.sharedViewTextColor}>
                            Share - {sharedView?.viewName} with
                        </span>
                    }
                    onBackButtonClick={() => {
                        setEnableShareView((prevState) => !prevState);
                        getInitialViews();
                    }}
                    backButtonColor="secondary"
                    width="md"
                    open={enableShareView}
                    onCloseIconClick={() => {
                        setEnableShareView(false);
                    }}
                    onBackdropClick={() => {
                        setEnableShareView(false);
                        getInitialViews();
                    }}
                    enableBackButton={true}
                    BackdropProps={{
                        style: {
                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                        },
                    }}
                    padding="0 16px 8px 16px"
                    displayXIcon={false}
                >
                    <div
                        style={{
                            padding: '10px',
                        }}
                    >
                        <ShareView view={sharedView} />
                    </div>
                </SlidingDrawer>
            )}
        </>
    );
};
