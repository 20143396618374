import React, {
    ChangeEvent,
    FC,
    ReactElement,
    ReactNode,
    useEffect,
} from 'react';
import {
    FormControlLabel,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import clsx from 'clsx';

import { ChevronRightIcon } from '../../assets/icons';
import { StandardProps } from '../../types';

import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';

export interface IAccordionProps extends StandardProps<HTMLDivElement> {
    /**
     * The title of accordion
     */
    title: string | ReactNode;
    /**
     * The content of the accordion
     */
    children: NonNullable<ReactNode>;
    /**
     * If `true`, the accordion will be displayed in a disabled state
     */
    disabled?: boolean;
    /**
     * If `true`, expands the accordion, otherwise collapse it
     * Setting this prop enables control over the accordion
     */
    expanded?: boolean;
    /**
     * Callback fired when the expand/collapse state is changed
     */
    onChange?: (event: ChangeEvent<{}>, expanded: boolean) => void;
    /**
     * The icon to display as the expand indicator
     */
    expandIcon?: ReactNode;
    /**
     * AccordionVariant styles
     */
    variant?: AccordionVariant;
    /**
     * Send custom component
     */
    action?: ReactElement;
}

export type AccordionVariant = 'default' | 'info';

const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        marginRight: theme.spacing(1),
        transform: 'rotate(0)',
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.short,
        }),
    },
    expandedIcon: {
        transform: 'rotate(90deg)',
    },
    title: {
        lineHeight: 2,
    },
}));

const Accordion: FC<IAccordionProps> = ({
    children,
    className,
    expanded = false,
    title,
    expandIcon,
    onChange,
    variant = 'default',
    action,
    ...rest
}) => {
    const classes = useStyles();
    const [isExpanded, setExpanded] = React.useState<boolean>(expanded);

    useEffect(() => {
        setExpanded(expanded);
    }, [expanded]);

    const handleChange = (ev: ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded);
        if (onChange) {
            onChange(ev, isExpanded);
        }
    };

    return (
        <MuiAccordion
            id={`accordion-header-${title}`}
            className={clsx(classes.root, className)}
            expanded={isExpanded}
            onChange={handleChange}
            {...rest}
        >
            <AccordionSummary
                aria-label={
                    isExpanded ? `Collapse-${title}` : `Expand-${title}`
                }
                variant={variant}
                style={{
                    textAlign: 'left',
                }}
            >
                {expandIcon || (
                    <IconButton
                        size="small"
                        className={clsx(classes.icon, {
                            [classes.expandedIcon]: isExpanded,
                        })}
                        aria-label={
                            typeof title === 'string'
                                ? title
                                : 'Expand/Collapse Accordion'
                        }
                        role="img"
                    >
                        <ChevronRightIcon />
                    </IconButton>
                )}
                {typeof title === 'string' ? (
                    <Typography className={classes.title}>{title}</Typography>
                ) : (
                    title
                )}
                {action && (
                    <FormControlLabel
                        style={{
                            position: 'absolute',
                            right: '10px',
                        }}
                        onClick={(event: any) => event.stopPropagation()}
                        onFocus={(event: any) => event.stopPropagation()}
                        label=""
                        control={action}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails variant={variant}>{children}</AccordionDetails>
        </MuiAccordion>
    );
};

export default Accordion;
