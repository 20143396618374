import type { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        '@global': {
            '.MuiFormLabel-asterisk': {
                color: '#EB0000',
            },
            '.MuiDrawer-paper': {
                top: 56,
            },
            '.MuiDialog-paperFullScreen': {
                top: 56,
            },
            '.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                paddingRight: '65px !important',
            },
            '.MuiOutlinedInput-root': {
                borderRadius: 0,
            },
        },
    })
);

const MaterialStyles: FC = () => {
    useStyles();

    return null;
};

export default MaterialStyles;
