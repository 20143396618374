/**
 * @function
 * Function to determine the current environment
 * @returns string environment
 */
export const getEnvironment = () => {
    const host = window.location.host.split('.');
    if (host[0].toLowerCase() === 'solutions') {
        return 'Demo';
    } else if (host[0].toLowerCase() === 'qa') {
        return 'QA';
    } else if (host[0].toLowerCase() === 'stg') {
        return 'STG';
    } else if (host[0].toLowerCase() === 'uat') {
        return 'Uat';
    } else if (host[0].toLowerCase() === 'preview') {
        return 'Preview';
    } else if (host[0].toLowerCase() === 'migration') {
        return 'Migration';
    } else if (host[0].toLowerCase() === 'login') {
        return 'PRODUCTION';
    } else if (host[0].toLowerCase() === 'learn') {
        return 'Training';
    } else if (host[0].toLowerCase() === 'sales') {
        return 'Sales';
    } else {
        return 'Development';
    }
};
