import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { delay, isNil, isString } from 'lodash';

import { AuthContext, useProfile } from '../../core/Providers';
import useSystemByPass from '../../hooks/useSystemByPass';
import { getChatSettings } from '../../utils/api/APIUtils';

import './styles.scss';

const FRESH_CHAT_SOURCE = 'https://primeroedge.freshchat.com/js/widget.js';
const scriptType = 'text/javascript';
const HelpWidget = ({ apimUrl, activeDistrict }: any) => {
    const { user } = useProfile();
    const { environment } = useContext(AuthContext);
    const systemByPass = useSystemByPass();
    const [showChatWidget, setShowChatWidget] = useState<boolean>(false);

    const setTags = function () {
        (window as any).fcWidget.setFaqTags({
            tags: ['public'],
            filterType: 'category',
        });
    };

    const setChatUserInfo = function () {
        (window as any).fcWidget.setExternalId(user?.UserId);
        (window as any).fcWidget.user.setFirstName(user?.FirstName);
        (window as any).fcWidget.user.setEmail(user?.Email);
    };

    const executeChat = function () {
        if (('fcWidget' in window) as any) {
            (window as any).fcWidget.init({
                token: '45e70b3f-9f88-4767-aaba-1a0f20364239',
                host: 'https://primeroedge.freshchat.com',
                widgetUuid: '0418420e-f534-4605-a17f-33224e63ffd3',
                config: {
                    headerProperty: {
                        direction: 'ltr',
                    },
                    hideFAQ: true,
                },
                faqTags: {
                    tags: ['public'],
                },
            });
            delay(setChatUserInfo, 900);
            delay(setTags, 1000);
        }
    };

    useEffect(function () {
        return () => {
            (window as any)?.fcWidget?.destroy?.();
        };
    }, []);

    useEffect(
        function () {
            if (!isNil(activeDistrict) && !isString(activeDistrict)) {
                const canAllow =
                    systemByPass(
                        !!(
                            environment &&
                            ['production', 'sales'].includes(
                                environment?.toLowerCase?.() ?? ''
                            )
                        ),
                        'ENBLCHAT'
                    ) && !!user?.IsFreshdeskEnabled;
                getChatSettings(
                    apimUrl,
                    activeDistrict?.attributes?.regionId?.[0],
                    canAllow
                )
                    .then((response: any) => {
                        setShowChatWidget(
                            response.data?.PayLoad === '1' || false
                        );
                    })
                    .catch(() => {
                        setShowChatWidget(false);
                    });
            }
        },
        [activeDistrict]
    );

    useEffect(
        function () {
            if (showChatWidget) {
                delay(function () {
                    executeChat();
                }, 1000);
            }
        },
        [showChatWidget]
    );

    return (
        <>
            <Helmet
                async
                defer
                script={[
                    {
                        src: FRESH_CHAT_SOURCE,
                        type: scriptType,
                    },
                ]}
            ></Helmet>
        </>
    );
};

export default HelpWidget;
