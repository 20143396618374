import React, { FC, ReactNode } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { ITheme } from '../../core/Providers';

export interface IAsideContentProps {
    /**
     * Content to display
     */
    component: ReactNode | null;
    /**
     * Width of panel
     */
    width?: number | string;
    /**
     * Position of panel
     */
    placement?: 'left' | 'right';
    /**
     * Fixed position
     */
    fixed?: boolean;
    /**
     * Side panel variant
     */
    variant?: 'default' | 'contained';
    /**
     * Position for sticky side panel
     */
    top?: number | null;
}

const useStyles = ({
    width,
    top,
}: {
    width?: string | number;
    top: number | null;
}) =>
    makeStyles((theme: ITheme) => ({
        root: {
            minWidth: width,
            maxWidth: width,
        },
        wrapper: {
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        contained: {
            padding: theme.spacing(2),
            boxShadow: theme.shadows[1],
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.background.paper,
        },
        fixedPanel: {
            top: top && top > 0 ? top : 24,
            position: 'sticky',
        },
    }));

const AsideContent: FC<IAsideContentProps> = ({
    children,
    component,
    width = 240,
    placement = 'left',
    fixed = true,
    variant = 'default',
    top,
}) => {
    const classes = useStyles({
        width: width,
        top: top ?? null,
    })();

    return (
        <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="stretch"
            flexDirection={placement === 'right' ? 'row-reverse' : 'row'}
        >
            {component && (
                <Box
                    className={classes.root}
                    pr={placement === 'left' ? 3 : 0}
                    pl={placement === 'right' ? 3 : 0}
                >
                    <Box
                        className={clsx(
                            classes.wrapper,
                            {
                                [classes.fixedPanel]: fixed === true,
                            },
                            {
                                [classes.contained]: variant === 'contained',
                            }
                        )}
                    >
                        <Box>{component}</Box>
                    </Box>
                </Box>
            )}
            <Box flexGrow={1} overflow="hidden" padding="4px" margin="-4px">
                {children}
            </Box>
        </Box>
    );
};

export default AsideContent;
