import React, { ComponentPropsWithRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface ISvgIconProps extends ComponentPropsWithRef<'svg'> {
    className?: string;
    style?: object;
    title?: string;
    viewBox?: string;
    css?: any;
    size?:
        | string
        | number
        | {
              width?: string | number;
              height?: string | number;
          };
    selected?: string;
    color?: string;
}

const useStyles = makeStyles(() => ({
    root: {
        display: 'inline-block',
        width: 24,
        height: 24,
        fill: 'currentColor',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
    },
}));

export const SvgIcon = React.forwardRef<SVGSVGElement, ISvgIconProps>(
    ({ children, className, style, title, size, viewBox, ...rest }, ref) => {
        const classes = useStyles();
        const width = typeof size === 'object' ? size.width : size;
        const height = typeof size === 'object' ? size.height : size;

        return (
            <svg
                className={clsx(classes.root, className)}
                role="img"
                aria-hidden="true"
                focusable="false"
                style={{
                    ...style,
                    width: width,
                    height: height,
                }}
                viewBox={viewBox}
                ref={ref}
                {...rest}
            >
                <title>{title}</title>
                {children}
            </svg>
        );
    }
);

export default SvgIcon;
