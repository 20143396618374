import React, { FC, useEffect } from 'react';

import AppInsightsProvider from '../../AppInsightsProvider';
import SettingsProvider from '../Settings/SettingsProvider';
import GlobalStyles from '../Theme/GlobalStyles';
import KendoStyles from '../Theme/KendoStyles';
import MaterialStyles from '../Theme/MaterialStyles';
import ThemeProvider, { IThemeProviderProps } from '../Theme/ThemeProvider';

export interface IMainProviderProps extends IThemeProviderProps {
    moduleName?: string;
    navClosedPagesList?: string[];
}

/**
 * @MainProvider
 * Functional Component that wraps the AuthProvider and RouteConfig in application modules.
 * This Component exposes
 *   1. SettingsProvider
 *   2. ThemeProvider
 *   3. GlobalStyles
 *   4. KendoStyles
 *   5. MaterialStyles
 * Initializes AppInsightsProvider
 *
 * @props navClosedPagesList list of page urls for which sidenav must collpase on load and must only use hover expand
 * @props moduleName module name of the application consuming the MainProvider component
 *
 * ```typescript
 * import { AuthProvider, MainProvider } from "@primeroedge/ui-components";
 *
 *  <MainProvider>
 *       <AuthProvider loginUrl={} environment={APIM_ENVIRONMENT} subscriptionKey={APIM_SUBSCRIPTION_KEY}>
 *           <RouteConfig />
 *       </AuthProvider>
 *   </MainProvider>
 * ```
 */
const MainProvider: FC<IMainProviderProps> = ({
    moduleName = 'Systems',
    navClosedPagesList = [],
    children,
    theme,
}) => {
    useEffect(function () {
        AppInsightsProvider.createConnection(moduleName);

        return () => {
            AppInsightsProvider.removeLogger();
        };
    }, []);

    return (
        <>
            <SettingsProvider navClosedOnLoadPagesList={navClosedPagesList}>
                <ThemeProvider theme={theme}>
                    <GlobalStyles />
                    <KendoStyles />
                    <MaterialStyles />
                    {children}
                </ThemeProvider>
            </SettingsProvider>
        </>
    );
};

export default MainProvider;
