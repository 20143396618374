import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Grid,
    makeStyles,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core';

import {
    AppLoader,
    Button,
    componentsAPIInstance,
    Dialog,
    NotificationWithDialog,
    scChannel,
    useAuth,
} from '../..';
import { Checkbox } from '../../components/Checkbox';
import ScStorage from '../../core/Storage';
import { IGroup, IUser } from '../../types/user';
import { getSettingRolesV1 } from '../Grid/Kendo/views/api';

interface IDistrictDropdownProps {
    apimUrl: string;
    user?: IUser | null;
    storedDistrict: IGroup | null;
    onDistrictChange?: (district: IGroup, asDefaultDistrict?: boolean) => void;
}

const useStyles = makeStyles({
    main: {
        width: 366,
        borderColor: '#5755BA',
    },
    input: {
        color: '#000',
    },
    border: {
        borderColor: '#5755BA',
    },
});

const DistrictDropDown = ({
    apimUrl,
    user,
    onDistrictChange,
    storedDistrict,
}: IDistrictDropdownProps) => {
    const classes = useStyles();
    const DISTRICT_PLACEHOLDER = 'Select';
    const history = useHistory();
    const { isAuthenticated, logout } = useAuth();
    const [
        displayWarningForDistrictChange,
        setDisplayWarningForDistrictChange,
    ] = useState<boolean>(false);
    const [
        isDistrictSelectionInProgress,
        setisDistrictSelectionInProgress,
    ] = useState<boolean>(false);
    const [selectedGroupGuid, setSelectedGroupGuid] = useState<string>(
        storedDistrict?.groupGuid as string
    );
    const [selectedRegionId, setSelectedRegionId] = useState<string>('');
    const [selectedGroup, setSelectedGroup] = useState<any>();
    const [asDefaultSelected, setAsDefaultSelected] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    /**
     * Update district selection on initial load
     */
    useEffect(() => {
        if (storedDistrict) {
            setSelectedGroupGuid(
                storedDistrict?.groupGuid ?? DISTRICT_PLACEHOLDER
            );
            setSelectedGroup(storedDistrict);
        }
    }, [storedDistrict]);

    const handleDistrictChange = async (
        ev: ChangeEvent<{ value: unknown }>
    ) => {
        setisDistrictSelectionInProgress(true);
        const group = user?.Groups?.find(
            (group: IGroup) => group.groupGuid === ev.target.value
        );

        if (group?.groupGuid) {
            const region = group?.attributes?.regionId
                ? group?.attributes.regionId[0]
                : '0';
            await getSettingRolesV1(apimUrl, region).then(
                async (response: any) => {
                    if (response.data.StatusCode === 200) {
                        const settingValue = response.data.PayLoad;
                        const previousDistrictRolesV1Setting = ScStorage().getItem(
                            'settingValueRolesV1'
                        );
                        if (settingValue === previousDistrictRolesV1Setting) {
                            ScStorage().removeItem(
                                'displayDistrictSelectionWarning'
                            );
                            setDisplayWarningForDistrictChange(false);
                            setSelectedGroupGuid(group.groupGuid);
                            setSelectedGroup(group);
                        } else {
                            setSelectedRegionId(region);
                            ScStorage().setItem(
                                'displayDistrictSelectionWarning',
                                'true'
                            );
                            setDisplayWarningForDistrictChange(true);
                        }
                        setisDistrictSelectionInProgress(false);
                    }
                }
            );
        } else {
            setSelectedGroupGuid(DISTRICT_PLACEHOLDER);
        }
    };

    const onContinue = async () => {
        if (isAuthenticated) {
            if (onDistrictChange) {
                await onDistrictChange(selectedGroup, asDefaultSelected);
                setTimeout(() => {
                    ScStorage().setItem('district-change', '1');

                    scChannel.postMessage('district-change');
                    history.push('/');
                    if (user?.Groups?.length && user?.Groups?.length === 1) {
                        window.location.reload();
                    }
                }, 1000);
            }
        }
    };

    const onDefaultSelectedChange = function (event: any) {
        setAsDefaultSelected(event.target.checked);
    };

    return (
        <>
            <NotificationWithDialog
                message="You will be logged out because the selected district is in 
                different identity provider. are you sure?"
                open={displayWarningForDistrictChange}
                onClose={() => {
                    setDisplayWarningForDistrictChange(false);
                }}
                primaryAction={{
                    title: 'Yes',
                    callback: async () => {
                        await componentsAPIInstance.axios({
                            method: 'PATCH',
                            url: `${apimUrl}/administration/v1.0/api/User/${user?.UserId}/regionId/${selectedRegionId}`,
                        });
                        setDisplayWarningForDistrictChange(false);
                        ScStorage().setItem('userRegion', selectedRegionId);
                        await logout();
                    },
                }}
                secondaryAction={{
                    title: 'No',
                    callback: () => {
                        setDisplayWarningForDistrictChange(false);
                    },
                }}
            />
            {selectedGroupGuid && user?.Groups && user?.Groups.length > 0 ? (
                <>
                    <Grid
                        container={true}
                        direction="column"
                        style={{
                            justifyContent: 'center',
                            marginBottom: 16,
                        }}
                        alignItems="center"
                        spacing={8}
                    >
                        <Grid item style={{ display: 'block', width: '100%' }}>
                            <img
                                src="/logo/SchoolCafe.svg"
                                style={{
                                    maxWidth: 350,
                                }}
                                alt="SchoolCafé"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container={true}
                        direction="column"
                        style={{
                            justifyContent: 'center',
                            marginBottom: 16,
                        }}
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Typography>Please select a district:</Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="selectActiveDistrict"
                                select
                                fullWidth
                                className={classes.main}
                                placeholder="Select District"
                                value={selectedGroupGuid}
                                disabled={
                                    isDistrictSelectionInProgress ||
                                    displayWarningForDistrictChange
                                }
                                defaultValue={selectedGroupGuid}
                                key={selectedGroupGuid}
                                InputLabelProps={{
                                    'aria-label': 'Select District',
                                }}
                                SelectProps={{
                                    classes: {
                                        icon: classes.input,
                                    },
                                }}
                                InputProps={{
                                    classes: {
                                        notchedOutline: classes.border,
                                    },
                                }}
                                inputProps={{
                                    className: classes.input,
                                    'aria-labelledby': 'selectActiveDistrict',
                                }}
                                onChange={handleDistrictChange}
                                variant="outlined"
                                size="small"
                            >
                                <MenuItem disabled value={DISTRICT_PLACEHOLDER}>
                                    Select District
                                </MenuItem>
                                {user?.Groups?.map((group: IGroup) => (
                                    <MenuItem
                                        key={group.groupGuid}
                                        value={group.groupGuid}
                                    >
                                        {group.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Checkbox
                                id="district-selction-set-as-default"
                                label="Set as default district"
                                color={'primary'}
                                checked={asDefaultSelected}
                                onChange={onDefaultSelectedChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} md={12} sm={12}>
                        <Button
                            buttonGroupColor="secondary"
                            buttonGroupVariant="text"
                            color="primary"
                            id="primaryButton"
                            label="CONTINUE"
                            name="Button"
                            onClick={() => setShowWarning(true)}
                            type="button"
                            loading={
                                isDistrictSelectionInProgress ||
                                displayWarningForDistrictChange
                            }
                            useEllipsis
                            variant="default"
                            fullWidth
                        />
                    </Grid>
                    <Dialog
                        id={'logout-confirmation'}
                        open={showWarning}
                        title={'Confirm District Change'}
                        titleStyles={{
                            fontSize: 20,
                            padding: '4px 0px',
                            lineHeight: 1.2,
                        }}
                        primaryText="Confirm"
                        secondaryText="Cancel"
                        onPrimaryAction={onContinue}
                        onClose={() => setShowWarning(false)}
                        onSecondaryAction={() => setShowWarning(false)}
                        content={
                            <p style={{ width: 300 }}>
                                Once confirmed, All the tabs will be refreshed
                                to Workspace and unsaved changes will be lost.{' '}
                                <br /> Click <b>Cancel</b>, to continue with
                                same district.
                            </p>
                        }
                    />
                </>
            ) : (
                <AppLoader />
            )}
        </>
    );
};

export default DistrictDropDown;
