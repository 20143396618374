import React from 'react';

import { IVerticalModuleIcons } from './index';

const InventoryModuleIcon = ({ selected }: IVerticalModuleIcons) => (
    <svg
        width="19"
        height="20"
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.06 17.87L11.23 15.04L12.64 13.63L14.05 15.04L17.59 11.5L19 12.91L14.06 17.87Z"
            fill={selected === 'white' ? selected : '#04004D'}
        />
        <path
            d="M14.06 11.88L11.23 9.04L12.64 7.63L14.05 9.04L17.59 5.5L19 6.92L14.06 11.88Z"
            fill={selected === 'white' ? selected : '#04004D'}
        />
        <path
            d="M10 13.99H4V15.99H10V13.99Z"
            fill={selected === 'white' ? selected : '#04004D'}
        />
        <path
            d="M10 8H4V10H10V8Z"
            fill={selected === 'white' ? selected : '#04004D'}
        />
        <path
            d="M16 2.00004H11.82C11.6098 1.42088 11.2264 0.920496 10.7218 0.566878C10.2173 0.213259 9.61612 0.0235596 9 0.0235596C8.38388 0.0235596 7.7827 0.213259 7.27816 0.566878C6.77363 0.920496 6.3902 1.42088 6.18 2.00004H2C1.46957 2.00004 0.960859 2.21075 0.585786 2.58582C0.210714 2.9609 0 3.4696 0 4.00004V18C0 18.5305 0.210714 19.0392 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H11V18H2V4.00004H4V6.00004H14V4.00004H16V2.00004ZM9 4.00004C8.80222 4.00004 8.60888 3.94139 8.44443 3.83151C8.27998 3.72162 8.15181 3.56545 8.07612 3.38272C8.00043 3.19999 7.98063 2.99893 8.01921 2.80495C8.0578 2.61097 8.15304 2.43278 8.29289 2.29293C8.43275 2.15308 8.61093 2.05784 8.80491 2.01925C8.99889 1.98067 9.19996 2.00047 9.38268 2.07616C9.56541 2.15184 9.72159 2.28002 9.83147 2.44447C9.94135 2.60892 10 2.80225 10 3.00004C10 3.26525 9.89464 3.51961 9.70711 3.70714C9.51957 3.89468 9.26522 4.00004 9 4.00004Z"
            fill={selected}
        />
    </svg>
);

export default InventoryModuleIcon;
