import { ReactNode } from 'react';

import { IAPIRequestProps } from '../../../types/api-request';
import { ResponseStatus } from '../../../types/response';
import { StandardProps } from '../../../types/standardProps';
import FileExtensions from '../../../utils/file/extensions';

export interface IFileDropzoneProps extends StandardProps<HTMLDivElement> {
    /**
     * List of files
     */
    files?: IFileProps[];
    /**
     * Display custom title
     */
    title?: string | ReactNode;
    /**
     * Minimum size per file
     */
    minSize?: number;
    /**
     * Size limit per file
     */
    maxSize?: string | number;
    /**
     * Maximum number of files allowed to select
     */
    maxFiles?: number;
    /**
     * Supported file types
     */
    acceptedFileTypes?: FileExtensions[];
    /**
     * Show download file option
     */
    downloadFiles?: boolean;
    /**
     * Show remove file option
     */
    removeFiles?: boolean;
    /**
     * Disable drop zone
     */
    disabled?: boolean;
    /**
     * Allow files with same name
     */
    uniqueFileNames?: boolean;
    /**
     * Event emitted for remove all
     */
    onFilesChange?: (ev: IFilesChange) => void;
    /**
     * File dropzone style variant
     */
    variant?: 'contained' | 'transparent';
    /**
     * Show preview panel
     * Default - 'file'
     */
    preview?: PreviewType;
    /**
     * Placement position of preview panel
     * Default: auto - Right on desktop, Bottom on mobile
     */
    previewPlacement?: PreviewPlacementType;
    /**
     * Upload props
     */
    upload?: IAPIRequestProps;
    /**
     * Placement position of file limits info
     */
    infoPlacement?: InfoPlacementType;
}

export interface IFilesChange {
    /**
     * Updated list of files
     */
    files: IFileProps[];
    /**
     * List of files removed
     */
    remove?: IFileProps[];
    /**
     * List of files added
     */
    add?: IFileProps[];
}

export interface IFileProps extends File {
    /**
     * Unique file id
     */
    id?: string;
    /**
     * File Status
     */
    status?: ResponseStatus;
    /**
     * File Size
     */
    bytes?: number;
}

export type PreviewType = 'none' | 'file' | 'image';

export type PreviewPlacementType = 'auto' | 'bottom' | 'right';

export type InfoPlacementType = 'none' | 'bottom';

export enum FileErrorCode {
    FileTooLarge = 'file-too-large',
    FileTooSmall = 'file-too-small',
    TooManyFiles = 'too-many-files',
    FileInvalidType = 'file-invalid-type',
}
