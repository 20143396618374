import React, { useContext, useEffect, useState } from 'react';

import useProfile from '../../core/Providers/Auth/Profile/useProfile';
import ModuleInfoContext from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import { IPermission, IResources } from '../../types/user';

/* eslint-disable */
const NavPermissions = (NavComponent: React.ComponentType<any>) => (props: any) => {
    const { user } = useProfile();
    const { moduleName } = useContext(ModuleInfoContext);
    const [loading, setLoadingStatus] = useState(true);
    const [showModule, setShowModule] = useState(true);

    useEffect(() => {
        if (
            moduleName !== '' &&
            user &&
            user.RolesPermissions &&
            user.RolesPermissions.length > 0
        ) {
            const module = user.RolesPermissions.find(
                (permissions: IPermission) => {
                    return (
                        permissions.moduleName.toLowerCase() ===
                        moduleName.toLowerCase()
                    );
                }
            );

            if (module) {
                module.resources.forEach((resource: IResources) => {
                    if (
                        resource.resourceName.toLowerCase() === props.title.toLowerCase() &&
                        resource.scopes.length > 0 &&
                        resource.scopes.includes('View')
                    ) {
                        setShowModule(() => true);
                    }
                    setLoadingStatus(() => false);
                });
            }
        }
    }, [user, moduleName]);

    if (showModule) {
        return <NavComponent {...props} />;
    } else if (loading) {
        return <></>;
    } else {
        return <></>;
    }
};

export default NavPermissions;
