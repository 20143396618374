import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    CancelTokenSource,
} from 'axios';

// import moment from 'moment';
import AppInsightsProvider from '../../core/AppInsightsProvider';
import { MAX_TOKEN_RETRIES } from '../../core/constants';
import { IAuthResponse } from '../../core/Providers';
import ScStorage from '../../core/Storage';
import { getRefreshToken, persistAuth } from '../../core/Store/persist';
import { IGlobalAPIError } from '../../layouts';
import sleep from '../promise/sleep';

export interface IAPIClientProps
    extends AxiosRequestConfig,
        IRequestInterceptorProps {}

export interface IRequestInterceptorProps {
    accessToken?: string | null;
    environment?: string | null;
    subscriptionKey?: string | null;
    enableRefreshToken?: boolean;
}

export interface IRefreshTokenProps {
    origin: {
        apiInstance: APIInstance;
        apiRequest: AxiosRequestConfig;
        error?: AxiosError;
    };
    maxRetries?: number;
    callback?: () => void;
}

class APIInstance {
    /**
     * Axios api client
     */
    public axios: AxiosInstance;

    /**
     * flag Allow all requests
     * It is used to reject additional requests post logout
     */
    private static _allowReuqests = true;
    private _cancelToken: CancelTokenSource;
    private _environment: string | undefined | null;

    /**
     * Save axios interceptor id's to eject unused
     */
    private baseURLHandler?: number | null;
    private environmentHandler?: number | null;
    private subscriptionKeyHandler?: number | null;
    private accessTokenHandler?: number | null;

    constructor({
        accessToken,
        environment,
        subscriptionKey,
        enableRefreshToken = true,
        ...axiosProps
    }: IAPIClientProps = {}) {
        this._environment = environment;
        /**
         * Create axios instance
         */
        this.axios = axios.create({
            ...axiosProps,
        });

        this._cancelToken = axios.CancelToken.source();

        this.setErrorHandler();

        /**
         * Add custom headers to be used commonly across all API request
         */
        this.injectUtilityHeaders();

        /**
         * Add default access token
         */

        this.setDefaultAccessToken();

        /**
         * Add unauthorized interceptor
         */
        if (enableRefreshToken) {
            this.addRefreshTokenInterceptor();
        }

        /**
         *Add APIM environment handler
         */

        if (environment) {
            this.setEnvironment(environment);
        }

        /**
         * Add APIM subscription key handler
         */
        if (subscriptionKey) {
            this.setSubscriptionKey(subscriptionKey);
        }

        /**
         * Add access token handler
         */

        if (accessToken || ScStorage().getItem('accessToken')) {
            this.setAccessToken(
                accessToken ?? (ScStorage().getItem('accessToken') as string)
            );
        }
    }

    /**
     * Request Interceptor 1
     * Custom headers
     * @private
     */
    private injectUtilityHeaders() {
        this.axios.interceptors.request.use(
            (config) => {
                const activeDistrict = ScStorage().getItem('activeDistrict');
                if (activeDistrict) {
                    const districtDetails = JSON.parse(activeDistrict);
                    config.headers.groupGuid = districtDetails.groupGuid;
                    config.headers.regionId =
                        districtDetails.attributes.regionId;
                    config.headers.districtId =
                        districtDetails.attributes.regionId;
                    config.headers['Cache-Control'] = 'no-cache';
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    /**
     * Request Interceptor 2
     * Set default access token to use from localStorage
     * @private
     */
    private setDefaultAccessToken() {
        this.axios.interceptors.request.use(
            (config) => {
                config.headers.Authorization = `Bearer ${ScStorage().getItem(
                    'accessToken'
                )}`;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    /**
     * Request Interceptor 3
     * Update interceptor environment
     */
    public setEnvironment(environment: string) {
        this.resetEnvironment();
        this.environmentHandler = this.axios.interceptors.request.use(
            (config) => {
                config.headers.Environment = environment;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    /**
     * Request Interceptor 4
     * Update interceptor subscription key
     */
    public setSubscriptionKey(subscriptionKey: string) {
        this.resetSubscriptionKey();
        this.subscriptionKeyHandler = this.axios.interceptors.request.use(
            (config) => {
                config.headers['Ocp-Apim-Subscription-Key'] = subscriptionKey;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    /**
     * Request Interceptor 5
     * Update base url
     */
    public setBaseURL(baseURL?: string) {
        this.resetBaseURL();
        this.baseURLHandler = this.axios.interceptors.request.use(
            (config) => {
                config.baseURL = baseURL;
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    /**
     * Request Interceptor 6
     * Update interceptor access token
     */
    // @ts-ignore
    public setAccessToken(accessToken?: string, callback?: () => void) {
        this.resetAccessToken();

        this.accessTokenHandler = this.axios.interceptors.request.use(
            async (config) => {
                // const token = JSON.parse(
                //     window.atob(
                //         ((localStorage.getItem('accessToken') as string)).split('.')[1]
                //     )
                // );
                // const expireTime = moment(token.exp * 1000);
                // const expiresIn = Number(localStorage.getItem('expiresIn'));
                // const isNotExipred =
                //     expireTime.diff(moment(), 'seconds') > expiresIn - 2000;

                // if (isNotExipred) {
                if (APIInstance._allowReuqests) {
                    config.headers.Authorization = `Bearer ${ScStorage().getItem(
                        'accessToken'
                    )}`;
                    config.cancelToken = this._cancelToken.token;
                    config.timeout = 300000;
                    config.headers.isExipred = false;
                    return config;
                } else {
                    config.headers.cancelled = true;
                    if (
                        ScStorage().getItem('accessToken') &&
                        ScStorage().getItem('refreshToken')
                    ) {
                        window?.dispatchEvent?.(
                            new CustomEvent('refresh-token')
                        );
                    }
                    throw config;
                }
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        callback?.();
    }

    /**
     * Remove base url
     */
    public resetBaseURL() {
        if (this.baseURLHandler) {
            this.axios.interceptors.request.eject(this.baseURLHandler);
            this.baseURLHandler = null;
        }
    }

    /**
     * Remove interceptor environment
     */
    public resetEnvironment() {
        if (this.environmentHandler) {
            this.axios.interceptors.request.eject(this.environmentHandler);
            this.environmentHandler = null;
        }
    }

    /**
     * Remove interceptor subscription key
     */
    public resetSubscriptionKey() {
        if (this.subscriptionKeyHandler) {
            this.axios.interceptors.request.eject(this.subscriptionKeyHandler);
            this.subscriptionKeyHandler = null;
        }
    }

    public cancelAllRequests() {
        APIInstance._allowReuqests = false;
        this._cancelToken.cancel('User not authenticated');
    }

    /**
     * Interceptor to handle API failures and errors.
     */
    private setErrorHandler() {
        this.axios.interceptors.response.use(
            (response) => {
                if (response.status > 399) {
                    AppInsightsProvider.createException({
                        exception: 'API Failure Error',
                        exceptionMsg: response.data.ExceptionDetails,
                        page: '',
                        api: {
                            url: response.config.url,
                            method: response.config.method,
                        },
                    });

                    if (response.status >= 399 && response.status < 500) {
                        throw response;
                    }
                }
                return response;
            },
            (error) => {
                if (error?.config?.method) {
                    AppInsightsProvider.createException({
                        exception: error.message,
                        exceptionMsg: JSON.stringify(error.response),
                        page: '',
                        api: {
                            url: error.request,
                            method: error?.config?.method,
                        },
                    });
                }
                throw error;
            }
        );
    }

    /**
     * Remove interceptor access token
     */
    public resetAccessToken() {
        if (this.accessTokenHandler) {
            this.axios.interceptors.request.eject(this.accessTokenHandler);
            this.accessTokenHandler = null;
        }
    }

    /**
     * Add response interceptor
     * @private
     */
    private addRefreshTokenInterceptor() {
        const statusCodesToIgnoreForErrorPgae = [412, 422];
        this.axios.interceptors.response.use(
            async (response) => {
                if (!response.headers.isExipred || response?.status < 399) {
                    return response;
                } else {
                    throw response;
                }
            },
            (error) => {
                const request = error.config;
                if (
                    // Retry unauthorized requests
                    (error.response?.status === 401 &&
                        // Ignore retry requests
                        !request._retry &&
                        // Ignore RefreshToken requests
                        !request.url?.contains('RefreshToken') &&
                        // Ignore Authentication requests
                        !request.url?.contains('AuthenticationToken')) ||
                    (error?.headers && !error?.headers?.isExipred)
                ) {
                    // request._retry = true;
                    // return APIInstance.refreshToken({
                    //     origin: {
                    //         apiInstance: this,
                    //         apiRequest: request,
                    //         error: error,
                    //     },
                    // });
                    window?.dispatchEvent?.(
                        new CustomEvent('unauthorized-logout', {
                            detail: true,
                        })
                    );
                }
                if (
                    !request.url?.includes('api/Audits/') &&
                    !request.url?.endsWith('api/Token/RefreshToken') &&
                    !statusCodesToIgnoreForErrorPgae.includes(
                        error.response?.status
                    ) &&
                    error.response?.status >= 400
                ) {
                    window?.dispatchEvent?.(
                        new CustomEvent<IGlobalAPIError>('api-error-event', {
                            detail: error,
                        })
                    );
                }
                return Promise.reject(error);
            }
        );
    }

    /**
     * Refresh token on auth failure
     * Send original api request to retry
     * @private
     */
    private static async refreshToken({
        origin,
        maxRetries = MAX_TOKEN_RETRIES,
    }: IRefreshTokenProps): Promise<AxiosResponse | void> {
        const retryAgain = async () => {
            maxRetries--;
            if (maxRetries > 0) {
                await sleep(300);
                return APIInstance.refreshToken({
                    origin,
                    maxRetries,
                });
            } else {
                return Promise.reject(origin.error);
            }
        };

        try {
            const response = await componentsAPIInstance.axios({
                method: 'POST',
                url: `api/Token/RefreshToken`,
                data: {
                    refreshToken: getRefreshToken(),
                    userName: ScStorage().getItem('email'),
                },
            });
            const {
                PayLoad,
                StatusCode,
            }: { PayLoad: IAuthResponse; StatusCode: number } = response.data;
            if (StatusCode === 200 && PayLoad?.accessToken) {
                persistAuth(PayLoad);
                origin.apiInstance.setAccessToken(PayLoad?.accessToken);
                return origin.apiInstance.axios(origin.apiRequest);
            } else {
                return retryAgain();
            }
        } catch (error) {
            return;
        }
    }

    /**
     * get current environment
     */
    public getEnvironment() {
        return this._environment;
    }
}

/**
 * Axios instance used within UIComponents
 * @private
 */
export const componentsAPIInstance = new APIInstance();

export default APIInstance;
