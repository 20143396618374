import Axios from 'axios';
import { isNil } from 'lodash';

export const fetchIcon = async function (assetName: string) {
    if (!isNil(localStorage.getItem('storybook-layout'))) {
        const response = await Axios({
            url: `https://dev.perseusedge.com/api/asset/${assetName}`,
            method: 'GET',
        });
        return response.data;
    } else {
        const response = await Axios.get(`/api/asset/${assetName}`);
        return response.data;
    }
};
