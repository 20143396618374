import React, { ChangeEvent, FC, useEffect } from 'react';
import {
    Checkbox as MuiCheckbox,
    CheckboxProps,
    FormControlLabel,
} from '@material-ui/core';
import clsx from 'clsx';

export interface ICheckboxProps extends CheckboxProps {
    /**
     * Unique id for Checkbox
     */
    id: string;

    /**
     * The Checkbox is checked
     */
    checked: boolean;

    /**
     * Checkbox color
     */
    color: 'default' | 'primary' | 'secondary';

    /**
     * Disable the Checkbox
     */
    disabled?: boolean;

    /**
     * Label for  the Checkbox
     */
    label?: string;

    /**
     * OnChange event
     */
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;

    /**
     * Label Placement for the Switch
     */
    labelPlacement?: 'top' | 'start' | 'bottom' | 'end';

    /**
     * If `true`, the component appears indeterminate.
     * This does not set the native input element to indeterminate due
     * to inconsistent behavior across browsers.
     * However, we set a `data-indeterminate` attribute on the input.
     */
    indeterminate?: boolean;

    /**
     * The size of the checkbox.
     * `small` is equivalent to the dense checkbox styling.
     */
    size?: 'small' | 'medium';

    /**
     * The icon to display when the component is unchecked.
     */
    icon?: React.ReactNode;

    /**
     * The icon to display when the component is checked.
     */
    checkedIcon?: React.ReactNode;
}

const Checkbox: FC<ICheckboxProps> = ({
    id,
    className,
    checked,
    color = 'primary',
    disabled = false,
    onChange,
    label,
    labelPlacement,
    indeterminate,
    size = 'medium',
    icon,
    checkedIcon,
    ...rest
}) => {
    const [isChecked, setIsChecked] = React.useState<boolean>(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <>
            {label ? (
                <FormControlLabel
                    control={
                        <MuiCheckbox
                            id={id}
                            color={color}
                            checked={isChecked}
                            disabled={disabled}
                            onChange={handleChange}
                            name="checkedA"
                            indeterminate={indeterminate}
                            size={size}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            inputProps={{
                                'aria-label': `${label}`,
                            }}
                            className={clsx(className)}
                            {...rest}
                        />
                    }
                    label={label}
                    labelPlacement={labelPlacement}
                />
            ) : (
                <MuiCheckbox
                    id={id}
                    color={color}
                    checked={isChecked}
                    disabled={disabled}
                    onChange={handleChange}
                    indeterminate={indeterminate}
                    size={size}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    inputProps={{
                        'aria-label': `${label}`,
                    }}
                    className={clsx(className)}
                    {...rest}
                />
            )}
        </>
    );
};
export default Checkbox;
