/**
 * Check and add slash at the beginning
 */
export const addLeadingSlash = (val: string) => {
    return val ? val.replace(/^\/?/, '/') : val;
};

/**
 * Remove leading slash
 */
export const removeLeadingSlash = (val: string) => {
    return val ? val.replace(/^\/?/, '') : val;
};

/**
 * Check and add slash at end
 */
export const addTrailingSlash = (val: string) => {
    return val ? val.replace(/\/?$/, '/') : val;
};

/**
 * Remove ending slash
 */
export const removeTrailingSlash = (val: string) => {
    return val ? val.replace(/\/?$/, '') : val;
};
