import React, { FC, ReactNode } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';

import { StandardProps } from '../../../types';
import { IOption } from '../../../types/searchFilters';

export interface ISelectProps extends StandardProps<HTMLDivElement> {
    id: string;
    options: IOption[];
    label: string | ReactNode;
    multiple?: boolean;
    onChange: (val: any) => void;
    onInputChange?: (e: any) => void;
    value?: any;
    error?: boolean;
    helperText?: any;
    disabled?: boolean;
    /**
     * The size of the autocomplete
     */
    size?: 'small' | 'medium';
}

const Select: FC<ISelectProps> = ({
    id,
    label,
    options,
    multiple,
    onChange,
    onInputChange,
    value,
    error,
    helperText,
    disabled,
    size,
    ...rest
}: ISelectProps) => {
    return (
        <Autocomplete
            id={id}
            options={options}
            getOptionLabel={(option: any) => option?.title}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    error={error || false}
                    helperText={helperText}
                    disabled={disabled || false}
                />
            )}
            multiple={multiple || false}
            onChange={(e, val) => (e ? onChange(val) : null)}
            onInputChange={onInputChange}
            value={value}
            size={size}
            {...rest}
        />
    );
};

export default Select;
