import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { getSettingRolesV1 } from '../../components/Grid/Kendo/views/api';
import { VerticalNavSection } from '../../components/Nav/VerticalNavSection';
import { HoverStates } from '../../components/TopBar/Modules';
import { ITheme, useProfile, useSettings } from '../../core/Providers';
import ModuleInfoContext from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import ScStorage from '../../core/Storage';
import { storage } from '../../core/Store';
import { IPermission, IResources } from '../../types/user';
import { ModuleName } from '../../utils/modules/moduleName';

const styles = makeStyles((theme: ITheme) => ({
    fullNavRoot: {
        width: '77%',
    },
    headerRow: {
        borderBottom: '1px solid #ECECEC',
        margin: '0 0 10px',
        height: '52px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '20px',
    },
    headerText: {
        color: theme.palette.primary.main,
    },
    MultidistrictName: {
        lineHeight: '1.2em',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: 'rgba(4, 0, 77, 0.04)',
        },
        fontSize: '15px',
        paddingTop: '5px',
    },
    districtName: {
        lineHeight: '1.2em',
        cursor: 'pointer',
        fontSize: '15px',
        paddingTop: '5px',
    },
}));

interface IFullNavProps {
    currentModule: ModuleName;
    isExpanded: boolean;
    expandType: HoverStates;
}

export const FullNav = ({
    currentModule,
    isExpanded,
    expandType,
}: IFullNavProps) => {
    const classes = styles();
    const { user } = useProfile();
    const location = useLocation();
    const { navClosedOnLoad } = useSettings();
    const moduleContext = useContext(ModuleInfoContext);

    const [selectedPermissions, setSelectedPermissions] = useState<
        IResources[] | null
    >();
    const [isDistrictLoading, setIsDistrictLoading] = useState<boolean>(true);
    const activeDistrictName = storage.getItem('activeDistrict');
    const activeDistrict = JSON.parse(activeDistrictName ?? '{}');
    const history = useHistory();

    const shouldShowExpandedView = useMemo(() => {
        return (
            expandType === HoverStates.ENTER ||
            (isExpanded && !navClosedOnLoad.includes(location.pathname))
        );
    }, [isExpanded, location, expandType]);

    useEffect(() => {
        if (activeDistrict && activeDistrict.name) {
            setIsDistrictLoading(false);
        }
    }, [activeDistrict]);

    useEffect(() => {
        if (currentModule && user && user.RolesPermissions) {
            const permissions = user.RolesPermissions.filter(
                (permission: IPermission) =>
                    permission.moduleName === currentModule
            );
            if (permissions && permissions.length === 1) {
                setSelectedPermissions(() => permissions[0].resources);
            }
        }
    }, [user, currentModule]);

    const opendistrictSelectionPage = async () => {
        if (user?.Groups?.length && user?.Groups?.length > 1) {
            await getSettingRolesV1(
                moduleContext.apimUrl,
                activeDistrict?.attributes?.regionId?.[0]
            ).then((response: any) => {
                if (response.data.StatusCode === 200) {
                    ScStorage().setItem(
                        'settingValueRolesV1',
                        response.data.PayLoad
                    );
                }
            });
            if (currentModule === ModuleName.Workspace) {
                history.push('/login?districtSelection=true');
            } else {
                window.location.href = '/login?districtSelection=true';
            }
        }
    };

    if (shouldShowExpandedView) {
        return (
            <>
                <div className={classes.fullNavRoot}>
                    <div
                        style={{
                            display: 'flex',
                            borderBottom: '1px solid #ECECEC',
                            height: '52px',
                            width: '100%',
                            padding: '17px',
                            marginTop: '-52px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            // alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="h1"
                            onClick={opendistrictSelectionPage}
                            className={
                                user?.Groups?.length && user?.Groups?.length > 1
                                    ? classes.MultidistrictName
                                    : classes.districtName
                            }
                        >
                            {isDistrictLoading
                                ? 'Loading...'
                                : activeDistrict.name}
                        </Typography>
                    </div>
                    <div className={classes.headerRow}>
                        <Typography
                            variant="h5"
                            classes={{
                                root: classes.headerText,
                            }}
                            color="textPrimary"
                        >
                            {currentModule}
                        </Typography>
                    </div>
                    <VerticalNavSection
                        currentModule={currentModule}
                        resources={selectedPermissions}
                    />
                </div>
            </>
        );
    } else return null;
};
