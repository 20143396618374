export enum Themes {
    Perseus = 'Perseus',
    Light = 'Light',
    Dark = 'Dark',
}

export type ThemeType = keyof typeof Themes;

export enum ThemeColor {
    default = 'default',
    primary = 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    success = 'success',
    warning = 'warning',
    error = 'error',
    info = 'info',
}

export type ThemeColorType = keyof typeof ThemeColor;

export type TextColorType =
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';

export type TextAlignType = 'inherit' | 'left' | 'center' | 'right' | 'justify';
