import React, { FC } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';

import { ITheme } from '../../../core/Providers';

export interface IUserAvatarProps {
    src: string;
    alt?: string;
}

const classNames = makeStyles((theme: ITheme) => {
    return {
        avtar: {
            backgroundColor: '#F2F2F2',
            color: theme.palette.primary.main,
        },
    };
});

const UserAvatar: FC<IUserAvatarProps> = ({ ...rest }) => {
    const styles = classNames();

    return (
        <Avatar
            style={{
                width: 120,
                height: 120,
                margin: 20,
            }}
            className={styles.avtar}
            {...rest}
        />
    );
};

export default UserAvatar;
