import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ITheme } from '../../../core/Providers';

export enum TitleType {
    FILTERS = 'FILTERS',
    COLUMNS = 'COLUMNS',
    VIEWS = 'VIEWS',
}

export const titleNames = {
    filters: 'FILTERS',
    columns: 'COLUMNS',
    views: 'SAVED VIEWS',
};

export interface IColumnsAndViewsTitle {
    onTitleSelection: (title: TitleType) => void;
    showViews: boolean;
    selectedType: TitleType;
    showColumns?: boolean;
    showFilters?: boolean;
}

const styles = makeStyles((theme: ITheme) => ({
    root: {
        display: 'flex',
        gap: '0px',
        position: 'absolute',
        marginTop: '-8px',
    },
    selected: {
        color: theme.palette.primary.main,
        fontSize: '12px',
        fontWeight: 500,
    },
    selectedTab: {
        borderBottom: '2px solid #665AD8',
    },
    default: {
        color: theme.palette.text.disabled,
        fontSize: '12px',
        fontWeight: 500,
    },
    item: {
        padding: '5px 10px',
        '&:hover': {
            cursor: 'pointer',
            background: theme.palette.background.grey,
        },
    },
}));

export const ColumnsAndViewsTitle = ({
    onTitleSelection,
    showViews,
    selectedType,
    showColumns,
    showFilters,
}: IColumnsAndViewsTitle) => {
    const classes = styles();
    const [selectedTitle, setSelectedTitle] = useState<TitleType>(selectedType);

    function onTabClick(type: TitleType) {
        setSelectedTitle(() => type);
        onTitleSelection(type);
    }
    return (
        <div className={classes.root}>
            {showFilters && (
                <div
                    className={clsx(
                        classes.item,
                        selectedTitle === TitleType.FILTERS
                            ? classes.selectedTab
                            : ''
                    )}
                    onClick={onTabClick.bind(null, TitleType.FILTERS)}
                >
                    <Typography
                        id="filters-tab-title"
                        variant="body1"
                        color="textPrimary"
                        className={
                            selectedTitle === TitleType.FILTERS
                                ? classes.selected
                                : classes.default
                        }
                    >
                        {titleNames.filters}
                    </Typography>
                </div>
            )}

            {showColumns && (
                <div
                    className={clsx(
                        classes.item,
                        selectedTitle === TitleType.COLUMNS
                            ? classes.selectedTab
                            : ''
                    )}
                    onClick={onTabClick.bind(null, TitleType.COLUMNS)}
                >
                    <Typography
                        id="columns-tab-title"
                        variant="body1"
                        color="textPrimary"
                        className={
                            selectedTitle === TitleType.COLUMNS
                                ? classes.selected
                                : classes.default
                        }
                    >
                        {titleNames.columns}
                    </Typography>
                </div>
            )}

            {showViews && (
                <div
                    className={clsx(
                        classes.item,
                        selectedTitle === TitleType.VIEWS
                            ? classes.selectedTab
                            : ''
                    )}
                    onClick={onTabClick.bind(null, TitleType.VIEWS)}
                >
                    <Typography
                        id="saved-views-tab-title"
                        variant="body1"
                        color="textPrimary"
                        className={
                            selectedTitle === TitleType.VIEWS
                                ? classes.selected
                                : classes.default
                        }
                    >
                        {titleNames.views}
                    </Typography>
                </div>
            )}
        </div>
    );
};
