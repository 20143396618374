import React, { FC, forwardRef, useContext, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { Card } from '../../components/Card';
import DistrictDropDown from '../../components/TopBar/DistrctDropDown';
import { useAuth, useProfile } from '../../core/Providers';
import ModuleInfoContext from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import ScStorage from '../../core/Storage';
import { IGroup } from '../../types';
import { componentsAPIInstance } from '../../utils';

import { useStyles } from './Login';

interface IDistrictSelectionProps {
    apimUrl: string;
    subscriptionKey?: string;
    refresh?: boolean;
    isDialogClosedWithoutSave?: boolean | undefined;
}

const DistrictSelection: FC<IDistrictSelectionProps> = forwardRef(
    ({ apimUrl, subscriptionKey }) => {
        const { user } = useProfile();
        const classes = useStyles();
        const { isAuthenticated } = useAuth();
        const { setActiveDistrict } = useContext(ModuleInfoContext);
        const [storedDistrict, setStoredDistrict] = useState<IGroup | null>(
            null
        );
        /**
         * Get stored district from localStorage
         */
        useEffect(() => {
            try {
                if (user?.Groups?.length && user?.Groups?.length > 1) {
                    const districtData = user?.Groups?.find((group: any) => {
                        if (
                            group.attributes.regionId[0] ===
                            JSON.parse(
                                ScStorage().getItem('activeDistrict') ?? '{}'
                            )?.attributes?.regionId?.[0]
                        ) {
                            return group;
                        }
                    });
                    if (districtData) {
                        setStoredDistrict(districtData);
                    }
                }
            } catch (e) {
                console.error(e);
                window.location.href = '/';
            }
        }, [apimUrl, subscriptionKey, user]);

        const handleDistrictChange = async (
            district: IGroup | null,
            asDefaultSelected?: boolean
        ) => {
            setStoredDistrict(district);
            if (district?.attributes?.regionId?.[0]) {
                ScStorage().setItem(
                    'userRegion',
                    district?.attributes?.regionId?.[0]
                );
            }
            district && setActiveDistrict(district);
            ScStorage().setItem('activeDistrict', JSON.stringify(district));
            ScStorage().removeItem('allowedRoutes');
            if (asDefaultSelected) {
                await componentsAPIInstance.axios({
                    method: 'PATCH',
                    url: `${apimUrl}/administration/v1.0/api/User/${user?.UserId}/regionId/${district?.attributes?.regionId?.[0]}`,
                });
            }
        };

        return (
            <Box className={clsx(classes.cardContainer)}>
                {isAuthenticated &&
                    user?.Groups?.length &&
                    user?.Groups?.length > 1 && (
                        <>
                            <Card
                                className={classes.card}
                                id="loginCard"
                                content={
                                    <>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            height="100%"
                                            width="100%"
                                        >
                                            <Box
                                                className={classes.cardContent}
                                                style={{
                                                    minWidth: 400,
                                                    minHeight: 220,
                                                }}
                                            >
                                                <Box
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    {user && (
                                                        <DistrictDropDown
                                                            apimUrl={apimUrl}
                                                            user={user}
                                                            storedDistrict={
                                                                storedDistrict
                                                            }
                                                            onDistrictChange={
                                                                handleDistrictChange
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </>
                                }
                            ></Card>
                        </>
                    )}
            </Box>
        );
    }
);
export default DistrictSelection;
