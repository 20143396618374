import React, { useCallback, useContext } from 'react';
import {
    Divider,
    FormControl,
    IconButton,
    Typography,
} from '@material-ui/core';
import { isEmpty } from 'lodash';

import { storage, Trash2Icon } from '../../..';
import { PageProviderContext } from '../../../core/Providers';
import ModuleInfoContext from '../../../core/Providers/ModuleInfo/ModuleInfoContext';
import { Button } from '../../Button';
import { Label } from '../../Label';
import { Notification } from '../../Notifications';

import { updateView } from './views/api';
import ShareViewUtil from './views/ShareViewUtil';
import KGrid from './KGrid';

const ShareView = ({ view }: any) => {
    const moduleContext = useContext(ModuleInfoContext);
    const [selectedUsers, setSelectedUsers] = React.useState<any>([]);
    const activeDistrict = storage.getItem('activeDistrict');
    const activeDistrictParsed = JSON.parse(activeDistrict ?? '{}');
    const regionId = activeDistrictParsed?.attributes?.regionId?.[0];
    const [disableShareButton, setDisableShareButton] = React.useState<boolean>(
        true
    );
    const [sharedReportSuccess, setSharedReportSuccesss] = React.useState<
        boolean
    >(false);
    const [sharedReportError, setSharedReportError] = React.useState<boolean>(
        false
    );
    const [deleteUserSuccess, setDeleteUserSuccess] = React.useState<boolean>(
        false
    );
    const [deleteUserError, setDeleteUserError] = React.useState<boolean>(
        false
    );
    const [loading, setLoading] = React.useState<boolean>(true);
    const [reportSharedUsers, setReportSharedUsers] = React.useState<any>([]);
    const [savedViews, setSavedViews] = React.useState<any>(
        view?.customViewShare
    );
    const pageContext = useContext(PageProviderContext);
    //this is needed to clear the autocomplete value after sharing the view
    const [clearValues, setClearValues] = React.useState<string>('1');

    const customViewPageName = pageContext.customViewPageName!;
    const shareReport = useCallback(async () => {
        //changing the clearValues to 2, so that the component will rerender with cleared values
        setClearValues('2');
        setSharedReportSuccesss(false);
        setSharedReportError(false);
        const allSelectedUsers: any[] = [];
        const allSelectedRoles: any[] = [];
        const allSelectedGroups: any[] = [];
        if (savedViews && !isEmpty(savedViews?.userIds)) {
            allSelectedUsers.push(
                ...savedViews?.userIds,
                ...selectedUsers?.userIds
            );
        } else {
            allSelectedUsers.push(...selectedUsers?.userIds);
        }
        if (savedViews && !isEmpty(savedViews?.roleIds)) {
            allSelectedRoles.push(
                ...savedViews?.roleIds,
                ...selectedUsers.roleIds
            );
        } else {
            allSelectedRoles.push(...selectedUsers.roleIds);
        }
        if (savedViews && !isEmpty(savedViews?.groupIds)) {
            allSelectedGroups.push(
                ...savedViews?.groupIds,
                ...selectedUsers.groupIds
            );
        } else {
            allSelectedGroups.push(...selectedUsers.groupIds);
        }

        const resultedUsres = allSelectedUsers.filter(
            (item: any, index: any) => {
                return allSelectedUsers.indexOf(item) === index;
            }
        );
        const resultedRoles = allSelectedRoles.filter(
            (item: any, index: any) => {
                return allSelectedRoles.indexOf(item) === index;
            }
        );
        const resultedGroups = allSelectedGroups.filter(
            (item: any, index: any) => {
                return allSelectedGroups.indexOf(item) === index;
            }
        );
        const requestData = {
            updateCustomViewShareRequest: {
                userIds: resultedUsres,
                roleIds: resultedRoles,
                groupIds: resultedGroups,
            },
            viewName: view.viewName,
        };
        await updateView(
            view.id,
            customViewPageName,
            moduleContext.apimUrl,
            requestData
        )
            .then((response: any) => {
                if (
                    response.data.StatusCode === 200 &&
                    response.data.PayLoad === 'true'
                ) {
                    setSharedReportSuccesss(true);
                    setSavedViews({
                        userIds: resultedUsres,
                        roleIds: resultedRoles,
                        groupIds: resultedGroups,
                    });
                }
            })
            .catch((e: any) => {
                console.log('error sharing view ', e);
                setSharedReportSuccesss(false);
                setSharedReportError(true);
            })
            .finally(() => {
                setSelectedUsers(() => []);
                //setting the clearValue back to 1 to make autocomplete not clear on second selection
                setClearValues('1');
            });
    }, [selectedUsers]);

    async function deleteSharedUser(userToDelete: any) {
        setDeleteUserSuccess(false);
        setDeleteUserError(false);
        const rsu = reportSharedUsers
            .filter(
                (item: any) => item.primeroUserId !== userToDelete.primeroUserId
            )
            .map((data: any) => {
                return parseInt(data.primeroUserId);
            })
            .filter((el: number) => !Number.isNaN(el));

        const roles = reportSharedUsers
            .filter((item: any) => item.roleGuid !== userToDelete.roleGuid)
            .map((data: any) => {
                return data.roleGuid;
            })
            .filter((el: string) => el != null);
        const groups = reportSharedUsers
            .filter((item: any) => item.groupGuid !== userToDelete.groupGuid)
            .map((data: any) => {
                return data.groupGuid;
            })
            .filter((el: string) => el != null);
        const requestData = {
            updateCustomViewShareRequest: {
                userIds: rsu,
                roleIds: roles,
                groupIds: groups,
            },
            viewName: view.viewName,
        };
        await updateView(
            view.id,
            customViewPageName,
            moduleContext.apimUrl,
            requestData
        )
            .then((response: any) => {
                if (response.data.StatusCode === 200) {
                    setDeleteUserSuccess(true);
                    setSelectedUsers([]);
                    setSavedViews({
                        userIds: rsu,
                        roleIds: roles,
                        groupIds: groups,
                    });
                }
            })
            .catch((e: any) => {
                console.log('Unable to delete ', e);
                setDeleteUserSuccess(false);
                setDeleteUserError(true);
            });
    }
    const deleteUserFromShare = (data: any) => {
        deleteSharedUser(data.dataItem);
    };
    return (
        <div
            style={{
                padding: '20px 0px',
                textAlign: 'right',
            }}
        >
            {sharedReportSuccess && (
                <Notification
                    color="success"
                    duration={5000}
                    message="View shared successfully"
                    onClose={() => setSharedReportSuccesss(false)}
                    open
                    variant="filled"
                />
            )}

            {sharedReportError && (
                <Notification
                    color="error"
                    duration={5000}
                    message="Error sharing view"
                    onClose={() => setSharedReportError(false)}
                    open
                    variant="filled"
                />
            )}
            {deleteUserSuccess && (
                <Notification
                    color="success"
                    duration={5000}
                    message="Record deleted successfully"
                    onClose={() => setDeleteUserSuccess(false)}
                    open
                    variant="filled"
                />
            )}

            {deleteUserError && (
                <Notification
                    color="error"
                    duration={5000}
                    message="Error deleting record"
                    onClose={() => setDeleteUserError(false)}
                    open
                    variant="filled"
                />
            )}
            <ShareViewUtil
                moduleContext={moduleContext.apimUrl}
                setSelectedUsersToShare={setSelectedUsers}
                selectedUsersToShare={selectedUsers}
                autoCompleteFlag={true}
                savedViews={savedViews}
                setReportSharedUsers={setReportSharedUsers}
                reportSharedUsers={reportSharedUsers}
                setLoading={setLoading}
                setDisableShareButton={setDisableShareButton}
                clearValues={clearValues}
                setClearValues={setClearValues}
                regionId={regionId}
            />
            <FormControl variant="standard">
                <Button
                    id="button"
                    fullWidth={true}
                    color="primary"
                    onClick={shareReport}
                    style={{
                        float: 'right',
                        marginTop: '10px',
                    }}
                    disabled={disableShareButton}
                >
                    SHARE
                </Button>
            </FormControl>
            <Divider
                variant="fullWidth"
                style={{
                    marginTop: '20px',
                }}
            />
            <Typography
                variant="h6"
                style={{
                    textAlign: 'left',
                    marginTop: '10px',
                }}
            >
                View shared with following users & roles
            </Typography>
            <KGrid
                style={{
                    marginTop: '10px',
                }}
                columns={[
                    {
                        field: 'userName',
                        title: 'Users',
                        cell: (data) => {
                            return (
                                <td>
                                    {data.dataItem.description.includes(
                                        `(Group)`
                                    ) ||
                                    data.dataItem.description.includes(
                                        `(Role)`
                                    ) ? (
                                        <>
                                            {
                                                data.dataItem.description.split(
                                                    '('
                                                )[0]
                                            }
                                            <Label color="info" id="infoLabel">
                                                {data.dataItem?.description
                                                    ?.split('(')[1]
                                                    ?.replace(/[{()}]/g, '')}
                                            </Label>
                                        </>
                                    ) : (
                                        data.dataItem.description
                                    )}
                                </td>
                            );
                        },
                    },
                    {
                        cell: (data) => {
                            return (
                                <td>
                                    <IconButton
                                        color="default"
                                        id="views-menu-dropdown"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            deleteUserFromShare(data);
                                        }}
                                        type="button"
                                        aria-label="views menu dropdown"
                                    >
                                        <Trash2Icon />
                                    </IconButton>
                                </td>
                            );
                        },
                        title: 'Action',
                    },
                ]}
                data={reportSharedUsers}
                loading={loading}
                primaryField="Personalinvite"
                headerStyles={[]}
                pageable={true}
                pageSize={10}
                pageSizes={[5, 10, 15, 20]}
            />
        </div>
    );
};
export default ShareView;
