import React, { FC } from 'react';
import {
    jssPreset,
    StylesProvider,
    ThemeProvider as MuiThemeProvider,
    ThemeProviderProps,
} from '@material-ui/core';
import { create } from 'jss';

import { Optional } from '../../../utils';
import useSettings from '../Settings/useSettings';

import { createTheme, ITheme } from './theme';

export interface IThemeProviderProps
    extends Optional<ThemeProviderProps, 'theme'> {}

const ThemeProvider: FC<IThemeProviderProps> = ({ children, theme }) => {
    const { settings } = useSettings();
    const jss = create({
        plugins: [...jssPreset().plugins],
    });

    const initialTheme = createTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    });

    /**
     * Update root CSS variables
     */
    updateCSSVariables(theme || initialTheme);

    /**
     * Add theme classname to body
     */
    document.body.setAttribute('data-theme', initialTheme.name?.toLowerCase());

    return (
        <MuiThemeProvider theme={theme || initialTheme}>
            <StylesProvider jss={jss}>{children}</StylesProvider>
        </MuiThemeProvider>
    );
};

const updateCSSVariables = (theme: Partial<ITheme>) => {
    const values = new Map([
        ['--primary', theme.palette?.primary.main],
        ['--secondary', theme.palette?.secondary.main],
        ['--tertiary', theme.palette?.tertiary.main],
        ['--success', theme.palette?.success.main],
        ['--warning', theme.palette?.warning.main],
        ['--error', theme.palette?.error.main],
        ['--info', theme.palette?.info.main],
        ['--grey', theme.palette?.grey[600]],
        ['--gray', theme.palette?.grey[600]],
        ['--light-grey', theme.palette?.grey[100]],
        ['--light-gray', theme.palette?.grey[100]],
        ['--background', theme.palette?.background.default],
        ['--text', theme.palette?.text.primary],
        ['--text-secondary', theme.palette?.text.secondary],
    ]);

    values.forEach((value, key) => {
        if (value) {
            document.documentElement.style.setProperty(key, value);
        } else {
            console.warn(`Invalid value provided for ${key}`);
        }
    });
};

export default ThemeProvider;
