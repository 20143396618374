import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import { GridHeaderCellProps } from '@progress/kendo-react-grid';
import { GridCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridCellProps';

import { IGridExpandChangeEvent } from './types';

export interface IExpandCollapseHeaderCellProps extends GridHeaderCellProps {}

export interface IExpandCollapseCellProps extends GridCellProps {
    expandField: string;
    hideExpandField: string;
    onExpandChange: (ev: IGridExpandChangeEvent) => void;
}

export interface IExpandChangeProps {
    dataItem: any;
    dataIndex: number;
}

/**
 * Header Cell
 */
export const ExpandCollapseHeaderCell: FC<IExpandCollapseHeaderCellProps> = () => (
    <></>
);

/**
 * Cell
 */
export const ExpandCollapseCell: FC<IExpandCollapseCellProps> = ({
    dataItem,
    dataIndex,
    onExpandChange,
    expandField,
    hideExpandField,
}) => {
    const handleExpandChange = () => {
        onExpandChange({
            value: !dataItem[expandField],
            dataItem: Object.assign(dataItem, {
                [expandField]: !dataItem[expandField],
            }),
            dataIndex,
        });
    };

    /**
     * Do not render expand collapse icon based on HIDE_EXPAND_FIELD prop
     */
    const containsKey = (object: object, key: string) => {
        return !!Object.keys(object).find(
            (k) => k.toLowerCase() === key.toLowerCase()
        );
    };

    if (containsKey(dataItem, hideExpandField)) {
        return <td className="k-hierarchy-cell" />;
    }

    return (
        <>
            <td className="k-hierarchy-cell">
                {dataItem[expandField] ? (
                    <IconButton
                        color="primary"
                        size="small"
                        onClick={handleExpandChange}
                    >
                        <span className="k-icon k-minus" />
                    </IconButton>
                ) : (
                    <IconButton
                        color="primary"
                        size="small"
                        onClick={handleExpandChange}
                    >
                        <span className="k-icon k-plus" />
                    </IconButton>
                )}
            </td>
        </>
    );
};
