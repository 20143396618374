import React, { FC, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { isNull } from 'lodash';

import { Button } from '../../components/Button';
export interface IErrorPageProps {
    errorCode?: number;
    errorMessage?: string;
    setAPIFailureMessage?: any;
}

const errorMessages = {
    OOPS: 'Oops!',
    SOMETHING_WRONG: 'Something is not working...',
    DESCRIPTION: 'An error has occurred and we will look into the problem.',
};

const useStyles = makeStyles(() => ({
    oops: {
        color: '#459BEA',
        fontSize: '2rem',
        fontWeight: 700,
        textAlign: 'left',
        margin: '0',
    },
    errorCode: {
        color: '#459BEA',
        fontSize: '2rem',
        fontWeight: 700,
        textAlign: 'left',
        margin: 0,
    },
    somethingWrong: {
        color: '#544AB4',
        fontSize: '2.5rem',
        fontWeight: 500,
        textAlign: 'left',
        margin: 0,
    },
    description: {
        color: '#666666',
        fontSize: '1rem',
        fontWeight: 700,
        textAlign: 'left',
        margin: 0,
    },
    actionContainer: {
        fontSize: '1rem',
        fontWeight: 700,
        textAlign: 'left',
        margin: 0,
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
    },
    content: {
        textAlign: 'center',
    },
}));

const ErrorPage: FC<IErrorPageProps> = ({
    errorMessage,
    errorCode,
    setAPIFailureMessage,
}: IErrorPageProps) => {
    const classes = useStyles();
    useEffect(() => {
        if (setAPIFailureMessage) {
            setAPIFailureMessage(null);
        }
    });

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <Typography className={classes.oops}>
                    {errorMessages.OOPS}
                </Typography>
                <Typography className={classes.somethingWrong}>
                    {errorMessages.SOMETHING_WRONG}
                </Typography>
                <Typography className={classes.description}>
                    {errorMessages.DESCRIPTION}
                    {!isNull(errorMessage) && JSON.stringify(errorMessage)}
                </Typography>
                <Typography className={classes.errorCode}>
                    Error Code: {errorCode}
                </Typography>
                <Typography className={classes.actionContainer}>
                    <Button
                        onClick={() => window.location.reload()}
                        id="try-again"
                        label="TRY AGAIN"
                        color="primary"
                    />
                </Typography>
            </div>
        </div>
    );
};
export default ErrorPage;
