import React, { FC } from 'react';
import {
    IconButton,
    Menu as MatMenu,
    MenuItem,
    MenuListProps,
    PaperProps,
    PopoverOrigin,
    PopoverProps,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';

import Button, { IButtonProps } from '../Button/Button';

interface IMenuProps {
    /**
     * ID
     */
    id: string;
    /**
     * A HTML element, or a function that returns it.
     * It's used to set the position of the menu.
     * @document
     */
    anchorEl?: PopoverProps['anchorEl'];
    /**
     * If `true` (Default) will focus the `[role="menu"]` if no focusable child is found. Disabled
     * children are not focusable. If you set this prop to `false` focus will be placed
     * on the parent modal container. This has severe accessibility implications
     * and should only be considered if you manage focus otherwise.
     */
    autoFocus?: boolean;
    /**
     * Menu contents, normally `MenuItem`s.
     */
    children?: React.ReactNode;
    /**
     * When opening the menu will not focus the active item but the `[role="menu"]`
     * unless `autoFocus` is also set to `false`. Not using the default means not
     * following WAI-ARIA authoring practices. Please be considerate about possible
     * accessibility implications.
     */
    disableAutoFocusItem?: boolean;
    /**
     * Props applied to the [`MenuList`](/api/menu-list/) element.
     */
    MenuListProps?: Partial<MenuListProps>;
    /**
     * Callback fired when the component requests to be closed.
     *
     * @param {object} event The event source of the callback.
     * @param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`, `"tabKeyDown"`.
     */
    onClose?: PopoverProps['onClose'];
    /**
     * Callback fired before the Menu enters.
     * @document
     */
    onEnter?: PopoverProps['onEnter'];
    /**
     * Callback fired when the Menu has entered.
     * @document
     */
    onEntered?: PopoverProps['onEntered'];
    /**
     * Callback fired when the Menu is entering.
     * @document
     */
    onEntering?: PopoverProps['onEntering'];
    /**
     * Callback fired before the Menu exits.
     * @document
     */
    onExit?: PopoverProps['onExit'];
    /**
     * Callback fired when the Menu has exited.
     * @document
     */
    onExited?: PopoverProps['onExited'];
    /**
     * Callback fired when the Menu is exiting.
     * @document
     */
    onExiting?: PopoverProps['onExiting'];
    /**
     * If `true`, the menu is visible.
     */
    open?: boolean;
    /**
     * `classes` prop applied to the [`Popover`](/api/popover/) element.
     */
    PopoverClasses?: PopoverProps['classes'];
    /**
     * The length of the transition in `ms`, or 'auto'
     */
    transitionDuration?: TransitionProps['timeout'] | 'auto';
    /**
     * The variant to use. Use `menu` to prevent selected items from impacting the initial focus
     * and the vertical alignment relative to the anchor element.
     */
    variant?: 'menu' | 'selectedMenu';

    PaperProps?: Partial<PaperProps>;

    icon?: React.ReactNode;

    buttonLabel?: string;

    menuContent: IMenuItems[];

    buttonProps?: IButtonProps;

    /**
     * icon color
     */
    iconColor?: 'inherit' | 'default' | 'primary' | 'secondary' | undefined;
    /**
     * To remove the matginTop when adding the icon button in KGrid
     */
    className?: any;

    style?: any;

    anchorOrigin?: PopoverOrigin;

    transformOrigin?: PopoverOrigin;
}
export interface IMenuItems {
    menuItem: string;
    icon?: any;
    onClick?: (
        event: React.MouseEventHandler<HTMLLIElement>,
        index: number
    ) => void;
}
const Menu: FC<IMenuProps> = ({
    id,
    menuContent,
    PaperProps,
    icon,
    buttonLabel,
    iconColor,
    buttonProps,
    className,
    style,
    anchorOrigin,
    transformOrigin,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(null);
    };
    return (
        <>
            {icon ? (
                <IconButton
                    aria-label={id}
                    id={id}
                    aria-controls={open ? 'simple-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    color={iconColor}
                    className={className}
                    style={style}
                >
                    {icon}
                </IconButton>
            ) : (
                <Button
                    id={id}
                    aria-controls={open ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    {...buttonProps}
                >
                    {buttonLabel}
                </Button>
            )}

            <MatMenu
                id="menu"
                MenuListProps={{
                    'aria-labelledby': 'simple-menu',
                }}
                anchorEl={anchorEl}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                open={open}
                onClose={handleClose}
                PaperProps={PaperProps}
            >
                {menuContent.map((menu, index) => {
                    return (
                        <div>
                            <MenuItem
                                key={index}
                                onClick={(event: any) => {
                                    if (menu.onClick) {
                                        menu.onClick(event, index);
                                    }
                                    setAnchorEl(null);
                                }}
                            >
                                {menu.icon && menu.icon}
                                <span
                                    style={{
                                        marginLeft: '5px',
                                    }}
                                >
                                    {menu.menuItem}
                                </span>
                            </MenuItem>
                        </div>
                    );
                })}
            </MatMenu>
        </>
    );
};

export default Menu;
