import React from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

interface IPasswordStrengthProps {
    minCharacters: number;
    validLength: boolean;
    upperLowerChar: string;
    lowerCaseValid: boolean;
    specialChar: number;
    isSpecial: boolean;
    numericChar: number;
    isNumber: boolean;
}

const PasswordStrength = ({
    minCharacters,
    validLength,
    upperLowerChar,
    lowerCaseValid,
    specialChar,
    isSpecial,
    numericChar,
    isNumber,
}: IPasswordStrengthProps) => {
    return (
        <Box my={1}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="p" gutterBottom>
                        Password must have:
                    </Typography>
                    {minCharacters ? (
                        <Typography
                            color={validLength ? 'primary' : 'textPrimary'}
                        >
                            - At least {minCharacters} characters
                        </Typography>
                    ) : (
                        ''
                    )}
                    {upperLowerChar === 'True' ? (
                        <Typography
                            color={lowerCaseValid ? 'primary' : 'textPrimary'}
                        >
                            - Both uppercase and lowercase characters
                        </Typography>
                    ) : (
                        ''
                    )}
                    {specialChar ? (
                        <Typography
                            color={isSpecial ? 'primary' : 'textPrimary'}
                        >
                            - At least {specialChar} special{' '}
                            {specialChar > 1 ? 'characters' : 'character'} (
                            {'>'}, {'<'}, and {'%'} are not allowed)
                        </Typography>
                    ) : (
                        ''
                    )}
                    {numericChar ? (
                        <Typography
                            color={isNumber ? 'primary' : 'textPrimary'}
                        >
                            - At least {numericChar}{' '}
                            {numericChar > 1 ? 'numbers' : 'number'}
                        </Typography>
                    ) : (
                        ''
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default PasswordStrength;
