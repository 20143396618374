import { useEffect, useRef, useState } from 'react';
import { isNull } from 'lodash';
import moment from 'moment';

const MAX_TIME = 40;

export interface IUseWaitTillElementExistsProps {
    elementId: string;
    refresh?: boolean;
    resetRefresh?: Function;
}

export interface IUseWaitTillElementExistsResult {
    isVisibleInDom: boolean;
    isTimeEllapsed: boolean;
}

/**
 * @hooks
 * Custom hook to check if an element is visible on dom
 * @param elementId string unique ID of the element.
 * @param refresh boolean Optional param to retrigger the hook.
 * @param resetRefresh Function Optional function to reset the refresh param to false if refresh is passed.
 *
 * @returns isVisibleInDom boolean
 * @returns isTimeEllapsed boolean
 *
 * ```typescript
 *
 *  import { useWaitTillElementExists } from '@primeroedge/ui-components';
 *
 *  const {isVisibleInDom, isTimeEllapsed} = useWaitTillElementExists({elementId: 'some-id'});
 * ```
 */
const useWaitTillElementExists = ({
    elementId,
    refresh = false,
    resetRefresh = undefined,
}: IUseWaitTillElementExistsProps): IUseWaitTillElementExistsResult => {
    const [isVisibleInDom, setIsVisibleInDom] = useState<boolean>(false);
    const [isTimeEllapsed, setIsTimeEllapsed] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<number>(moment().unix());
    const timerRef = useRef<any>(null);

    const checkInDom = function () {
        if (startTime + MAX_TIME > moment().unix()) {
            const element = document.getElementById(elementId);
            if (!isNull(element)) {
                setIsVisibleInDom(true);
                clearInterval(timerRef.current);
            } else {
                setIsVisibleInDom(false);
            }
        } else {
            setIsTimeEllapsed(true);
            clearInterval(timerRef.current);
        }
    };

    const timer = function () {
        timerRef.current = setInterval(checkInDom, 2000);
    };

    useEffect(function () {
        timer();

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []);

    useEffect(
        function () {
            if (refresh) {
                setIsVisibleInDom(false);
                setIsTimeEllapsed(false);
                setStartTime(moment().unix());
            }
        },
        [refresh]
    );

    useEffect(
        function () {
            if (refresh) {
                timer();
                resetRefresh?.();
            }
        },
        [startTime]
    );

    return {
        isVisibleInDom,
        isTimeEllapsed,
    };
};

export default useWaitTillElementExists;
