/**
 * Default page size
 */
export const PAGE_SIZE = 10;

/**
 * Default page sizes
 */
export const PAGE_SIZES = [5, 10, 20, 50, 100];

/**
 * Minimum number of columns to show hide columns
 */
export const FILTER_MIN_COLUMNS = 1;

/**
 * Field name for expand collapse cell
 */
export const EXPAND_FIELD = 'expanded';

/**
 * Property name to hide expand collapse cell in a row
 * This will not work when table has grouping
 */
export const HIDE_EXPAND_FIELD = 'HideRowDetail';
