import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useReducer,
} from 'react';
import { AxiosError } from 'axios';
import { isNil } from 'lodash';

import { AppLoader } from '../../../../components/Loaders';
import { useUserInfo } from '../../../../hooks';
import { ResponseStatus } from '../../../../types/response';
import { IUser } from '../../../../types/user';
import { componentsAPIInstance } from '../../../../utils';
import ScStorage from '../../../Storage';
import { persistUserProfile } from '../../../Store/persist';
import AuthContext from '../AuthContext';

import profileReducer from './profile.reducer';
import ProfileContext, { initialUserState } from './ProfileContext';

interface IUserProfileProviderProps {
    isAuthenticated: boolean;
    apimUrl?: string;
    environment?: string;
    subscriptionKey?: string;
    onError: (error: AxiosError) => void;
    children: any;
}
interface ICSUserProfileCallBackProps {
    regionId: number;
    onSuccessCallback: () => void;
    onErrorCallBack: () => void;
}

const ProfileProvider = forwardRef(
    (
        {
            children,
            isAuthenticated,
            apimUrl,
            subscriptionKey,
            onError,
        }: IUserProfileProviderProps,
        ref
    ) => {
        const [state, dispatch] = useReducer(profileReducer, initialUserState);
        const { logout } = useContext(AuthContext);
        const { getUserInfo } = useUserInfo();

        useImperativeHandle(
            ref,
            () => ({
                user: () => state.user,
            }),
            [state]
        );

        const dispatchUserProfileStatus = (status: ResponseStatus) => {
            dispatch({
                type: 'SET_USER_PROFILE_STATUS',
                payload: {
                    status,
                },
            });
        };

        const getUserProfile = useCallback(async () => {
            try {
                dispatchUserProfileStatus(ResponseStatus.Pending);
                const response = await componentsAPIInstance.axios({
                    method: 'GET',
                    url: `api/Users/UserProfile`,
                });

                const {
                    PayLoad,
                    StatusCode,
                }: {
                    PayLoad: IUser;
                    StatusCode: number;
                } = response.data;
                if (
                    StatusCode === 200 &&
                    PayLoad.RolesPermissions &&
                    PayLoad.RolesPermissions.length > 0
                ) {
                    localStorage.setItem('UserGuid', PayLoad?.UserGuid);
                    if (PayLoad?.Groups?.length === 1) {
                        const userInfo = await getUserInfo({
                            apimUrl: apimUrl ?? '',
                            subscriptionKey: subscriptionKey ?? '',
                            userId: PayLoad.UserId,
                        });
                        const userInfoPayload = userInfo?.data?.PayLoad;
                        localStorage.setItem(
                            'dashboardV2Settings',
                            userInfo?.data?.PayLoad?.dashboardV2
                        );
                        localStorage.setItem(
                            'bannerSettings',
                            JSON.stringify({
                                audioPing: !!userInfoPayload?.audioPing,
                                bannerDuration:
                                    (userInfoPayload?.bannerDuration ?? 0) > 0
                                        ? userInfoPayload.bannerDuration
                                        : 10000,
                                bannerLocation:
                                    userInfoPayload?.bannerLocation ?? '',
                                showBanner: !!userInfoPayload?.showBanner,
                            })
                        );
                        if (isNil(ScStorage().getItem('dashboardV2'))) {
                            localStorage.setItem(
                                'dashboardV2',
                                userInfo?.data?.PayLoad?.dashboardV2
                            );
                        }
                        ScStorage().setItem(
                            'activeDistrict',
                            JSON.stringify({
                                groupGuid: PayLoad.Groups[0]?.groupGuid,
                                name: PayLoad.Groups[0]?.name,
                                attributes: PayLoad.Groups[0]?.attributes,
                            })
                        );
                    } else {
                        try {
                            const userInfo = await getUserInfo({
                                apimUrl: apimUrl ?? '',
                                subscriptionKey: subscriptionKey ?? '',
                                userId: PayLoad.UserId,
                            });
                            const userInfoPayload = userInfo?.data?.PayLoad;
                            localStorage.setItem(
                                'dashboardV2Settings',
                                userInfo?.data?.PayLoad?.dashboardV2
                            );
                            localStorage.setItem(
                                'bannerSettings',
                                JSON.stringify({
                                    audioPing: !!userInfoPayload?.audioPing,
                                    bannerDuration:
                                        userInfoPayload.bannerDuration ?? 10,
                                    bannerLocation:
                                        userInfoPayload.bannerLocation ??
                                        'right',
                                    showBanner: userInfoPayload.showBanner,
                                })
                            );
                            if (isNil(ScStorage().getItem('dashboardV2'))) {
                                localStorage.setItem(
                                    'dashboardV2',
                                    userInfo?.data?.PayLoad?.dashboardV2
                                );
                            }
                            const activeDist = JSON.parse(
                                localStorage.getItem('activeDistrict') ?? '{}'
                            );
                            if (PayLoad?.Groups && isNil(activeDist?.name)) {
                                const currentDefaultRegion =
                                    userInfo.data.PayLoad.defaultRegionId;
                                if (PayLoad?.Groups?.length > 1) {
                                    const districtData = PayLoad?.Groups?.find(
                                        (group: any) => {
                                            if (
                                                parseInt(
                                                    group.attributes.regionId[0]
                                                ) === currentDefaultRegion
                                            ) {
                                                return group;
                                            }
                                        }
                                    );
                                    localStorage.setItem(
                                        'activeDistrict',
                                        JSON.stringify(districtData)
                                    );
                                } else {
                                    localStorage.setItem(
                                        'activeDistrict',
                                        JSON.stringify(PayLoad?.Groups[0])
                                    );
                                }
                                localStorage.setItem(
                                    'userRegion',
                                    userInfo.data.PayLoad.regionId
                                );
                            }
                        } catch (e) {
                            console.log('exception in user info', e);
                        }
                    }
                    persistUserProfile(PayLoad);
                    dispatch({
                        type: 'SET_USER_PROFILE',
                        payload: {
                            user: PayLoad,
                        },
                    });
                    dispatchUserProfileStatus(ResponseStatus.Success);
                } else {
                    dispatchUserProfileStatus(ResponseStatus.Error);
                    await logout();
                }
            } catch (error) {
                dispatchUserProfileStatus(ResponseStatus.Error);
                onError(error);
            }
        }, [onError]);

        /**
         * This function is to load the user profile for customer support role.
         * this will be executed from module when new district has been created and on dispatching the event
         */
        const getCSUserProfile = async (
            event: CustomEvent<ICSUserProfileCallBackProps>
        ) => {
            try {
                const {
                    detail: { regionId, onSuccessCallback, onErrorCallBack },
                } = event;
                dispatchUserProfileStatus(ResponseStatus.Pending);
                const response = await componentsAPIInstance.axios({
                    method: 'GET',
                    url: `api/Users/CSUserProfile`,
                });

                const {
                    PayLoad,
                    StatusCode,
                }: {
                    PayLoad: IUser;
                    StatusCode: number;
                } = response.data;
                if (
                    StatusCode === 200 &&
                    PayLoad.RolesPermissions &&
                    PayLoad.RolesPermissions.length > 0
                ) {
                    if (PayLoad?.Groups?.length === 1) {
                        ScStorage().setItem(
                            'activeDistrict',
                            JSON.stringify({
                                groupGuid: PayLoad.Groups[0]?.groupGuid,
                                name: PayLoad.Groups[0]?.name,
                                attributes: PayLoad.Groups[0]?.attributes,
                            })
                        );
                    } else {
                        const newRegion = PayLoad.Groups.filter(
                            (region: any) =>
                                region?.attributes?.regionId?.[0] ===
                                `${regionId}`
                        );
                        if (newRegion?.length > 0) {
                            ScStorage().setItem(
                                'activeDistrict',
                                JSON.stringify({
                                    groupGuid: newRegion[0]?.groupGuid,
                                    name: newRegion[0]?.name,
                                    attributes: newRegion[0]?.attributes,
                                })
                            );
                        }
                    }
                    persistUserProfile(PayLoad);
                    dispatch({
                        type: 'SET_USER_PROFILE',
                        payload: {
                            user: PayLoad,
                        },
                    });
                    dispatchUserProfileStatus(ResponseStatus.Success);
                    onSuccessCallback();
                } else {
                    dispatchUserProfileStatus(ResponseStatus.Error);
                    onErrorCallBack();
                    await logout();
                }
            } catch (error) {
                dispatchUserProfileStatus(ResponseStatus.Error);
                onError(error);
            }
        };

        useEffect(function () {
            window?.addEventListener?.(
                'cs-user-profile',
                getCSUserProfile as any
            );
            return () => {
                window?.removeEventListener?.(
                    'cs-user-profile',
                    getCSUserProfile as any
                );
            };
        }, []);

        useEffect(() => {
            if (isAuthenticated) {
                getUserProfile();
            }
        }, [isAuthenticated, getUserProfile]);

        if (state.status === ResponseStatus.Pending) {
            return <AppLoader />;
        } else {
            return (
                <ProfileContext.Provider
                    value={{
                        ...state,
                        getUserProfile,
                    }}
                >
                    {children}
                </ProfileContext.Provider>
            );
        }
    }
);

export default ProfileProvider;
