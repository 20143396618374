import { componentsAPIInstance } from '../../../utils';

export async function createFavoriteAsync(apimUrl: string, payload: any) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'PUT',
            url: `${apimUrl}/administration/v1.0/api/Favorite/CreateFavorite`,
            data: payload,
        });
        return response;
    } catch (error) {
        console.log('error', error);
        return [];
    }
}

export async function getFavoritesByUserIdAsync(
    apimUrl: string,
    userId: any,
    regionId: number
) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'GET',
            url: `${apimUrl}/administration/v1.0/api/Favorite/GetFavoritesByUserIdAsync?userId=${userId}&regionId=${regionId}`,
        });
        return response;
    } catch (error) {
        console.log('error', error);
        return [];
    }
}

export async function deleteFavoriteAsync(apimUrl: string, favoriteId: number) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'DELETE',
            url: `${apimUrl}/administration/v1.0/api/Favorite/DeleteFavorite?favoriteId=${favoriteId}`,
        });
        return response;
    } catch (error) {
        console.log('error', error);
        return [];
    }
}

export async function updateFavoriteAsync(apimUrl: string, payload: any) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'PATCH',
            url: `${apimUrl}/administration/v1.0/api/Favorite/UpdateFavorite`,
            data: payload,
        });
        return response;
    } catch (error) {
        console.log('error', error);
        return [];
    }
}

export async function updateFavoritesOrderAsync(apimUrl: string, payload: any) {
    try {
        const response = await componentsAPIInstance.axios({
            method: 'POST',
            url: `${apimUrl}/administration/v1.0/api/Favorite/UpdateFavoriteOrderAsync`,
            data: payload,
        });
        return response;
    } catch (error) {
        console.log('error', error);
        return [];
    }
}
