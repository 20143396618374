import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    IconButton,
    Menu,
    MenuItem,
    Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { isEmpty, toLower } from 'lodash';

import { MoreVerticalIcon, ViewWeekIcon } from '../../../assets/icons/icons';
import { ITheme, useProfile } from '../../../core/Providers';
import ModuleInfoContext from '../../../core/Providers/ModuleInfo/ModuleInfoContext';
import { PageProviderContext } from '../../../core/Providers/PageProviders/PageProvider';
import ScStorage from '../../../core/Storage';
import { storage } from '../../../core/Store';
import { Alert } from '../../Alert';
import { Button } from '../../Button';
import { Notification } from '../../Notifications';
import { SlidingDrawer } from '../../SlidingDrawer';
import { TextField } from '../../TextField';

import {
    checkViewNameAsync,
    getViewAsync,
    saveViewAsync,
    updateView,
} from './views/api';
import ShareViewUtil from './views/ShareViewUtil';
interface IColumnsAndViewsProps {
    openColumnSettingsPanelState: () => void;
    showViews: boolean;
    showHideFilters?: boolean;
    showFilters?: boolean;
    toggleColumnSearch: () => void;
    onClearView?: () => void;
    displayClearViewButton?: boolean;
}

export enum SORT_TYPE {
    ASC = 0,
    DESC = 1,
}

export interface IViewsPageRequest {
    filterCriteria: string;
    selectedColumns: string;
    dataModelName: string;
    sortBy: string;
    sortOrder: SORT_TYPE;
}

export interface IViewsRequest extends IViewsPageRequest {
    viewName: string;
    isFavorite: boolean;
}

const styles = makeStyles((theme: ITheme) => ({
    splitButton: {
        borderRight: 'none',
    },
    viewsContent: {
        position: 'absolute',
        top: '38px !important',
        background: 'white',
        boxShadow:
            ' rgb(67 71 85 / 27%) 0px 0px 0.25em, rgb(90 125 188 / 5%) 0px 0.25em 1em',
        minWidth: '174px',
        zIndex: 9999,
        listStyleType: 'none',
        height: 'auto',
        margin: '0px 0px',
        // maxHeight: '100px',
        // overflow: 'scroll',
    },
    viewNameInput: {
        '&:invalid': {
            border: 'red solid 1px',
        },
    },
    menuButton: {
        background: 'none',
        border: 'none',
        width: '100%',
        textAlign: 'left',
        fontSize: '10px',
        height: '46px',
        padding: '0 10px',
        '&:hover': {
            background: theme.palette.background.grey,
            cursor: 'pointer',
        },
        '&:disabled': {
            cursor: 'pointer',
            backgroundColor: '#cccccc',
            color: '#78909c',
        },
    },
    disabledButtonText: {
        color: '#666666',
        filter: 'brightness(2.5)',
    },
    defaultViewSelection: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export const ColumnsAndViews = ({
    openColumnSettingsPanelState,
    showViews,
    showHideFilters,
    showFilters,
    toggleColumnSearch,
    onClearView,
    displayClearViewButton = false,
}: IColumnsAndViewsProps) => {
    const classes = styles();
    const pageContext = useContext(PageProviderContext);
    const moduleContext = useContext(ModuleInfoContext);
    // const [canShowViewsMenu, setShowViewsMenu] = useState(false);
    const [canShowSaveDrawer, setShowSaveDrawer] = useState(false);
    const [viewName, setViewName] = useState<string>('');
    const [isSystemDefault, setIsSystemDefault] = useState<boolean>(false);
    const [isFavoriteView, setisFavoriteView] = useState<boolean>(false);
    const [isGlobalView, setIsGlobalView] = useState<boolean>(false);
    const activeDistrict = storage.getItem('activeDistrict');
    const activeDistrictParsed = JSON.parse(activeDistrict ?? '{}');
    const regionId = activeDistrictParsed?.attributes?.regionId?.[0];
    const showViewMenuRef = useRef<HTMLUListElement>(null);
    const [successAlertMessage, setSuccessAlertMessage] = useState<String>('');
    const [errorAlertMessage, setErrorAlertMessage] = useState<String>('');
    const [isViewNameAllowed, setIsViewNameAllowed] = useState<string>('');
    const [shareWithOthers, setShareWithOthers] = useState<boolean>(false);
    const [showClearViewButton, setShowClearViewButton] = useState<boolean>(
        false
    );
    const [selectedUsersToShare, setSelectedUsersToShare] = React.useState<any>(
        []
    );
    const customViewPageName = pageContext.customViewPageName!;
    const { user } = useProfile();
    const isCustomerSupport = user?.Roles?.includes('Customer Support');

    useEffect(() => {
        if (
            (pageContext &&
                pageContext.viewAttributes &&
                pageContext.viewAttributes.currentViewId) ||
            (pageContext && pageContext.currentViewId)
        ) {
            setShowClearViewButton(true);
        } else {
            setShowClearViewButton(false);
        }
    }, [pageContext]);

    const clearSelectedView = () => {
        pageContext.setViewAttributes({});
        if (pageContext.updateDefaultViewAttributes) {
            pageContext.updateDefaultViewAttributes({});
        }
        pageContext.currentView = '';
        pageContext.currentViewId = '';
        localStorage.removeItem('newSavedViewId');
        localStorage.setItem('isViewClearing', 'true');
        if (onClearView) {
            onClearView?.();
        }
    };

    function onMenuClick() {
        setShowSaveDrawer(() => true);
        // setShowViewsMenu(() => false);
    }
    useEffect(() => {
        /**
         * Close menu on outside click
         */
        function handleClickOutside(event: any) {
            if (
                showViewMenuRef.current &&
                !showViewMenuRef.current.contains(event.target)
            ) {
                // setShowViewsMenu(false);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showViewMenuRef]);

    function onDrawerClose() {
        setViewName(() => '');
        setIsGlobalView(() => false);
        setIsSystemDefault(() => false);
        setisFavoriteView(() => false);
        setShareWithOthers(() => false);
        setShowSaveDrawer(() => false);
        setIsViewNameAllowed('');
    }

    async function shareReport(viewId: string) {
        try {
            const allSelectedUsers: any[] = [];
            const allSelectedRoles: any[] = [];
            const allSelectedGroups: any[] = [];
            allSelectedUsers.push(...selectedUsersToShare?.userIds);

            allSelectedRoles.push(...selectedUsersToShare?.roleIds);

            allSelectedGroups.push(...selectedUsersToShare?.groupIds);

            const resultedUsres = allSelectedUsers?.filter(
                (item: any, index: any) => {
                    return allSelectedUsers?.indexOf(item) === index;
                }
            );
            const resultedRoles = allSelectedRoles?.filter(
                (item: any, index: any) => {
                    return allSelectedRoles?.indexOf(item) === index;
                }
            );
            const resultedGroups = allSelectedGroups?.filter(
                (item: any, index: any) => {
                    return allSelectedGroups?.indexOf(item) === index;
                }
            );
            const requestData = {
                updateCustomViewShareRequest: {
                    userIds: resultedUsres,
                    roleIds: resultedRoles,
                    groupIds: resultedGroups,
                },
                viewName: viewName,
            };
            await updateView(
                viewId,
                customViewPageName,
                moduleContext.apimUrl,
                requestData
            )
                .then((response: any) => {
                    if (
                        response.data.StatusCode === 200 &&
                        response.data.PayLoad === 'true'
                    ) {
                        setSelectedUsersToShare([]);
                        onDrawerClose();
                    }
                })
                .catch((e: any) => {
                    console.log('Error sharing the report', e);
                });
        } catch (e) {
            console.log('Shared report error======', e);
        }
    }
    const [loadingOnSave, setLoadingOnSave] = useState(false);
    async function onViewSave() {
        try {
            setLoadingOnSave(true);
            const viewSaveRequestObject = {
                ...pageContext.viewAttributes,
                viewName: viewName,
                isFavorite: isFavoriteView,
                isSystem: isSystemDefault || isGlobalView,
                regionId: isGlobalView ? '-10' : regionId,
                sortOrder:
                    toLower(pageContext?.viewAttributes?.sortOrder) === 'desc'
                        ? 1
                        : 0,
            };
            await saveViewAsync(
                moduleContext.apimUrl,
                viewSaveRequestObject
            ).then((response: any) => {
                if (
                    response.status === 200 &&
                    !isEmpty(response.data.PayLoad)
                ) {
                    if (shareWithOthers) {
                        shareReport(
                            response.data.PayLoad.replace(/['"]+/g, '')
                        );
                    } else {
                        onDrawerClose();
                    }
                    getNewViewDetails(
                        response.data.PayLoad.replace(/['"]+/g, '')
                    );
                    ScStorage().setItem(
                        'newSavedViewId',
                        response.data.PayLoad.replace(/['"]+/g, '')
                    );
                    setSuccessAlertMessage('View saved successfully!');
                    setLoadingOnSave(false);
                }
            });
        } catch (e) {
            console.error('Error saving Views', e);
            onDrawerClose();
            setErrorAlertMessage('Error saving view!');
            setLoadingOnSave(false);
        }
    }

    const getNewViewDetails = async (id: string) => {
        if (
            pageContext.customViewPageName ===
            pageContext.viewAttributes.dataModelName
        ) {
            pageContext.setViewAttributes({});
            pageContext.currentViewId = '';
            pageContext.currentView = '';
            await getViewAsync(moduleContext.apimUrl, id).then(
                (response: any) => {
                    if (response.status === 200) {
                        pageContext.setViewAttributes(response.data.PayLoad);
                        pageContext.currentView =
                            response.data.PayLoad.viewName;
                        pageContext.currentViewId = response.data.PayLoad?.id;
                        if (pageContext.setCanUpdateFilters) {
                            pageContext.setCanUpdateFilters(true);
                        }
                        if (pageContext.setIsSystem) {
                            if (response.data.PayLoad.isSystem) {
                                pageContext.setIsSystem(true);
                            } else {
                                pageContext.setIsSystem(false);
                            }
                        }
                        if (pageContext.setIsSharedView) {
                            if (response.data.PayLoad.isShared) {
                                pageContext.setIsSharedView(true);
                            } else {
                                pageContext.setIsSharedView(false);
                            }
                        }
                    }
                }
            );
        }
    };
    async function onViewUpdate() {
        const viewSaveRequestObject = {
            ...pageContext.viewAttributes,
            sortOrder:
                toLower(pageContext?.viewAttributes?.sortOrder) === 'desc'
                    ? 1
                    : 0,
            viewName:
                pageContext?.currentView ||
                pageContext?.viewAttributes?.viewName ||
                pageContext?.viewAttributes?.currentViewName ||
                pageContext?.defaultViewAttributes?.viewName,
        };
        await updateView(
            pageContext.viewAttributes.currentViewId ||
                pageContext.viewAttributes.id,
            customViewPageName,
            moduleContext.apimUrl,
            viewSaveRequestObject
        )
            .then((response: any) => {
                if (
                    response.data.StatusCode === 200 &&
                    response.data.PayLoad === 'true'
                ) {
                    setSuccessAlertMessage('View updated successfully');
                }
            })
            .catch((e: any) => {
                console.error('Error updating view', e);
                setErrorAlertMessage('Error Saving view!');
            });
    }
    const [wrongViewName, setWrongViewName] = useState<boolean>(false);
    const [
        displayViewNameLengthError,
        setDisplayViewNameLengthError,
    ] = useState<boolean>(false);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsViewNameAllowed('');
        if (
            /^[ A-Za-z0-9-]*$/.test(event.target.value) &&
            /^\S*$/.test(event.target.value)
        ) {
            setWrongViewName(false);
            if (event.target.value.length <= 50) {
                setDisplayViewNameLengthError(false);
                setViewName(event.target.value);
            } else {
                setDisplayViewNameLengthError(true);
                setIsViewNameAllowed('true');
            }
        } else {
            setIsViewNameAllowed('true');
            setWrongViewName(true);
        }
    };
    useEffect(() => {
        if (viewName) {
            const timeoutId = setTimeout(() => {
                checkViewName();
            }, 1000);
            return () => clearTimeout(timeoutId);
        }
        return () => {};
    }, [viewName]);
    async function checkViewName() {
        setIsViewNameAllowed('');
        if (isEmpty(viewName)) {
            setIsViewNameAllowed('false');
        } else {
            await checkViewNameAsync(
                moduleContext.apimUrl,
                viewName,
                customViewPageName
            ).then((response: any) => {
                setIsViewNameAllowed(() => response);
            });
        }
    }
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpen(true);
        // setShowViewsMenu(true);
        localStorage.removeItem('isViewClearing');
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <>
            {successAlertMessage !== '' && (
                <Notification
                    color="success"
                    duration={5000}
                    message={successAlertMessage}
                    onClose={() => setSuccessAlertMessage(() => '')}
                    open
                    variant="filled"
                />
            )}
            {errorAlertMessage !== '' && (
                <Notification
                    color="error"
                    duration={5000}
                    message={errorAlertMessage}
                    onClose={() => setErrorAlertMessage(() => '')}
                    open
                    variant="filled"
                />
            )}
            <div
            // onMouseLeave={() => setShowViewsMenu(false)}
            >
                <Button
                    id="manageView"
                    label="Manage View"
                    onClick={openColumnSettingsPanelState}
                    dense
                    startIcon={<ViewWeekIcon />}
                    className={showViews ? classes.splitButton : ''}
                >
                    Manage Views
                </Button>
                {showViews && (
                    <IconButton
                        color="default"
                        id="views-menu-dropdown"
                        onClick={handleClick}
                        type="button"
                        style={{
                            borderRadius: '0%',
                            borderLeft: '2px solid rgb(102, 102, 102)',
                            height: '30px',
                            backgroundColor: '#F4F7FA',
                            width: '30px',
                            padding: '8px 12px',
                            lineHeight: '16px',
                        }}
                        aria-label="views menu dropdown"
                    >
                        <MoreVerticalIcon />
                    </IconButton>
                )}
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{ 'aria-labelledby': 'b1' }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MenuItem
                        key={1}
                        id="saveViewAsButtonDQ"
                        onClick={onMenuClick}
                        tabIndex={0}
                    >
                        <Typography variant="button" color="textPrimary">
                            SAVE AS
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        key={2}
                        id="saveButtonDQ"
                        onClick={onViewUpdate}
                        disabled={
                            (isEmpty(pageContext.currentViewId) &&
                                isEmpty(
                                    pageContext.viewAttributes.currentViewId
                                )) ||
                            pageContext.viewAttributes?.isSharedView ||
                            pageContext.isSharedView ||
                            pageContext.viewAttributes.isSystem ||
                            pageContext.isSystem ||
                            pageContext.defaultViewAttributes.isSystem
                                ? true
                                : false
                        }
                    >
                        <Typography
                            className={
                                (isEmpty(pageContext.currentViewId) &&
                                    isEmpty(
                                        pageContext.viewAttributes.currentViewId
                                    )) ||
                                pageContext.viewAttributes?.isSharedView ||
                                pageContext.isSharedView ||
                                pageContext.viewAttributes.isSystem ||
                                pageContext.isSystem ||
                                pageContext.defaultViewAttributes.isSystem
                                    ? 'disabledButtonText'
                                    : ''
                            }
                            variant="button"
                        >
                            SAVE
                        </Typography>
                    </MenuItem>
                    {displayClearViewButton && showClearViewButton && (
                        <MenuItem
                            key={3}
                            id="clear-view"
                            tabIndex={0}
                            onClick={clearSelectedView}
                        >
                            <Typography variant="button">CLEAR VIEW</Typography>
                        </MenuItem>
                    )}
                    {showHideFilters && (
                        <MenuItem
                            id="showhidefilters"
                            key={4}
                            onClick={toggleColumnSearch}
                        >
                            <Typography variant="button" color="textPrimary">
                                {showFilters ? 'Hide Filters' : 'Show Filters'}
                            </Typography>
                        </MenuItem>
                    )}
                </Menu>
            </div>
            <SlidingDrawer
                title={'Save View as'}
                open={canShowSaveDrawer}
                primaryAction={{
                    label: 'Save',
                    onClick: onViewSave,
                    loading: loadingOnSave,
                    disabled: isEmpty(viewName)
                        ? true
                        : isViewNameAllowed === 'true' &&
                          !wrongViewName &&
                          !displayViewNameLengthError
                        ? false
                        : true,
                }}
                onCloseIconClick={onDrawerClose}
                onBackdropClick={onDrawerClose}
            >
                <TextField
                    color="primary"
                    id="view-name"
                    fullWidth
                    label="View Name"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onInputChange(event)
                    }
                    size="small"
                    value={viewName}
                    variant="outlined"
                    helperText={
                        wrongViewName
                            ? 'Only alphanumeric characters and a hyphen (“-”) allowed (spaces not allowed)'
                            : displayViewNameLengthError
                            ? 'View name should be <= 50 characters'
                            : ''
                    }
                    error={wrongViewName || displayViewNameLengthError}
                    className={classes.viewNameInput}
                />
                {isCustomerSupport && (
                    <div className={classes.defaultViewSelection}>
                        <Checkbox
                            checked={isGlobalView}
                            onChange={(event: any) =>
                                setIsGlobalView(event.target.checked)
                            }
                            inputProps={{
                                'aria-label': 'global-view',
                            }}
                        />
                        <Typography variant="body1" color="textPrimary">
                            Set as global view
                        </Typography>
                    </div>
                )}
                <div className={classes.defaultViewSelection}>
                    <Checkbox
                        checked={isFavoriteView}
                        onChange={(event: any) =>
                            setisFavoriteView(event.target.checked)
                        }
                        inputProps={{
                            'aria-label': 'favorite-view',
                        }}
                        disabled={isGlobalView}
                    />
                    <Typography variant="body1" color="textPrimary">
                        Set as favorite view
                    </Typography>
                </div>
                <div className={classes.defaultViewSelection}>
                    <Checkbox
                        inputProps={{
                            'aria-label': 'shareWithOthers',
                        }}
                        checked={shareWithOthers}
                        onChange={(event: any) =>
                            setShareWithOthers(event.target.checked)
                        }
                        disabled={isGlobalView}
                    />
                    <Typography variant="body1" color="textPrimary">
                        Share view with other users
                    </Typography>
                </div>
                {isViewNameAllowed === 'false' && (
                    <Alert
                        color="error"
                        message="View name already exists!"
                        onClose={() => setIsViewNameAllowed(() => '')}
                        open
                    />
                )}
                {shareWithOthers && (
                    <ShareViewUtil
                        moduleContext={moduleContext.apimUrl}
                        setSelectedUsersToShare={setSelectedUsersToShare}
                        selectedUsersToShare={selectedUsersToShare}
                        autoCompleteFlag={false}
                        regionId={regionId}
                    />
                )}
            </SlidingDrawer>
        </>
    );
};
