import React from 'react';

import { IVerticalModuleIcons } from './index';

const EligibilityModuleIcon = ({ selected }: IVerticalModuleIcons) => (
    <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M22 10.5L19.56 7.7L19.9 4.01L16.29 3.19L14.4 0L11 1.5L7.6 0L5.71 3.19L2.1 4L2.44 7.7L0 10.5L2.44 13.29L2.1 16.99L5.71 17.81L7.6 21L11 19.5L14.4 20.96L16.29 17.77L19.9 16.95L19.56 13.26L22 10.5ZM18.05 11.98L17.49 12.63L17.57 13.48L17.75 15.48L15.85 15.91L15.01 16.1L14.57 16.84L13.57 18.52L11.79 17.75L10.99 17.41L10.2 17.75L8.43 18.5L7.43 16.83L7 16.05L6.16 15.86L4.26 15.43L4.44 13.43L4.52 12.58L4 11.97L2.67 10.5L4 9.02L4.56 8.37L4.43 7.5L4.25 5.57L6.15 5.14L7 4.95L7.44 4.21L8.42 2.5L10.2 3.27L11 3.61L11.79 3.27L13.57 2.5L14.57 4.18L15.01 4.92L15.85 5.11L17.75 5.54L17.56 7.5L17.48 8.35L18.04 9L19.33 10.5L18.05 11.98Z"
            fill={selected}
        />
        <path
            d="M11 10.5C11.3956 10.5 11.7823 10.3827 12.1112 10.1629C12.4401 9.94318 12.6964 9.63082 12.8478 9.26537C12.9992 8.89992 13.0388 8.49778 12.9616 8.10982C12.8844 7.72186 12.6939 7.36549 12.4142 7.08579C12.1345 6.80608 11.7782 6.6156 11.3902 6.53843C11.0022 6.46126 10.6001 6.50087 10.2347 6.65224C9.8692 6.80362 9.55684 7.05996 9.33708 7.38886C9.11732 7.71776 9.00002 8.10444 9.00002 8.5C9.00002 9.03043 9.21073 9.53914 9.5858 9.91421C9.96088 10.2893 10.4696 10.5 11 10.5ZM15 13.93C15.0017 13.5357 14.8867 13.1497 14.6697 12.8206C14.4526 12.4914 14.1431 12.2338 13.78 12.08C12.9024 11.7002 11.9563 11.5043 11 11.5043C10.0438 11.5043 9.09763 11.7002 8.22002 12.08C7.85695 12.2338 7.54743 12.4914 7.33037 12.8206C7.1133 13.1497 6.99837 13.5357 7.00002 13.93V14.5H15V13.93Z"
            fill={selected === 'white' ? selected : '#04004D'}
        />
    </svg>
);

export default EligibilityModuleIcon;
