import type { FC } from 'react';
import React, { ReactNode, useState } from 'react';
import {
    Box,
    fade,
    makeStyles,
    Paper,
    Portal,
    Slide,
    Typography,
} from '@material-ui/core';

import { ITheme } from '../../core/Providers';
import { ThemeColorType } from '../../types';
import Button from '../Button/Button';

export interface INotificationWithActionProps {
    title: string;
    message: string | ReactNode;
    open?: boolean;
    color?: ThemeColorType;
    primaryAction?: {
        title: string;
        callback: () => void;
    };
    secondaryAction?: {
        title: string;
        callback: () => void;
    };
    direction?: 'left' | 'right' | 'up' | 'down' | undefined;
    positionDirecton?:
        | 'Upper Left'
        | 'Upper Right'
        | 'Bottom Left'
        | 'Bottom Right'
        | undefined;
}

const useStyles = (positionStyle: any) =>
    makeStyles((theme: ITheme) => ({
        root: {
            minWidth: 280,
            maxWidth: 420,
            position: 'fixed',
            margin: theme.spacing(3),
            outline: 'none',
            zIndex: 2000,
            ...positionStyle,
        },
        title: {},
        content: {},
        actions: {
            backgroundColor: fade(theme.palette.primary.main, 0.04),
        },
    }));

const NotificationWithActions: FC<INotificationWithActionProps> = ({
    open = true,
    color = 'primary',
    title,
    message,
    primaryAction,
    secondaryAction,
    direction = 'left',
    positionDirecton = undefined,
}) => {
    let positionStyle;
    let transitionDirection: any;
    switch (positionDirecton) {
        case 'Upper Left':
            positionStyle = { top: 20, left: 20 };
            transitionDirection = 'right';
            break;
        case 'Upper Right':
            positionStyle = { top: 20, right: 20 };
            transitionDirection = 'left';
            break;
        case 'Bottom Left':
            positionStyle = { bottom: 20, left: 20 };
            transitionDirection = 'right';
            break;
        case 'Bottom Right':
            positionStyle = { bottom: 20, right: 20 };
            transitionDirection = 'left';
            break;
        default:
            transitionDirection = direction;
            positionStyle = {
                bottom: 0,
                right: 0,
            };
            break;
    }
    const classes = useStyles(positionStyle)();
    const [isOpen, setOpen] = useState<boolean>(open);

    React.useEffect(() => {
        setOpen(open);
    }, [open]);

    const handlePrimaryAction = (): void => {
        if (primaryAction) {
            primaryAction.callback();
        }
        setOpen(false);
    };

    const handleSecondaryAction = (): void => {
        if (secondaryAction) {
            secondaryAction.callback();
        }
        setOpen(false);
    };

    if (!isOpen) {
        return null;
    }

    return (
        <Portal>
            <Slide
                direction={transitionDirection}
                in={isOpen}
                mountOnEnter
                unmountOnExit
            >
                <Paper className={classes.root} elevation={3}>
                    <Box px={2} pt={2} pb={1}>
                        <Typography
                            variant="h4"
                            component="h2"
                            color="textPrimary"
                        >
                            {title}
                        </Typography>
                    </Box>
                    {message && (
                        <Box px={2} py={1}>
                            {typeof message === 'string' ? (
                                <Typography variant="body1" color="textPrimary">
                                    {message}
                                </Typography>
                            ) : (
                                message
                            )}
                        </Box>
                    )}
                    <Box
                        p={1}
                        display="flex"
                        justifyContent="flex-end"
                        className={classes.actions}
                    >
                        {secondaryAction && (
                            <Box mr={2}>
                                <Button
                                    id="nSecondaryAction"
                                    onClick={handleSecondaryAction}
                                >
                                    {secondaryAction.title}
                                </Button>
                            </Box>
                        )}
                        {primaryAction && (
                            <Button
                                id="nPrimaryAction"
                                color={color}
                                onClick={handlePrimaryAction}
                            >
                                {primaryAction.title}
                            </Button>
                        )}
                    </Box>
                </Paper>
            </Slide>
        </Portal>
    );
};

export default NotificationWithActions;
