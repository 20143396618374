import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { isNil } from 'lodash';

import { useProfile } from '../../core/Providers';
import { componentsAPIInstance } from '../../utils';

const GTag = ({ apimUrl }: any) => {
    const { user } = useProfile();
    const isInitializedRef = useRef<boolean>(false);
    const [GASetting, setGASetting] = useState<string>('');
    const [gTagScript, setGTagScript] = useState<string>('');
    function gtag() {
        //@ts-ignore
        dataLayer.push(arguments);
    }
    useEffect(() => {
        if (GASetting && !isInitializedRef.current) {
            isInitializedRef.current = true;
            //@ts-ignore
            window.dataLayer = window.dataLayer || [];
            //@ts-ignore
            gtag('js', new Date());
            //@ts-ignore
            gtag('config', GASetting);
            if (!isNil(user)) {
                //@ts-ignore
                gtag('userId', user.UserId);
            }
            ReactGA.initialize(GASetting);
        }
    }, [GASetting]);

    async function getGoogleAnalyticsSystemSetting(): Promise<any> {
        try {
            const response = await componentsAPIInstance.axios({
                url: `${apimUrl}/administration/v1.0/api/SystemSettings/GLEANLYTCO`,
                method: 'GET',
            });

            return response;
        } catch (e) {
            console.log('Error getting google analytics setting');
        }
    }

    useEffect(() => {
        let isMounted = true;
        getGoogleAnalyticsSystemSetting()
            .then((response) => {
                if (response && response.data && response.data.PayLoad) {
                    if (isMounted) {
                        setGASetting(response.data.PayLoad);
                        setGTagScript(
                            `https://www.googletagmanager.com/gtag/js?id=${GASetting}`
                        );
                    }
                }
            })
            .catch(() => {
                console.log('Error getting google analytics setting');
            });
        return () => {
            isMounted = false;
        };
    }, []);

    const scriptType = 'text/javascript';
    return (
        <>
            {GASetting && (
                <Helmet
                    script={[
                        {
                            src: gTagScript,
                            type: scriptType,
                        },
                    ]}
                ></Helmet>
            )}
        </>
    );
};

export default React.memo(GTag);
