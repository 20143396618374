import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import MuiButtonGroup from '@material-ui/core/ButtonGroup';

import { ITheme } from '../../core/Providers';

import Button from './Button';

const styles = makeStyles((theme: ITheme) => ({
    groupRoot: {
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
    },
    buttonChild: {
        textTransform: 'none',
        border: 'none !important',
        borderRight: `1px solid ${theme.palette.divider} !important`,
    },
    buttonChildVertical: {
        textTransform: 'none',
        border: 'none !important',
        borderBottom: `1px solid ${theme.palette.divider} !important`,
    },
}));

interface IButtons {
    name: string;
    id: string;
    label?: string;
    onClick: (args?: any) => void;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    disabled?: boolean;
}

export interface IButtonGroupProps {
    buttons: IButtons[];
    title: string;
    selectedIndex: number;
    color?: 'inherit' | 'primary' | 'secondary' | 'default';
    orientation?: 'horizontal' | 'vertical';
}

const ButtonGroup: FC<IButtonGroupProps> = ({
    buttons,
    title,
    selectedIndex,
    color = 'secondary',
    orientation = 'horizontal',
}: IButtonGroupProps) => {
    const classes = styles();

    return (
        <MuiButtonGroup
            color={color}
            aria-label="Button group"
            disableRipple={false}
            disableElevation={false}
            size="medium"
            variant="contained"
            classes={{
                root: classes.groupRoot,
            }}
            orientation={orientation}
        >
            {buttons.map(
                (
                    {
                        name,
                        id,
                        label,
                        onClick,
                        startIcon,
                        endIcon,
                        disabled,
                    }: IButtons,
                    index: number
                ) => (
                    <Button
                        key={`${title}_${index}`}
                        buttonGroupColor={color}
                        type="button"
                        id={id}
                        disabled={disabled}
                        name={name}
                        buttonGroupVariant={
                            index === selectedIndex ? 'contained' : 'outlined'
                        }
                        buttonGroupclassCss={{
                            root:
                                orientation === 'vertical'
                                    ? classes.buttonChildVertical
                                    : classes.buttonChild,
                        }}
                        onClick={onClick}
                        endIcon={endIcon}
                        startIcon={startIcon}
                    >
                        <span>{label}</span>
                    </Button>
                )
            )}
        </MuiButtonGroup>
    );
};

export default ButtonGroup;
