import type { FC } from 'react';
import React, {
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import {
    Dialog,
    DialogContent,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { delay, isNil, isNull, isString } from 'lodash';
import moment from 'moment';

import { PagePermissions } from '../../components/Permissions';
import MessageComponent from '../../components/PushNotifications/signalr/MessageComponent';
import TopBar from '../../components/TopBar/TopBar';
import { ITourStatusRegisterType } from '../../components/Tour';
import {
    IAuthResponse,
    ITheme,
    useAuth,
    useProfile,
    useSettings,
} from '../../core/Providers';
import ModuleInfoContext, {
    IPageLevelPermissions,
} from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import ScStorage from '../../core/Storage';
import {
    getLatestTime,
    getRefreshCount,
    getRefreshToken,
    persistAuth,
    updateRefreshTokenCount,
} from '../../core/Store/persist';
import useRoutes from '../../hooks/useRoutes';
import { IGroup, Scope } from '../../types';
import { StandardProps } from '../../types/standardProps';
import { componentsAPIInstance, ModuleName } from '../../utils';
import { ErrorPage } from '../ErrorPage';
import { IGlobalAPIError } from '../PageLayout';
import HelpWidget from '../PageLayout/HelpWidget';

import AppInsightsLogger from './AppInsightsLogger';
import AppTitle from './AppTitle';
import GTag from './GTag';
import MultiUserLogin from './MultiUserLogin';
import TopNav from './TopNav';
import { saveTours } from './ToursApi';

export interface IMainLayoutProps extends StandardProps<HTMLDivElement> {
    /**
     * Left side navigation items
     */
    sidenav?: ReactNode;
    /**
     * Current state of sidenav
     */
    openSidenav?: boolean;
    /**
     * Callback fired when active district is changed
     */
    onActiveDistrictChange?: (district: IGroup) => void;
    /**
     * Module Name as per KeyCloak permissions
     */
    moduleName: ModuleName;
    /**
     * APIM base URL
     */
    apimUrl: string;

    /* Search Box Component */
    searchBox?: ReactNode;

    /** Subscription Key */
    subscriptionKey: string;

    /** Is Broadcast message exists */
    broadcastMessageExists?: boolean;

    noNavigation?: boolean;
}

export interface ISidenavChangeEvent {
    /**
     * The state of the sidenav based on the user action
     */
    open?: boolean;
}

export interface IPoliciesType {
    policyId: number;
    policyValue: string;
    code: string;
    name: string;
    strengthId: number;
}

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        width: '100%',
        minHeight: '100%',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.common,
        overflow: 'hidden',
    },
    wrapper: {
        width: '100%',
        height: '100vh',
        overflow: 'auto',
    },
    wrapperWithMargin: {
        marginLeft: '60px',
    },
    wrapperOnboarding: {
        marginLeft: '0px !important',
    },
    content: {
        padding: (props: { noNavigation: boolean }) =>
            props.noNavigation ? 0 : theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
        },
    },
    moduleSelector: {
        height: 100,
        padding: theme.spacing(2),
        display: 'flex',
        placeItems: 'center',
    },
    inactivityText: {
        textAlign: 'center',
    },
    dialogControl: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#e7e7f3',
    },
    layout: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        placeContent: 'stretch flex-start',
        whiteSpace: 'normal',
        overflow: 'hidden',
        height: '100vh',
    },
}));

const MainLayout: FC<IMainLayoutProps> = ({
    children,
    className,
    sidenav,
    openSidenav = true,
    onActiveDistrictChange,
    apimUrl,
    moduleName,
    searchBox,
    subscriptionKey,
    broadcastMessageExists = false,
    noNavigation = false,
    ...rest
}) => {
    const [showNewUserLogin, setShowNewUserLogin] = useState<boolean>(false);
    const queryParameters = new URLSearchParams(window.location.search);
    const enableToastMessage =
        queryParameters.get('enableToastMessage') === 'true';
    const classes = useStyles({ noNavigation });
    const theme = useTheme();
    const { user } = useProfile();
    const history = useHistory();
    const routes = useRoutes(apimUrl);
    const refreshTokenAsyncCalledRef = useRef<boolean>(false);
    const { navClosedOnLoad, currentPage } = useSettings();
    const [logoutDuration, setLogoutDuration] = useState<number | null>(null);
    const tokenRefreshRef = useRef<any>(null);
    const [pageName, setPageName] = useState('');
    const [currentTourInfo, setCurrentTourInfo] = useState<any>(null);
    const [isNavExpanded, setIsNavExpanded] = useState<boolean>(true);
    const [showPage, setShowPage] = useState<boolean>(false);
    const { auth, logout } = useAuth();
    const validationCollectionFieldForOnboarding = ScStorage().getItem(
        'validationCollectionFieldForOnboarding'
    );
    ScStorage().setItem('displayDistrictSelectionWarning', 'false');
    const [apiFailureMessage, setAPIFailureMessage] = useState<any>(null);
    const [pageLevelPermissions, setPageLevelPermissions] = useState<
        IPageLevelPermissions
    >({
        canView: false,
        canEdit: false,
        canDelete: false,
        canAdd: false,
    });
    const [hoverMode, setoverMode] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    ScStorage().removeItem('newSavedViewId');
    const [broadcastMessagesStatus, setBroadcastMessagesStatus] = useState<
        boolean
    >(false);
    const [showHelp, setShowHelp] = useState<boolean>(false);

    const [activeDistrict, setActiveDistrict] = useState<
        string | IGroup | null
    >(function () {
        const activeDist = ScStorage().getItem('activeDistrict');
        return activeDist ? JSON.parse(activeDist) : null;
    });

    const [bannerSettings, setBannerSettings] = useState<{
        audioPing: boolean;
        bannerDuration: number;
        bannerLocation: string;
        showBanner: boolean;
    }>(() => {
        const settings = localStorage.getItem('bannerSettings');
        if (!isNull(settings)) {
            return JSON.parse(settings);
        }

        return {
            audioPing: false,
            bannerDuration: 0,
            bannerLocation: '',
            showBanner: false,
        };
    });

    useEffect(() => {
        if (onActiveDistrictChange && showPage) {
            onActiveDistrictChange(
                isString(activeDistrict)
                    ? JSON.parse(activeDistrict as any)
                    : activeDistrict
            );
        }
    }, [activeDistrict, showPage]);

    useEffect(() => {
        setBroadcastMessagesStatus(broadcastMessageExists);
    }, [broadcastMessageExists]);

    const listenRefreshToken = async function () {
        if (!refreshTokenAsyncCalledRef.current) {
            refreshTokenAsyncCalledRef.current = true;
            await getReFreshTokenAsync();
        }
    };

    const onLogout = async function () {
        await logout();
    };

    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const getDurationPolicy = useCallback(async () => {
        if (Number(ScStorage().getItem('clientSessionIdleTimeout')) > 0) {
            setLogoutDuration(
                () =>
                    Number(ScStorage().getItem('clientSessionIdleTimeout')) *
                    1000
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getReFreshTokenAsync = async () => {
        try {
            const hasAccessToken =
                auth?.accessToken ??
                ScStorage().getItem('accessToken')?.toString()?.length;
            const hasRefreshAccessToken =
                auth?.refreshToken ??
                ScStorage().getItem('refreshToken')?.toString()?.length;
            const expiresIn = parseInt(ScStorage().getItem('expiresIn') ?? '0');
            const lastRefreshTime = getLatestTime();
            const refreshCount = getRefreshCount();
            const needsRefresh =
                moment().diff(lastRefreshTime, 'seconds') >= expiresIn - 60;
            if (
                hasAccessToken &&
                hasRefreshAccessToken &&
                needsRefresh &&
                refreshCount < 10
            ) {
                const response = await componentsAPIInstance.axios({
                    method: 'POST',
                    url: `api/Token/RefreshToken`,
                    data: {
                        refreshToken: auth?.refreshToken ?? getRefreshToken(),
                        userName: ScStorage().getItem('email'),
                    },
                });
                const {
                    PayLoad,
                    StatusCode,
                }: {
                    PayLoad: IAuthResponse;
                    StatusCode: number;
                } = response.data;
                if (StatusCode === 200 && PayLoad?.accessToken) {
                    componentsAPIInstance.setAccessToken(PayLoad?.accessToken);
                    const persistancePayLoad = {
                        ...PayLoad,
                    };
                    const sessionIdleTime = ScStorage().getItem(
                        'clientSessionIdleTimeout'
                    );
                    const betaVersion = ScStorage().getItem('posBetaVersion');
                    const version = ScStorage().getItem('posVersion');
                    persistancePayLoad.clientSessionIdleTimeout = parseInt(
                        sessionIdleTime ?? '3600'
                    );
                    if (!isNil(version)) {
                        persistancePayLoad.posVersion =
                            ScStorage().getItem('posVersion') ?? undefined;
                    }

                    if (!isNil(betaVersion)) {
                        persistancePayLoad.posBetaVersion =
                            ScStorage().getItem('posBetaVersion') ?? undefined;
                    }

                    persistAuth(persistancePayLoad);
                    updateRefreshTokenCount();
                } else {
                    onLogout();
                }
            } else if (needsRefresh && refreshCount === 10) {
                throw 'Error';
            }
            refreshTokenAsyncCalledRef.current = false;
            delay(() => {
                checkAuth();
            }, 1000);
        } catch (e) {
            onLogout();
        }
    };

    useEffect(() => {
        if (!logoutDuration && ScStorage().getItem('activeDistrict')) {
            getDurationPolicy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Emit event on when left side bar is toggled on desktop
     */
    useEffect(() => {
        if (isLargeScreen) {
            window.dispatchEvent(new Event('resize'));
        }
    }, [isLargeScreen]);

    const topBarRef: Ref<HTMLDivElement> = useRef(null);

    const updatePageLevelPermissions = (
        currentPage: string,
        scopes: Scope[]
    ) => {
        setPageName(() => currentPage);
        if (scopes.length === 0) {
            setPageLevelPermissions(() => ({
                canView: false,
                canEdit: false,
                canDelete: false,
                canAdd: false,
            }));
        } else {
            setPageLevelPermissions(() => ({
                canView: scopes.includes('View'),
                canEdit: scopes.includes('Edit'),
                canDelete: scopes.includes('Delete'),
                canAdd: scopes.includes('Add'),
            }));
        }
    };

    const checkOnboardingComplete = () => {
        const activeDistrict = JSON.parse(
            ScStorage().getItem('activeDistrict') || '{}'
        );
        if (user?.Roles.includes('District Onboarding')) {
            if (
                activeDistrict?.attributes?.onboardingCompleted[0] !== 'False'
            ) {
                return true;
            }
            return false;
        }
        return true;
    };
    const loadWidgets = useCallback(() => {
        return (
            <>
                <GTag apimUrl={apimUrl} />
                <HelpWidget
                    moduleName={moduleName}
                    apimUrl={apimUrl}
                    activeDistrict={activeDistrict}
                />
            </>
        );
    }, [activeDistrict]);

    const errorListener = function (event: CustomEvent<IGlobalAPIError>) {
        const { detail: error } = event;
        setAPIFailureMessage(error);
    } as EventListener;

    const resetErrorBoundry = () => {
        setAPIFailureMessage(null);
    };

    const checkAuth = function () {
        if (auth && auth.accessToken && auth.expiresIn) {
            const loginTime = ScStorage().getItem('loginTime');
            const refreshTimes = JSON.parse(
                ScStorage().getItem('refreshTime') ?? '[]'
            );
            const expiresIn = Number(auth.expiresIn);
            const refreshTokenLastTime =
                refreshTimes[refreshTimes.length - 1] ?? loginTime;
            const needFirstTokenLogout = moment().diff(
                moment(refreshTokenLastTime),
                'second'
            );
            if (refreshTimes.length <= 10) {
                tokenRefreshRef.current = setInterval(function () {
                    if (
                        !isNil(auth) &&
                        !isNil(auth.accessToken) &&
                        !refreshTokenAsyncCalledRef.current
                    ) {
                        refreshTokenAsyncCalledRef.current = true;
                        getReFreshTokenAsync()
                            .then(() => {
                                refreshTokenAsyncCalledRef.current = false;
                            })
                            .catch((error) => {
                                console.log('error', error);
                                onLogout();
                            });
                    }
                    clearTokenRefreshInterval();
                }, (expiresIn - needFirstTokenLogout) * 1000 - 2000);
            } else {
                onLogout();
            }
        }
    };

    const clearTokenRefreshInterval = () => {
        if (tokenRefreshRef.current) {
            clearInterval(tokenRefreshRef.current);
            tokenRefreshRef.current = null;
        }
    };

    //Global error handling
    useEffect(function () {
        window?.addEventListener?.('api-error-event', errorListener);
        return () => {
            window?.removeEventListener?.('api-error-event', errorListener);
            tokenRefreshRef.current = null;
        };
    }, []);

    const updateTours = async (
        toursData: ITourStatusRegisterType,
        refetch?: boolean
    ) => {
        await saveTours({
            url: apimUrl,
            userId: user?.UserId || '',
            toursData,
            refetchCallback: refetch ? setCurrentTourInfo : undefined,
        });
    };

    const onShowHelp = function (show: boolean) {
        setShowHelp(show);
    };

    useEffect(
        function () {
            checkAuth();
        },
        [auth]
    );

    const showNavigation =
        (user?.Roles.length === 1 &&
            user?.Roles.includes('District Onboarding')) ||
        validationCollectionFieldForOnboarding === 'RedirectToProfilePage'
            ? false
            : true;

    const onMultiUserSignInWindow = function () {
        setShowNewUserLogin(true);
    };

    useEffect(function () {
        const accessToken = ScStorage().getItem('accessToken');
        if (!isNil(accessToken)) {
            componentsAPIInstance.setAccessToken(accessToken, () =>
                setShowPage(true)
            );
        } else {
            if (moduleName === ModuleName.Workspace) {
                history.push('/login');
            } else {
                window.location.href = '/login';
            }
        }

        window?.addEventListener('refresh-token', listenRefreshToken);
        window?.addEventListener('unauthorized-logout', onLogout);
        window?.addEventListener('multi-user-signin', onMultiUserSignInWindow);

        return () => {
            window?.removeEventListener('refresh-token', listenRefreshToken);
            window?.removeEventListener('unauthorized-logout', onLogout);
            window?.removeEventListener(
                'multi-user-signin',
                onMultiUserSignInWindow
            );
        };
    }, []);

    return (
        <>
            <Helmet>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                        (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "hiwwqho1ko");`,
                    }}
                />
            </Helmet>
            <AppInsightsLogger>
                <ModuleInfoContext.Provider
                    value={{
                        moduleName,
                        pageName,
                        apimUrl,
                        activeDistrict,
                        setActiveDistrict,
                        permissions: {
                            ...pageLevelPermissions,
                        },
                        resetErrorBoundry,
                        tours: currentTourInfo,
                        updateTours: updateTours,
                        isNavExpanded,
                        setIsNavExpanded,
                        showHelp,
                        routes,
                        setBannerSettings,
                        setShowHelp: onShowHelp,
                        subscriptionKey: subscriptionKey,
                    }}
                >
                    {user && (
                        <div
                            className={clsx(classes.root, className)}
                            {...rest}
                        >
                            <AppTitle title={moduleName} />
                            {enableToastMessage &&
                                bannerSettings?.showBanner && (
                                    <MessageComponent />
                                )}
                            {!noNavigation && (
                                <TopBar
                                    showReturnToSmartDashboard={
                                        moduleName === ModuleName.Workspace
                                    }
                                    ref={topBarRef}
                                    apimUrl={apimUrl}
                                    searchBox={searchBox}
                                    subscriptionKey={subscriptionKey}
                                    setCurrentTourInfo={setCurrentTourInfo}
                                />
                            )}

                            {showPage && loadWidgets()}
                            <div className={classes.layout}>
                                {checkOnboardingComplete() &&
                                    showNavigation &&
                                    !noNavigation && (
                                        <TopNav
                                            moduleName={moduleName}
                                            getNavMode={(mode: boolean) => {
                                                setoverMode(() => mode);
                                            }}
                                            getExpandedMode={(mode: boolean) =>
                                                setIsExpanded(mode)
                                            }
                                            broadcastMessageExists={
                                                broadcastMessagesStatus
                                                    ? broadcastMessagesStatus
                                                    : false
                                            }
                                        />
                                    )}
                                <main
                                    className={clsx(classes.wrapper, {
                                        [classes.wrapperWithMargin]:
                                            ((!hoverMode ||
                                                checkOnboardingComplete()) &&
                                                !isExpanded) ||
                                            navClosedOnLoad?.includes(
                                                currentPage
                                            ),
                                        [classes.wrapperOnboarding]: !checkOnboardingComplete(),
                                    })}
                                >
                                    <PagePermissions
                                        level={'Page'}
                                        parentViewPermissions={
                                            updatePageLevelPermissions
                                        }
                                    >
                                        <div className={classes.content}>
                                            {apiFailureMessage?.response
                                                ?.status === 504 ? (
                                                <ErrorPage
                                                    errorCode={504}
                                                    errorMessage="A timeout has occurred. Refresh the screen to continue"
                                                    setAPIFailureMessage={
                                                        setAPIFailureMessage
                                                    }
                                                />
                                            ) : apiFailureMessage?.response
                                                  ?.status >= 400 ? (
                                                <ErrorPage
                                                    errorCode={
                                                        apiFailureMessage
                                                            ?.response?.status
                                                    }
                                                />
                                            ) : (
                                                <>
                                                    {showPage ? children : null}
                                                    <Dialog
                                                        open={showNewUserLogin}
                                                        onClose={() => {
                                                            setShowNewUserLogin(
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        <DialogContent>
                                                            <MultiUserLogin />
                                                        </DialogContent>
                                                    </Dialog>
                                                </>
                                            )}
                                        </div>
                                    </PagePermissions>
                                </main>
                            </div>
                        </div>
                    )}
                </ModuleInfoContext.Provider>
            </AppInsightsLogger>
        </>
    );
};

export default MainLayout;
