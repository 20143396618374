export const convertDataToString = async (blob: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = () => reject(null);
            reader.readAsDataURL(blob);
        } catch (e) {
            console.log('error in reading ', e);
        }
    });
};
