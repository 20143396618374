import React from 'react';

import { IVerticalModuleIcons } from './index';

const InsightsModuleIcon = ({ selected }: IVerticalModuleIcons) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            className="cls-1"
            x="7"
            y="13"
            width="2"
            height="5"
            fill={selected}
        />
        <rect
            className="cls-1"
            x="11"
            y="14"
            width="2"
            height="4"
            fill={selected}
        />
        <rect
            className="cls-1"
            x="15"
            y="12"
            width="2"
            height="6"
            fill={selected}
        />
        <polygon
            className="cls-2"
            points="16 2 16 4 18.59 4 13.97 8.62 10.53 5.18 5.17 10.54 6.58 11.96 10.53 8.01 13.97 11.45 20 5.41 20 8 22 8 22 2 16 2"
            fill={selected}
        />
        <path
            className="cls-1"
            d="M20,10V20H4V4H14V2H4A2,2,0,0,0,2,4V20a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V10Z"
            fill={selected}
        />
    </svg>
);

export default InsightsModuleIcon;
