import React, { ChangeEvent, FC, useEffect } from 'react';
import { FormControlLabel, Switch as MuiSwitch } from '@material-ui/core';
import { SwitchProps } from '@material-ui/core/Switch/Switch';
import clsx from 'clsx';

export interface ISwitchProps extends SwitchProps {
    /**
     * Unique id for Switch
     */
    id: string;
    /**
     * The switch is checked
     */
    checked: boolean;
    /**
     * Switch color
     */
    color: 'default' | 'primary' | 'secondary';
    /**
     * Disable the Switch
     */
    disabled?: boolean;
    /**
     * Emit on change
     */
    onChange?: (event: ChangeEvent<{}>, checked: boolean) => void;
    /**
     * Label for the Switch
     */
    label?: string;
    /**
     * Label Placement for the Switch
     */
    labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
}

const Switch: FC<ISwitchProps> = ({
    id,
    className,
    checked,
    color = 'default',
    disabled = false,
    onChange,
    label,
    labelPlacement = 'start',
    ...rest
}) => {
    const switchLabel = {
        inputProps: {
            'aria-label': label ? label : 'switch',
        },
    };
    const [isChecked, setIsChecked] = React.useState<boolean>(checked);
    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    const handleChange = (ev: ChangeEvent<{}>, isSwitchChecked: boolean) => {
        setIsChecked(isSwitchChecked);
        if (onChange) {
            onChange(ev, isSwitchChecked);
        }
    };

    return (
        <>
            {label && labelPlacement ? (
                <FormControlLabel
                    value={labelPlacement}
                    control={
                        <MuiSwitch
                            id={id}
                            checked={isChecked}
                            disabled={disabled}
                            onChange={handleChange}
                            color={color}
                            style={{
                                cursor: disabled ? 'initial' : 'pointer',
                            }}
                            {...switchLabel}
                            className={clsx(className)}
                            {...rest}
                        />
                    }
                    label={label}
                    labelPlacement={labelPlacement}
                />
            ) : (
                <MuiSwitch
                    id={id}
                    checked={isChecked}
                    disabled={disabled}
                    onChange={handleChange}
                    color={color}
                    style={{
                        cursor: disabled ? 'initial' : 'pointer',
                    }}
                    {...switchLabel}
                    {...rest}
                />
            )}
        </>
    );
};
export default Switch;
