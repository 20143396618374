import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface IBreadcrumbTextProps {
    label: string;
    isActive?: boolean;
}

const useStyles = makeStyles({
    root: {
        lineHeight: 1,
    },
});

const BreadcrumbText: FC<IBreadcrumbTextProps> = ({
    label,
    isActive = true,
    ...rest
}) => {
    const classes = useStyles();
    return (
        <Typography
            color={isActive ? 'textPrimary' : 'inherit'}
            variant="body1"
            className={classes.root}
            {...rest}
        >
            {label}
        </Typography>
    );
};

export default BreadcrumbText;
