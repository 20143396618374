/* eslint-disable no-restricted-globals */
import React, { FC } from 'react';
import { Menu, MenuItem } from '@material-ui/core';

export interface IContextMenuProps {
    menu: any;
    url: string | undefined;
    fromSideNav?: boolean;
}

export const ContextMenu: FC<IContextMenuProps> = ({
    menu,
    url,
    fromSideNav = false,
}) => {
    const [contextMenu, setContextMenu] = React.useState<any>(null);

    const menuOptions = [
        'Open in new tab',
        'Copy link address',
        'Open in new window',
    ];
    const handleContextMenu = (event: any) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX - 2,
                      mouseY: event.clientY - 4,
                  }
                : null
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };
    const handleRedirect = (option: any) => {
        if (option === 'Open in new window') {
            window.open(
                fromSideNav ? `/${url}` : url,
                '_blank',
                'targetWindow'
            );
        } else if (option === 'Open in new tab') {
            window.open(fromSideNav ? `/${url}` : url, '_blank');
        } else if (option === 'Copy link address') {
            navigator.clipboard.writeText(
                `${location?.origin}${fromSideNav ? `/${url}` : url}`
            );
        }
        setContextMenu(null);
    };
    return (
        <div
            onContextMenu={handleContextMenu}
            style={{
                cursor: 'context-menu',
            }}
        >
            {menu}
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? {
                              top: contextMenu.mouseY,
                              left: contextMenu.mouseX,
                          }
                        : undefined
                }
            >
                {menuOptions.map((option) => (
                    <MenuItem
                        key={option}
                        value={option}
                        onClick={() => {
                            handleRedirect(option);
                        }}
                        onMouseDown={(event) => {
                            if (event.button === 1) {
                                event.preventDefault();
                                handleRedirect(option);
                            }
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
