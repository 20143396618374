import React, { FC } from 'react';
import { Box, Button, Typography } from '@material-ui/core';

const PageNotFound: FC = () => {
    const navigateToHome = () => {
        window.location.replace('/');
    };

    return (
        <Box
            style={{
                padding: '270px',
            }}
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <Typography
                align="center"
                variant="h2"
                color="textPrimary"
                gutterBottom
            >
                The page you are looking for isn’t here!
            </Typography>
            <Typography align="center" variant="body2" color="textSecondary">
                You either tried some shady route or you came here by mistake.
                Whichever it is, try using the navigation.
            </Typography>
            <Box mt={6} display="flex" justifyContent="center">
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={navigateToHome}
                >
                    Back to Home
                </Button>
            </Box>
        </Box>
    );
};

export default PageNotFound;
