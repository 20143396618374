import type { FC } from 'react';
import React, { ReactNode, SyntheticEvent, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Alert, IAlertProps } from '../Alert';

export interface INotificationProps extends IAlertProps {
    /**
     * The number of milliseconds to wait before automatically calling the
     * `onClose` function. `onClose` should then set the state of the `open`
     * prop to hide the Notification. This behavior is disabled by default with
     * the `null` value.
     */
    duration?: number | null;
    /**
     * Change top alignment of Notification
     */
    offsetTop?: string;
    /**
     * Custom Message
     */
    customMessage?: ReactNode;
    /**
     * Hide icon
     */
    hideIcon?: boolean;
}

const useStyles = makeStyles(() => ({
    root: {
        top: 56 + 48 + 24, // Top bar + padding
    },
}));

const Notification: FC<INotificationProps> = ({
    message,
    duration = 5000,
    open = true,
    onClose,
    offsetTop,
    customMessage,
    hideIcon = false,
    ...rest
}) => {
    const classes = useStyles();
    const [isOpen, setOpen] = useState<boolean>(open);

    React.useEffect(() => {
        setOpen(open);
    }, [open]);

    const handleClose = (ev: SyntheticEvent): void => {
        if (onClose) {
            onClose(ev);
        }
        setOpen(false);
    };

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={duration}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            className={classes.root}
            style={{
                top: offsetTop,
            }}
        >
            <Alert
                onClose={handleClose}
                message={message || customMessage}
                hideIcon={hideIcon}
                {...rest}
            />
        </Snackbar>
    );
};

export default Notification;
