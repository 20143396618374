import { Method } from 'axios';

/**
 * @ErrorSourceEnum
 * Defines the type of error source.
 * Component, Service, Network are the error logging sources
 */
export enum ErrorSourceEnum {
    COMPONENT = 'COMPONENT',
    SERVICE = 'SERVICE',
    NETWORK = 'NETWORK',
}

/**
 * @ErrorSeverity
 * Error Severity for App Insights logging
 */
export enum ErrorSeverity {
    VERBOSE = 0,
    INFO = 1,
    WARN = 2,
    ERROR = 3,
    CRITICAL = 4,
}

/**
 * @interface ILoggerMessage
 * Generic Logger interface type.
 *
 * @param moduleName string
 * @param page string
 * @param severity ErrorSeverity
 * @param error string
 * @param time any
 * @param description string (optional)
 */
export interface ILoggerMessage {
    moduleName: string;
    page: string;
    severity: ErrorSeverity;
    error: string;
    description?: string | undefined;
    time: any;
}

/**
 * @interface ILoggerTraceType
 * @extends ILoggerMessage
 * Component trace error logger.
 *
 * @param moduleName string
 * @param page string
 * @param severity ErrorSeverity
 * @param error string
 * @param time any
 * @param description string (optional)
 * @param component string (optional)
 */
export interface ILoggerTraceType extends ILoggerMessage {
    component?: string | undefined;
}

/**
 * @interface ILoggerError
 * @extends ILoggerMessage
 * Network error logger.
 *
 * @param moduleName string
 * @param page string
 * @param severity ErrorSeverity
 * @param error string
 * @param time any
 * @param description string (optional)
 * @param api {method: axios.Method; url: string} (optional)
 */
export interface ILoggerError extends ILoggerMessage {
    api?:
        | {
              method?: Method | undefined;
              url?: string | undefined;
          }
        | undefined;
}

/**
 * @interface ILoggerException
 * @extends ILoggerMessage
 * Functional exception/error logger.
 *
 * @param moduleName string
 * @param page string
 * @param severity ErrorSeverity
 * @param error string
 * @param time any
 * @param description string (optional)
 * @param api {method: axios.Method; url: string} (optional)
 */
export interface ILoggerException extends ILoggerMessage {
    api?:
        | {
              method?: Method | undefined;
              url?: string | undefined;
          }
        | undefined;
}
