import type { FC } from 'react';
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useLocation } from 'react-router';
import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { Card } from '../../components/Card';
import { AppLoader } from '../../components/Loaders';
import type { ITheme } from '../../core/Providers';
import { useAuth } from '../../core/Providers';
import { storage } from '../../core/Store';
import {
    persistRememberMe,
    resetRememberedUser,
} from '../../core/Store/persist';
import { StandardProps } from '../../types/standardProps';

import ChangePassword from './ChangePassword';
import ForgotPassword from './ForgotPassword';
import LinkExpired from './LinkExpired';
import LoginForm from './LoginForm';
import LoginFormRemembered from './LoginFormRemembered';

export interface ILoginViewProps extends StandardProps<HTMLDivElement> {
    handleRememberMe?: (checked: boolean) => void;
    rememberedUser?: {
        [key: string]: string;
    };
}

export const useStyles = makeStyles((theme: ITheme) => ({
    cardContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: {
        minWidth: 280,
        maxWidth: 500,
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minWidth: 280,
        maxWidth: 500,
        padding: theme.spacing(2),
    },
    logo: {
        color: theme.palette.primary.main,
    },
}));

const LoginView: FC<ILoginViewProps> = ({
    rememberedUser,
    className,
    ...rest
}) => {
    const classes = useStyles();
    const {
        isAuthenticated,
        changeDefaults,
        validatePasswordChangeToken,
    } = useAuth();
    const [showForgotPassword, canShowForgotPassword] = useState(false);
    const [showRememberUserFlow, setShowRememberUserFlow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showLinkExpiredFlow, setShowLinkExpiredFlow] = useState(false);
    const [showSetPasswordFlow, setShowSetPasswordFlow] = useState(false);
    const location = useLocation();

    const checkLinkValid = useCallback(
        async ({ shortKey, regionId }: any) => {
            setLoading(true);
            const data = await validatePasswordChangeToken({
                shortKey,
                regionId,
            });
            if ('PayLoad' in data && data.PayLoad.length === 0) {
                setShowLinkExpiredFlow(true);
            } else if ('PayLoad' in data) {
                setShowLinkExpiredFlow(false);
            } else {
                setShowLinkExpiredFlow(false);
            }
            setLoading(false);
        },
        [validatePasswordChangeToken]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const shortKey = params.get('ShortKey');
        const regionId = params.get('RegionId');
        if (shortKey && regionId) {
            setShowSetPasswordFlow(true);
            checkLinkValid({
                shortKey,
                regionId,
            });
        }
        // const rememberMe = storage.getItem('rememberMe') === 'true';
        const rememberedUser = storage.getItem('rememberedUser');
        const isUserRemembered = rememberedUser
            ? !!JSON.parse(rememberedUser)
            : false;
        setShowRememberUserFlow(isUserRemembered);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* useEffect(() => {
        setShowRememberUserFlow(!!rememberedUser);
    }, [rememberedUser]); */

    const handleRememberMe = (checked: boolean) => {
        persistRememberMe(checked);
    };

    const handleDifferentUser = () => {
        resetRememberedUser();
        setShowRememberUserFlow(false);
    };

    const getLoginForm = () => {
        if (showRememberUserFlow) {
            return (
                <LoginFormRemembered
                    rememberedUser={rememberedUser || {}}
                    forgotPassword={() => canShowForgotPassword(() => true)}
                    onDifferentUser={handleDifferentUser}
                />
            );
        }

        return (
            <LoginForm
                onRememberMe={handleRememberMe}
                forgotPassword={() => canShowForgotPassword(() => true)}
            />
        );
    };

    const renderPasswordFlow = useMemo(() => {
        return showLinkExpiredFlow ? <LinkExpired /> : <ChangePassword />;
    }, [showLinkExpiredFlow]);

    return (
        <Box {...rest} className={clsx(classes.cardContainer, className)}>
            {loading ? (
                <AppLoader />
            ) : showSetPasswordFlow ? (
                renderPasswordFlow
            ) : (
                <Fragment>
                    <Card
                        className={classes.card}
                        id="loginCard"
                        content={
                            <>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="100%"
                                    width="100%"
                                >
                                    <Box className={classes.cardContent}>
                                        <Box
                                            alignItems="center"
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                container={true}
                                                direction="column"
                                                style={{
                                                    justifyContent: 'center',
                                                }}
                                                alignItems="center"
                                                spacing={3}
                                            >
                                                <img
                                                    src="/logo/SchoolCafe.svg"
                                                    style={{
                                                        maxWidth: 350,
                                                    }}
                                                    alt="SchoolCafé"
                                                />
                                                {changeDefaults &&
                                                !isAuthenticated &&
                                                !showForgotPassword ? (
                                                    <Grid item={true}>
                                                        <Typography
                                                            variant="h4"
                                                            component="p"
                                                            color="primary"
                                                        >
                                                            Welcome to
                                                            SchoolCafé!
                                                        </Typography>
                                                        <Typography color="textPrimary">
                                                            Please set a new
                                                            password before
                                                            continuing.
                                                        </Typography>
                                                        <Divider />
                                                    </Grid>
                                                ) : (
                                                    ''
                                                )}
                                                <Grid
                                                    item={true}
                                                    style={{
                                                        marginTop: 20,
                                                    }}
                                                >
                                                    <div>
                                                        {showForgotPassword && (
                                                            <Typography
                                                                color="textPrimary"
                                                                gutterBottom
                                                                variant="h3"
                                                            >
                                                                Forgot your
                                                                password?
                                                            </Typography>
                                                        )}

                                                        {isAuthenticated && (
                                                            <Typography
                                                                color="textPrimary"
                                                                gutterBottom
                                                                variant="h3"
                                                            >
                                                                Successfully
                                                                Signed In
                                                            </Typography>
                                                        )}

                                                        {!changeDefaults &&
                                                            !isAuthenticated &&
                                                            showForgotPassword && (
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textPrimary"
                                                                >
                                                                    To reset
                                                                    your
                                                                    password,
                                                                    please enter
                                                                    your email
                                                                    address in
                                                                    the box
                                                                    below and
                                                                    press
                                                                    the&nbsp;
                                                                    <b>
                                                                        REQUEST
                                                                        PASSWORD
                                                                        RESET&nbsp;
                                                                    </b>
                                                                    button.
                                                                </Typography>
                                                            )}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box flexGrow={1} mt={2}>
                                            {!changeDefaults &&
                                                !isAuthenticated &&
                                                !showForgotPassword &&
                                                getLoginForm()}
                                            {!changeDefaults &&
                                                isAuthenticated &&
                                                !showForgotPassword && (
                                                    <>
                                                        <AppLoader />
                                                        <Typography
                                                            variant="body2"
                                                            color="textPrimary"
                                                            align="center"
                                                        >
                                                            Preparing your
                                                            profile...
                                                        </Typography>
                                                    </>
                                                )}
                                            {changeDefaults &&
                                                !isAuthenticated &&
                                                !showForgotPassword && (
                                                    <ChangePassword />
                                                )}
                                            {showForgotPassword && (
                                                <ForgotPassword
                                                    hideForgotPassword={() =>
                                                        canShowForgotPassword(
                                                            () => false
                                                        )
                                                    }
                                                />
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        }
                    />
                </Fragment>
            )}
        </Box>
    );
};

export default LoginView;
