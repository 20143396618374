import React from 'react';

import { IVerticalModuleIcons } from './index';

const ReportsModuleIcon = ({ selected }: IVerticalModuleIcons) => (
    <svg
        width="26"
        height="26"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.3333 8.20831C19.3542 8.24998 19.375 8.29165 19.4167 8.33331C19.5 8.41665 19.5 8.58331 19.5 8.66665V18.6666C19.5 20.0833 18.4167 21.1666 17 21.1666H7C5.58333 21.1666 4.5 20.0833 4.5 18.6666V5.33331C4.5 3.91665 5.58333 2.83331 7 2.83331H13.6667C13.75 2.83331 13.9167 2.83331 14 2.91665C14.0833 2.91665 14.1667 2.99998 14.25 3.08331L19.25 8.08331C19.2917 8.12498 19.3125 8.16665 19.3333 8.20831ZM16.6667 7.83331L14.5 5.66665V7.83331H16.6667ZM17 19.5H7C6.5 19.5 6.16667 19.1666 6.16667 18.6666V5.33331C6.16667 4.83331 6.5 4.49998 7 4.49998H12.8333V8.66665C12.8333 9.16665 13.1667 9.49998 13.6667 9.49998H17.8333V18.6666C17.8333 19.1666 17.5 19.5 17 19.5ZM15.3333 13.6666C15.8333 13.6666 16.1667 13.3333 16.1667 12.8333C16.1667 12.3333 15.8333 12 15.3333 12H8.66667C8.16667 12 7.83333 12.3333 7.83333 12.8333C7.83333 13.3333 8.16667 13.6666 8.66667 13.6666H15.3333ZM16.1667 16.1666C16.1667 16.6666 15.8333 17 15.3333 17H8.66667C8.16667 17 7.83333 16.6666 7.83333 16.1666C7.83333 15.6666 8.16667 15.3333 8.66667 15.3333H15.3333C15.8333 15.3333 16.1667 15.6666 16.1667 16.1666ZM8.66667 8.66665C8.16667 8.66665 7.83333 8.99998 7.83333 9.49998C7.83333 9.99998 8.16667 10.3333 8.66667 10.3333H10.3333C10.8333 10.3333 11.1667 9.99998 11.1667 9.49998C11.1667 8.99998 10.8333 8.66665 10.3333 8.66665H8.66667Z"
            fill={selected === 'white' ? selected : 'rgb(102, 90, 216)'}
        />
    </svg>
);

export default ReportsModuleIcon;
