import { darken, makeStyles } from '@material-ui/core';

import { ITheme } from '../../core/Providers';

export const navStyles = makeStyles((theme: ITheme) => ({
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    buttonLeaf: {
        color: 'inherit',
        padding: '8px 16px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        borderRadius: 0,
        '&:hover': {
            color: theme.palette.getContrastText(theme.palette.tertiary.main),
            backgroundColor: darken(theme.palette.tertiary.main, 0.08),
        },
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        width: '20px',
        height: '20px',
    },
    title: {
        marginRight: 'auto',
    },
    active: {
        fontWeight: theme.typography.fontWeightBold,
    },
}));
