import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { isNil, isNull, isString, sortBy } from 'lodash';

import ModuleInfoContext from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import ScStorage from '../../core/Storage';
import {
    useSessionCheckOnRefresh,
    useWaitTillElementExists,
} from '../../hooks';
import { fetchAllTours } from '../../layouts/Main/ToursApi';

import {
    ISteps,
    ITourElementButtonType,
    ITourProgressType,
    ITourStatusRegisterType,
    ITourStepsFromAPI,
    TourStatusEnum,
} from './types';
import {
    createDetailedSteps,
    createTourEntry,
    getTourEntry,
    isAnyStepSkipped,
    isTourAvailable,
    resetEntry,
} from './util';

export interface IProps {
    steps: ISteps[];
    tourName: string;
    uniqueDisplayEnableElement: string;
}

/**
 * @hook
 * @useTourStepsInitializer
 * Hook that initializes the steps for the tours based on the user progress.
 * Creates a new entry for the tour in database if the user is visiting the page
 * for the first time.
 *
 * @param tourName string name of the tour
 * @param steps ISteps[] array of predefined steps
 * @param uniqueDisplayEnableElement string unique element that will attach the tour with the correct page.
 *
 * @returns ISteps[] array of steps
 *
 * ```typescript
 * const steps = useTourSettings({tourName, steps, uniqueDisplayEnableElement});
 * ```
 */
export const useTourStepsInitializer = ({
    steps: stepsDetails,
    tourName,
    uniqueDisplayEnableElement,
}: IProps) => {
    const {
        setSession,
        isSameSession,
        isSessionSet,
    } = useSessionCheckOnRefresh();

    const { pathname } = useLocation();
    const { tours, updateTours, apimUrl } = useContext(ModuleInfoContext);
    const { isVisibleInDom, isTimeEllapsed } = useWaitTillElementExists({
        elementId: uniqueDisplayEnableElement,
    });

    const toursInfo = async function () {
        let response: any = ScStorage().getItem('allTours');
        if (isNil(response)) {
            response = await fetchAllTours(apimUrl);
            ScStorage().setItem('allTours', JSON.stringify(response));
        } else {
            response = JSON.parse(response);
        }
        if (!isNull(response)) {
            const pageResponse = response.filter(
                ({ path, screenName }: ITourStepsFromAPI) =>
                    pathname.includes(path) && screenName === tourName
            );
            return pageResponse;
        }

        return [];
    };

    const [tourSteps, setTourSteps] = useState<ISteps[]>([]);
    const [newTourSaved, setNewTourSaved] = useState<boolean>(false);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const initializeSteps = async function (isSavingTour: boolean) {
        resetEntry(tourName);
        let initializedSteps: ISteps[] = [];

        setShowLoader(true);
        const stepsFromApi: ITourStepsFromAPI[] | null = await toursInfo();
        const detailedSteps = createDetailedSteps(
            sortBy(stepsFromApi ?? [], 'displayOrder'),
            stepsDetails
        );
        const savedTour = isTourAvailable(tours, tourName);
        if (
            !isSavingTour &&
            !isNil(savedTour) &&
            !savedTour.isFinished &&
            !isAnyStepSkipped(savedTour) &&
            !isSessionSet(tourName)
        ) {
            initializedSteps = [...detailedSteps];
            createTourEntry(tourName, savedTour.steps);
        } else if (isSavingTour) {
            initializedSteps = [...detailedSteps];
            createTourEntry(
                tourName,
                initializedSteps.map((thisStep: ISteps) => ({
                    name: thisStep.id,
                    status: TourStatusEnum.NOT_VISITED,
                }))
            );
        }

        initializedSteps?.forEach((step: ISteps) => {
            step.beforeShowPromise = function () {
                return new Promise((resolve, reject) => {
                    const currentTour = getTourEntry(tourName);
                    if (!isNil(currentTour)) {
                        if (!currentTour.isDisabled) {
                            resolve(true);
                        } else {
                            reject(false);
                        }
                    } else {
                        resolve(true);
                    }
                });
            };
            step.buttons.forEach((button: ITourElementButtonType) => {
                if (button.text.toLowerCase().includes('start')) {
                    button.action = () => {
                        window?.dispatchEvent?.(
                            new CustomEvent<ITourProgressType>(
                                'tour-progress',
                                {
                                    detail: {
                                        tourName,
                                        status: TourStatusEnum.STARTED,
                                        step: step.id,
                                    },
                                }
                            )
                        );
                    };
                } else if (button.text.toLowerCase().includes('skip')) {
                    button.action = () => {
                        window?.dispatchEvent?.(
                            new CustomEvent<ITourProgressType>(
                                'tour-progress',
                                {
                                    detail: {
                                        tourName,
                                        status: TourStatusEnum.SKIPPED,
                                        step: step.id,
                                    },
                                }
                            )
                        );
                    };
                } else if (button.text.toLowerCase().includes('finish')) {
                    button.action = () => {
                        window?.dispatchEvent?.(
                            new CustomEvent<ITourProgressType>(
                                'tour-progress',
                                {
                                    detail: {
                                        tourName,
                                        status: TourStatusEnum.FINISH,
                                        step: step.id,
                                    },
                                }
                            )
                        );
                    };
                } else if (button.text.toLowerCase().includes('exit')) {
                    button.action = () => {
                        window?.dispatchEvent?.(
                            new CustomEvent<ITourProgressType>(
                                'tour-progress',
                                {
                                    detail: {
                                        tourName,
                                        status: TourStatusEnum.EXIT,
                                        step: step.id,
                                    },
                                }
                            )
                        );
                    };
                } else if (
                    button.text.toLowerCase().includes('previous') ||
                    button.text.toLocaleLowerCase().includes('back')
                ) {
                    button.action = () => {
                        window?.dispatchEvent?.(
                            new CustomEvent<ITourProgressType>(
                                'tour-progress',
                                {
                                    detail: {
                                        tourName,
                                        status: TourStatusEnum.BACK,
                                        step: step.id,
                                    },
                                }
                            )
                        );
                    };
                } else if (button.text.toLowerCase().includes('next')) {
                    button.action = () => {
                        window?.dispatchEvent?.(
                            new CustomEvent<ITourProgressType>(
                                'tour-progress',
                                {
                                    detail: {
                                        tourName,
                                        status: TourStatusEnum.NEXT,
                                        step: step.id,
                                    },
                                }
                            )
                        );
                    };
                }
            });
        });

        if (initializedSteps?.length > 0 && !isSameSession(tourName)) {
            setSession(tourName);
            setTourSteps(initializedSteps);
            setShowLoader(false);
        } else {
            window?.dispatchEvent?.(
                new CustomEvent<ITourProgressType>('tour-progress', {
                    detail: {
                        tourName,
                        status: TourStatusEnum.NOT_VISITED,
                        step: '',
                    },
                })
            );
            setShowLoader(false);
        }
    };

    const saveTours = async () => {
        if (!newTourSaved) {
            setNewTourSaved(true);
            const savableTour: ITourStatusRegisterType = {
                tourname: tourName,
                isFinished: false,
                isExited: false,
                isDisabled: false,
                disabledAt: -1,
                steps: stepsDetails.map(({ id }: ISteps) => ({
                    name: id,
                    status: TourStatusEnum.NOT_VISITED,
                })),
            };
            if (
                isNil(tours) ||
                (isString(tours) && tours.trim().length === 0)
            ) {
                await updateTours?.([savableTour], true);
            } else {
                await updateTours?.([...tours, savableTour], true);
            }
        }
    };

    useEffect(
        function () {
            if (!isVisibleInDom) {
                window?.dispatchEvent?.(
                    new CustomEvent<ITourProgressType>('tour-progress', {
                        detail: {
                            tourName,
                            status: TourStatusEnum.NOT_VISITED,
                            step: '',
                        },
                    })
                );
            } else {
                let isSavingTour = false;
                if (
                    isNull(tours) ||
                    tours?.length === 0 ||
                    !isTourAvailable(tours, tourName)
                ) {
                    isSavingTour = true;
                    saveTours();
                }
                initializeSteps(isSavingTour);
            }
        },
        [isVisibleInDom, isTimeEllapsed]
    );

    return {
        showLoader,
        tourSteps,
        tours,
    };
};
