import React, { useContext, useEffect, useState } from 'react';
import { FormControl, Grid, MenuItem, TextField } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { storage } from '../../..';
import ModuleInfoContext from '../../../core/Providers/ModuleInfo/ModuleInfoContext';
import { componentsAPIInstance } from '../../../utils';
import { getSystemSetting } from '../../../utils/api/APIUtils';
import { AutoComplete, IAutoCompleteProps } from '../AutoComplete/AutoComplete';

export const Sites = ({
    onChange,
    id,
    value,
    defaultValue,
    autoComplete = true,
    freeSolo = false,
    getOptionSelected,
    filterOptions,
    optionsToDisplay = 1,
    loading = false,
    autoSelect = false,
    autoHighlight = false,
    onInputChange,
    label = '',
    tooltipPlacement = 'bottom',
    selectAllLabel = 'Select All',
    noOptionsText,
    clearText = 'Clear',
    renderInput,
    multiple = true,
    disabled,
    groupable,
    groupableField,
    error = false,
    helperText = '',
    required = false,
    OnOptionsChange,
    showAllOption = false,
    flag = false,
    disablePortal = false,
    displaySiteNameDropDown = false,
    siteTypeLabel = 'Site Type',
    clearInputValues = false,
}: IAutoCompleteProps<any>) => {
    const [optionsData, setOptionsData] = useState<any[]>([]);
    const [siteTypes, setSiteTypes] = useState<any[]>([]);
    const [tempOptions, setTempOptions] = useState<any[]>([]);
    const [selectedSiteType, setSelectedSiteType] = useState<any>('');
    const [isSortBySiteType, setIsSortBySiteType] = useState<boolean>(false);
    const moduleContext = useContext(ModuleInfoContext);
    const SORT_BY_SITE_TYPE = 'Sort By Site Type';
    const SORT_BY_SITE_NAME = 'Sort By Site Name';
    const activeDistrictName = storage.getItem('activeDistrict');
    const resourceURL = flag
        ? 'GetAllSitesAsync'
        : 'GetGroupsSelectListItemsAsync';
    const getSitesList = async () => {
        if (activeDistrictName) {
            const activeDistrict = JSON.parse(activeDistrictName);
            try {
                const response = await componentsAPIInstance.axios({
                    method: 'GET',
                    url: `${moduleContext?.apimUrl}/UserManagement/v1.0/api/Groups/${resourceURL}?groupGuid=${activeDistrict?.groupGuid}`,
                });
                return response;
            } catch (error) {
                return [];
            }
        } else {
            return [];
        }
    };

    useEffect(() => {
        if (clearInputValues) {
            setSelectedSiteType('');
        }
    }, [clearInputValues]);
    useEffect(() => {
        const options: any[] = [];
        let sortMethod = '';
        getSystemSetting('SITEDRPSTY', moduleContext.apimUrl)
            .then((response: any) => {
                if (response) {
                    sortMethod = response.data?.PayLoad;
                }
            })
            .catch((error) =>
                console.error(
                    'error getting system setting for SITEDRPSTY',
                    error
                )
            );
        getSitesList().then((response: any) => {
            let sortBySiteType = false;
            if (response && response.data && response.data.StatusCode === 200) {
                response.data.PayLoad.Sites.sort((a: any, b: any) => {
                    if (sortMethod === SORT_BY_SITE_NAME) {
                        return a.name > b.name ? 1 : -1;
                    } else if (sortMethod === SORT_BY_SITE_TYPE) {
                        sortBySiteType = true;
                        setIsSortBySiteType(true);
                        return a.name > b.name ? 1 : -1;
                    } else {
                        return a.siteCode > b.siteCode ? 1 : -1;
                    }
                }).map((data: any) => {
                    if (
                        data.isActive &&
                        data.siteCode &&
                        data.isActive === 'True'
                    ) {
                        options.push({
                            id: data.siteCode,
                            description: data.name,
                            siteId: data.siteId,
                            siteTypeId: data?.siteTypeId,
                            siteTypeDescription: data?.siteTypeDescription,
                        });
                    }
                });
                if (sortBySiteType && displaySiteNameDropDown) {
                    const unique1 = [];
                    const distinct = [];
                    if (options.length > 0 && displaySiteNameDropDown) {
                        for (let i = 0; i < options?.length; i++) {
                            if (
                                !isEmpty(options[i]?.siteTypeDescription) &&
                                !unique1[options[i].siteTypeId]
                            ) {
                                distinct.push(options[i]);
                                unique1[options[i].siteTypeId] = 1;
                            }
                        }
                    }
                    setSiteTypes(distinct);
                }
            }
        });
        if (showAllOption && options.length > 0 && !multiple) {
            options.push({
                id: 0,
                description: 'All',
            });
        }
        setTempOptions(options);
        setOptionsData(options);
    }, []);

    const customOptionLabel = (option: any) => {
        if (option.description === 'All') {
            return option.description;
        } else {
            return `${option.id} - ${option.description}`;
        }
    };
    const handleSiteTypeChange = (event: any) => {
        setSelectedSiteType(event.target.value);
        if (event.target.value === 0) {
            setOptionsData(tempOptions);
        } else {
            const updatedOptions = tempOptions.map((options: any) => {
                if (options.siteTypeId === event.target.value) {
                    return options;
                }
            });
            setOptionsData(
                updatedOptions.filter((option) => option !== undefined)
            );
        }
    };
    return (
        <>
            <Grid
                style={{
                    width: '600px',
                }}
                container={true}
                xs={12}
                spacing={2}
            >
                {isSortBySiteType && displaySiteNameDropDown && (
                    <Grid xs={12} md={6} item={true}>
                        <FormControl variant="standard" fullWidth>
                            <TextField
                                select
                                id="site-type-sleect"
                                value={selectedSiteType}
                                label={siteTypeLabel}
                                onChange={handleSiteTypeChange}
                                variant="outlined"
                            >
                                <MenuItem value={0}>ALL</MenuItem>
                                {siteTypes
                                    .sort((a: any, b: any) =>
                                        a.siteTypeDescription >
                                        b.siteTypeDescription
                                            ? 1
                                            : -1
                                    )
                                    .map((data: any) => {
                                        return (
                                            <MenuItem value={data?.siteTypeId}>
                                                {data?.siteTypeDescription}
                                            </MenuItem>
                                        );
                                    })}
                            </TextField>
                        </FormControl>
                    </Grid>
                )}

                <Grid xs={12} md={6} item={true}>
                    <AutoComplete
                        options={optionsData}
                        OnOptionsChange={OnOptionsChange}
                        defaultValue={defaultValue}
                        siteType={selectedSiteType}
                        multiple={multiple}
                        getOptionLabel={customOptionLabel}
                        required={required}
                        helperText={helperText}
                        error={error}
                        groupableField={groupableField}
                        groupable={groupable}
                        disabled={disabled}
                        renderInput={renderInput}
                        clearText={clearText}
                        noOptionsText={noOptionsText}
                        selectAllLabel={selectAllLabel}
                        tooltipPlacement={tooltipPlacement}
                        label={label}
                        onInputChange={onInputChange}
                        autoHighlight={autoHighlight}
                        autoSelect={autoSelect}
                        loading={loading}
                        optionsToDisplay={optionsToDisplay}
                        filterOptions={filterOptions}
                        getOptionSelected={getOptionSelected}
                        freeSolo={freeSolo}
                        autoComplete={autoComplete}
                        disablePortal={disablePortal}
                        value={value}
                        id={id}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
        </>
    );
};
