import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import {
    DateInputCustomFormatPlaceholder,
    DateTimePicker as DatePicker,
    DateTimePickerChangeEvent,
    DateTimePickerProps,
} from '@progress/kendo-react-dateinputs';
import { DateFormatOptions } from '@progress/kendo-react-intl';

export interface IDateTimePickerProps extends DateTimePickerProps {
    /**
     * Gets the value of the DateTimePicker.
     */
    value?: Date | null;
    /**
     * Default date values to display
     */
    defaultValue?: Date;
    /**
     * Fires each time the user selects a part of a date range
     */
    onChange?: (event: DateTimePickerChangeEvent) => void;
    /**
     * Sets the default state of the DateTimePicker upon render
     */
    defaultShow?: boolean;
    /**
     * Determines whether the DateTimePicker is disabled
     */
    disabled?: boolean;
    /**
     * Specifies the focused date of the DateTimePicker
     */
    focusedDate?: Date;
    /**
     * Specifies the date format which is used for formatting the value of the DateInput
     */
    format?: string | DateFormatOptions;
    /**
     * Identifies the element(s) which will label the component.
     */
    areaLabelledBy?: string;
    /**
     * Tp display or hide the cancel button.
     */
    cancelButton?: boolean;
    /**
     * placeholder for the date input field
     */
    formatPlaceholder?:
        | 'wide'
        | 'narrow'
        | 'short'
        | 'formatPattern'
        | DateInputCustomFormatPlaceholder;
    /**
     * required attribute.
     */
    required?: boolean;
    /**
     * Show hide week numbers.
     */
    weekNumber?: boolean;
    /**
     * Width of the calendar input field
     */
    width?: number;
    /**
     * show the calendar popup
     */
    show?: boolean;
    /**
     * Specifies the `name` property of the `input` DOM element.
     */
    name?: string;
    /**
     * Specifies the label of the date picker
     */
    label?: string;
}

export interface IDateTimePickerChangeEvent extends DateTimePickerChangeEvent {}

const DateTimePicker: FC<IDateTimePickerProps> = ({
    value,
    defaultValue,
    onChange,
    defaultShow,
    disabled,
    focusedDate,
    format,
    min,
    max,
    areaLabelledBy,
    cancelButton,
    formatPlaceholder,
    required,
    show,
    weekNumber,
    width,
    name,
    label,
    ...rest
}) => {
    return (
        <Box className="k-datetime-picker">
            <p className="k-date-time-label">{label}</p>
            <DatePicker
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                defaultShow={defaultShow}
                disabled={disabled}
                focusedDate={focusedDate}
                format={format || 'hh:mm a'}
                min={min}
                max={max}
                ariaLabelledBy={areaLabelledBy}
                cancelButton={cancelButton}
                formatPlaceholder={formatPlaceholder}
                required={required}
                show={show}
                weekNumber={weekNumber}
                width={width}
                name={name}
                valid={value !== null || value !== undefined}
                {...rest}
            />
        </Box>
    );
};

export default DateTimePicker;
