import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { ITheme } from '../../core/Providers';
import { StandardProps } from '../../types/standardProps';

export interface IAppLoaderProps extends StandardProps<HTMLDivElement> {
    /**
     * Conditionally start and stop loading
     */
    loading?: boolean;
    /**
     * Width and height of loader
     */
    size?: number;
}

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    box: {
        width: '50px',
        height: '50px',
        fontSize: '6px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    path: {
        position: 'absolute',
        borderRadius: '50%',
        borderStyle: 'solid',
        animation: '$animateTurn 1.4s linear infinite',
    },
    primaryPath: {
        color: theme.palette.primary.main,
        animationDirection: 'normal',
    },
    secondaryPath: {
        color: theme.palette.secondary.main,
        animationDirection: 'reverse',
    },
    firstPath: {
        width: '100%',
        height: '100%',
        borderWidth: '0.3em 0 0 0.3em',
        borderColor: 'transparent transparent transparent currentColor',
        animationDuration: '1.6s',
    },
    secondPath: {
        width: '100%',
        height: '100%',
        borderWidth: '0.3em 0.3em 0 0',
        borderColor: 'transparent currentColor transparent transparent',
        animationDuration: '1.9s',
    },
    thirdPath: {
        width: '72%',
        height: '72%',
        borderWidth: '0 0.3em 0.3em 0',
        borderColor: 'transparent currentColor transparent transparent',
        animationDuration: '1.8s',
    },
    fourthPath: {
        width: '72%',
        height: '72%',
        borderWidth: '0 0 0.3em 0.3em',
        borderColor: 'transparent transparent transparent currentColor',
        animationDuration: '2.0s',
    },
    fifthPath: {
        width: '44%',
        height: '44%',
        borderWidth: '0.3em 0 0 0.3em',
        borderColor: 'transparent transparent transparent currentColor',
        animationDuration: '2.1s',
    },
    sixthPath: {
        width: '44%',
        height: '44%',
        borderWidth: '0.3em 0.3em 0 0',
        borderColor: 'transparent currentColor transparent transparent',
        animationDuration: '1.7s',
    },
    '@keyframes animateTurn': {
        to: {
            transform: 'rotate(1turn)',
        },
    },
}));

const MIN_LOADER_SIZE = 40;
const MAX_LOADER_SIZE = 200;

const AppLoader: FC<IAppLoaderProps> = ({
    className,
    loading = true,
    size,
    ...rest
}) => {
    const classes = useStyles();
    const [boxSize, setBoxSize] = useState({});

    useEffect(() => {
        if (size) {
            // Size is divisible by 4 within given bounds
            let computedSize = Math.min(
                Math.max(size, MIN_LOADER_SIZE),
                MAX_LOADER_SIZE
            );
            computedSize = Math.round(computedSize / 4) * 4;
            setBoxSize({
                width: computedSize,
                height: computedSize,
            });
            if (size < MIN_LOADER_SIZE || size > MAX_LOADER_SIZE) {
                console.warn(
                    `AppLoader size should be in between ${MIN_LOADER_SIZE} to ${MAX_LOADER_SIZE}.`
                );
            }
        }
    }, [size]);

    if (!loading) {
        return null;
    }

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <div className={classes.box} style={boxSize}>
                <div
                    className={clsx(
                        classes.path,
                        classes.primaryPath,
                        classes.firstPath
                    )}
                />
                <div
                    className={clsx(
                        classes.path,
                        classes.secondaryPath,
                        classes.secondPath
                    )}
                />
                <div
                    className={clsx(
                        classes.path,
                        classes.primaryPath,
                        classes.thirdPath
                    )}
                />
                <div
                    className={clsx(
                        classes.path,
                        classes.secondaryPath,
                        classes.fourthPath
                    )}
                />
                <div
                    className={clsx(
                        classes.path,
                        classes.primaryPath,
                        classes.fifthPath
                    )}
                />
                <div
                    className={clsx(
                        classes.path,
                        classes.secondaryPath,
                        classes.sixthPath
                    )}
                />
            </div>
        </div>
    );
};

export default AppLoader;
