import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import clsx from 'clsx';

import { useSettings } from '../../core/Providers';
import ModuleInfoContext from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import ScStorage from '../../core/Storage';
import { componentsAPIInstance } from '../../utils';
import { Button } from '../Button';
import { ContextMenu } from '../ContextMenu';

import { IDisplayableRoutes } from './VerticalNavSection';
const expressPointNonProdUrl = (env: string) => {
    if (env.toLowerCase() === 'solutions') {
        return `https://demoexpresspointui.schoolcafe.com`;
    } else if (env.toLowerCase().includes('localhost')) {
        return `https://devexpresspointui.perseusedge.com`;
    } else if (env.toLowerCase().includes('learn')) {
        return `https://learnexpresspointui.schoolcafe.com`;
    } else if (env.toLowerCase().includes('sales')) {
        return `https://salesexpresspointui.schoolcafe.com`;
    } else {
        return `https://${env}expresspointui.perseusedge.com`;
    }
};

interface IVerticalSection {
    route: IDisplayableRoutes;
    currentModule: string;
}

const styles = makeStyles(() => ({
    btnFullWidth: {
        width: '220px',
        justifyContent: 'flex-start',
        height: '34px',
        paddingLeft: '40px',
        textTransform: 'initial',
        fontWeight: 400,
        margin: '6px 0',
        '&:hover': {
            background: '#F1F0FB',
        },
    },
    selected: {
        background: '#F8F7FD',
    },
    dlRoot: {
        paddingLeft: '20px',
    },
    navDt: {
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
    },
}));

export const VerticalSection = ({ route, currentModule }: IVerticalSection) => {
    const history = useHistory();
    const classes = styles();
    const { setCurrentPage } = useSettings();
    const { moduleName, apimUrl, resetErrorBoundry } = useContext(
        ModuleInfoContext
    );
    const [contextMenu, setContextMenu] = React.useState<any>(null);
    const menuOptions = [
        'Open in new tab',
        'Copy link address',
        'Open in new window',
    ];
    const handleContextMenu = (event: any) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX - 2,
                      mouseY: event.clientY - 4,
                  }
                : null
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };
    const handleRedirect = (option: any, event: any) => {
        resetErrorBoundry?.();
        if (option === 'Open in new window') {
            window.open(`/${route.url}`, '_blank', 'targetWindow');
        } else if (option === 'Open in new tab' || event.button === 1) {
            window.open(`/${route.url}`, '_blank');
        } else if (option === 'Copy link address') {
            // eslint-disable-next-line no-restricted-globals
            navigator.clipboard.writeText(`${location?.origin}/${route.url}`);
        }
        setContextMenu(null);
    };
    const triggerSubRoute = useCallback(
        async (url?: string, redirect?: boolean) => {
            //Start: This code block is for menu planning module to save a flag in DB on menu-entry and meny-cycle-entry
            if (
                moduleName === 'Menu Planning' &&
                window.location.pathname === '/menu-planning/menu-entry' &&
                sessionStorage.getItem('menuId') !== null
            ) {
                const menuId = Number(sessionStorage.getItem('menuId'));
                await componentsAPIInstance
                    .axios({
                        method: 'POST',
                        url: `${apimUrl}/menuplanning/v1.0/api/Menus/${menuId}/CheckOutCheckIn?checkOut=false`,
                        data: {
                            menuId: Number(menuId),
                            checkOut: false,
                        },
                    })
                    .catch((error: any) => {
                        console.log(
                            'UiComponents: CheckOutCheckIn API failure in menu planning ',
                            error
                        );
                    });
                sessionStorage.removeItem('menuId');
            } else if (
                moduleName === 'Menu Planning' &&
                window.location.pathname ===
                    '/menu-planning/menu-cycle-entry' &&
                sessionStorage.getItem('menuCycleId') !== null
            ) {
                const releaseMenuCycleId = sessionStorage.getItem(
                    'menuCycleId'
                );
                await componentsAPIInstance
                    .axios({
                        method: 'POST',
                        url: `${apimUrl}/menuplanning/v1.0/api/MenuCycles/CheckOutCheckInMenuCycle`,
                        data: {
                            menuCycleId: Number(releaseMenuCycleId),
                            checkOut: false,
                        },
                    })
                    .catch((error: any) => {
                        console.log(
                            'UiComponents: CheckOutCheckInMenuCycle API failure in menu planning ',
                            error
                        );
                    });
                sessionStorage.removeItem('menuCycleId');
            }
            //End: This code block is for menu planning module to save a flag in DB on menu-entry and meny-cycle-entry

            if (route.page === 'POS') {
                const host = window.location.host.split('.');
                const isProd = window.location.host.includes(
                    'login.schoolcafe'
                );
                if (isProd) {
                    window.open(
                        `https://expresspoint.login.schoolcafe.com?accessToken=${ScStorage().getItem(
                            'accessToken'
                        )}&expiresIn=${ScStorage().getItem(
                            'expiresIn'
                        )}&refreshExpiresIn=${ScStorage().getItem(
                            'refreshExpiresIn'
                        )}&posVersion=${ScStorage().getItem(
                            'posVersion'
                        )}&posBetaVersion=${ScStorage().getItem(
                            'posBetaVersion'
                        )}&tokenType=${ScStorage().getItem(
                            'tokenType'
                        )}&regionId=${ScStorage().getItem('userRegion')}`,
                        '_blank'
                    );
                } else {
                    window.open(
                        `${expressPointNonProdUrl(
                            host[0]
                        )}?accessToken=${ScStorage().getItem(
                            'accessToken'
                        )}&expiresIn=${ScStorage().getItem(
                            'expiresIn'
                        )}&refreshExpiresIn=${ScStorage().getItem(
                            'refreshExpiresIn'
                        )}&posVersion=${ScStorage().getItem(
                            'posVersion'
                        )}&posBetaVersion=${ScStorage().getItem(
                            'posBetaVersion'
                        )}&tokenType=${ScStorage().getItem('tokenType')}`,
                        '_blank'
                    );
                }
            } else {
                if (redirect) {
                    window.location.href = `/${url}`;
                } else {
                    if (moduleName === currentModule) {
                        history.push(`/${url}`);
                    } else {
                        window.location.href = `/${url}`;
                    }
                }
            }
            return null;
        },
        [history, currentModule]
    );
    if (!route.subSection) {
        return (
            <div
                id="context-menu"
                onContextMenu={handleContextMenu}
                style={{
                    cursor: 'context-menu',
                }}
            >
                <Button
                    color="default"
                    id={`nav-${
                        route.page ? route.page.split(' ').join('-') : ''
                    }`}
                    label={route.alias ? route.alias : route.page}
                    name="Button"
                    onClick={() => {
                        resetErrorBoundry?.();
                        setCurrentPage?.(route?.url ?? '');
                        triggerSubRoute(route.url, route.isRedirect);
                    }}
                    type="button"
                    variant="text"
                    className={clsx(classes.btnFullWidth, {
                        [classes.selected]:
                            history.location.pathname === route.url,
                    })}
                    useEllipsis={false}
                    style={{
                        padding: '20px',
                        fontWeight: 500,
                        marginLeft: '3px',
                    }}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? {
                                  top: contextMenu.mouseY,
                                  left: contextMenu.mouseX,
                              }
                            : undefined
                    }
                >
                    {menuOptions.map((option) => (
                        <MenuItem
                            key={option}
                            value={option}
                            onClick={(event) => handleRedirect(option, event)}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    } else {
        return (
            <dl className={classes.dlRoot}>
                <dt className={classes.navDt}>
                    <b>{route.subSection}</b>
                </dt>
                {route.subRoutes?.map(
                    (subRoute: IDisplayableRoutes, index: number) => {
                        return (
                            <dd
                                key={index}
                                style={{
                                    marginInlineStart: '-17px',
                                }}
                            >
                                <ContextMenu
                                    menu={
                                        <Button
                                            color="default"
                                            id={`nav-${
                                                subRoute.page
                                                    ? subRoute.page
                                                          .split(' ')
                                                          .join('-')
                                                    : ''
                                            }`}
                                            label={
                                                subRoute.alias
                                                    ? subRoute.alias
                                                    : subRoute.page
                                            }
                                            name={
                                                subRoute.alias
                                                    ? subRoute.alias
                                                    : subRoute.page
                                            }
                                            onClick={() => {
                                                resetErrorBoundry?.();
                                                setCurrentPage?.(
                                                    subRoute?.url ?? ''
                                                );
                                                triggerSubRoute(
                                                    subRoute.url,
                                                    subRoute.isRedirect
                                                );
                                            }}
                                            type="button"
                                            variant="text"
                                            className={clsx(
                                                classes.btnFullWidth,
                                                {
                                                    [classes.selected]:
                                                        history.location
                                                            .pathname ===
                                                        subRoute.url,
                                                }
                                            )}
                                            useEllipsis={false}
                                        />
                                    }
                                    fromSideNav={true}
                                    url={subRoute.url}
                                />
                            </dd>
                        );
                    }
                )}
            </dl>
        );
    }
};
