/**
 * Left side navigation panel width
 * 264px
 */
export const SIDENAV_SIZE = 272;

/**
 * Default value for set timeout
 * 100 ms
 */
export const DEFAULT_TIMEOUT = 100;

/**
 * Minimum value for set timeout
 * 100 ms
 */
export const MIN_TIMEOUT = 1;

/**
 * Maximum wait time
 * 1 minute - 60 * 1000 ms
 */
export const MAX_WAIT_TIME = 60000;

/**
 * Maximum number of time to retry token
 */
export const MAX_TOKEN_RETRIES = 1;

/**
 * Default size of icons
 */
export const DEFAULT_ICON_SIZE = 24;
