import React from 'react';

import {
    APP_INSIGHTS_INGESTION,
    APP_INSIGHTS_INSTRUMENTATION,
    APP_INSIGHTS_LIVE_ENDPOINT,
} from '../../core/api-constants';
import AppInsightsImpl from '../../core/Providers/logger/AppInsightsImpl';

interface IAppInsightsLoggerProps {
    children: React.ReactNode;
}

export const AppInsightsContext = React.createContext<any>(
    AppInsightsImpl.getPlugin()
);

/**
 * @AppInsightsLogger
 * Higher order component to log activities in Azure AppInsights.
 * If condition (APP_INSIGHTS_INSTRUMENTATION && APP_INSIGHTS_INGESTION && APP_INSIGHTS_LIVE_ENDPOINT) fails,
 * then app insights will not be applied to mainlayout.
 * @param children react children
 */
const AppInsightsLogger = ({ children }: IAppInsightsLoggerProps) => {
    if (
        APP_INSIGHTS_INSTRUMENTATION &&
        APP_INSIGHTS_INGESTION &&
        APP_INSIGHTS_LIVE_ENDPOINT
    ) {
        return (
            <AppInsightsContext.Provider value={AppInsightsImpl.getPlugin()}>
                {children}
            </AppInsightsContext.Provider>
        );
    }
    return <>{children}</>;
};

export default AppInsightsLogger;
