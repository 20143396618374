import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { groupBy, orderBy } from 'lodash';

import { useProfile } from '../../core/Providers';
import ModuleInfoContext, {
    IModuleInfoContextValue,
} from '../../core/Providers/ModuleInfo/ModuleInfoContext';
import { IResources } from '../../types/user';

import { VerticalSection } from './VerticalSection';

interface IVerticalNavSection {
    currentModule: string;
    resources?: IResources[] | null;
}

export interface IDisplayableRoutes {
    subSection?: string;
    page?: string;
    url?: string;
    isRedirect?: boolean;
    alias?: string;
    subRoutes?: IDisplayableRoutes[];
    subSectionOrder?: number;
    displayOrder?: number;
    isSearchable?: boolean;
}

const styles = makeStyles(() => ({
    navigavtionRoutesRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        overflow: 'auto',
        height: '100%',
        paddingBottom: '200px',
    },
    navigavtionRoutesRootWhenBroadCastExists: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        overflow: 'auto',
        height: '100%',
        paddingBottom: '175px',
    },
}));

export const VerticalNavSection = ({
    currentModule,
    resources,
}: IVerticalNavSection) => {
    const { routes } = useContext<IModuleInfoContextValue>(ModuleInfoContext);
    const [newNav, setNewNav] = useState<IDisplayableRoutes[]>([]);
    const classes = styles();
    const { user } = useProfile();
    const globalBroadcastElement = document.getElementById(
        'global-broadcast-messages-container'
    );
    const broadcastElement = document.getElementById(
        'broadcast-messages-container'
    );
    const [isBroadCastMessageExists, setIsBroadcastMessageExists] = useState<
        boolean
    >(false);

    useEffect(() => {
        if (globalBroadcastElement || broadcastElement) {
            setIsBroadcastMessageExists(true);
        } else {
            setIsBroadcastMessageExists(false);
        }
    }, [broadcastElement, globalBroadcastElement]);
    useEffect(() => {
        if (routes.length > 0) {
            const groupedRoutes = groupBy(routes, 'moduleName');
            const moduleGrouped = groupedRoutes[currentModule];
            const selectedRoute = { resources: moduleGrouped };

            if (selectedRoute && selectedRoute.resources?.length > 0) {
                const routesResources = selectedRoute.resources;
                let tempAllowedRouted: IDisplayableRoutes[] = [];
                routesResources.forEach((route: any) => {
                    const isPageExists = resources?.filter(
                        (resource: IResources) =>
                            resource.resourceName === route.routeName &&
                            resource.type === 'Page' &&
                            resource.scopes.includes('View')
                    );
                    if (
                        isPageExists &&
                        isPageExists.length > 0 &&
                        (isPageExists[0].scopes.length === 0 ||
                            isPageExists[0].scopes.includes('View'))
                    ) {
                        if (route['subSection']) {
                            const subSectionsIndex = tempAllowedRouted.findIndex(
                                (allowedRoute: IDisplayableRoutes) =>
                                    allowedRoute.subSection ===
                                    route['subSection']
                            );
                            if (
                                subSectionsIndex >= 0 &&
                                tempAllowedRouted[subSectionsIndex] &&
                                tempAllowedRouted[subSectionsIndex].subRoutes
                            ) {
                                // @ts-ignore: Object is possibly 'null'
                                tempAllowedRouted[
                                    subSectionsIndex
                                ].subRoutes.push({
                                    subSection: route['subSection'],
                                    page: route.routeName,
                                    url: route.routePath,
                                    isRedirect: !!route.isRedirect,
                                    alias: route.alias || undefined,
                                    subSectionOrder: route.subSectionOrder ?? 0,
                                    displayOrder: route.displayOrder ?? 0,
                                    isSearchable: !!route.isSearchable,
                                });
                            } else {
                                tempAllowedRouted.push({
                                    subSection: route['subSection'],
                                    subSectionOrder: route.subSectionOrder ?? 0,
                                    subRoutes: [
                                        {
                                            subSection: route['subSection'],
                                            page: route.routeName,
                                            url: route.routePath,
                                            isRedirect: !!route.isRedirect,
                                            alias: route.alias || undefined,
                                            subSectionOrder:
                                                route.subSectionOrder ?? 0,
                                            displayOrder:
                                                route.displayOrder ?? 0,
                                            isSearchable: !!route.isSearchable,
                                        },
                                    ],
                                });
                            }
                        } else {
                            if (route.page === 'Setup Onboarding') {
                                if (
                                    user?.Roles.includes('District Onboarding')
                                ) {
                                    tempAllowedRouted.push({
                                        page: route.routeName,
                                        url: route.routePath,
                                        isRedirect: !!route.isRedirect,
                                        alias: route.alias || undefined,
                                        subSectionOrder:
                                            route.subSectionOrder ?? 0,
                                        displayOrder: route.displayOrder ?? 0,
                                        isSearchable: !!route.isSearchable,
                                    });
                                }
                            } else {
                                tempAllowedRouted.push({
                                    page: route.routeName,
                                    url: route.routePath,
                                    isRedirect: !!route.isRedirect,
                                    alias: route.alias || undefined,
                                    subSectionOrder: route.subSectionOrder ?? 0,
                                    displayOrder: route.displayOrder ?? 0,
                                    isSearchable: !!route.isSearchable,
                                });
                            }
                        }
                    }
                });
                tempAllowedRouted = orderBy(
                    tempAllowedRouted,
                    'subSectionOrder'
                );
                tempAllowedRouted.forEach((route: any) => {
                    if ('subRoutes' in route) {
                        route.subRoutes = orderBy(
                            route.subRoutes,
                            'displayOrder'
                        );
                    }
                });
                setNewNav(() => tempAllowedRouted);
            } else {
                setNewNav(() => []);
            }
        }
    }, [currentModule, resources, routes]);
    return (
        <div
            className={
                isBroadCastMessageExists
                    ? classes.navigavtionRoutesRootWhenBroadCastExists
                    : classes.navigavtionRoutesRoot
            }
        >
            {newNav.map((nav: IDisplayableRoutes, index: number) => (
                <VerticalSection
                    key={index}
                    route={nav}
                    currentModule={currentModule}
                />
            ))}
        </div>
    );
};
