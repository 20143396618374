import React, { cloneElement, useEffect, useState } from 'react';
import { Tour } from 'react-shepherd';

import { ISteps } from './types';
import useTourSettings from './useTourSettings';

interface IProps {
    children: React.ReactElement;
    tourContext: React.Context<Tour | null>;
    steps: ISteps[];
    tourName: string;
}

/**
 * @component
 * @TourSettingsProcessor
 * HOC component to pass the tours steps needed based on previous user progress.
 * @param children React Element
 * @param steps ISteps[] array of Steps for tour need for the tour based on user's progress.
 * @param tourName string Tour name on the initial screen.
 */
export default function TourSettingsProcessor({
    children,
    tourContext,
    steps,
    tourName,
}: IProps) {
    const [currentSteps, setCurrentSteps] = useState<ISteps[]>([]);
    useEffect(
        function () {
            if (steps?.length > 0) {
                setCurrentSteps(steps);
            }
        },
        [steps]
    );
    useTourSettings({ tourContext, steps: currentSteps, tourName });
    return <>{cloneElement(children, { tourContext })}</>;
}
