import Axios from 'axios';

import { getSettingRolesV1 } from '../components/Grid/Kendo/views/api';
import { getEnvironment } from '../core/environments';
import ScStorage from '../core/Storage';

export default function useUserInfo() {
    const getUserInfo = async function ({
        apimUrl,
        userId,
        subscriptionKey,
    }: {
        apimUrl: string;
        userId: number | string;
        subscriptionKey: string;
    }) {
        return Axios.get(`${apimUrl}/administration/v1.0/api/User/${userId}`, {
            headers: {
                Environment: getEnvironment(),
                'Ocp-Apim-Subscription-Key': subscriptionKey,
                Authorization: `Bearer ${ScStorage().getItem('accessToken')}`,
            },
        });
    };

    const loadScInfo = function () {
        const storedRegionId = JSON.parse(
            ScStorage().getItem('activeDistrict') ?? '{}'
        )?.attributes?.regionId?.[0];
        console.log(storedRegionId);
    };

    const setDistrictKeycloakType = function ({
        apimUrl,
        regionId,
    }: {
        apimUrl: string;
        regionId: string;
    }) {
        getSettingRolesV1(apimUrl, regionId).then((response: any) => {
            if (response.data.StatusCode === 200) {
                ScStorage().setItem(
                    'settingValueRolesV1',
                    response.data.PayLoad
                );
            }
        });
    };

    return {
        setDistrictKeycloakType,
        getUserInfo,
        loadScInfo,
    };
}
