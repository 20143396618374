import React, { useRef, useState } from 'react';
import { Avatar, IconButton, makeStyles, Popover } from '@material-ui/core';

import { Button } from '../Button';
import { Dialog } from '../Dialog';
import { Tooltip } from '../Tooltip';

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: '#F2F2F2',
        color: theme.palette.primary.main,
    },
    popover: {
        width: 280,
        padding: theme.spacing(1.5),
    },
    accountButton: {
        padding: 0,
    },
    avatarImage: {
        aspectRatio: '1/3',
        zoom: 'normal',
        objectFit: 'contain',
        height: 300,
    },
}));

export default function SmartAccount({
    userImageUrl,
    user,
    navigateToProfile,
    handleLogout,
}: {
    userImageUrl: string;
    user?: {
        FirstName: string;
        LastName: string;
    } | null;
    navigateToProfile: () => void;
    handleLogout: () => void;
}) {
    const classes = useStyles();
    const ref = useRef<HTMLButtonElement | null>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title="Account">
                <IconButton
                    ref={ref}
                    color="inherit"
                    onClick={handleOpen}
                    aria-label="Account"
                    className={classes.accountButton}
                >
                    {userImageUrl !== '' ? (
                        <Avatar
                            alt="User Image"
                            id={'user-profile-image'}
                            src={userImageUrl}
                            classes={{ img: classes.avatarImage }}
                        />
                    ) : (
                        <Avatar className={classes.avatar}>
                            {`${user?.FirstName} ${user?.LastName}`
                                .replace(/\s*(\S)\S*/g, '$1')
                                .toUpperCase()}
                        </Avatar>
                    )}
                </IconButton>
            </Tooltip>
            <Dialog
                id={'logout-confirmation'}
                open={showWarning}
                title={'Confirm Logout'}
                titleStyles={{
                    fontSize: 20,
                    padding: '4px 0px',
                    lineHeight: 1.2,
                }}
                primaryText="Confirm"
                secondaryText="Cancel"
                onPrimaryAction={handleLogout}
                onClose={() => setShowWarning(false)}
                onSecondaryAction={() => setShowWarning(false)}
                content={
                    <p style={{ width: 300 }}>
                        Once confirmed, All the tabs will be logged out and
                        unsaved changes will be lost. <br /> Click <b>Cancel</b>
                        , to continue the session.
                    </p>
                }
            />
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{
                    paper: classes.popover,
                }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Button
                    id="profile"
                    onClick={navigateToProfile}
                    startIcon={
                        <>
                            {userImageUrl !== '' ? (
                                <Avatar alt="User Image" src={userImageUrl} />
                            ) : (
                                <Avatar className={classes.avatar}>
                                    {`${user?.FirstName} ${user?.LastName}`
                                        .replace(/\s*(\S)\S*/g, '$1')
                                        .toUpperCase()}
                                </Avatar>
                            )}
                        </>
                    }
                    fullWidth
                    variant="text"
                    textAlign="start"
                >
                    {user ? `${user.FirstName} ${user.LastName}` : 'Profile'}
                </Button>
                {/* {user?.Roles?.includes('Customer Support') && (
                    <Button
                        id="multi-user-login"
                        onClick={onMultiUserSignIn}
                        fullWidth
                        variant="text"
                        textAlign="start"
                    >
                        Impersonate User
                    </Button>
                )} */}
                <Button
                    id="logout"
                    onClick={() => {
                        handleClose();
                        setShowWarning(true);
                    }}
                    fullWidth
                    variant="text"
                    textAlign="start"
                >
                    Sign Out
                </Button>
            </Popover>
        </>
    );
}
