import React, { FC, forwardRef, ReactNode, useEffect } from 'react';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import {
    AppBar,
    Box,
    IconButton,
    makeStyles,
    Theme,
    Toolbar,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import clsx from 'clsx';

import { BookmarkStarIcon } from '../../assets/icons';
import { useProfile } from '../../core/Providers';
import ScStorage from '../../core/Storage';
import { useUserInfo } from '../../hooks';
import { StandardProps } from '../../types/standardProps';

import Account from './Account';
import Favorites from './Favorites';
import HelpDocumentation from './HelpDocumentation';
import Notifications from './Notifications';
import ProjectTitle from './ProjectTitle';
import TopBarPageSearch from './TopBarPageSearch';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface ITopBarProps extends StandardProps<HTMLDivElement> {
    apimUrl: string;
    subscriptionKey: string;
    /* Search Box Component */
    searchBox?: ReactNode;
    setCurrentTourInfo?: Function;
    showReturnToSmartDashboard?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
        overflow: 'hidden',
    },
    toolbar: {
        color: 'white',
        minHeight: 56,
        paddingLeft: 16,
        paddingRight: 16,
    },
    allPageContainer: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
    },
    returnToV2: {
        '&:hover': {
            backgroundColor: 'tranparent',
        },
    },
}));

const TopBar: FC<ITopBarProps> = forwardRef(
    (
        {
            className,
            apimUrl,
            searchBox,
            subscriptionKey,
            setCurrentTourInfo,
            showReturnToSmartDashboard = false,
            ...rest
        },
        ref
    ) => {
        const classes = useStyles();
        const { user } = useProfile();
        const theme = useTheme();
        const { getUserInfo } = useUserInfo();

        const validationCollectionFieldForOnboarding = ScStorage().getItem(
            'validationCollectionFieldForOnboarding'
        );
        const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

        const isDashboardV2Enabled = localStorage.getItem(
            'dashboardV2Settings'
        );

        /**
         * Get stored district from localStorage
         */
        useEffect(() => {
            try {
                const timeoutRef = setTimeout(function () {
                    if (ScStorage().getItem('district-change')) {
                        ScStorage().removeItem('district-change');
                    }
                    clearTimeout(timeoutRef);
                }, 5000);
                if (user?.Groups?.length && user?.UserId) {
                    getUserInfo({
                        apimUrl,
                        subscriptionKey,
                        userId: user.UserId,
                    }).then((resp) => {
                        setCurrentTourInfo?.(
                            resp.data.PayLoad.shepherdTour?.length > 0
                                ? JSON.parse(resp.data.PayLoad.shepherdTour)
                                : null
                        );
                    });
                }
            } catch (e) {
                console.error(e);
            }
        }, [apimUrl, subscriptionKey, user]);

        return (
            <AppBar
                title=""
                ref={ref}
                position="absolute"
                className={clsx(classes.root, className)}
                {...rest}
            >
                <header>
                    <Toolbar
                        classes={{
                            root: classes.toolbar,
                        }}
                    >
                        <ProjectTitle />
                        <Box
                            my={1}
                            width={'300px'}
                            style={{
                                paddingTop: 4,
                                paddingBottom: 4,
                                marginLeft: 90,
                            }}
                        >
                            {!user?.Roles.includes('District Onboarding') &&
                                validationCollectionFieldForOnboarding !==
                                    'RedirectToProfilePage' && (
                                    <TopBarPageSearch />
                                )}
                        </Box>
                        <Box flexGrow={1} />
                        {isLargeScreen && (
                            <>
                                <Box flexGrow={1} />
                                <Box
                                    my={1}
                                    width={'300px'}
                                    style={{
                                        paddingTop: 4,
                                        paddingBottom: 4,
                                        marginLeft: 30,
                                    }}
                                >
                                    {searchBox}
                                </Box>
                            </>
                        )}
                        {showReturnToSmartDashboard &&
                            JSON.parse(isDashboardV2Enabled ?? 'false') && (
                                <IconButton
                                    classes={{
                                        root: classes.returnToV2,
                                    }}
                                    title={'Return to Dashboard (Beta)'}
                                    aria-label="Return to Dashboard Beta 2.0"
                                    onClick={() => {
                                        window.dispatchEvent(
                                            new CustomEvent('show-dashboard-v2')
                                        );
                                    }}
                                >
                                    <BookmarkStarIcon
                                        height={28}
                                        width={28}
                                        color={'#fff'}
                                    />
                                </IconButton>
                            )}
                        <Box>
                            <Notifications
                                apimUrl={apimUrl}
                                subscriptionKey={subscriptionKey}
                            />
                        </Box>
                        <Box
                            style={{
                                marginRight: 10,
                            }}
                        >
                            <Favorites />
                        </Box>
                        <Box
                            style={{
                                marginRight: 10,
                            }}
                        >
                            <HelpDocumentation />
                        </Box>

                        {/* <Box
                            style={{
                                marginRight: 10,
                            }}
                        >
                            <Favorites />
                        </Box> */}

                        <Account
                            user={user}
                            apimUrl={apimUrl}
                            subscriptionKey={subscriptionKey}
                        />
                    </Toolbar>
                </header>
            </AppBar>
        );
    }
);

export default TopBar;
