import React from 'react';
import Helmet from 'react-helmet';

/**
 * @component
 * @TourStyles
 * Functional component that provides basic theme for the Shepherd tour.
 */
export const TourStyles: React.FC = () => (
    <>
        <Helmet>
            <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/shepherd.js@10.0.1/dist/css/shepherd.css"
            />
        </Helmet>
        <style>{`
            .shepherd-footer:has(> :last-child:nth-child(1)) { /* 1 element */
                margin-top: 10px;
                border-top: 1px solid #b5b5b5;
                padding-top: 10px;
                display: flex;
                width: 100%;
                justify-content: end;
            }
            .shepherd-footer:has(> :last-child:nth-child(2)) { /* 2 elements */
                margin-top: 10px;
                border-top: 1px solid #b5b5b5;
                padding-top: 10px;
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
            .shepherd-footer:has(> :last-child:nth-child(3)) { /* 3 elements */
                margin-top: 10px;
                border-top: 1px solid #b5b5b5;
                padding-top: 10px;
                display: flex;
                width: 100%;
                justify-content: space-evenly;
            }
            .shepherd-text {
                margin-left: 16px;
            }
            .shepherd-element[data-popper-placement="top"] {
                margin-top: -12px;
            }
            .shepherd-element[data-popper-placement="bottom"] {
                margin-top: 12px;
            }
            .shepherd-element[data-popper-placement="left"] {
                margin-left: -12px;
            }
            .shepherd-element[data-popper-placement="right"] {
                margin-left: 12px;
            }
            .shepherd-header {
                padding: 6px 12px !important;
                background: #e6e6e6 !important;
            }
            .shepherd-title {
                font-weight: 500 !important;
                font-size: 20px !important;
                flex: 1 0 auto;
                margin: 0;
                padding: 0;
                width: 370px;
                word-break: break-word;
            }
            .highlight {
                background-color: #665AD8 !important;
                color: white !important;
            }
            .shepherd-button-action {
                font-size: 16px;
                color: white;
            }
            .shepard-button-finish {
                background: #43a047 !important;
                color: #fff !important;
            }
            .shepard-button-primary {
                background: #665AD8 !important;
            }
            .shepard-button-secondary {
                background: #f4f7fa !important;
            }
            .shepherd-button-end {
                background-color: #43a047 !important;
            }
        `}</style>
    </>
);
