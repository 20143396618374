import { useEffect, useRef, useState } from 'react';
import { flatten, isEmpty } from 'lodash';

import { useAuth, useProfile } from '../core/Providers';
import { IPageRoute } from '../core/Providers/ModuleInfo/ModuleInfoContext';
import ScStorage from '../core/Storage';
import { IPermission, IResources } from '../types';
import { componentsAPIInstance } from '../utils/api/APIInstance';

const useRoutes = function (apimUrl: string) {
    const { auth } = useAuth();
    const { user } = useProfile();
    const routesRef = useRef<{
        initiated: boolean;
    }>({
        initiated: false,
    });
    const [routes, setRoutes] = useState<IPageRoute[]>(function () {
        const allowedRoutesString = ScStorage().getItem('allowedRoutes');
        const allowedRoutes = JSON.parse(
            allowedRoutesString && allowedRoutesString?.length > 0
                ? allowedRoutesString
                : '[]'
        );

        return allowedRoutes?.length < 1 ? [] : allowedRoutes;
    });

    const getPages = async function () {
        const {
            data: { PayLoad, StatusCode },
        } = await componentsAPIInstance.axios({
            method: 'GET',
            url: `${apimUrl}/administration/v1.0/api/Route/Routes`,
        });
        if (StatusCode === 200) {
            const userAuthenticatedPages = flatten(
                user?.RolesPermissions.map((item: IPermission) => {
                    const pages = item.resources.reduce(
                        (acc: string[], curr: IResources) => {
                            if (
                                curr.type === 'Page' &&
                                curr.scopes.length > 0 &&
                                curr.scopes.includes('View')
                            ) {
                                acc.push(curr.resourceName);
                            }

                            return acc;
                        },
                        []
                    );

                    return pages;
                })
            );

            const allowedPagesForUser =
                PayLoad?.length > 0
                    ? PayLoad.filter((page: any) =>
                          userAuthenticatedPages.includes(page.routeName)
                      )
                    : [];
            ScStorage().setItem(
                'allowedRoutes',
                JSON.stringify(allowedPagesForUser)
            );
            setRoutes(allowedPagesForUser);
        }
    };

    useEffect(
        function () {
            if (
                auth?.accessToken &&
                isEmpty(routes) &&
                !routesRef.current.initiated
            ) {
                routesRef.current.initiated = true;
                getPages();
            }
        },
        [auth]
    );

    return routes;
};

export default useRoutes;
