import React, { FC, useEffect, useReducer, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { isNil, isNull } from 'lodash';
import moment from 'moment';

import { Dialog } from '../../../components/Dialog';
import { NotificationWithDialog } from '../../../components/Notifications';
import { SplashScreen } from '../../../components/SplashScreen';
import { useSessionCheckOnRefresh } from '../../../hooks';
import { addTrailingSlash, componentsAPIInstance } from '../../../utils';
import { scChannel } from '../../Channel';
import ScStorage from '../../Storage';
import {
    persistAuth,
    resetLoginTime,
    resetPersistedValues,
    setLoginTime,
    updateRefreshTokenCount,
} from '../../Store/persist';

import ProfileProvider from './Profile/ProfileProvider';
import { IAuthResponse } from './auth.model';
import authReducer from './auth.reducer';
import AuthContext, {
    ILogoutCleanupList,
    initialAuthState,
    IPasswordChangePayload,
} from './AuthContext';

enum CLEANUPSTATUS {
    INACTIVE = 'INACTIVE',
    INITIATED = 'INITIATED',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    CANCELLED = 'CANCELLED',
}

export interface IAuthProviderProps {
    /**
     * Login URL
     */
    loginUrl: string;
    /**
     * SUBSCRIPTION KEY
     */
    subscriptionKey: string;
    /**
     * Application environment
     */
    environment: string;

    /**
     * APIM Base URL
     */
    apimUrl?: string;
}

const AuthProvider: FC<IAuthProviderProps> = ({
    children,
    loginUrl,
    subscriptionKey,
    environment,
    apimUrl = '',
}) => {
    const { resetSession } = useSessionCheckOnRefresh();
    const userProfileRef = useRef<{ user: () => any }>(null);
    const PASSWORD_CHANGE_SUCCESS_MESSAGE =
        'New password created successfully! You will now be logged out. Please log back in to continue.';
    const [state, dispatch] = useReducer(authReducer, initialAuthState);
    const [isCleanupCompleted, setIsCleanupCompleted] = useState<boolean>(
        false
    );
    const [
        showPasswordChangedConfirmsation,
        setShowPasswordChangedConfirmaiton,
    ] = useState<boolean>(false);
    const [cleanupStatus, setCleanupStatus] = useState<CLEANUPSTATUS>(
        CLEANUPSTATUS.INACTIVE
    );
    const cleanupApiRef = useRef<ILogoutCleanupList[]>([]);

    const updateCleanupApi = ({
        api,
        property,
        method = 'GET',
        isLocalStorage = false,
        localStorageProperties = [],
    }: ILogoutCleanupList) => {
        const isAvailable = cleanupApiRef.current?.filter?.(
            ({ api: availableAPI }: ILogoutCleanupList) => availableAPI === api
        );
        if (isAvailable?.length === 0) {
            cleanupApiRef.current = [
                ...cleanupApiRef.current,
                {
                    api,
                    property,
                    method,
                    isLocalStorage,
                    localStorageProperties,
                },
            ];
        }
    };

    useEffect(() => {
        if (subscriptionKey) {
            componentsAPIInstance.setSubscriptionKey(subscriptionKey);
        } else {
            componentsAPIInstance.resetSubscriptionKey();
        }
    }, [subscriptionKey]);

    useEffect(() => {
        if (environment) {
            componentsAPIInstance.setEnvironment(environment);
        } else {
            componentsAPIInstance.resetEnvironment();
        }
    }, [environment]);

    useEffect(() => {
        if (loginUrl) {
            componentsAPIInstance.setBaseURL(addTrailingSlash(loginUrl));
        } else {
            componentsAPIInstance.resetBaseURL();
        }
    }, [loginUrl]);

    const isValidToken = (accessToken: string): boolean => {
        return !!accessToken;
    };

    const getUserSession = async (token: string) => {
        return new Promise(async (resolve) => {
            const hasXsession = document.cookie
                .split(';')
                .some((cookie: string) => cookie.includes('x-session'));
            if (!hasXsession) {
                try {
                    await axios.post('/api/session-authentication', {
                        token,
                    });
                    resolve(true);
                } catch (ex) {
                    resolve(false);
                }
            } else {
                resolve(true);
            }
        });
    };

    const login = async (email: string, password: string) => {
        try {
            ScStorage().removeItem('validationCollectionFieldForOnboarding');
            const response = await componentsAPIInstance.axios({
                method: 'POST',
                url: `api/Token/AuthenticationToken`,
                data: {
                    username: email,
                    password,
                },
            });

            const {
                PayLoad,
                StatusCode,
                ResponseEnvelope: {
                    ValidationCollection,
                    NotificationCollection,
                },
            }: {
                PayLoad: IAuthResponse;
                StatusCode: number;
                ResponseEnvelope: {
                    ValidationCollection: any[] | null;
                    NotificationCollection: any;
                    MessageInfoCollection: any;
                };
            } = response.data;
            if (
                StatusCode === 200 &&
                PayLoad?.accessToken &&
                PayLoad?.refreshToken &&
                (ValidationCollection === null ||
                    ValidationCollection?.[0].Field ===
                        'RedirectToProfilePage') &&
                NotificationCollection === null
            ) {
                persistAuth(PayLoad);

                ScStorage().setItem(
                    'validationCollectionFieldForOnboarding',
                    ValidationCollection?.[0].Field
                );
                setLoginTime();
                ScStorage().setItem(
                    'activeDistrict',
                    JSON.stringify({
                        attributes: {
                            regionId: [PayLoad?.regionId?.toString()],
                        },
                        groupGuid: PayLoad.groupGuid,
                    })
                );
                componentsAPIInstance.setAccessToken(PayLoad?.accessToken);
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        auth: PayLoad,
                    },
                });
                await getUserSession(PayLoad.accessToken);
                if (
                    ValidationCollection?.[0].Field === 'RedirectToProfilePage'
                ) {
                    window.location.replace('/workspace/profile');
                } else if (
                    window &&
                    window.location &&
                    window.location.search &&
                    window.location.search.includes('path=')
                ) {
                    window.location.replace(
                        `${window.location.search.split('path=')[1]}`
                    );
                }
            } else if (
                StatusCode === 200 &&
                NotificationCollection &&
                NotificationCollection[0].Code === '20'
            ) {
                dispatch({
                    type: 'SET_AUTH_STATUS',
                    payload: {
                        isAuthenticated: false,
                        auth: null,
                        error: NotificationCollection[0].Code,
                        errorMessage: NotificationCollection[0].Description,
                        changeDefaults: false,
                        registeredEmail: undefined,
                        firstToken: null,
                    },
                });
            } else if (
                StatusCode === 200 &&
                ValidationCollection &&
                ValidationCollection[0].Code === '231'
            ) {
                dispatch({
                    type: 'FIRST_LOGIN',
                    payload: {
                        email: email,
                        firstToken: PayLoad?.accessToken,
                    },
                });
            } else {
                dispatch({
                    type: 'SET_AUTH_STATUS',
                    payload: {
                        isAuthenticated: false,
                        auth: null,
                        error: StatusCode,
                        changeDefaults: false,
                        registeredEmail: undefined,
                        firstToken: null,
                        errorMessage: '',
                    },
                });
            }
        } catch (err) {
            dispatch({
                type: 'SET_AUTH_STATUS',
                payload: {
                    isAuthenticated: false,
                    auth: null,
                    error: err,
                    errorMessage: '',
                    changeDefaults: false,
                    registeredEmail: undefined,
                    firstToken: null,
                },
            });
        }
    };
    const handleUserProfileError = (error: AxiosError) => {
        console.log('auth error', error);
        resetSession();
        onLogout();
    };

    const onLogout = async function (skipChannel = false) {
        try {
            const displayDistrictSelectionWarning = ScStorage().getItem(
                'displayDistrictSelectionWarning'
            );
            const selectedDistrict = JSON.parse(
                ScStorage().getItem('activeDistrict') ?? '{}'
            );
            ScStorage().removeItem('allowedRoutes');
            const regionId = selectedDistrict?.attributes?.regionId
                ? selectedDistrict?.attributes?.regionId[0]
                : null;
            if (
                !isNull(regionId) &&
                displayDistrictSelectionWarning !== 'true'
            ) {
                ScStorage().removeItem('displayDistrictSelectionWarning');
                ScStorage().removeItem('settingValueRolesV1');
            }

            if (userProfileRef.current?.user?.()?.UserId) {
                await componentsAPIInstance.axios({
                    method: 'GET',
                    url: `api/Users/Logout`,
                });
            }
            resetLoginTime();
            resetValuesOnLogout();
            if (!skipChannel) {
                scChannel.postMessage('logout');
            }
        } catch (e) {
            resetValuesOnLogout();
        }
    };

    const logout = async function () {
        if (cleanupApiRef?.current?.length > 0) {
            setCleanupStatus(CLEANUPSTATUS.INITIATED);
            const selectedDistrict = JSON.parse(
                ScStorage().getItem('activeDistrict') ?? '{}'
            );
            const regionId = selectedDistrict?.attributes?.regionId
                ? selectedDistrict?.attributes?.regionId[0]
                : 0;
            const items = cleanupApiRef.current.map(
                ({
                    api,
                    property,
                    method,
                    isLocalStorage,
                    localStorageProperties,
                }: ILogoutCleanupList) => {
                    return new Promise(async (resolve, reject) => {
                        try {
                            resetLoginTime();
                            if (!isLocalStorage) {
                                const url = api(ScStorage().getItem(property));
                                await componentsAPIInstance.axios({
                                    method,
                                    url,
                                    headers: {
                                        Authorization: `Bearer ${
                                            ScStorage().getItem(
                                                'accessToken'
                                            ) || state.firstToken
                                        }`,
                                        'Ocp-Apim-Subscription-Key': subscriptionKey,
                                        Environment: environment,
                                        regionId,
                                    },
                                });

                                ScStorage().removeItem(property);
                                resolve(true);
                            } else {
                                localStorageProperties?.forEach(
                                    (currentProperty: string) => {
                                        ScStorage().removeItem(currentProperty);
                                    }
                                );
                                resolve(true);
                            }
                        } catch (e) {
                            reject(e);
                        }
                    });
                }
            );
            Promise.allSettled(items)
                .then(async () => {
                    setCleanupStatus(CLEANUPSTATUS.COMPLETED);
                    setIsCleanupCompleted(true);
                })
                .catch((error) => {
                    console.log('cleanup error', error);
                    setCleanupStatus(CLEANUPSTATUS.FAILED);
                    setIsCleanupCompleted(true);
                });
        } else {
            onLogout();
        }
    };

    useEffect(
        function () {
            if (cleanupApiRef?.current?.length > 0 && isCleanupCompleted) {
                onLogout();
            }
        },
        [isCleanupCompleted]
    );

    const resetValuesOnLogout = () => {
        const isMultiUser = ScStorage().getItem('mutli-user');
        resetPersistedValues();
        componentsAPIInstance.cancelAllRequests();
        componentsAPIInstance.resetAccessToken();
        if (isNil(isMultiUser)) {
            resetSession();
            ScStorage().setItem('totalSessionActive', '0');
            setTimeout(navigateToLogin, 600);
            dispatch({
                type: 'LOGOUT',
            });
        } else {
            sessionStorage.removeItem('mutli-user');
            window.location.reload();
        }
    };

    const navigateToLogin = () => {
        window.location.replace('/login');
    };

    const passwordChange = async (payload: IPasswordChangePayload) => {
        try {
            const params = new URLSearchParams(window.location.search);
            const shortKey = params.get('ShortKey');
            const regionId = Number(params.get('RegionId'));
            const response = await axios({
                method: 'POST',
                url: `${loginUrl}/api/Users/ConfirmPasswordResetEmailAsync`,
                data: {
                    email: state.registeredEmail,
                    regionId: regionId,
                    shortKey: shortKey,
                    ...payload,
                },
                headers: {
                    Authorization: `Bearer ${state.firstToken}`,
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                    Environment: environment,
                    regionId,
                },
            });
            const {
                data: { StatusCode },
            } = response;
            if (StatusCode === 200) {
                setShowPasswordChangedConfirmaiton(true);
                return true;
            } else return false;
        } catch (e) {
            return false;
        }
    };

    const passwordPolicies = async () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const activeDistrict: any = ScStorage().getItem('activeDistrict');
        const regionId =
            queryParameters.get('RegionId') ||
            JSON.parse(activeDistrict).attributes.regionId;
        const pwdPolicyUrl = loginUrl.split('/UserManagement')[0];
        const response = await axios({
            method: 'GET',
            url: `${pwdPolicyUrl}/administration/v1.0/api/Security/PasswordPolicesByRegion`,
            headers: {
                Authorization: `Bearer ${
                    ScStorage().getItem('accessToken') || state.firstToken
                }`,
                'Ocp-Apim-Subscription-Key': subscriptionKey,
                Environment: environment,
                regionId,
            },
        });
        if (response.data.PayLoad && response.data.PayLoad.length > 0) {
            return response.data.PayLoad;
        } else {
            onLogout();
        }
    };

    const broadCastListener = function () {
        scChannel.onmessage = function (event: any) {
            if (event.data === 'logout') {
                onLogout(true);
            }
            if (event.data === 'district-change') {
                window.location.href = '/';
            }
        };
    };
    const initialise = async () => {
        try {
            const accessToken = ScStorage().getItem('accessToken');
            const refreshToken = ScStorage().getItem('refreshToken');
            const expiresIn = Number(ScStorage().getItem('expiresIn')) || 0;
            const refreshExpiresIn =
                Number(ScStorage().getItem('refreshExpiresIn')) || 0;
            const clientSessionIdleTimeout =
                Number(ScStorage().getItem('clientSessionIdleTimeout')) || 0;
            const message = ScStorage().getItem('message') || '';
            const tokenType = ScStorage().getItem('tokenType') || '';
            const loginTime = ScStorage().getItem('loginTime');
            const refreshTimes = JSON.parse(
                ScStorage().getItem('refreshTime') ?? '[]'
            );
            const needFirstTokenLogout =
                moment().diff(moment(loginTime), 'second') >= expiresIn;
            const needRefreshLogoutLogout =
                moment().diff(
                    moment(refreshTimes[refreshTimes.length - 1]),
                    'second'
                ) >= expiresIn ||
                (refreshTimes.length === 10 &&
                    moment().diff(
                        moment(refreshTimes[refreshTimes.length - 1]),
                        'second'
                    ) >= expiresIn);
            const needLogout = needFirstTokenLogout || needRefreshLogoutLogout;
            if (
                !needLogout &&
                accessToken &&
                refreshToken &&
                isValidToken(accessToken) &&
                isValidToken(refreshToken)
            ) {
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        auth: {
                            accessToken,
                            refreshToken,
                            expiresIn,
                            refreshExpiresIn,
                            message,
                            tokenType,
                            clientSessionIdleTimeout,
                        },
                    },
                });
                await getUserSession(accessToken);
            } else {
                if (
                    refreshTimes.length < 10 &&
                    accessToken &&
                    refreshToken &&
                    ScStorage().getItem('email')
                ) {
                    const response = await componentsAPIInstance.axios({
                        method: 'POST',
                        url: `api/Token/RefreshToken`,
                        data: {
                            refreshToken: refreshToken,
                            userName: ScStorage().getItem('email'),
                        },
                    });
                    const {
                        PayLoad,
                        StatusCode,
                    }: {
                        PayLoad: IAuthResponse;
                        StatusCode: number;
                    } = response.data;
                    if (StatusCode === 200 && PayLoad?.accessToken) {
                        const persistancePayLoad = {
                            ...PayLoad,
                        };
                        const sessionIdleTime = ScStorage().getItem(
                            'clientSessionIdleTimeout'
                        );
                        const betaVersion = ScStorage().getItem(
                            'posBetaVersion'
                        );
                        const version = ScStorage().getItem('posVersion');
                        persistancePayLoad.clientSessionIdleTimeout = parseInt(
                            sessionIdleTime ?? '3600'
                        );
                        if (!isNil(version)) {
                            persistancePayLoad.posVersion =
                                ScStorage().getItem('posVersion') ?? undefined;
                        }

                        if (!isNil(betaVersion)) {
                            persistancePayLoad.posBetaVersion =
                                ScStorage().getItem('posBetaVersion') ??
                                undefined;
                        }

                        persistAuth(persistancePayLoad);
                        updateRefreshTokenCount();

                        dispatch({
                            type: 'LOGIN',
                            payload: {
                                auth: persistancePayLoad,
                            },
                        });
                        await getUserSession(persistancePayLoad.accessToken);
                    }
                } else {
                    if (!window.location.href.includes('/login')) {
                        dispatch({
                            type: 'LOGOUT',
                        });
                        onLogout();
                    }
                }
            }
        } catch (err) {
            dispatch({
                type: 'SET_AUTH_STATUS',
                payload: {
                    isAuthenticated: false,
                    auth: null,
                    error: err,
                    errorMessage: '',
                    changeDefaults: false,
                    registeredEmail: undefined,
                    firstToken: null,
                },
            });
            if (!window.location.href.includes('/login')) {
                dispatch({
                    type: 'LOGOUT',
                });
                onLogout();
            }
        }
    };

    const impersonationInitialization = function () {};

    useEffect(() => {
        broadCastListener();
        initialise();
        dispatch({
            type: 'INITIALISE',
        });

        window?.addEventListener?.(
            'sc-impersonation',
            impersonationInitialization
        );

        return () => {
            window?.removeEventListener?.(
                'sc-impersonation',
                impersonationInitialization
            );
        };
    }, []);

    const validatePasswordChangeToken = async (payload: any) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${loginUrl}/api/Users/VerifyToken`,
                params: payload,
                headers: {
                    Authorization: `Bearer ${state.firstToken}`,
                    'Ocp-Apim-Subscription-Key': subscriptionKey,
                    Environment: environment,
                },
            });
            if (
                response.status === 200 &&
                response.data.PayLoad &&
                response.data.PayLoad.length > 0
            ) {
                dispatch({
                    type: 'FIRST_LOGIN',
                    payload: {
                        email: response.data.PayLoad[0].userName,
                        firstToken: response.data.PayLoad[0].accessToken,
                    },
                });
            }
            return response.data;
        } catch (e) {
            return e;
        }
    };

    if (!state.isInitialised) {
        return <SplashScreen />;
    }

    const onCloseCleanup = function () {
        setCleanupStatus(CLEANUPSTATUS.CANCELLED);
        setIsCleanupCompleted(true);
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout,
                passwordChange,
                passwordPolicies,
                validatePasswordChangeToken,
                cleanupApi: cleanupApiRef.current,
                updateCleanupApi,
                environment,
            }}
        >
            {showPasswordChangedConfirmsation && (
                <NotificationWithDialog
                    message={PASSWORD_CHANGE_SUCCESS_MESSAGE}
                    open
                    primaryAction={{
                        callback: () => {
                            setShowPasswordChangedConfirmaiton(false);
                            dispatch({
                                type: 'LOGOUT',
                            });
                            setTimeout(navigateToLogin, 0);
                        },
                        title: 'OK',
                    }}
                />
            )}
            {cleanupStatus === CLEANUPSTATUS.INITIATED && (
                <Dialog
                    open={true}
                    onClose={onCloseCleanup}
                    maxWidth={'md'}
                    id={'cleanup-status'}
                    content={
                        <div>You will now be logged out of SchoolCafé.</div>
                    }
                />
            )}
            {cleanupApiRef?.current?.length === 0 && (
                <ProfileProvider
                    ref={userProfileRef}
                    isAuthenticated={state.isAuthenticated}
                    onError={handleUserProfileError}
                    apimUrl={apimUrl}
                    environment={environment}
                    subscriptionKey={subscriptionKey}
                >
                    {children}
                </ProfileProvider>
            )}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
