import React, { FC, useEffect } from 'react';
import {
    BaseTextFieldProps,
    InputAdornment,
    TextField as MuiTextField,
} from '@material-ui/core';
import clsx from 'clsx';

export interface ITextFieldProps extends BaseTextFieldProps {
    /**
     * Unique id for TextField
     */
    id: string;

    /**
     * Label for TextField
     */
    label: string;

    /**
     * Placeholder for TextField
     */
    placeholder?: string;

    /**
     * defaultValue for TextField
     */
    defaultValue?: any;

    /**
     * Value for TextField
     */
    value?: any;

    /**
     * Disabled for TextField
     */
    disabled?: boolean;

    /**
     * Required for TextField
     */
    required?: boolean;

    /**
     * Required for TextField
     */
    type?: 'email' | 'number' | 'password' | 'tel' | 'text';

    /**
     * ReadOnly for TextField
     */
    readOnly?: boolean;

    /**
     * Error for TextField
     */
    error?: boolean;

    /**
     * Helper Text for TextField
     */
    helperText?: string;

    /**
     * Multi Line for TextField
     */
    multiline?: boolean;

    /**
     * Max Rows for TextField
     */
    rows?: number;

    /**
     * OnChnage Event for TextField
     */
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

    /**
     * Prefix Icon for TextField
     */
    prefixIcon?: React.ReactNode;

    /**
     * Suffix Icon for TextField
     */
    suffixIcon?: React.ReactNode;

    /**
     * Prefix Text for TextField
     */
    prefixText?: string;

    /**
     * Suffix Text for TextField
     */
    suffixText?: string;

    /**
     * Size for TextField
     */
    size?: 'medium' | 'small';

    /**
     * Color for TextField
     */
    color?: 'primary' | 'secondary';

    /**
     * Variant for TextField
     */
    variant?: 'filled' | 'outlined' | 'standard';

    /**
     * Full Width for TextField
     */
    fullWidth?: boolean;

    /**
     * maxlength for TextField
     */
    maxLength?: number;
}

const TextField: FC<ITextFieldProps> = ({
    id,
    label,
    placeholder,
    defaultValue,
    value,
    disabled,
    className,
    required,
    type,
    readOnly,
    error = false,
    helperText,
    multiline = false,
    rows,
    onChange,
    prefixIcon,
    suffixIcon,
    prefixText = '',
    suffixText = '',
    size = 'small',
    color = 'primary',
    variant = 'outlined',
    fullWidth = false,
    maxLength,
    ...rest
}) => {
    const [textFieldValue, setTextFieldValue] = React.useState(value);
    const [textInputProps, setTextInputProps] = React.useState<any>({});

    useEffect(() => {
        setTextFieldValue(value);
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextFieldValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };

    useEffect(() => {
        if (prefixIcon || prefixText) {
            setTextInputProps({
                startAdornment: (
                    <InputAdornment position="start">
                        {prefixIcon ? prefixIcon : prefixText}
                    </InputAdornment>
                ),
            });
        } else if (suffixIcon || suffixText) {
            setTextInputProps({
                endAdornment: (
                    <InputAdornment position="end">
                        {suffixIcon ? suffixIcon : suffixText}
                    </InputAdornment>
                ),
            });
        }
    }, [prefixIcon, suffixIcon, prefixText, suffixText]);

    return (
        <MuiTextField
            id={id}
            label={label}
            defaultValue={defaultValue}
            value={textFieldValue}
            name={label}
            color={color}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
            variant={variant}
            fullWidth={fullWidth}
            onChange={handleChange}
            type={type}
            className={clsx(className)}
            size={size}
            error={error}
            helperText={helperText}
            multiline={multiline}
            rows={rows}
            inputProps={{
                maxLength: maxLength,
                'aria-label': `${label}`,
            }}
            InputProps={{
                readOnly: readOnly,
                ...textInputProps,
            }}
            {...rest}
        />
    );
};

export default TextField;
