import React, { FC, useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { isNull } from 'lodash';

import { Alert } from '../../components/Alert';
import { Button } from '../../components/Button';
import { componentsAPIInstance, isValidEmail } from '../../utils';

const useStyles = makeStyles(() => ({
    box: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
    },
    errorBox: {
        marginTop: 8,
        display: 'block',
        width: '100%',
    },
}));

interface IMessageType {
    type: 'error' | 'success' | null;
    message: string | null;
}

export interface IForgotPasswordProps {
    hideForgotPassword: () => void;
}

const ForgotPassword: FC<IForgotPasswordProps> = ({
    hideForgotPassword,
}: IForgotPasswordProps) => {
    const classes = useStyles();
    const [error, setError] = useState<boolean | null>(null);
    const [message, setMessage] = useState<IMessageType>({
        type: null,
        message: null,
    });
    const [submitted, canBeSubmitted] = useState<boolean>(false);
    const resetEmail = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: async (values) => {
            canBeSubmitted(() => true);
            if (values.email && isValidEmail(values.email)) {
                const response = await componentsAPIInstance.axios({
                    method: 'POST',
                    url: 'api/Users/ForgotPasswordAsync',
                    data: {
                        userEmail: values.email,
                    },
                });
                const {
                    StatusCode,
                    PayLoad,
                    ResponseEnvelope: { MessageInfoCollection },
                } = response.data;
                if (StatusCode === 200) {
                    if (isNull(MessageInfoCollection) && PayLoad) {
                        setError(() => null);
                        setMessage(() => ({
                            type: 'success',
                            message:
                                'An email with instructions to reset your password will be sent to your registered email account.',
                        }));
                        canBeSubmitted(() => false);
                    } else if (
                        MessageInfoCollection &&
                        MessageInfoCollection[0].Description ===
                            'No data found.'
                    ) {
                        setError(() => false);
                        setMessage(() => ({
                            type: 'error',
                            message:
                                'Email did not match our records. Please enter a registered email address.',
                        }));
                        canBeSubmitted(() => false);
                    }
                } else {
                    setMessage(() => ({
                        type: 'error',
                        message:
                            'Something went wrong. Please try again later.',
                    }));
                    canBeSubmitted(() => false);
                }
            } else {
                setError(() => true);
                canBeSubmitted(() => false);
            }
        },
    });

    const resetMessage = () => {
        setMessage(() => ({
            type: null,
            message: null,
        }));
    };

    return (
        <form onSubmit={resetEmail.handleSubmit}>
            {message.message && (
                <div className={classes.errorBox}>
                    <Alert
                        message={message.message || ''}
                        color={message.type || 'default'}
                        onClose={resetMessage}
                    />
                </div>
            )}
            <TextField
                error={error ? true : false}
                helperText={error ? 'Email format is incorrect.' : null}
                fullWidth
                label="Email"
                id="forgotPwdEmail"
                margin="normal"
                name="email"
                autoComplete="email"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                type="text"
                value={resetEmail.values.email}
                variant="outlined"
                color="secondary"
                onChange={resetEmail.handleChange}
            />
            <div className={classes.box}>
                <Button
                    id="password-change-submit"
                    type="submit"
                    color="primary"
                    loading={submitted}
                >
                    Request Password Reset
                </Button>
                <Button
                    id="password-change-cancel"
                    type="button"
                    onClick={hideForgotPassword}
                    style={{
                        marginTop: 10,
                    }}
                >
                    Cancel
                </Button>
            </div>
        </form>
    );
};

export default ForgotPassword;
