import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { getYear } from 'date-fns';

const PROJECT_START_YEAR = 2021;

const Copyright = () => {
    const [displayYear, setDisplayYear] = useState<string | number>();

    useEffect(() => {
        const currentYear = getYear(new Date());
        if (currentYear > PROJECT_START_YEAR) {
            setDisplayYear(`${PROJECT_START_YEAR} - ${currentYear}`);
        } else {
            setDisplayYear(PROJECT_START_YEAR);
        }
    }, []);

    return (
        <footer>
            <Typography
                variant="body2"
                align="center"
                color="textSecondary"
                style={{
                    color: 'black',
                    fontWeight: 500,
                }}
            >
                Copyright &copy; {displayYear} Cybersoft Technologies, Inc. All
                rights reserved.
            </Typography>
        </footer>
    );
};

export default Copyright;
