import type { FC } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core';

import type { ITheme } from '../../core/Providers';
import { AppLoader } from '../Loaders';

const useStyles = makeStyles((theme: ITheme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(3),
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: theme.zIndex.drawer,
    },
}));

const SplashScreen: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppLoader />
        </div>
    );
};

export default SplashScreen;
